import theme_color from 'resources/theme/theme.colors';
import { createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiSlider: {
      defaultProps: {
        disabled: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          width: '150px',
          '& .MuiSlider-track': {
            display: 'none',
          },
          '& .MuiSlider-rail': {
            backgroundColor: 'transparent',
            height: '60%',
            border: '2px solid',
          },
          '& .MuiSlider-thumb': {
            color: typeof ownerState?.defaultValue === 'number' && ownerState.defaultValue >= 0 && ownerState.defaultValue <= 100 ? theme_color.palette.graph.success : theme_color.palette.graph.error,
            '&:before': {
              boxShadow: 'none',
            },
          },
        }),
      },
    },
  },
});

export default theme;
