import { useAuthenticated } from 'hooks';
import { FC } from 'react';

import UserIsLogged from './components/UserIsLogged';
import UserIsNotLogged from './components/UserIsNotLogged';

const IndexHeader: FC = () => {
  const isAuthenticated = useAuthenticated();

  return isAuthenticated ? <UserIsLogged /> : <UserIsNotLogged />;
};

export default IndexHeader;
