import { Box, Divider, Grid, Radio, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GenericFormProps } from 'models/result.patient.model';
import { FC, Fragment } from 'react';

export const GenericMatrixForm: FC<GenericFormProps> = ({ question }) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: `fit-content(40%) repeat(${question.values.length + (question.sub_questions[0].has_comment ? 1 : 0)}, 1fr)`,
            gridGap: '10px',
          }}
        >
          {/* header */}
          <Typography sx={{ pr: 4 }}>{question.name}</Typography>
          {question.values.map((value, index) => (
            <Typography key={index}>{value.name}</Typography>
          ))}
          <Divider sx={{ gridColumn: '1/-1', borderColor: 'primary.main' }} />
          {/* body */}
          {question.sub_questions.map((row, index, array) => (
            <Fragment key={index}>
              <Typography alignSelf='center'>{row.name}</Typography>
              {question.values.map((value, index) => {
                return (
                  <Radio
                    key={index}
                    value={value.id}
                    checked={value.id === Number(row.answers[0])}
                    name={value.name}
                    sx={{
                      width: '100%',
                      justifyContent: 'left',
                    }}
                    disabled
                  />
                );
              })}
              {question.values.length === 0 && (
                <TextField
                  fullWidth
                  variant='standard'
                  InputLabelProps={{
                    sx: { p: 0 },
                  }}
                  value={row.answers[0] ?? ''}
                  disabled
                />
              )}
              {row.has_comment && (
                <TextField
                  label={t('results.details.remarksLabel')}
                  fullWidth
                  variant='standard'
                  InputLabelProps={{
                    sx: { p: 0 },
                  }}
                  value={row.answer_comment ?? ''}
                  disabled
                />
              )}
              {index + 1 !== array.length && <Divider sx={{ gridColumn: '1/-1' }} />}
            </Fragment>
          ))}
        </Box>
      </Grid>
      {question.has_comment && (
        <Grid item xs={12}>
          <TextField
            multiline
            label={t('results.details.remarksLabel')}
            minRows={4}
            fullWidth
            variant='outlined'
            InputLabelProps={{
              sx: { p: 0 },
            }}
            value={question.answer_comment ?? ''}
            disabled
          />
        </Grid>
      )}
    </Grid>
  );
};
