import { Card, CardContent, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Patient } from 'models/patients.model';
import { FC, useState } from 'react';
import Dossiers from './Dossiers';
import Prescriptions from './Prescriptions';
import { useIsAdmin, useIsLaboratory } from 'hooks';

export interface BodyProps {
  patient: Patient;
}

export type PatientDetailsTab = 'RECORDS' | 'PRESCRIPTIONS';

const Body: FC<BodyProps> = ({ patient }) => {
  const { t } = useTranslation();
  const initialTab: PatientDetailsTab = 'RECORDS';
  const [selectedTab, setSelectedTab] = useState<PatientDetailsTab>(initialTab);
  const isLaboratory = useIsLaboratory();
  const isAdmin = useIsAdmin();

  const handleChange = (event: React.SyntheticEvent, selectedTab: PatientDetailsTab) => {
    setSelectedTab(selectedTab);
  };

  return (
    <Card>
      <CardContent>
        {isLaboratory || isAdmin ? (
          <Dossiers patient={patient} />
        ) : (
          <>
            <Tabs value={selectedTab} onChange={handleChange} variant='scrollable' sx={{ pb: 4 }}>
              <Tab label={t('patient_details.tab.record.title')} value={'RECORDS'} />
              <Tab label={t('patient_details.tab.prescription.title')} value={'PRESCRIPTIONS'} />
            </Tabs>
            {selectedTab === 'RECORDS' ? <Dossiers patient={patient} /> : <Prescriptions patient={patient} />}
          </>
        )}
      </CardContent>
    </Card>
  );
};
export default Body;
