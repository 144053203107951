import { Box, Card, CardContent, CardHeader, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, Typography } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import PatientDetails from 'components/patient-details';
import { useNotification, useQuery } from 'hooks';
import { Crosstab } from 'models/analysis.model';
import { Patient } from 'models/patients.model';
import { Record } from 'models/record.model';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { tryCatch } from 'utils';
import { convertDate } from 'utils/func/Date.func';
import ResultsCrosstabAccordions from './components/ResultsCrosstabAccordions';

const PatientHistory: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const queryParams = useQuery();
  const { notification } = useNotification();

  const [patient, setPatient] = useState<Patient>();
  const [patientHistory, setPatientHistory] = useState<Crosstab>();
  const [referenceLabels, setReferenceLabels] = useState<string[]>([]);
  const [referenceValues, setReferenceValues] = useState<string[]>([]);
  const [referenceList, setReferenceList] = useState<Record[]>();

  const convertRefListToDates = (references: string | string[]) => {
    return (referenceList ?? [])
      .filter((ref) => (typeof references === 'string' ? ref.reference === references : references.includes(ref.reference)))
      .map((ref) => convertDate(ref.sampling_time, true));
  };

  const handleChange = (event: SelectChangeEvent<typeof referenceLabels>) => {
    const {
      target: { value },
    } = event;
    const refDates = convertRefListToDates(value);
    setReferenceLabels(refDates);
    setReferenceValues(typeof value === 'string' ? value.split(',') : value);
  };

  const getCrosstab = useCallback(
    (patient: Patient, references: string[]) => {
      tryCatch(
        () =>
          api.results.getResultsCrosstabByPatientId(patient.id, references).then((response: Crosstab) => {
            setPatientHistory(response);
          }),
        (error) => notification(t('patient_details.history.notification.error'), 'error'),
      );
    },
    [notification, t],
  );

  useEffect(() => {
    if (id) {
      tryCatch(
        () =>
          api.patients.getPatientDetails(+id).then((response: Patient) => {
            setPatient(response);
          }),
        () => notification(t('patient_details.notification.error'), 'error'),
      );
      tryCatch(
        () =>
          api.results.getResults(9999, 0, +id).then((res) => {
            setReferenceList(res.results);
            const references: string[] = (res.results ?? []).map((dossier) => dossier.reference).slice(0, 3);
            const ref = queryParams.get('reference');
            if (ref && !references.includes(ref)) {
              references.push(ref);
            }
            const refDates = convertRefListToDates(references);
            setReferenceLabels(refDates);
            setReferenceValues(references);
          }),
        () => notification(t('patient_details.history.notification.error'), 'error'),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, queryParams]);

  useEffect(() => {
    if (patient) {
      getCrosstab(patient, referenceValues);
    }
  }, [getCrosstab, patient, referenceValues]);

  return (
    <BodyTemplate title={t('bodyTemplate.doctor.patients')} backButton>
      <Grid container columnSpacing={2} rowSpacing={3}>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <PatientDetails patient={patient} />
          </Grid>
          <Grid item xs={12}>
            {patientHistory && (
              <Card>
                <CardHeader
                  title={
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant='h2' mb={4}>
                        {t('bodyTemplate.patient.crosstab')}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* TODO: Btn has been hide because request to download pdf file is missing */}
                        {/* <Button variant="outlined" sx={{ mr: 1 }}>
                          {t('common.action.pdfDownload')}
                        </Button> */}
                        <FormControl sx={{ m: 1, width: 300 }}>
                          <InputLabel sx={{ pl: 0 }}>{t('patient_details.history.addResults')}</InputLabel>
                          <Select
                            multiple
                            value={referenceValues}
                            onChange={handleChange}
                            input={<OutlinedInput label={t('patient_details.history.addResults')} />}
                            renderValue={(selected) => convertRefListToDates(selected).join(', ')}
                          >
                            {referenceList &&
                              referenceList.map((record, key) => (
                                <MenuItem key={key} value={record.reference}>
                                  <Checkbox checked={referenceValues.indexOf(record.reference) > -1} sx={{ mr: 1 }} />
                                  <ListItemText primary={convertDate(record.sampling_time, true)} />
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                  }
                />
                <CardContent>
                  <ResultsCrosstabAccordions results={patientHistory} />
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default PatientHistory;
