import { Box, Button, Typography } from '@mui/material';
import ContextLink from 'components/_base/ContextLink';
import { useNotification } from 'hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onClose: () => void;
};

const AddRelativeConfirmationDialog: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const { notification } = useNotification();
  return (
    <Box
      sx={{
        m: 5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant='h2'>{t('profile.relatives.add.form.dialog.confirmation.title')}</Typography>
      <Typography sx={{ m: 2, whiteSpace: 'pre-line' }}>{t('profile.relatives.add.form.dialog.confirmation.text')}</Typography>
      <ContextLink to='PROFILE'>
        <Button
          onClick={() => {
            onClose();
            notification(t('profile.relatives.add.form.dialog.confirmation.notification.success'), 'success');
          }}
        >
          {t('profile.relatives.add.form.dialog.confirmation.buttons.confirm')}
        </Button>
      </ContextLink>
    </Box>
  );
};

export default AddRelativeConfirmationDialog;
