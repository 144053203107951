import { format, Locale, parseISO } from 'date-fns';
import { de, enGB, fr, pt } from 'date-fns/locale';
import i18n from 'i18next';
import { MIN_DATE } from 'utils/Constants';

const getDateFnsLocale = (): Locale => {
  const locale = i18n.language.split('-').shift();
  switch (locale) {
    case 'de':
      return de;
    case 'en':
      return enGB;
    case 'pt':
      return pt;
    default:
      return fr;
  }
};

export const waitFormatHours = (number: number): string => {
  const tmp = number.toString();
  return [tmp.slice(0, -2), tmp.slice(-2)].join(':');
};

export const convertDate = (date: Date | string | undefined | null, fullDate?: boolean, dateFormat: string = 'dd/MM/yyyy', timeFormat: string = 'HH:mm'): string => {
  if (!date) {
    return '';
  }
  let _date = date;
  if (_date instanceof Date) {
    _date = _date.toISOString();
  }
  if (fullDate) {
    dateFormat += ` ${timeFormat}`;
  }

  return format(parseISO(_date), dateFormat, {
    locale: getDateFnsLocale(),
  });
};

export const convertSecToMin = (seconds: number) => {
  return new Date(seconds * 1000).toISOString().slice(14, 19);
};

const isDateBetweenMinAndMax = (d: Date) => {
  const date = d;

  const start = new Date(MIN_DATE);
  const end = new Date();

  if (date > start && date < end) {
    return true;
  } else {
    return false;
  }
};

export const isManualValidDate = (d: string | null | Date | undefined) => {
  if (!d) return false;
  else if (typeof d === 'string') d = new Date(d);
  return !isNaN(d.getTime()) && isDateBetweenMinAndMax(d);
};
