import { Button, Fade, Menu, MenuItem } from '@mui/material';
import { useAuthenticated } from 'hooks';
import { FC, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { availableLanguages } from 'i18n';

const LanguageSwitcher: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [active, setActive] = useState(true);
  const { pathname } = useLocation();
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setActive(!active);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setActive(!active);
  };
  const { i18n } = useTranslation();

  const isAuthenticated = useAuthenticated();

  return (
    <>
      <Button
        id='fade-button'
        onClick={handleClick}
        variant={isAuthenticated ? 'header_light' : 'header_dark'}
        endIcon={
          <ExpandMoreIcon
            sx={{
              fontSize: 16,
              transform: `rotate(${active ? 0 : 180}deg)`,
            }}
          />
        }
      >
        {i18n.language.split('-').shift()?.toUpperCase()}
      </Button>

      <Menu id='fade-menu' anchorEl={anchorEl} open={open} onClick={handleClose} onClose={handleClose} TransitionComponent={Fade}>
        {availableLanguages.map((language, key) => (
          <Link
            style={{
              textDecoration: 'none',
              color: 'black',
            }}
            key={key}
            to={`/${pathname.replace(/^\/.{2}\/(.*)$/, `${language.split('-').shift()}/$1`)}`}
            lang={language.split('-').shift()}
          >
            <MenuItem>{language.split('-').shift()?.toUpperCase()}</MenuItem>
          </Link>
        ))}
      </Menu>
    </>
  );
};

export default LanguageSwitcher;
