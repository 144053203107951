// const dec2hex = (dec: number) => {

import { encode as base64encode } from 'base64-arraybuffer';
import config from 'config';
import { sha256 } from 'js-sha256';
import { OAuthParameters } from 'models/eSante.model';
import { nanoid } from 'nanoid';
import { pipe, replace, dissoc, mapObjIndexed, values, concat } from 'ramda';
import { CONST_ESANTE_ACCESS_TOKEN, CONST_ESANTE_PKCE_TOKEN, CONST_ESANTE_REFRESH_TOKEN, CONST_ESANTE_TOKEN_EXPIRATION_TIME } from 'utils/Constants';

export const storeTokens = (accessToken: string, refreshToken: string, expiresIn: number) => {
  localStorage.setItem(CONST_ESANTE_ACCESS_TOKEN, accessToken);
  localStorage.setItem(CONST_ESANTE_REFRESH_TOKEN, refreshToken);
  const expirationTime = Math.floor(Date.now() / 1000) + expiresIn - 1;
  localStorage.setItem(CONST_ESANTE_TOKEN_EXPIRATION_TIME, expirationTime.toString());
};

// API Endpoints
export const eSanteAutorizationUri = `/api/v2/oauth2/authorize`;
export const eSanteRedirectUri = `callback/aes-eprescription`;
export const eSanteGetToken = `/api/v2/oauth2/token`;

export const generateCodeVerifier = (): string => nanoid(43);
export const generateCodeChallenge = (codeVerifier: string): string => pipe(sha256.arrayBuffer, base64encode, replace(/\+/g, '-'), replace(/\//g, '_'), replace(/=/g, ''))(codeVerifier);

const buildAuthUrl = (baseUrl: string, parameters: OAuthParameters): string => {
  const encodedParameters = pipe(
    dissoc('scope'),
    mapObjIndexed((value: string, key: string, obj: any) => `${key}=${encodeURIComponent(value)}`),
    values,
    concat([`scope=${parameters.scope}`]),
  )(parameters);

  return `${baseUrl}?${encodedParameters.join('&')}`;
};

export const buildingUrl = () => {
  const codeVerifier = generateCodeVerifier();
  localStorage.setItem(CONST_ESANTE_PKCE_TOKEN, codeVerifier);

  const baseUrl = `${config.ePrescription.apiUrl}${eSanteAutorizationUri}`;

  const queryParams = {
    response_type: 'code',
    client_id: config.ePrescription.clientId || '',
    state: '1234',
    scope: 'openid',
    code_challenge_method: 'S256',
    redirect_uri: `${window.location.origin}/${eSanteRedirectUri}`,
    code_challenge: generateCodeChallenge(codeVerifier),
  };

  return buildAuthUrl(baseUrl, queryParams);
};
