import theme_color from 'resources/theme/theme.colors';

import { createTheme } from '@mui/material';
import { createBreakpoints } from '@mui/system';

const breakpoints = createBreakpoints({});

const theme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: theme_color.palette.background.paper,
          borderRadius: '4px',
          boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.16)',
          padding: '32px',
          [breakpoints.down('md')]: {
            padding: '16px',
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '0px',
          '&:last-child': {
            padding: '0px',
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '0px',
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          padding: '0px',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '0px',
        },
      },
    },
  },
});

// theme.components?.MuiCard?.defaultProps? = {
// 	[theme.breakpoints.down('md')]: {
// 		//fontSize: 20
// 	}
// };

export default theme;
