import { Box, Button, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import ContextLink from 'components/_base/ContextLink';
import { useIsMobileView } from 'hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const ValidationConfirm: FC<{ patientId: string; idPrescription: string | undefined }> = ({ patientId, idPrescription }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant='h1' gutterBottom>
            {t('prescriptions_making.validation.generatedPrescription.title')}
          </Typography>
        }
      />
      <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid container>
          <Grid item xs={12} md={6} sx={{ alignSelf: 'center' }}>
            <Typography>{t('prescriptions_making.validation.generatedPrescription.fixAppointment')}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display={!isMobileView ? 'flex' : ''} justifyContent='flex-end'>
              <ContextLink to='DOCTOR_APPOINTMENT_PRESCRIPTION' params={{ id: +patientId, prescId: +idPrescription! }}>
                <Button variant='contained' sx={{ m: 1 }}>
                  {t('prescriptions_making.validation.generatedPrescription.btnAppointment')}
                </Button>
              </ContextLink>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ValidationConfirm;
