import { Box, Button, Card, CardActions, CardContent, List, Typography } from '@mui/material';
import api from 'api';
import ContextLink from 'components/_base/ContextLink';
import { useNotification } from 'hooks';
import { EudccCertificate } from 'models/eudcc-certificate.model';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store/reducers';
import { convertDate } from 'utils/func/Date.func';
import HomepageListingItems from './HomepageListingItems';

const HomepageCertificates: FC = () => {
  const { t } = useTranslation();
  const [certificates, setCertificates] = useState<EudccCertificate[]>([]);
  const currentUser = useSelector((state: GlobalState) => state.session.currentUser);
  const { notification } = useNotification();
  const [error, setError] = useState(false);

  useEffect(() => {
    currentUser &&
      api.eudccCertificates
        .getEUDCCCertifiates(3, 1, currentUser.id)
        .then((res) => setCertificates(res.results))
        .catch(() => {
          setError(true);
          notification(t('homepage.certificate.notification.error'), 'error');
        });
  }, [currentUser, notification, t]);

  return (
    <Card
      sx={{
        display: 'grid',
        width: '100%',
        minHeight: (theme) => theme.spacing(25),
      }}
    >
      <CardContent>
        <Typography variant='h2'>{t('homepage.certificate.title')}</Typography>
        <List dense>
          {certificates && certificates.length > 0 ? (
            certificates.map((certificate, key) => {
              return (
                <HomepageListingItems
                  title={
                    <ContextLink
                      to='EUDCC_CERTIFICATE_DETAILS'
                      params={{
                        id: certificate.reference,
                        fileName: certificate.report.slice(0, -4),
                      }}
                    >
                      <Box component={Button} variant='header_light' display='flex'>
                        <Typography textAlign='left'>{`${t(`homepage.certificate.label.${certificate.report_type}`)} - ${convertDate(certificate.report_time, false, 'PPPp')}`}</Typography>
                      </Box>
                    </ContextLink>
                  }
                  key={key}
                />
              );
            })
          ) : error ? (
            <Typography>{t('homepage.certificate.notification.error')}</Typography>
          ) : (
            <Typography>{t('homepage.certificate.noCertificate')}</Typography>
          )}
        </List>
      </CardContent>
      <CardActions sx={{ alignItems: 'flex-end' }}>
        <Box width='100%' display='flex' justifyContent='flex-end'>
          <ContextLink to='EUDCC_CERTIFICATES'>
            <Button variant='contained'>{t('homepage.certificate.buttonLabel')}</Button>
          </ContextLink>
        </Box>
      </CardActions>
    </Card>
  );
};

export default HomepageCertificates;
