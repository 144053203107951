import MyLocationIcon from '@mui/icons-material/MyLocation';
import { LoadingButton } from '@mui/lab';
import { Backdrop, Box, Card, CardContent, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { useIsMobileView, useLocale, useNotification } from 'hooks';
import { Center, CenterIncludedFile, CenterIncludedService, CentersFormValues, CityChoice, ScheduleChoice, SpecialityChoice } from 'models/centers.model';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import api from '../../../api';
import CenterDetails from './components/CenterDetails';
import CentersForm from './components/CentersForm';
import CentersList from './components/CentersList';
import MapCenters from './components/MapCenters';

const ScheduleChoices: ScheduleChoice[] = [
  { value: 'MOR', label: 'Matin' },
  { value: 'AFT', label: 'Après-midi' },
  { value: 'SAT', label: 'Samedi' },
];

const defaultValues: CentersFormValues = {
  language: 'fr',
  city: '',
  speciality: '',
  schedule: '',
  PRMAccessRequired: false,
  publicParkingRequired: false,
};

const Centers: FC = () => {
  const isMobileView = useIsMobileView();
  //  Translations
  const { t } = useTranslation();
  const locale = useLocale();

  // User's current position
  const [myPosition, setMyPosition] = useState<{
    lat: number;
    lng: number;
  }>();

  // Form
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [isLoadingGeolocation, setLoadingGeolocation] = useState(false);
  const { register, watch, setValue, getValues } = useForm<CentersFormValues>({
    defaultValues: { ...defaultValues, language: locale },
  });
  const [cities, setCities] = useState<CityChoice[]>([]);
  const [specialities, setSpecialities] = useState<SpecialityChoice[]>([]);
  const [schedules, setSchedules] = useState<ScheduleChoice[]>([]);
  const { notification } = useNotification();

  // Data to display
  const [centersInfo, setCentersInfo] = useState<
    {
      center: Center;
      file: CenterIncludedFile;
      services: CenterIncludedService[];
    }[]
  >([]);
  const [markersState, setMarkersState] = useState<boolean[]>(centersInfo.map((position) => false));

  const [selectedCenterInfo, setSelectedCenterInfo] = useState<{
    center: Center;
    file: CenterIncludedFile;
    services: CenterIncludedService[];
  }>();

  const centerDetailsRef = useRef<HTMLDivElement>(null);

  // Init data and form options
  useEffect(() => {
    setSchedules(ScheduleChoices);
    api.bnl
      .searchCenters(getValues('language'))
      .then(async (val) => {
        setCentersInfo(val);
        setCities(
          Array.from(new Set(val.map((info) => info.center.attributes.field_prelevement_ville)))
            .sort((a, b) => a.localeCompare(b))
            .map((info) => ({ value: info, label: info })),
        );
        setSpecialities(
          Array.from(new Set(val.map((info) => info.services.map((service) => service.attributes.name)).flat()))
            .sort((a, b) => a.localeCompare(b))
            .map((info) => ({ value: info, label: info })),
        );
        setSubmitting(false);
      })
      .catch(() => notification(t('centers.notification.error'), 'error'));
  }, [getValues, notification, t]);

  useEffect(() => {
    setValue('language', locale);
  }, [locale, setValue]);

  // Update data on form change
  useEffect(() => {
    const subscription = watch((data) => {
      setSubmitting(true);
      api.bnl.searchCenters(data.language || 'fr', data.city, data.speciality, data.PRMAccessRequired, data.publicParkingRequired).then(async (val) => {
        const filteredVal = val.filter((info) => {
          switch (data.schedule) {
            case 'SAT':
              return info.center.attributes.field_prelevement_horaires.find((horaire) => horaire.day === 6);
            case 'MOR':
              return info.center.attributes.field_prelevement_horaires.find((horaire) => horaire.starthours < 1200);
            case 'AFT':
              return info.center.attributes.field_prelevement_horaires.find((horaire) => horaire.endhours > 1200);
            default:
              return true;
          }
        });
        setCentersInfo(filteredVal);
        setSubmitting(false);
      });
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  // Update markers on data change
  useEffect(() => {
    setMarkersState(centersInfo.map((position) => false));
  }, [centersInfo]);

  useEffect(() => {
    if (selectedCenterInfo && !isMobileView && centerDetailsRef.current) {
      centerDetailsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [centerDetailsRef, isMobileView, selectedCenterInfo]);

  const getCurrentPosition = useCallback(() => {
    setLoadingGeolocation(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setMyPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setLoadingGeolocation(false);
        },
        (positionError) => {
          notification(t('centers.notification.errorGeolocation'), 'error');
          setLoadingGeolocation(false);
        },
      );
    } else {
      notification(t('centers.notification.geolocationNotActivated'), 'error');
      setLoadingGeolocation(false);
    }
  }, [notification, t]);

  const handleOpenMapMarker = (index: number) => {
    const newMarkers = Array(markersState.length).fill(false);
    newMarkers[index] = true;
    setMarkersState(newMarkers);
    setSelectedCenterInfo(centersInfo[index]);
  };
  const handleCloseMapMarker = () => {
    const newMarkers = Array(markersState.length).fill(false);
    setMarkersState(newMarkers);
  };

  return (
    <BodyTemplate title={t('bodyTemplate.centers')}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant='h2'>{t('centers.title')}</Typography>
            </Grid>
            <Grid item md={6} textAlign='right' display={{ xs: 'none', md: 'block' }}>
              <LoadingButton variant='contained' endIcon={<MyLocationIcon />} onClick={getCurrentPosition} disabled={isLoadingGeolocation} loading={isLoadingGeolocation}>
                {t('centers.geolocate')}
              </LoadingButton>
            </Grid>
            <Grid item xs={12}>
              <CentersForm cities={cities} specialities={specialities} schedules={schedules} register={register} />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={0}
                sx={{
                  ...(!isMobileView && { minHeight: '50vh' }),
                }}
              >
                {!isMobileView && (
                  <Grid item xs={9} sx={{ borderRadius: 2, overflow: 'hidden' }}>
                    <MapCenters centersInfo={centersInfo} myPosition={myPosition} markersState={markersState} handleOpenMapMarker={handleOpenMapMarker} handleCloseMapMarker={handleCloseMapMarker} />
                  </Grid>
                )}
                <Grid item xs={isMobileView ? 12 : 3}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                    }}
                  >
                    <CentersList centersInfo={centersInfo} handleOpenMapMarker={handleOpenMapMarker} />
                  </Box>
                </Grid>
              </Grid>
              <Backdrop
                open={isSubmitting}
                sx={{
                  position: 'absolute',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
              >
                <CircularProgress color='inherit' />
              </Backdrop>
            </Grid>
            {selectedCenterInfo && !isMobileView && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} ref={centerDetailsRef}>
                  <CenterDetails centerInfo={selectedCenterInfo} />
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    </BodyTemplate>
  );
};

export default Centers;
