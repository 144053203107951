import { Address } from 'models/profile.model';

export const addressToString = (address: Address): string => {
  return address
    ? `${address.company ? address.company + ' -' : ''} ${address.number ? address.number + ', ' : ''} ${address.street ?? ''} ${address.complement ?? ''} ${address.postal_code ?? ''} ${
        address.city ?? ''
      } ${address.country ?? ''}`
    : 'no address';
};

export const isAddress = (obj: any): obj is Address => {
  const address = obj as Address & { city_name?: string };
  return (
    !!address &&
    address.street !== undefined &&
    address.street !== '' &&
    address.street !== null &&
    address.zip_code !== undefined &&
    address.zip_code !== '' &&
    address.zip_code !== null &&
    ((address.city !== undefined && address.city !== '' && address.city !== null) || (address.city_name !== undefined && address.city_name !== '' && address.city_name !== null)) &&
    address.country_iso !== undefined &&
    address.country_iso !== '' &&
    address.country_iso !== null
  );
};
