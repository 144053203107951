import { APIConfiguration, axiosePrescription } from 'api';
import config from 'config';
import { BaseService } from './base.service';
import { defaultTo } from 'ramda';
import { eSanteRedirectUri } from 'utils/func/eSante.func';

class ESanteService extends BaseService {
  async getToken(code: string | null, code_verifier: string | null) {
    const params = new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: config.ePrescription.clientId || '',
      client_secret: config.ePrescription.clientSecret || '',
      redirect_uri: `${window.location.origin}/${eSanteRedirectUri}`,
      code: defaultTo('', code),
      code_verifier: defaultTo('', code_verifier),
    });

    const headers = {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    };
    const data = axiosePrescription.post(`${this.apiConfig.EPRESCRIPTION.TOKEN_GENERATE}`, params, headers);

    this.log(`getToken()`);

    return data;
  }
}

export default (apiConfig: APIConfiguration) => {
  return new ESanteService(apiConfig);
};
