import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, CardContent, CardHeader, Dialog, DialogContent, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { RelationUsualInfo } from 'models/profile.model';
import { FC, useState } from 'react';
import { convertDate } from 'utils/func/Date.func';
import AccessCardModal from './AccessCardModal';

interface AccessCardProps {
  title: any;
  accessers: RelationUsualInfo[];
}

const AccessCard: FC<AccessCardProps> = ({ title, accessers }) => {
  const [modalRemoveRelative, openModalRemoveRelative] = useState(false);
  const [currentRelative, setCurrentRelative] = useState<RelationUsualInfo>({
    id: 0,
    first_name: '',
    last_name: '',
    birth_date: '',
    invitationId: 0,
  });

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <TableContainer component={Box}>
          <Table>
            <TableBody>
              {accessers.map((grantedAccess, i) => {
                return (
                  <TableRow hover key={grantedAccess.id}>
                    <TableCell>
                      <Typography variant='body1'>{`${grantedAccess.last_name} ${grantedAccess.first_name} ${
                        grantedAccess.birth_date ? `- ${convertDate(grantedAccess.birth_date)}` : ''
                      }`}</Typography>
                    </TableCell>
                    <TableCell align='right'>
                      <IconButton
                        onClick={() => {
                          openModalRemoveRelative(true);
                          setCurrentRelative(grantedAccess);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={modalRemoveRelative} onClose={() => openModalRemoveRelative(false)} fullWidth>
          <DialogContent>
            <AccessCardModal relative={currentRelative} closeModal={() => openModalRemoveRelative(false)} />
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default AccessCard;
