import { Box, Button, Card, CardContent, Grid, Tooltip } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import ContextLink from 'components/_base/ContextLink';
import PatientDetails from 'components/patient-details';
import { useAuthenticatedUser, useContextRedirection, useIsAdmin, useIsLaboratory, useNotification } from 'hooks';
import { Patient } from 'models/patients.model';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { canMakeAppointmentForPatient } from 'utils/func/Appointment.func';
import { PrescriptionRequestMaker } from 'utils/func/Prescription.func';
import RoutePaths from 'utils/RoutePaths';
import Body from './components/Body';

const PatientDetailsPage: FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [patient, setPatient] = useState<Patient>();
  const { notification } = useNotification();
  const authenticatedUser = useAuthenticatedUser();
  const navigate = useContextRedirection();
  const isLaboratory = useIsLaboratory();
  const isAdmin = useIsAdmin();

  useEffect(() => {
    id &&
      api.patients
        .getPatientDetails(+id)
        .then((response: Patient) => {
          setPatient(response);
        })
        .catch(() => {
          notification(t('patient_details.notification.error'), 'error');
          navigate(RoutePaths['NOT_FOUND']);
        });
  }, [id, notification, t, navigate]);

  return (
    <BodyTemplate title={t('bodyTemplate.doctor.patients')}>
      <Grid container columnSpacing={2} rowSpacing={3}>
        <Grid container item xs={12}>
          <Grid item xs={12} md={9} display='grid' alignItems='stretch'>
            <PatientDetails patient={patient} />
          </Grid>
          <Grid item xs={12} md={3} display='grid' alignItems='stretch'>
            <Card>
              <CardContent>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateRows: 'repeat(3, 1fr)',
                    gap: (theme) => theme.spacing(1),
                    height: '100%',
                  }}
                >
                  <ContextLink to={`DOCTOR_PATIENTS_CROSSTAB`} params={patient && { id: patient.id.toString() }}>
                    <Button type='button' variant='outlined' fullWidth>
                      {t('bodyTemplate.patient.crosstab')}
                    </Button>
                  </ContextLink>
                  {!isLaboratory && !isAdmin && (
                    <>
                      {patient && canMakeAppointmentForPatient(patient) ? (
                        <ContextLink to={`DOCTOR_APPOINTMENT`} params={patient && { id: patient.id }}>
                          <Button type='button' variant='outlined' fullWidth>
                            {t('patient_details.actions.appointment')}
                          </Button>
                        </ContextLink>
                      ) : (
                        <Tooltip title={t('appointment.tooltip.incompleteProfil')}>
                          <span>
                            <Button type='button' variant='outlined' fullWidth disabled={true}>
                              {t('patient_details.actions.appointment')}
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                      <Button
                        type='button'
                        variant='outlined'
                        fullWidth
                        onClick={() => {
                          authenticatedUser && patient && id && PrescriptionRequestMaker(+id, navigate);
                        }}
                      >
                        {t('common.action.makePrescription')}
                      </Button>
                    </>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {patient && <Body patient={patient} />}
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};
export default PatientDetailsPage;
