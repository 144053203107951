import { ReduxThunkAction } from 'utils';

export enum NotificationsActionType {
  DISPLAY_NOTIFICATION = 'DISPLAY_NOTIFICATION',
  RESET_NOTIFICATION = 'RESET_NOTIFICATION',
}

export type AlertColor = 'success' | 'info' | 'warning' | 'error';

export type NotificationsContent = {
  type: AlertColor;
  message: string;
  setButton?: boolean;
  buttonLabel?: string;
};

export type NotificationsAction =
  | {
      type: NotificationsActionType.DISPLAY_NOTIFICATION;
      isNotification: boolean;
      content?: NotificationsContent;
    }
  | { type: NotificationsActionType.RESET_NOTIFICATION };

export const displayNotification = (content: NotificationsContent): NotificationsAction => ({
  type: NotificationsActionType.DISPLAY_NOTIFICATION,
  isNotification: true,
  content: content,
});

export const resetNotification = (): NotificationsAction => ({
  type: NotificationsActionType.RESET_NOTIFICATION,
});

export const setNotificiation =
  (content: NotificationsContent): ReduxThunkAction =>
  async (dispatch) => {
    dispatch(displayNotification(content));
    const resetTimer = setTimeout(() => {
      dispatch(resetNotification());
    }, 5000);
    return () => clearTimeout(resetTimer);
  };
