import { Box, CardContent, Skeleton } from '@mui/material';
import { FC } from 'react';

const ResultViewerSkeleton: FC = () => {
  return (
    <CardContent>
      <Box display='flex' justifyContent='flex-end' mb={2}>
        <Skeleton
          variant='rectangular'
          height={48}
          width={212}
          sx={{
            borderRadius: 4,
          }}
        />
      </Box>
      <Skeleton variant='rectangular' width='100%' height={750} />
    </CardContent>
  );
};

export default ResultViewerSkeleton;
