import { Box, Button, Card, CardContent, CardHeader, Checkbox, Dialog, FormControlLabel, FormGroup, Grid, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import PhoneInput from 'components/phone-input';
import { useIsMobileView, useNotification } from 'hooks';
import { Gender } from 'models/profile.model';
import { AddRelativeRequest } from 'models/user.model';
import { FC, useCallback, useState } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { ReactComponent as AddIcon } from 'resources/icons/add.svg';
import { convertDate } from 'utils/func/Date.func';
import { blobToBase64 } from 'utils/func/File.func';
import { sexes } from 'models/patients.model';
import { isManualValidDate } from 'utils/func/Date.func';
import api from 'api';
import AddRelativeConfirmationDialog from './AddRelativeConfirmation.dialog';
import GenericDatePicker from 'components/date-picker';
import Files from './relativesFormFiles';
import { useTranslation } from 'react-i18next';

const AddRelativeForm: FC = () => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const addRelativeDefaultValue = {
    first_name: '',
    last_name: '',
    gender: Gender.FEMALE,
    cns: '',
    birth_date: new Date(),
    email: '',
    mobile_phone: '',
    files: [{}],
  };
  const [confirmationDialogOpened, setConfirmationDialogOpened] = useState(false);
  const { register, control, handleSubmit, setValue, getValues, formState } = useForm({
    defaultValues: {
      minor: false,
      agree: false,
      addRelative: [addRelativeDefaultValue],
    },
  });
  const { notification } = useNotification();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'addRelative',
  });
  const onSubmit: SubmitHandler<any> = useCallback(
    async (data) => {
      const request: AddRelativeRequest = {
        minor: data.minor,
        persons: data.addRelative.map((addRelative: any) => ({
          gender: addRelative.gender,
          first_name: addRelative.first_name,
          last_name: addRelative.last_name,
          birth_date: convertDate(addRelative.birth_date, false, 'yyyy-MM-dd'),
          cns: addRelative.cns,
          email: addRelative.email,
          mobile_phone: addRelative.mobile_phone,
          files: addRelative.files.filter((file: File | undefined): file is File => file?.name !== undefined).map(async (file: File) => await blobToBase64(file)),
        })),
      };
      api.users
        .addRelation(request)
        .then(() => {
          setConfirmationDialogOpened(true);
        })
        .catch(() => {
          notification(t('profile.relatives.add.form.notification.error'), 'error');
        });
    },
    [notification, t],
  );
  return (
    <BodyTemplate title={t('bodyTemplate.addRelative')} backButton>
      <Card>
        <CardHeader
          title={
            <Box display='flex' alignItems='center'>
              <Typography variant='h1'>{t('profile.relatives.add.form.title')}</Typography>
              <IconButton
                sx={{ ml: (theme) => theme.spacing(1) }}
                onClick={() => {
                  append(addRelativeDefaultValue);
                }}
              >
                <AddIcon />
              </IconButton>
            </Box>
          }
          sx={{ mb: 3 }}
        />
        <CardContent>
          <Grid container spacing={0}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              {fields.map((_, index) => {
                return (
                  <Box
                    display='flex'
                    key={index}
                    py={5}
                    sx={{
                      borderBottom: (theme) => (index !== fields.length - 1 ? `1px solid ${theme.palette.divider}` : ''),
                    }}
                  >
                    <Grid item xs={12} container spacing={1}>
                      <Grid item xs={12}>
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                          <Typography variant='h2'>
                            {t('profile.relatives.add.form.relativeInfoTitle', {
                              replace: { index: index + 1 },
                            })}
                          </Typography>
                          {index !== 0 && (
                            <Button
                              variant='danger'
                              onClick={() => {
                                if (fields.length > 1) {
                                  remove(index);
                                }
                              }}
                            >
                              {t('common.action.delete')}
                            </Button>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          select
                          variant='filled'
                          label={t('profile.relatives.add.form.your_relative_is')}
                          fullWidth
                          defaultValue={Gender.FEMALE_FULL}
                          {...register(`addRelative.${index}.gender`, { required: true })}
                          error={formState.errors?.addRelative?.[index]?.gender !== undefined}
                        >
                          {sexes.map(({ label, value }, i) => {
                            return (
                              <MenuItem value={value} key={`sex-${i}`}>
                                {t(label)}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label={t('profile.relatives.add.form.last_name')}
                          fullWidth
                          required
                          variant='filled'
                          {...register(`addRelative.${index}.last_name`, {
                            required: true,
                          })}
                          error={formState.errors?.addRelative?.[index]?.last_name !== undefined}
                          helperText={formState.errors?.addRelative?.[index]?.last_name ? t('preregistration.myData.form.error.invalidBirthname') : null}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label={t('profile.relatives.add.form.first_name')}
                          fullWidth
                          required
                          variant='filled'
                          {...register(`addRelative.${index}.first_name`, {
                            required: true,
                          })}
                          error={formState.errors?.addRelative?.[index]?.first_name !== undefined}
                          helperText={formState.errors?.addRelative?.[index]?.first_name ? t('preregistration.myData.form.error.invalidFirstName') : null}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField label={t('profile.relatives.add.form.cns')} fullWidth variant='filled' {...register(`addRelative.${index}.cns`)} />
                      </Grid>
                      <Grid item xs={12}>
                        <GenericDatePicker
                          name={`addRelative.${index}.birth_date`}
                          control={control}
                          onValidate={() => isManualValidDate(getValues(`addRelative.${index}.birth_date`))}
                          required
                          error={formState.errors?.addRelative?.[index]?.birth_date !== undefined}
                          helperText={t('preregistration.myData.form.helperText.birth_date')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField label={t('profile.relatives.add.form.email')} type='email' fullWidth variant='filled' {...register(`addRelative.${index}.email`)} />
                      </Grid>
                      <Grid item xs={12}>
                        <PhoneInput
                          onChange={(e) => {
                            setValue(`addRelative.${index}.mobile_phone`, e.toString());
                          }}
                          label={t('profile.relatives.add.form.mobile_phone')}
                        />
                      </Grid>
                      <Files index={index} control={control} register={register} formState={formState} setValue={setValue} />
                    </Grid>
                  </Box>
                );
              })}
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel label={t('profile.relatives.add.form.checkbox.minor')} control={<Checkbox {...register('minor')} sx={{ pr: 2 }} />} />
                  <FormControlLabel
                    label={t('profile.relatives.add.form.checkbox.agreement')}
                    control={
                      <Checkbox
                        required
                        {...register('agree', {
                          required: true,
                        })}
                        sx={{ pr: 2 }}
                      />
                    }
                  />
                  {formState.errors?.agree !== undefined && (
                    <Typography variant='body2' color='error'>
                      {t('profile.error.required')}
                    </Typography>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} textAlign='left' mt={3}>
                <Button
                  variant='contained'
                  fullWidth={isMobileView}
                  type='submit'
                  // disabled={isSubmitDisabled}
                >
                  {t('profile.relatives.add.form.send')}
                </Button>
              </Grid>
            </form>
          </Grid>
        </CardContent>
      </Card>
      <Dialog open={confirmationDialogOpened}>
        <AddRelativeConfirmationDialog onClose={() => setConfirmationDialogOpened(false)} />
      </Dialog>
    </BodyTemplate>
  );
};
export default AddRelativeForm;
