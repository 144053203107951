import { Grid, IconButton, SvgIcon, Typography } from '@mui/material';
import ContextLink from 'components/_base/ContextLink';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { ReactComponent as IconNext } from 'resources/icons/next.svg';
import { convertDate } from 'utils/func/Date.func';
import MobileResultsSkeleton from './MobileResults.skeleton';

const MobileResults: FC<{
  results: any;
  isLoading: boolean;
}> = ({ results, isLoading }) => {
  const { t } = useTranslation();

  return results.map((value: any, key: number) => {
    const headerText = (value.issuer.title || '') + ' ' + value.issuer.last_name + ' ' + value.issuer.first_name + ' | ' + convertDate(value.consultation_time);
    const descriptionText = `${value.reference} | ${convertDate(value.consultation_time, true)} | ${
      value.status === 'complete' ? t('results.list.table.completeLabel') : t('results.list.table.partialLabel')
    }`;

    return !isLoading && results ? (
      <Grid container spacing={0} display='flex' key={key} py={1}>
        <Grid item xs={10}>
          <Typography variant='subtitle1'>{headerText}</Typography>
          <Typography>{descriptionText}</Typography>
        </Grid>
        <Grid item xs={2} alignSelf='center' display='flex' justifyContent='flex-end'>
          <ContextLink to='DETAIL_RESULT' params={{ id: value.reference }}>
            <IconButton>
              <SvgIcon component={IconNext} sx={{ fontSize: 16 }} />
            </IconButton>
          </ContextLink>
        </Grid>
      </Grid>
    ) : (
      <MobileResultsSkeleton />
    );
  });
};

export default MobileResults;
