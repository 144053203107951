import theme_color from 'resources/theme/theme.colors';

import { createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          '& .MuiAvatar-root': {
            borderColor: theme_color.palette.background.paper,
            boxSizing: 'border-box',
            fontSize: 12,
          },
          '& .MuiAvatar-root:last-child': {
            borderColor: theme_color.palette.primary.main,
            boxSizing: 'border-box',
          },
        },
      },
    },
  },
});

export default theme;
