import { Box, Button, Card, CardContent, CardHeader, SvgIcon, Typography } from '@mui/material';
import ContextLink from 'components/_base/ContextLink';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { ReactComponent as ExternalLinkIcon } from 'resources/icons/external-link.svg';
import MyDSPLogo from 'resources/images/logo_mydsp.svg';

const NoDSP: FC = () => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader title={<Box component='img' alt={t('myDSP.alt')} src={MyDSPLogo} sx={{ height: '12rem', width: '100%' }} />}></CardHeader>
      <CardContent>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant='h2' sx={{ py: 2 }}>
            {t('myDSP.noDSP.title')}
          </Typography>
          <Typography variant='body1' sx={{ pb: 2 }}>
            {t('myDSP.noDSP.subtitle')}
          </Typography>
          <Box sx={{ display: 'grid', placeItems: 'center', py: 2 }}>
            <ContextLink to='ACTIVATE_DSP' external>
              <Button variant='contained'>
                {t('myDSP.noDSP.action')}
                <SvgIcon fontSize='small' sx={{ ml: 1, color: 'text.secondary' }} component={ExternalLinkIcon} />
              </Button>
            </ContextLink>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
export default NoDSP;
