import { Box, Button, Card, CardContent, CardHeader, Typography } from '@mui/material';
import api from 'api';
import { useIsMobileView, useNotification } from 'hooks';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { convertDate } from 'utils/func/Date.func';
import { downloadBlob } from 'utils/func/File.func';

const ValidationPdfGenerator: FC<{
  idPrescription: string;
}> = ({ idPrescription }) => {
  const { t } = useTranslation();
  const [pdfData, setpdfData] = useState<Blob>();
  const isMobileView = useIsMobileView();
  const { notification } = useNotification();
  const [accidentDate, setAccidentDate] = useState(convertDate(new Date().toISOString(), false, 'dd-MM-yyyy'));

  const prescriptionPdfFile = useCallback(async () => {
    return await api.prescriptions.getPrescriptionPdfFile(idPrescription);
  }, [idPrescription]);

  const generatePrescriptionFile = useCallback(() => {
    const prescription = prescriptionPdfFile();

    prescription.then((response) => setpdfData(response)).catch(() => notification(t('prescriptions_making.step2.notification.errorWhenLoadingPdf'), 'error'));
  }, [notification, prescriptionPdfFile, t]);

  const handleSubmit = () => {
    const prescription = prescriptionPdfFile();
    const pdfPrefixFilename = `ordonnance__`;

    prescription
      .then((response: Blob) => {
        downloadBlob(response, `${pdfPrefixFilename}${accidentDate}.pdf`);
      })
      .catch(() => {
        notification(t('prescriptions_making.validation.prescription.notification.errorWhenPdfNotDownloading'), 'error');
      });
  };

  useEffect(() => {
    api.prescriptions.getPrescriptionByBasketId(idPrescription).then((response) => {
      setAccidentDate(convertDate(new Date(response.accident_date).toISOString(), false, 'dd-MM-yyyy'));
      generatePrescriptionFile();
    });
  }, [generatePrescriptionFile, idPrescription]);

  const files = pdfData && new Blob([pdfData], { type: 'application/pdf' });
  const fileURL = files && URL.createObjectURL(files);

  return (
    <Card>
      <CardHeader
        title={
          <Box display='flex' alignItems='center' sx={{ pb: 2 }}>
            <Typography variant='h2' gutterBottom>
              {t('prescriptions_making.validation.generatedPrescription.print')}
            </Typography>
            <Box flexGrow={1} />
            <Button variant='outlined' onClick={handleSubmit}>
              {t('common.action.pdfDownload')}
            </Button>
          </Box>
        }
      />
      <CardContent>
        <iframe src={fileURL} title='document' style={{ border: 0 }} width='100%' height={isMobileView ? '500px' : '800px'} />
      </CardContent>
    </Card>
  );
};

export default ValidationPdfGenerator;
