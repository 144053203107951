import { format } from 'date-fns';
import { AppointmentPatient } from 'models/appointment.model';
import { AppointmentSlots, AvailableSlots } from 'models/appointment.slots.model';
import { Patient } from 'models/patients.model';
import { convertDate } from './Date.func';

/**
 * Returns the end time of the given slot depending on the given duration in H:mm format
 * @param {string} slotStartTime time in H:mm format (ex. "06:30")
 * @param {string} slotDuration duration in period time format (ex. "PT00H30M")
 * @returns {string} end time in H:mm format (ex. "07:00")
 */
export const getSlotEndTime = (slotStartTime: string, slotDuration: string) => {
  const now = new Date();
  now.setHours(parseInt(slotStartTime.split(':')[0]) + parseInt(slotDuration.split('H')[0].split('PT')[1]));
  now.setMinutes(parseInt(slotStartTime.split(':')[1]) + parseInt(slotDuration.split('H')[1].split('M')[0]));
  return convertDate(now, false, 'H:mm');
};

export const treatAppointmentSlots = (data: AppointmentSlots) => {
  const dates = Object.keys(data.slots);
  const slots: AvailableSlots = {};
  Object.keys(data.slots).forEach((date) => {
    slots[date] = data.slots[date].map((slotStartTime) => {
      const _slotValue = new Date(date);
      _slotValue.setHours(parseInt(slotStartTime.split(':')[0]));
      _slotValue.setMinutes(parseInt(slotStartTime.split(':')[1]));
      const slotValue = format(_slotValue, "yyyy-MM-dd'T'hh:mm:ssXXX");
      return {
        slotStartTime,
        slotEndTime: getSlotEndTime(slotStartTime, data.slot_duration),
        slotValue,
      };
    });
  });
  return { dates, slots };
};

export const canMakeAppointmentForPatient = (person: Patient) =>
  (!!person.first_name && person.first_name !== '' && person.first_name !== null) ||
  (!!person.last_name && person.last_name !== '' && person.last_name !== null && !!person.birth_name && person.birth_name !== '' && person.birth_name !== null);

export const canMakeAppointmentForAppointmentPatient = (person: AppointmentPatient) =>
  !!person.first_name &&
  person.first_name !== '' &&
  person.first_name !== null &&
  !!person.last_name &&
  person.last_name !== '' &&
  person.last_name !== null &&
  !!person.birth_name &&
  person.birth_name !== '' &&
  person.birth_name !== null;
