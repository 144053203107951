import { Button, Card, CardContent, CardHeader, SvgIcon, TableCell, TableRow, Typography } from '@mui/material';
import GenericTable, { GenericPaginator } from 'components/generic-table';
import { GenericHeader } from 'components/generic-table/GenericTableHeader';
import NoResultsTableRow from 'components/no-results-table-row';
import ContextLink, { RoutePathsKey } from 'components/_base/ContextLink';
import { useAuthenticatedUser, useContextRedirection } from 'hooks';
import { Patient } from 'models/patients.model';
import { Gender } from 'models/profile.model';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MaleIcon } from 'resources/icons/man.svg';
import { ReactComponent as FemaleIcon } from 'resources/icons/woman.svg';
import { addressToString } from 'utils/func/Address.func';
import { canMakeAppointmentForPatient } from 'utils/func/Appointment.func';
import { convertDate } from 'utils/func/Date.func';
import { PrescriptionRequestMaker } from 'utils/func/Prescription.func';
import RoutePaths from 'utils/RoutePaths';

interface PatientListProps {
  patientDetailAction: { to: RoutePathsKey };
  patientList?: Patient[];
  paginatorData: GenericPaginator;
  handlePaginatorAction: (data: GenericPaginator) => void;
  showPrescriptionAction?: boolean;
  showAppointmentAction?: boolean;
}

const PatientList: FC<PatientListProps> = ({ patientDetailAction, patientList, paginatorData, handlePaginatorAction, showPrescriptionAction = false, showAppointmentAction = false }) => {
  const { t } = useTranslation();

  const paginatorAction = useCallback(
    (data: GenericPaginator) => {
      handlePaginatorAction(data);
    },
    [handlePaginatorAction],
  );

  const tableHeaders: GenericHeader[] = [
    { id: 'fullname', label: 'patients.list.headers.fullname' },
    { id: 'address', label: 'patients.list.headers.address' },
    { id: 'birth_date', label: 'common.table.tableHead.birth_date' },
    { id: 'cns', label: 'patients.list.headers.cns' },
    { id: 'gender', label: 'patients.list.headers.gender' },
    { id: 'actions', label: undefined },
  ];

  const authenticatedUser = useAuthenticatedUser();
  const navigate = useContextRedirection();

  return (
    <>
      {patientList && (
        <Card>
          <CardHeader
            title={
              <Typography variant='h2' mb={4}>
                {t('patients.list.title', { count: paginatorData.total })}
              </Typography>
            }
          />
          <CardContent>
            <GenericTable
              headers={tableHeaders}
              {...(patientList.length
                ? {
                    pagination: paginatorData,
                    setPagination: paginatorAction,
                  }
                : {
                    noPagination: true,
                  })}
            >
              {patientList.length ? (
                patientList.map((patient, key) => {
                  return (
                    <TableRow hover key={key}>
                      <TableCell>
                        <Typography>{`${patient.first_name} ${patient.last_name.toLocaleUpperCase()}`}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{patient.addresses.home ? addressToString(patient.addresses.home) : ''}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{convertDate(patient.birth_date)}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{patient.cns}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{patient.gender === Gender.FEMALE ? <SvgIcon fontSize='small' component={FemaleIcon} /> : <SvgIcon fontSize='small' component={MaleIcon} />}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          <ContextLink {...patientDetailAction} params={patient.id}>
                            <Button type='button' variant='text'>
                              {t('patients.list.details')}
                            </Button>
                          </ContextLink>
                        </Typography>
                      </TableCell>
                      {showPrescriptionAction && (
                        <TableCell>
                          <Typography>
                            <Button
                              type='button'
                              variant='text'
                              onClick={() => {
                                authenticatedUser && PrescriptionRequestMaker(patient.id, navigate);
                              }}
                            >
                              {t('common.action.makePrescription')}
                            </Button>
                          </Typography>
                        </TableCell>
                      )}
                      {showAppointmentAction && patient && (
                        <TableCell>
                          <Typography>
                            <Button type='button' variant='text' disabled={!canMakeAppointmentForPatient(patient)} onClick={() => navigate(RoutePaths['DOCTOR_APPOINTMENT']({ id: patient.id }))}>
                              {t('common.action.bookAppointment')}
                            </Button>
                          </Typography>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })
              ) : (
                <NoResultsTableRow colSpan={tableHeaders.length} />
              )}
            </GenericTable>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default PatientList;
