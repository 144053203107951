import { useTranslation } from 'react-i18next';
import { FC } from 'react';

const MissingData: FC = () => {
  const { t } = useTranslation();

  return <i>{t('common.missingData')}</i>;
};

export default MissingData;
