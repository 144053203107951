import theme_color from 'resources/theme/theme.colors';

import { createTheme } from '@mui/material';
import { createBreakpoints } from '@mui/system';

const breakpoints = createBreakpoints({});

const theme = createTheme({
  components: {
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          '&:not(:last-of-type)': {
            marginRight: '24px',
            border: '2px solid',
            borderRadius: 4,
            [breakpoints.down('md')]: {
              marginRight: '0px',
              marginBottom: '8px',
            },
          },
          '&:not(:first-of-type)': {
            marginLeft: 0,
            border: '2px solid',
            borderRadius: 4,
          },
          '&.Mui-selected': {
            border: '2px solid',
            borderColor: theme_color.palette.primary.main,
            backgroundColor: theme_color.palette.primary.main,
            '&:hover': {
              color: theme_color.palette.text.primary,
              borderColor: theme_color.palette.primary.main,
              backgroundColor: theme_color.palette.primary.main,
            },
          },
          '&:hover': {
            borderColor: theme_color.palette.secondary.main,
            backgroundColor: theme_color.palette.secondary.main,
            color: theme_color.palette.common.white,
            '>.MuiTypography-body1': {
              color: theme_color.palette.common.white,
            },
          },
          '&.Mui-selected+.MuiToggleButtonGroup-grouped.Mui-selected': {
            border: '2px solid transparent !important',
            backgroundColor: theme_color.palette.primary.main,
            color: theme_color.palette.text.primary,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          color: theme_color.palette.text.primary,
          border: '2px solid',
          borderColor: theme_color.palette.secondary.main,
          padding: '8px 24px',
        },
      },
    },
  },
});

// const theme = createTheme({
// 	components: {
// 		MuiToggleButtonGroup: {
// 			styleOverrides: {
// 				grouped: {
// 					//borderRadius: 0
// 				}
// 			}
// 		},
// 		MuiToggleButton: {
// 			styleOverrides: {
// 				root: {
// 					backgroundColor: 'transparent',
// 					color: theme_color.palette.text.primary,
// 					border: '2px solid',
// 					borderColor: theme_color.palette.secondary.main,
// 					disableElevation: true,
// 					boxShadow: 'none',
// 					margin: '24px',
// 					'&:not(:last-of-type)': {
// 						border: '2px solid'
// 					},
// 					'&:not(:first-of-type)': {
// 						border: '2px solid'
// 					},
// 					'& .MuiToggleButtonGroup-grouped.Mui-selected+.MuiToggleButtonGroup-grouped.Mui-selected':
// 						{
// 							border: '2px solid',
// 							borderColor: theme_color.palette.primary.main,
// 							backgroundColor: theme_color.palette.secondary.main,
// 							'&:not(:first-of-type):hover': {
// 								border: '2px solid'
// 							}
// 						}
// 				}
// 			}
// 		}
// 	}
// });

export default theme;
