import { Address } from 'models/profile.model';
import { PatientForm } from 'store/reducers/appointmentForm.reducer';

export enum AppointmentFormActionType {
  SET_PATIENT = 'SET_PATIENT',
  ADD_PRESCRIPTION = 'ADD_PRESCRIPTION',
  DELETE_PRESCRIPTION = 'DELETE_PRESCRIPTION',
  HANDLE_MEDICAL_ACTS = 'HANDLE_MEDICAL_ACTS',
  HANDLE_TEXT_AREA = 'HANDLE_TEXT_AREA',
  HANDLE_OPTIONS = 'HANDLE_OPTIONS',
  HANDLE_ADDRESS_TYPE = 'HANDLE_ADDRESS_TYPE',
  HANDLE_ADDRESS_ONLY = 'HANDLE_ADDRESS_ONLY',
  HANDLE_SLOT = 'HANDLE_SLOT',
  RESET = 'RESET',
}

export type AppointmentFormAction =
  | {
      type: AppointmentFormActionType.SET_PATIENT;
      patient: PatientForm;
    }
  | {
      type: AppointmentFormActionType.ADD_PRESCRIPTION;
      file: File;
    }
  | {
      type: AppointmentFormActionType.DELETE_PRESCRIPTION;
      index: number;
    }
  | {
      type: AppointmentFormActionType.HANDLE_MEDICAL_ACTS;
      index: number[];
    }
  | {
      type: AppointmentFormActionType.HANDLE_TEXT_AREA;
      content: string;
    }
  | {
      type: AppointmentFormActionType.HANDLE_OPTIONS;
      index: number;
    }
  | {
      type: AppointmentFormActionType.HANDLE_ADDRESS_TYPE;
      option: string;
    }
  | {
      type: AppointmentFormActionType.HANDLE_ADDRESS_ONLY;
      address: Address;
    }
  | {
      type: AppointmentFormActionType.HANDLE_SLOT;
      slot: Date | null;
    }
  | {
      type: AppointmentFormActionType.RESET;
    };

export const setPatient = (patient: PatientForm): AppointmentFormAction => ({
  type: AppointmentFormActionType.SET_PATIENT,
  patient: patient,
});

export const addPrescription = (file: File): AppointmentFormAction => ({
  type: AppointmentFormActionType.ADD_PRESCRIPTION,
  file: file,
});

export const deletePrescription = (idx: number): AppointmentFormAction => ({
  type: AppointmentFormActionType.DELETE_PRESCRIPTION,
  index: idx,
});

export const handleMedicalActs = (idx: number[]): AppointmentFormAction => ({
  type: AppointmentFormActionType.HANDLE_MEDICAL_ACTS,
  index: idx,
});
export const handleTextArea = (content: string): AppointmentFormAction => ({
  type: AppointmentFormActionType.HANDLE_TEXT_AREA,
  content: content,
});
export const handleOptions = (index: number): AppointmentFormAction => ({
  type: AppointmentFormActionType.HANDLE_OPTIONS,
  index: index,
});
export const handleAddressType = (option: string): AppointmentFormAction => ({
  type: AppointmentFormActionType.HANDLE_ADDRESS_TYPE,
  option: option,
});

export const handleAddressOnly = (element: Address): AppointmentFormAction => ({
  type: AppointmentFormActionType.HANDLE_ADDRESS_ONLY,
  address: element,
});
export const handleSlot = (slot: Date | null): AppointmentFormAction => ({
  type: AppointmentFormActionType.HANDLE_SLOT,
  slot: slot,
});
export const reset = (): AppointmentFormAction => ({
  type: AppointmentFormActionType.RESET,
});
