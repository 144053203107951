import { Checkbox, FormControlLabel, Grid, MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CentersFormValues, CityChoice, ScheduleChoice, SpecialityChoice } from 'models/centers.model';
import { FC } from 'react';
import { UseFormRegister } from 'react-hook-form';

interface CentersFormProps {
  cities: CityChoice[];
  specialities: SpecialityChoice[];
  schedules: ScheduleChoice[];
  register: UseFormRegister<CentersFormValues>;
}

const CentersForm: FC<CentersFormProps> = ({ cities, specialities, schedules, register }) => {
  const { t } = useTranslation();

  return (
    <form>
      <Grid container>
        <Grid container item>
          <Grid item xs={12} md={3}>
            <TextField select variant='filled' label={t('centers.form.city.label')} {...register('city')} defaultValue={-1} fullWidth>
              <MenuItem value={-1}>{t('centers.form.city.all')}</MenuItem>
              {cities.map((e, i) => {
                return (
                  <MenuItem value={e.value} key={i}>
                    {e.label}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField
              select
              variant='filled'
              label={t('centers.form.speciality.label')}
              {...register('speciality')}
              defaultValue={-1}
              inputProps={{
                name: 'speciality',
                id: 'speciality',
              }}
              fullWidth
            >
              <MenuItem value={-1}>{t('centers.form.speciality.all')}</MenuItem>
              {specialities.map((e, i) => {
                return (
                  <MenuItem value={e.value} key={i}>
                    {e.label}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField
              select
              variant='filled'
              label={t('centers.form.schedule.label')}
              {...register('schedule')}
              defaultValue={-1}
              inputProps={{
                name: 'schedule',
                id: 'schedule',
              }}
              fullWidth
            >
              <MenuItem value={-1}>{t('centers.form.schedule.all')}</MenuItem>
              {schedules.map((e, i) => {
                return (
                  <MenuItem value={e.value} key={i}>
                    {e.label}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
        </Grid>

        <Grid container item>
          <Grid item>
            <FormControlLabel {...register('PRMAccessRequired')} name='PRMAccessRequired' control={<Checkbox />} label={t('centers.form.prm_access')} sx={{ ml: 0 }} />
          </Grid>

          <Grid item>
            <FormControlLabel {...register('publicParkingRequired')} name='publicParkingRequired' control={<Checkbox />} label={t('centers.form.parking')} sx={{ ml: 0 }} />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default CentersForm;
