import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, AccordionDetails } from '@mui/material';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { useTranslation } from 'react-i18next';
import { Result } from 'models/result.model';
import { FC } from 'react';

const DetailTabPanelBodyIsTable: FC<{ val: Result }> = ({ val }) => {
  const { t } = useTranslation();

  const CLASSIFICATION_CODE_SIR = 'S/I/R';
  const CLASSIFICATION_CODE_SSFPR = 'S/SFP/R';

  let data = JSON.parse(val.value);

  let classification = CLASSIFICATION_CODE_SIR;
  if(CLASSIFICATION_CODE_SSFPR === data.classification) {
    classification = CLASSIFICATION_CODE_SSFPR;
  }
  // In other cases, keep default

  return (
    <TableCell colSpan={4}>
      {val.name && (
        <Typography
          variant='h3'
          sx={{
            textTransform: 'uppercase',
            margin: '1rem auto'
          }}
        >
          {val.name}
        </Typography>
      )}
      {data.germe && (
        <Typography
          variant='subtitle1'
          sx={{
            color: '#46BFF5',
          }}
        >
          {data.germe}
        </Typography>
      )}
      {data.isole && <Typography variant='subtitle1'> {data.isole} </Typography>}
      {classification === CLASSIFICATION_CODE_SSFPR && <Typography sx={{marginTop:'1rem'}} variant='subtitle1'><div dangerouslySetInnerHTML={{ __html: t('results.details.tableResults.label.antibiogrammeSSFPRBody1') }} /></Typography>}
      <AccordionDetails sx={{ margin: 0 }}>
        <TableContainer>
          <Table size='medium'>
            <TableHead>
              <TableRow>
                <TableCell width='35%'>
                  <Typography variant='subtitle1'>{t('results.details.tableResults.label.antibiogrammeTabTitle1')}</Typography>
                </TableCell>
                <TableCell width='55%'>
                  <Typography variant='subtitle1'>{t('results.details.tableResults.label.antibiogrammeTabTitle2')}</Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography variant='subtitle1'>{'S'}</Typography>
                </TableCell>
                <TableCell align='center'>
                  {classification === CLASSIFICATION_CODE_SSFPR && <Typography variant='subtitle1'>{'SFP'}</Typography>}
                  {classification === CLASSIFICATION_CODE_SIR && <Typography variant='subtitle1'>{'I'}</Typography>}
                </TableCell>
                <TableCell align='center'>
                  <Typography variant='subtitle1'>{'R'}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.lines.map((val: any, key: number) => {
                return (
                  <TableRow hover tabIndex={-1} key={key}>
                    <TableCell
                      sx={{
                        backgroundColor: val.name ? '#e3eef6' : 'white',
                      }}
                    >
                      <Typography
                        variant='body1'
                        sx={{
                          fontWeight: '500',
                        }}
                      >
                        {val.name}
                      </Typography>
                      <Typography variant='body1'>{val.molecule}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: val.name ? '#e3eef6' : 'white',
                      }}
                    >
                      <Typography variant='body1'>{val.commercialName}</Typography>
                    </TableCell>
                    <TableCell
                      align='right'
                      sx={{
                        backgroundColor: val.name ? '#e3eef6' : 'white',
                      }}
                    >
                      {val.interpretation && val.interpretation === 'S' ? <Brightness1Icon color='success' /> : null}
                    </TableCell>
                    <TableCell
                      align='left'
                      sx={{
                        backgroundColor: val.name ? '#e3eef6' : 'white',
                      }}
                    >
                      {val.interpretation && val.interpretation === 'I' && classification === CLASSIFICATION_CODE_SIR ? <Brightness1Icon htmlColor='orange' /> : null}
                      {val.interpretation && val.interpretation === 'SFP' && classification === CLASSIFICATION_CODE_SSFPR ? <Brightness1Icon htmlColor='orange' /> : null}
                    </TableCell>
                    <TableCell
                      align='right'
                      sx={{
                        backgroundColor: val.name ? '#e3eef6' : 'white',
                      }}
                    >
                      {val.interpretation && val.interpretation === 'R' ? <Brightness1Icon color='error' /> : null}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
      {classification === CLASSIFICATION_CODE_SSFPR && <Typography sx={{textAlign:'right'}} variant='subtitle1'>{t('results.details.tableResults.label.antibiogrammeSSFPRLegend1')}</Typography>}
      {classification === CLASSIFICATION_CODE_SIR && <Typography sx={{textAlign:'right'}} variant='subtitle1'>{t('results.details.tableResults.label.antibiogrammeSIRLegend1')}</Typography>}
    </TableCell>
  );
};

export default DetailTabPanelBodyIsTable;
