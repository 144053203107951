import ResultAccordionSummary from 'pages/doctor/patients/history/components/ResultAccordionSummary';
import { AnalysisDisciplineCT, Crosstab } from 'models/analysis.model';
import { FC } from 'react';
import ResultsCrosstabAccordionDetails from './ResultCrosstabAccordionDetails';

type CommonProps = {
  results: Crosstab;
};

const ResultsCrosstabAccordions: FC<CommonProps> = ({ results }) => {
  return (
    <>
      {results &&
        results.map((result: AnalysisDisciplineCT, key: number) => {
          return (
            <ResultAccordionSummary title={result.name} nbResults={result.analyses.length} key={key}>
              <ResultsCrosstabAccordionDetails analyses={result.analyses} />
            </ResultAccordionSummary>
          );
        })}
    </>
  );
};

export default ResultsCrosstabAccordions;
