import { FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GenericFormProps } from 'models/result.patient.model';
import { FC } from 'react';

export const GenericBooleanForm: FC<GenericFormProps> = ({ question }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography alignSelf='center'>{question.name}</Typography>
      <RadioGroup value={question.answers[0]} row>
        {question.values.map((value, index) => {
          return <FormControlLabel key={index} value={value.id} control={<Radio />} label={value.name} disabled />;
        })}
      </RadioGroup>
      {question.has_comment && (
        <TextField
          label={t('results.details.remarksLabel')}
          fullWidth
          variant='standard'
          InputLabelProps={{
            sx: { p: 0 },
          }}
          value={question.answer_comment ?? ''}
          disabled
        />
      )}
    </>
  );
};
