import { Box, Button, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { FC, useCallback } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import api from 'api';
import { convertDate } from 'utils/func/Date.func';
import { useContextRedirection, useNotification } from 'hooks';
import RoutePaths from 'utils/RoutePaths';

interface FormValues {
  token: string;
  birth_date: Date | null;
}

const defaultValues: FormValues = { token: '', birth_date: null };

const AddRelativeWithTokenForm: FC = () => {
  const { t } = useTranslation();
  const { notification } = useNotification();
  const navigate = useContextRedirection();
  const {
    register,
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<FormValues>({ defaultValues, mode: 'onChange' });

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    (data) => {
      api.users
        .addRelationWithToken(data.token, convertDate(data.birth_date, false, 'yyyy-MM-dd'))
        .then(() => {
          navigate(RoutePaths['PROFILE_RELATIVE']);
          notification(t('landing_page.login.token.notification.validation.tokenRelative'), 'success');
        })
        .catch(() => {
          notification(t('landing_page.login.token.notification.error.tokenRelative'), 'success');
        });
    },
    [notification, t, navigate],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box py={2}>
        <TextField variant='filled' label={t('profile.relatives.add.token_received_by')} {...register('token', { required: true })} fullWidth />
      </Box>
      <Box py={2}>
        <Controller
          name='birth_date'
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <DatePicker
              label={t('profile.relatives.add.birth_date_relative')}
              disableFuture
              openTo='year'
              views={['year', 'month', 'day']}
              {...field}
              slotProps={{ textField: { variant: 'filled', fullWidth: true } }}
            />
          )}
        />
      </Box>
      <Box py={2} textAlign='right'>
        <Button type='submit' variant='contained' disabled={!isValid}>
          {t('profile.relatives.add.send')}
        </Button>
      </Box>
    </form>
  );
};

export default AddRelativeWithTokenForm;
