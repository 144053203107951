import { BadgesCount, Person, Relation, RelationInfo, ShortenPerson } from 'models/profile.model';
import { ReduxThunkAction } from 'utils';
import { CONST_BIONEXT_CURRENT_USER_TOKEN } from 'utils/Constants';

export enum SessionActionType {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  SET_USER = 'SET_USER',
  SET_CURRENT_USER = 'SET_CURRENT_USER',
  SET_RELATIVES_CURRENT_USER = 'SET_RELATIVES_CURRENT_USER',
  SET_BADGES_COUNT = 'SET_BADGES_COUNT',
  SET_CALL_CENTER = 'SET_CALL_CENTER',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  VALIDATE_DISCLAIMER = 'VALIDATE_DISCLAIMER',
}

export type SessionAction =
  | { type: SessionActionType.LOGIN }
  | { type: SessionActionType.LOGOUT }
  | { type: SessionActionType.SET_USER; authenticateUser: Person }
  | { type: SessionActionType.SET_CURRENT_USER; currentUser: ShortenPerson }
  | {
      type: SessionActionType.SET_RELATIVES_CURRENT_USER;
      relativesCurrentUser: Person[];
    }
  | {
      type: SessionActionType.SET_BADGES_COUNT;
      badgesCount: BadgesCount;
    }
  | {
      type: SessionActionType.SET_CALL_CENTER;
    }
  | {
      type: SessionActionType.REFRESH_TOKEN;
    }
  | {
      type: SessionActionType.VALIDATE_DISCLAIMER;
    };

export const loginUser = (): SessionAction => ({
  type: SessionActionType.LOGIN,
});

export const validateDisclaimer = (): SessionAction => ({
  type: SessionActionType.VALIDATE_DISCLAIMER,
});

export const logoutUser = (): SessionAction => ({
  type: SessionActionType.LOGOUT,
});

export const setCallCenterIsUp = (): SessionAction => ({
  type: SessionActionType.SET_CALL_CENTER,
});

export const setUserInfo = (dataUserInfos: Person): SessionAction => ({
  type: SessionActionType.SET_USER,
  authenticateUser: {
    ...dataUserInfos,
  },
});

export const setCurrentUser = (user: ShortenPerson): SessionAction => ({
  type: SessionActionType.SET_CURRENT_USER,
  currentUser: user,
});

export const setRelativesCurrentUser = (relatives: Person[]): SessionAction => ({
  type: SessionActionType.SET_RELATIVES_CURRENT_USER,
  relativesCurrentUser: relatives,
});

export const isMainUser = (user: Person | RelationInfo): user is Person => {
  return (user as Person).patient_id !== undefined;
};

export const convertToUser = (currentUser: Person | RelationInfo): ShortenPerson => {
  return {
    id: isMainUser(currentUser) ? currentUser.patient_id : currentUser.id,
    is_relative: !isMainUser(currentUser),
    first_name: currentUser.first_name,
    last_name: currentUser.last_name,
    gender: currentUser.gender,
    addresses: {
      home: currentUser.addresses.home,
      work: currentUser.addresses.work,
    },
    birth_name: currentUser.birth_name,
    birth_date: currentUser.birth_date,
    cns: currentUser.cns,
    phone: currentUser.phone,
    mobile: currentUser.mobile_phone,
    fax: currentUser.fax,
    email: currentUser.username,
  } as ShortenPerson;
};

export const fetchMe = (): ReduxThunkAction => async (dispatch, _, api) => {
  const allUserInformations = await api.users.getMe();
  const patientRole = allUserInformations.role === 'patient';

  let myGrantors: Relation[] = [];
  let myGrantees: Relation[] = [];
  let myGuests: Relation[] = [];

  if (patientRole) {
    const allUserRelatives: Relation[] = await api.users.getGrantorsAndGrantees();
    allUserRelatives &&
      allUserRelatives.forEach((allUserRelative: Relation) => {
        if (allUserRelative.grantee.id !== allUserInformations.patient_id) {
          if (allUserRelative.is_pending) {
            myGuests.push(allUserRelative);
          } else {
            myGrantors.push(allUserRelative);
          }
        }

        if (allUserRelative.grantor.id !== allUserInformations.patient_id) {
          myGrantees.push(allUserRelative);
        }
      });
  }

  dispatch(
    patientRole
      ? setUserInfo({
          ...allUserInformations,
          relatives: {
            grantees: myGrantees,
            grantors: myGrantors,
            guests: myGuests,
          },
        })
      : setUserInfo({
          ...allUserInformations,
        }),
  );

  // Get all relatives of authentified user
  const getAllGranteesProfile: any[] = [];
  getAllGranteesProfile.push(convertToUser(allUserInformations));
  myGrantees.forEach((i) => getAllGranteesProfile.push(convertToUser(i.grantor)));
  dispatch(setRelativesCurrentUser(getAllGranteesProfile));

  // Get current userinfos
  if (localStorage.getItem(CONST_BIONEXT_CURRENT_USER_TOKEN) === null) {
    localStorage.setItem(CONST_BIONEXT_CURRENT_USER_TOKEN, allUserInformations.patient_id.toString());
  }

  dispatch(setCurrentUser(getAllGranteesProfile.filter((relative) => relative.id === parseInt(localStorage.getItem(CONST_BIONEXT_CURRENT_USER_TOKEN)!))[0]));
};

export const getPatientBadgesCount = (): ReduxThunkAction => async (dispatch, getState, api) => {
  const authenticateUser = getState().session.authenticateUser;
  const currentUser = getState().session.currentUser;
  if (!authenticateUser || !currentUser) return;

  // Always get informations with authenticated user id to get all data
  api.users.getBadgesCount(authenticateUser.patient_id).then((count) => {
    // Create a copy of the object Count based on persons array item
    const customCount = count.persons.find((person) => person.id === currentUser.id);
    if (!customCount) return;
    // Add array of relatives to this object to keep the information
    customCount.cp = [];
    // Add authenticated user in this array because he is missing in the API response
    customCount.cp[authenticateUser.patient_id] = !!(count.appointments || count.dossiers || count.eudccs || count.invoices);
    dispatch(setPatientBadgesCount(customCount));
  });
};

export const setPatientBadgesCount = (count: BadgesCount): SessionAction => ({
  type: SessionActionType.SET_BADGES_COUNT,
  badgesCount: count,
});
