import { nopeResolver } from '@hookform/resolvers/nope';
import { Button, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import api from 'api';
import { useContextRedirection, useNotification } from 'hooks';
import { EmailUpdateProps } from 'models/profile.model';
import Nope from 'nope-validator';
import { FC, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeLeftDrawer } from 'store/actions/navMenu.actions';
import { clearAllPrescriptionProfile } from 'store/actions/prescriptionProfile.actions';
import { logoutUser } from 'store/actions/session.actions';
import RoutePaths from 'utils/RoutePaths';

const EmailUpdate: FC<{ closeModal: () => void }> = ({ closeModal }) => {
  const { t } = useTranslation();

  const UpdateEmailSchema = Nope.object().shape({
    email: Nope.string().email('security.validation.email.default').required('security.validation.email.required'),
    emailConfirmation: Nope.string()
      .oneOf([Nope.ref('email')])
      .required(),
  });

  const { register, handleSubmit, formState } = useForm<EmailUpdateProps>({
    resolver: nopeResolver(UpdateEmailSchema),
  });
  const { notification } = useNotification();
  const dispatch = useDispatch();
  const navigate = useContextRedirection();

  const onSubmit: SubmitHandler<EmailUpdateProps> = useCallback(
    (data) => {
      api.users
        .updateEmail(data)
        .then(() => {
          closeModal();
          notification(t('profile.updateEmail.notification.success'), 'success');
          dispatch(closeLeftDrawer());
          dispatch(clearAllPrescriptionProfile());
          dispatch(logoutUser());
          navigate(RoutePaths['LANDING_PAGE']);
        })
        .catch((e) => {
          notification(t('profile.updateEmail.notification.error'), 'error');
        });
    },
    [closeModal, navigate, dispatch, notification, t],
  );

  return (
    <>
      <DialogTitle>{t('profile.update.actions.modify.email')}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={0} flexDirection='column'>
          <DialogContent>
            <Grid item xs={12} py={1}>
              <TextField variant='filled' fullWidth {...register('email')} error={formState.errors?.email !== undefined} label={t('profile.updateEmail.form.newEmail')} required />
            </Grid>
            <Grid item xs={12} py={1}>
              <TextField
                variant='filled'
                fullWidth
                {...register('emailConfirmation')}
                error={formState.errors?.emailConfirmation !== undefined}
                label={t('profile.updateEmail.form.confirmNewEmail')}
                required
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12} textAlign='right'>
              <Button variant='contained' type='submit'>
                {t('common.action.validate')}
              </Button>
            </Grid>
          </DialogActions>
        </Grid>
      </form>
    </>
  );
};

export default EmailUpdate;
