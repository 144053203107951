import { Grid, Typography, CardActions, Button } from '@mui/material';
import { useIsMobileView } from 'hooks';
import { FC, useEffect } from 'react';
import { AppointmentPatient } from 'models/appointment.model';
import { useForm } from 'react-hook-form';
import { Props } from '../index';
import BookingReasonsOfPatient from './Booking.reasons.of.patient';
import { useTranslation } from 'react-i18next';

const BookingReasonsStep: FC<Props> = ({ isDoctor, handleNext, medicalActs, handleState, handleBack, state }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const { register, watch, setValue, handleSubmit, control, formState, getValues } = useForm<{
    patients: AppointmentPatient[];
  }>({ mode: 'onChange', defaultValues: { patients: state.patients }, shouldFocusError: true });

  //Default values when the appointment is made by the doctor
  useEffect(() => {
    if (isDoctor) {
      setValue('patients.0.fasting', state.patients[0].fasting);
    }
  }, [isDoctor, setValue, state]);

  return (
    <Grid item xs={12}>
      <form
        onSubmit={handleSubmit((data) => {
          if (!formState.errors.patients) {
            handleNext();
            handleState(data.patients, 'patients');
          }
        })}
      >
        <Grid item display='flex' alignItems='center' flexDirection='row'>
          <Typography variant='h2' sx={{ wordWrap: 'break-word' }}>
            {t('appointment.book.steps.title')}
          </Typography>
        </Grid>
        <Typography marginTop={isMobileView ? 3 : 0} variant='h1'>
          {t('appointment.book.steps.reasons.title')}
        </Typography>
        {state.patients.map((patient, index) => (
          <BookingReasonsOfPatient
            formState={formState}
            key={index}
            patientIndex={index}
            control={control}
            register={register}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
            medicalActs={medicalActs}
          />
        ))}
        <CardActions
          sx={{
            justifyContent: {
              xs: 'space-between',
              md: 'flex-end',
            },
            pt: 2,
          }}
        >
          <Button color='inherit' onClick={handleBack}>
            {t('appointment.book.action_buttons.previous')}
          </Button>
          <Button type='submit' variant='contained'>
            {t('appointment.book.action_buttons.next')}
          </Button>
        </CardActions>
      </form>
    </Grid>
  );
};

export default BookingReasonsStep;
