import { Avatar, AvatarGroup, Badge, IconButton, Menu, MenuItem, styled, Tooltip, Typography } from '@mui/material';
import { useRelatives } from 'hooks';
import { Person, Relation, RelationInfo } from 'models/profile.model';
import { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store/reducers';
import { MAX_DISPLAYED_RELATIVES } from 'utils/Constants';
import { getFullName, getInitials } from 'utils/func/Person.func';

const AvatarBloc: FC<{
  handleChangeUser: (user: RelationInfo | Person) => void;
}> = ({ handleChangeUser }) => {
  const authenticateUser = useSelector((state: GlobalState) => state.session.authenticateUser);
  const currentUser = useSelector((state: GlobalState) => state.session.currentUser);

  const relatives = useRelatives()?.grantees;
  const displayedRelatives = useMemo(() => (relatives ? relatives.slice(0, MAX_DISPLAYED_RELATIVES) : []), [relatives]);
  const remainingRelatives = useMemo(() => (relatives ? relatives.slice(MAX_DISPLAYED_RELATIVES) : []), [relatives]);
  const unreadData = useSelector((state: GlobalState) => state.session.badgesCount?.cp);

  // Variables of menu items of extra relatives
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  // Display of the Avatar with initials
  const AvatarIcon = useCallback(
    (id: number, user: Person | RelationInfo, hasBorderSizeOfAvatarGroup: boolean = true) => {
      const CustomAvatar = (
        <Avatar
          sx={
            !hasBorderSizeOfAvatarGroup
              ? {
                  border: (theme) => (currentUser?.id === id ? `2px solid ${theme.palette.primary.main} !important` : 'initial'),
                }
              : {
                  borderColor: (theme) => (currentUser?.id === id ? `${theme.palette.primary.main} !important` : 'initial'),
                }
          }
        >
          {getInitials(user)}
        </Avatar>
      );
      return unreadData && unreadData[id] ? (
        <StyledBadge overlap='circular' anchorOrigin={{ vertical: 'top', horizontal: 'right' }} variant='dot'>
          {CustomAvatar}
        </StyledBadge>
      ) : (
        CustomAvatar
      );
    },
    [StyledBadge, currentUser?.id, unreadData],
  );

  // Display of Avatar with a Tooltip all around
  const AvatarTooltip = useCallback(
    (id: number, user: Person | RelationInfo) => (
      <Tooltip title={getFullName(user)} key={id}>
        <IconButton key={id} onClick={() => handleChangeUser(user)} sx={{ p: 0 }}>
          {AvatarIcon(id, user)}
        </IconButton>
      </Tooltip>
    ),
    [AvatarIcon, handleChangeUser],
  );

  return (
    <>
      <AvatarGroup sx={{ justifyContent: 'center', p: 0, ml: 1.5 }}>
        {/* First position: authenticated user */}
        {authenticateUser && AvatarTooltip(authenticateUser.patient_id, authenticateUser)}
        {/* Next: as many as possible relatives, in the limit of the constant */}
        {displayedRelatives.map((granteeUser: Relation, key) => {
          const { grantor } = granteeUser;
          return AvatarTooltip(grantor.id, grantor);
        })}
        {/* At the end: the remaining total of relatives which open a menu to display them */}
        {remainingRelatives.length && (
          <IconButton sx={{ p: 0 }} onClick={handleClick}>
            {unreadData && remainingRelatives.some((relative) => unreadData[relative.grantor.id]) ? (
              <StyledBadge overlap='circular' anchorOrigin={{ vertical: 'top', horizontal: 'right' }} variant='dot'>
                <Avatar
                  sx={{
                    borderColor: (theme) => (remainingRelatives.some((relative) => relative.grantor.id === currentUser?.id) ? `${theme.palette.primary.main} !important` : 'initial'),
                  }}
                >{`+${remainingRelatives.length}`}</Avatar>
              </StyledBadge>
            ) : (
              <Avatar
                sx={{
                  borderColor: (theme) => (remainingRelatives.some((relative) => relative.grantor.id === currentUser?.id) ? `${theme.palette.primary.main} !important` : 'initial'),
                }}
              >{`+${remainingRelatives.length}`}</Avatar>
            )}
          </IconButton>
        )}
      </AvatarGroup>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} onClick={handleClose}>
        {remainingRelatives.map((granteeUser: Relation, key) => {
          const { grantor } = granteeUser;
          return (
            <MenuItem onClick={() => handleChangeUser(grantor)} key={grantor.toString()}>
              {AvatarIcon(grantor.id, grantor, false)}
              <Typography variant='body1' sx={{ ml: 1 }}>
                {getFullName(grantor)}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default AvatarBloc;
