import { TableCell, Typography } from '@mui/material';
import { Result } from 'models/result.model';
import { FC } from 'react';

const DetailTabPanelBodyIsTitle: FC<{ val: Result }> = ({ val }) => {
  return (
    <>
      <TableCell colSpan={5}>
        <Typography
          variant='subtitle1'
          sx={{
            textTransform: 'uppercase',
          }}
        >
          {val.name}
        </Typography>
        {val.analysis_method && <Typography variant='body2'>{val.analysis_method}</Typography>}
      </TableCell>
    </>
  );
};

export default DetailTabPanelBodyIsTitle;
