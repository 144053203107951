import { Checkbox, FormControl, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Selection } from 'models/record.model';
import { FC } from 'react';

const DetailMultiSelectRecord: FC<{
  selection: Selection[];
  setSelection: (array: Selection[]) => void;
}> = ({ selection, setSelection }) => {
  const { t } = useTranslation();
  const handleChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    let newArray = value.map((idx: number) => selection[idx]);
    setSelection([...newArray]);
  };

  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <Select
        disabled={selection.length === 0}
        multiple
        value={selection.map((elm, idx) => idx)}
        onChange={handleChange}
        renderValue={(list) => `${list.length} ${t('result_doctor.selectedElements')}`}
      >
        {selection.map((elem, idx) => (
          <MenuItem key={elem.ref} value={idx}>
            <Checkbox checked />
            <ListItemText primary={elem.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DetailMultiSelectRecord;
