import { Button, Card, CardContent, Dialog, DialogContent, DialogTitle, Grid, IconButton, SvgIcon, Typography } from '@mui/material';
import LoginForm from 'pages/shared/landing-page/form/LoginForm';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconClose } from 'resources/icons/close.svg';

type Props = {
  redirect?: string;
};

const ConnectAccountDialog: FC<Props> = ({ redirect }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
            <Typography>{t('bodyTemplate.have_account')}</Typography>
            <Button onClick={() => setOpen(true)}>{t('bodyTemplate.connect_to_account')}</Button>
          </CardContent>
        </Card>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ position: 'relative' }}>
          <Typography variant='h6' sx={{ pt: 2 }}>
            {t('landing_page.login.title')}
          </Typography>
          <IconButton size='small' onClick={handleClose} sx={{ position: 'absolute', zIndex: 2, top: 2, right: 2 }}>
            <SvgIcon fontSize='small' component={IconClose} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <LoginForm redirectionUrl={redirect} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConnectAccountDialog;
