import { Address } from 'models/profile.model';
import { AppointmentFormAction, AppointmentFormActionType } from 'store/actions/appointmentForm.actions';

type AddressForm = {
  type: string;
  address: Address;
};
export type PatientForm = {
  id: number | null;
  name: string | null;
};
type AppointmentFormState = {
  patient: PatientForm;
  medical_acts: number[];
  prescriptions: File[];
  remarks: string;
  options: number[];
  address: AddressForm;
  slot: Date | null;
};
const appointmentFormInitialState: AppointmentFormState = {
  patient: {
    id: null,
    name: null,
  },
  medical_acts: [],
  prescriptions: [],
  remarks: '',
  options: [],
  address: {
    type: 'at_home',
    address: {
      company: '',
      number: '',
      street: '',
      complement: '',
      zip_code: '',
      city: '',
      country_iso: '',
    },
  },
  slot: null,
};

export default function appointmentFormReducer(prevState = appointmentFormInitialState, action: AppointmentFormAction) {
  let newPrescriptions: File[] = [];
  let newNumberList: number[] = [];
  let currentIndex: number = -1;
  switch (action.type) {
    case AppointmentFormActionType.SET_PATIENT:
      return {
        ...prevState,
        patient: action.patient,
      };
    case AppointmentFormActionType.ADD_PRESCRIPTION:
      newPrescriptions = prevState.prescriptions;
      newPrescriptions.push(action.file);
      return {
        ...prevState,
        prescriptions: newPrescriptions,
      };
    case AppointmentFormActionType.DELETE_PRESCRIPTION:
      newPrescriptions = prevState.prescriptions;
      newPrescriptions.splice(action.index, 1);
      return {
        ...prevState,
        prescriptions: newPrescriptions,
      };
    case AppointmentFormActionType.HANDLE_MEDICAL_ACTS:
      return {
        ...prevState,
        medical_acts: action.index,
      };
    case AppointmentFormActionType.HANDLE_TEXT_AREA:
      return {
        ...prevState,
        remarks: action.content,
      };
    case AppointmentFormActionType.HANDLE_OPTIONS:
      newNumberList = prevState.options;
      currentIndex = newNumberList.indexOf(action.index);
      currentIndex === -1 ? newNumberList.push(action.index) : newNumberList.splice(currentIndex, 1);
      return {
        ...prevState,
        options: newNumberList,
      };
    case AppointmentFormActionType.HANDLE_ADDRESS_TYPE:
      return {
        ...prevState,
        address: {
          type: action.option,
          address: prevState.address.address,
        },
      };
    case AppointmentFormActionType.HANDLE_ADDRESS_ONLY:
      return {
        ...prevState,
        address: {
          type: prevState.address.type,
          address: action.address,
        },
      };
    case AppointmentFormActionType.HANDLE_SLOT:
      return {
        ...prevState,
        slot: action.slot,
      };
    case AppointmentFormActionType.RESET:
      return {
        ...appointmentFormInitialState,
        options: [],
        prescriptions: [],
      };

    default:
      return prevState;
  }
}
