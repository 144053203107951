import { Button, TableCell, TableRow, Typography } from '@mui/material';
import MissingData from 'components/missing-data';
import ContextLink from 'components/_base/ContextLink';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { convertDate } from 'utils/func/Date.func';

const DesktopResultsTable: FC<{ result: any }> = ({ result }) => {
  const { t } = useTranslation();

  return (
    <TableRow
      hover
      sx={{
        '& > td > p, & > td > a > p': {
          ...(result.consultation_time === null && {
            fontWeight: 'bold',
          }),
        },
      }}
    >
      <TableCell>
        <Typography variant='body1'>{`${convertDate(result.prescription_date)}` || t('common.missingData')}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1'>{`${convertDate(result.sampling_time)}` || t('common.missingData')}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1'>{result.reference || <MissingData />}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1'>{`${result.patient.first_name} ${result.patient.last_name}` || <MissingData />}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1'>{`${convertDate(result.patient.birth_date)}` || t('common.missingData')}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{result.patient.cns}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1'>{`${result.issuer?.title ?? ''} ${result.issuer?.first_name ?? ''} ${result.issuer?.last_name ?? ''}` || t('common.missingData')}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1'>{convertDate(result.last_report_time, true) || <MissingData />}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1'>{result.status === 'partial' ? t('results.list.table.partialLabel') : t('results.list.table.completeLabel')}</Typography>
      </TableCell>
      <TableCell>
        <ContextLink
          to='DETAIL_RESULT'
          params={{
            id: result.reference,
          }}
        >
          <Button variant='text'>{t('results.list.table.moreButtonLabel')}</Button>
        </ContextLink>
      </TableCell>
    </TableRow>
  );
};

export default DesktopResultsTable;
