import { Grid, SvgIcon, TextField, CardActions, ToggleButton, Typography, Button } from '@mui/material';
import { useIsMobileView } from 'hooks';
import { FC, useMemo } from 'react';
import { Props } from '../index';
import { ReactComponent as BloodCentersIcon } from 'resources/icons/blood_centers.svg';
import { ReactComponent as CalendarIcon } from 'resources/icons/calendar.svg';
import { ReactComponent as UserIcon } from 'resources/icons/user.svg';
import { convertDate } from 'utils/func/Date.func';
import { useForm } from 'react-hook-form';
import { Appointment } from 'models/appointment.model';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

const ValidationStep: FC<Props & { onSubmit: (data: Appointment) => void; loading: boolean }> = ({ state, handleBack, medicalActs, onSubmit, loading }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const watchedAddress = state.address;
  const { register, handleSubmit } = useForm<{ comment: string }>();

  const addressToString = useMemo(() => {
    if (!watchedAddress) return t('appointment.book.steps.validation.missing.address');
    let response = '';
    if (watchedAddress.number) {
      response += ` ${watchedAddress.number}`;
    }
    response += ` ${watchedAddress.street}`;
    if (watchedAddress.complement) {
      response += ` ${watchedAddress.complement}`;
    }
    if (watchedAddress.zip_code) {
      response += ` ${watchedAddress.zip_code}`;
    }
    response += ` ${watchedAddress.city_name} ${t(
      /*@ts-ignore*/
      `countries.${watchedAddress.country_iso}`,
    )}`;
    return response;
  }, [watchedAddress, t]);

  return (
    <form
      onSubmit={handleSubmit((data) => {
        onSubmit({ ...state, comment: data.comment });
      })}
    >
      <Grid container spacing={3} alignContent='center'>
        <Grid item xs={12} md={6} mb={2}>
          <Typography variant='h3' sx={{ wordWrap: 'break-word' }}>
            {t('appointment.book.steps.title')}
          </Typography>
          <Typography marginTop={isMobileView ? 3 : 0} marginBottom={3} variant='h2'>
            {t('appointment.book.steps.validation.title')}
          </Typography>
          <Grid mb={3}>
            {medicalActs
              .filter((m) => !m.additional && Array.from(new Set(state.patients.map((patient) => patient.medical_acts).flat())).includes(`${m.id}`))
              .map((medAct, idx) => (
                <ToggleButton key={idx} value={medAct.id} disabled sx={{ margin: '5px' }}>
                  <Typography variant='body1'>{medAct.name}</Typography>
                </ToggleButton>
              ))}
          </Grid>
          <TextField multiline minRows={4} label={t(`appointment.book.steps.reasons.my_remarks`)} {...register('comment')} fullWidth variant='filled' />
        </Grid>
        <Grid item xs={12} md={6} mb={2}>
          <Grid container spacing={3} display='flex' textAlign='center'>
            <Grid item xs={12}>
              <SvgIcon component={BloodCentersIcon} inheritViewBox fontSize='large' />
              <Typography>
                {
                  //@ts-ignore
                  t(`appointment.book.steps.place.${watchedAddress.type}`)
                }
              </Typography>
              <Typography>{addressToString}</Typography>
            </Grid>
            <Grid item xs={12}>
              <SvgIcon component={CalendarIcon} inheritViewBox fontSize='large' />
              <Typography>{convertDate(state.slot, false, 'eeee d MMMM yyyy')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <SvgIcon component={UserIcon} inheritViewBox fontSize='large' />
              {state.patients.map((patient, index) => (
                <Typography key={index}>{`${patient.last_name} ${patient.first_name}`}</Typography>
              ))}
            </Grid>
          </Grid>
          <CardActions
            sx={{
              justifyContent: {
                xs: 'space-between',
                md: 'flex-end',
              },
              pt: 2,
            }}
          >
            <Button color='inherit' onClick={handleBack}>
              {t('appointment.book.action_buttons.previous')}
            </Button>
            <LoadingButton type='submit' variant='contained' loading={loading}>
              {t('appointment.book.action_buttons.done')}
            </LoadingButton>
          </CardActions>
        </Grid>
      </Grid>
    </form>
  );
};

export default ValidationStep;
