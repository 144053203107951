import { Analysis } from 'models/analysis.model';
import { PrescriptionProfile } from 'models/prescription-profile.model';

export enum PrescriptionProfileActionType {
  NEW_ADD_ITEM = 'NEW_PRESCRIPTION_PROFILE_ADD_ITEM',
  NEW_REMOVE_ITEM = 'NEW_PRESCRIPTION_PROFILE_REMOVE_ITEM',
  NEW_CLEAR_ITEMS = 'NEW_PRESCRIPTION_PROFILE_CLEAR_ITEMS',
  NEW_SET_NAME = 'NEW_PRESCRIPTION_PROFILE_SET_NAME',
  NEW_SET_DESCRIPTION = 'NEW_PRESCRIPTION_PROFILE_SET_DESCRIPTION',
  NEW_SET_COMMENT = 'NEW_PRESCRIPTION_PROFILE_SET_COMMENT',
  NEW_CLEAR_PROFILE = 'NEW_PRESCRIPTION_PROFILE_CLEAR_PROFILE',
  EDIT_SET_PROFILE = 'EDIT_PRESCRIPTION_PROFILE_SET_PROFILE',
  EDIT_CLEAR_PROFILE = 'EDIT_PRESCRIPTION_PROFILE_CLEAR_PROFILE',
  ALL_CLEAR = 'ALL_PRESCRIPTION_PROFILE_CLEAR',
}

export type PrescriptionProfileAction =
  | {
      type: PrescriptionProfileActionType.NEW_ADD_ITEM;
      analysis: any;
    }
  | {
      type: PrescriptionProfileActionType.NEW_REMOVE_ITEM;
      analysis_reference: string;
    }
  | {
      type: PrescriptionProfileActionType.NEW_CLEAR_ITEMS;
    }
  | {
      type: PrescriptionProfileActionType.NEW_SET_NAME;
      name: string;
    }
  | {
      type: PrescriptionProfileActionType.NEW_SET_DESCRIPTION;
      description: string;
    }
  | {
    type: PrescriptionProfileActionType.NEW_SET_COMMENT;
    comment: string;
  }
  | {
      type: PrescriptionProfileActionType.NEW_CLEAR_PROFILE;
    }
  | {
      type: PrescriptionProfileActionType.EDIT_SET_PROFILE;
      profile: PrescriptionProfile;
    }
  | {
      type: PrescriptionProfileActionType.EDIT_CLEAR_PROFILE;
    }
  | {
      type: PrescriptionProfileActionType.ALL_CLEAR;
    };

export const addNewPrescriptionProfileItem = (analysis: Analysis): PrescriptionProfileAction => ({
  type: PrescriptionProfileActionType.NEW_ADD_ITEM,
  analysis,
});

export const removeNewPrescriptionProfileItem = (analysis_reference: string): PrescriptionProfileAction => ({
  type: PrescriptionProfileActionType.NEW_REMOVE_ITEM,
  analysis_reference,
});

export const clearNewPrescriptionProfileItems = (): PrescriptionProfileAction => ({
  type: PrescriptionProfileActionType.NEW_CLEAR_ITEMS,
});

export const setNewPrescriptionProfileName = (name: string): PrescriptionProfileAction => ({
  type: PrescriptionProfileActionType.NEW_SET_NAME,
  name,
});

export const setNewPrescriptionProfileDescription = (description: string): PrescriptionProfileAction => ({
  type: PrescriptionProfileActionType.NEW_SET_DESCRIPTION,
  description,
});

export const setNewPrescriptionProfileComment = (comment: string): PrescriptionProfileAction => ({
  type: PrescriptionProfileActionType.NEW_SET_COMMENT,
  comment,
});

export const clearNewPrescriptionProfile = (): PrescriptionProfileAction => ({
  type: PrescriptionProfileActionType.NEW_CLEAR_PROFILE,
});

export const setEditPrescriptionProfile = (profile: PrescriptionProfile): PrescriptionProfileAction => ({
  type: PrescriptionProfileActionType.EDIT_SET_PROFILE,
  profile,
});

export const clearEditPrescriptionProfile = (): PrescriptionProfileAction => ({
  type: PrescriptionProfileActionType.EDIT_CLEAR_PROFILE,
});

export const clearAllPrescriptionProfile = (): PrescriptionProfileAction => ({
  type: PrescriptionProfileActionType.ALL_CLEAR,
});
