import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import ContextLink from 'components/_base/ContextLink';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import AddRelativeWithTokenForm from '../shared/AddRelativeWithTokenForm';

const AddRelative: FC = () => {
  const { t } = useTranslation();

  return (
    <BodyTemplate title={t('bodyTemplate.addRelative')} backButton>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} display='flex' alignItems='stretch'>
          <Card sx={{ width: '100%' }}>
            <CardHeader title={<Typography variant='h1'>{t('profile.relatives.add.withToken')}</Typography>} sx={{ mb: 4 }} />
            <CardContent>
              <Box mb={4}>
                <Typography>{t('profile.relatives.add.desc_to_access_a_result')}</Typography>
              </Box>
              <AddRelativeWithTokenForm />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} display='flex' alignItems='stretch'>
          <Card sx={{ width: '100%' }}>
            <CardHeader title={<Typography variant='h1'>{t('profile.relatives.add.withoutToken')}</Typography>} sx={{ mb: 4 }} />
            <CardContent sx={{ mb: 4 }}>
              <Typography>{t('profile.relatives.add.desc_fulfill_form')}</Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <ContextLink to='PROFILE_ADD_RELATIVE_FORM'>
                <Button variant='contained'>{t('profile.relatives.add.add_relative')}</Button>
              </ContextLink>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default AddRelative;
