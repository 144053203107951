import { Card, CardContent, Grid, Tabs, Tab } from '@mui/material';
import ContextLink from 'components/_base/ContextLink';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';

export type RelativeTab = 'me' | 'relative';

const IndexProfileTabSwitcher: FC<{ profileType: string }> = ({ profileType }) => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState(profileType);

  const handleChange = (event: React.SyntheticEvent, selectedTab: RelativeTab) => {
    setSelectedTab(selectedTab);
  };

  return (
    <>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Tabs value={selectedTab} onChange={handleChange} variant='scrollable' sx={{ pb: 1 }}>
              <Tab label={<ContextLink to={'PROFILE'}>{t('navigation.shared.me_or_my_relatives.me')}</ContextLink>} value={'me'} />
              <Tab label={<ContextLink to={'PROFILE_RELATIVE'}>{t('navigation.shared.me_or_my_relatives.my_relatives')}</ContextLink>} value={'relative'} />
            </Tabs>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default IndexProfileTabSwitcher;
