import { Address } from './profile.model';

export type Directory = {
  public_uri?: string;
  id: number;
  type: SearchType;
  identity: string;
  address: Address;
  specialities: Speciality[];
  photo?: string;
  practice_name: string;
  phone: string;
  fax?: string;
  email?: string;
  website: string;
  distance?: number;
  doctena_doctor_id?: string;
  doctena_practice_e_id?: string;
  doctena_agenda_id?: string;
};

export type DisplayedDirectories = {
  letter: string;
  directories: Directory[];
};

export type DirectoryFormValues = {
  type: SearchType;
  city: string;
  name: string;
  speciality: number;
};

export type Speciality = {
  id: number;
  name: string;
};

export enum SearchType {
  PROFESSIONAL = 'professional',
  HEALTH_INSTITUTION = 'health_institution',
}
