import { LoadingButton } from '@mui/lab';
import { Card, CardContent, Grid, IconButton, TextField, Typography } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { GenericPaginator } from 'components/generic-table';
import { useNotification } from 'hooks';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { DEFAULT_PAGINATION_SIZE } from 'utils/Constants';
import AnalysisResults from './components/AnalysisResults';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';

const Analysis: FC = () => {
  const [data, setData] = useState<any>({});
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const paginatorData: GenericPaginator = {
    total: 0,
    size: DEFAULT_PAGINATION_SIZE,
    page: 1,
  };

  const [paginator, setPaginator] = useState(paginatorData);
  const { notification } = useNotification();
  const { t } = useTranslation();
  const nameRef = useRef<any>(null);

  const getAnalysisFunc = useCallback(async () => {
    setSubmitting(true);
    await api.analysis
      .getAnalysis(paginator.size, paginator.page, nameRef.current?.value)
      .then((response) => {
        setData(response);
        setPaginator({
          total: response.total,
          size: response.size,
          page: response.page,
        });
      })
      .catch(() => {
        notification(t('analysis.notification.error'), 'error');
      });
    setSubmitting(false);
  }, [notification, paginator.size, paginator.page, t, nameRef]);

  const clearSearch = useCallback(() => {
    nameRef.current.value = null;
    getAnalysisFunc();
  }, [nameRef, getAnalysisFunc]);

  useEffect(() => {
    getAnalysisFunc();
  }, [getAnalysisFunc]);

  const handlePaginatorData = (data: GenericPaginator) => {
    setPaginator(data);
  };

  return (
    <BodyTemplate title={t('bodyTemplate.doctor.analyses')}>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant='h2' mb={3}>
                {t('analysis.search_button')}
              </Typography>

              <form>
                <Grid container display='flex' alignItems='center' spacing={1}>
                  <Grid item xs={12} md={5}>
                    <TextField
                      variant='filled'
                      label={t('analysis.form.name')}
                      inputRef={nameRef}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <IconButton onClick={clearSearch}>
                            <ClearIcon />
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <LoadingButton variant='contained' type='submit' fullWidth loading={isSubmitting} onClick={getAnalysisFunc}>
                      {t('common.action.search')}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <AnalysisResults data={data} paginatorData={paginator} handlePaginatorAction={handlePaginatorData} />
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default Analysis;
