import { Backdrop, CircularProgress } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useContextRedirection, useIsDoctor, useLocale, useNotification } from 'hooks';
import QuestionnaireDraft from './edit';
import { SectionQuestionnaire } from 'models/questionnaire.model';
import { useParams } from 'react-router-dom';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import QuestionnairePdf from './view';
import RoutePaths from 'utils/RoutePaths';
import { useTranslation } from 'react-i18next';

const Questionnaire: FC = () => {
  const { t } = useTranslation();
  const locale = useLocale();
  const [questionnaire, setQuestionnaire] = useState<SectionQuestionnaire>();
  const { idResult: folder, questionnaire: questionnaireId } = useParams();
  const [isDraft, setIsDraft] = useState(false);
  const [loading, setLoading] = useState(false);
  const { notification } = useNotification();
  const isDoctor = useIsDoctor();
  const navigate = useContextRedirection();

  useEffect(() => {
    setLoading(true);
    if (folder !== undefined && questionnaireId !== undefined) {
      api.questionnaires
        .getQuestionnaireById(locale, questionnaireId)
        .then(async (quest) => {
          setQuestionnaire(quest);
          if (quest.status === 'draft') {
            if (isDoctor) {
              navigate(RoutePaths['DETAIL_RESULT']({ id: folder }));
            } else {
              setIsDraft(true);
            }
          }
        })
        .catch(() => notification(t('results.details.notification.errorViewingQuestionnaire'), 'error'))
        .finally(() => setLoading(false));
    }
  }, [folder, locale, notification, questionnaireId, t, navigate, isDoctor]);

  return (
    <BodyTemplate title={t('bodyTemplate.doctor.questionnaire')} backButton>
      {!loading && questionnaire ? (
        isDraft ? (
          <QuestionnaireDraft questionnaire={questionnaire} submit={setIsDraft} />
        ) : (
          <QuestionnairePdf questionnaire={questionnaire} />
        )
      ) : (
        <Backdrop
          open={loading}
          sx={{
            position: 'absolute',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
    </BodyTemplate>
  );
};

export default Questionnaire;
