import { APIConfiguration, axiosMYLAB } from 'api';
import { InvoicesResult, InvoiceStatus } from 'models/invoices.model';
import { Paginate } from 'models/utils.model';
import { BaseService } from './base.service';

class InvoicesService extends BaseService {
  async getInvoices(size: number, page: number, id: number, status?: InvoiceStatus) {
    const { data } = await axiosMYLAB.get<Paginate<InvoicesResult>>(`${this.apiConfig.INVOICES.GET(this.getLang())}`, {
      params: {
        patient_id: id,
        status: status ?? InvoiceStatus.OPEN,
        size,
        page,
      },
    });

    this.log(`getInvoices()`);

    return { ...data };
  }

  async getInvoiceByReference(reference: string) {
    const { data } = await axiosMYLAB.get<InvoicesResult>(`${this.apiConfig.INVOICES.GET_BY_REFERENCE(this.getLang(), reference)}`);

    this.log(`getInvoiceByReference()`);

    return { ...data, dossierReference: data.dossier_reference };
  }

  async getInvoiceByReferenceAndAmount(reference: string, amount: number, email?: string) {
    const { data } = await axiosMYLAB.get<InvoicesResult>(`${this.apiConfig.INVOICES.GET_BY_REFERENCE_AND_AMOUNT(this.getLang())}`, {
      params: {
        reference,
        amount,
        email: email ?? null,
      },
    });

    this.log(`getInvoiceByReferenceAndAmount()`);

    return { ...data };
  }

  async getInvoiceFileByReference(reference: string) {
    const { data } = await axiosMYLAB.get<any>(`${this.apiConfig.INVOICES.GET_FILE_BY_REFERENCE(this.getLang(), reference)}`, { responseType: 'blob' });

    this.log(`getInvoiceFileByReference()`);

    return data;
  }
}

export default (apiConfig: APIConfiguration) => {
  return new InvoicesService(apiConfig);
};
