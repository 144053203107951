import { Box, Grid, Icon, Typography } from '@mui/material';
import { Analysis } from 'models/analysis.model';
import { FC } from 'react';

type AnalysisAttributesProps = {
  analysis: Analysis;
};

const AnalysisAttributes: FC<AnalysisAttributesProps> = ({ analysis }) => {
  return (
    <Grid container display='flex' textAlign='center' spacing={2} p={3}>
      {analysis.attributes.map((attribute, key) => {
        return (
          <Grid item xs={12} sm={6} md={3} key={key} py={2} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                border: (theme) => `1px solid ${theme.palette.divider}`,
              }}
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              py={3}
            >
              <Icon sx={{ fontSize: 48, mb: 1, height: 'auto' }}>
                <img src={attribute.icon} alt={attribute.tag} />
              </Icon>
              <Typography variant='subtitle1'>{attribute.name}</Typography>
              <Typography>{attribute.value}</Typography>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AnalysisAttributes;
