import theme_color from 'resources/theme/theme.colors';

import { alpha, createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          borderRadius: 4,
          border: '2px solid',
          borderColor: theme_color.palette.secondary.main,
          padding: '0px 16px',
          // minWidth: '300px',
          '& .MuiSvgIcon-root': {
            color: theme_color.palette.text.primary,
          },
          '&:hover': {
            borderColor: theme_color.palette.primary.main,
            backgroundColor: 'transparent',
          },
          '&.Mui-focused': {
            borderColor: theme_color.palette.primary.main,
            backgroundColor: 'transparent',
          },
          '&.Mui-disabled': {
            borderColor: theme_color.palette.text.disabled,
            backgroundColor: alpha(theme_color.palette.text.disabled, 0.1),
          },
          '&.Mui-error': {
            borderColor: theme_color.palette.error.main,
            backgroundColor: 'transparent',
          },
        },
        underline: {
          '&&&:before': {
            borderBottom: 'none',
          },
          '&&:after': {
            borderBottom: 'none',
          },
        },
        input: {
          paddingBottom: '4px',
          '&:-webkit-autofill': {
            borderRadius: 0,
            backgroundColor: 'transparent',
          },
          '&:focus': { backgroundColor: 'transparent' },
        },
        multiline: {
          padding: '24px',
          borderRadius: 4,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          paddingLeft: '24px',
          paddingRight: '24px',
          boxSizing: 'border-box',
          '&.Mui-focused': {
            color: theme_color.palette.text.secondary,
          },
        },
      },
    },
  },
});

export default theme;
