import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, SvgIcon, Typography, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AnalysisReference, AnalysisReferenceOption } from 'models/analysis.model';
import { FC, useState } from 'react';
import { ReactComponent as IconClose } from 'resources/icons/close.svg';
import { useForm } from 'react-hook-form';
import GenericForm from './AnalysisGenericForm';

type Props = {
  open: boolean;
  setOpen: (bool: boolean) => void;
  analysis: AnalysisReference[];
  submitAction: (data: AnalysisReference[]) => void;
};

const AnalysisQuestionsDialog: FC<Props> = ({ open, setOpen, analysis, submitAction }) => {
  const { t } = useTranslation();
  const [index, setIndex] = useState<number>(0);
  const [analysisToAdd, setAnalysisToAdd] = useState<AnalysisReference[]>(
    analysis.map((item: AnalysisReference, index: number) => ({
      id: index.toString(),
      ...item,
    })),
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    shouldFocusError: true,
    mode: 'onChange',
  });
  let analysesFiltered: AnalysisReference[] = analysisToAdd.filter((res: AnalysisReference) => !res.motivation && res.questions && res.questions?.length > 0);

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: 'fit-content',
        },
      }}
    >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        <Box display='flex' sx={{ width: '100%' }} justifyContent='space-between' alignItems='center'>
          <Typography>{t('prescriptions_making.step1.dialog.title2')}</Typography>
          <IconButton size='small' onClick={() => setOpen(false)}>
            <SvgIcon fontSize='small' component={IconClose} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={handleSubmit((res: any) => {
            let response: AnalysisReferenceOption[] = Object.keys(res).map((key: any) => ({
              question: key,
              motivation: false,
              value: res[key as keyof Object],
            }));

            setAnalysisToAdd((prev: any) => {
              prev[analysesFiltered[index].id!].options = response;
              return prev;
            });
            setIndex(index + 1);

            if (index === analysesFiltered.length - 1) {
              submitAction(analysisToAdd);
              setOpen(false);
            }
          })}
        >
          {analysesFiltered[index].questions.map((question: any, key: any) => {
            return (
              <Box py={1} key={key}>
                <GenericForm errors={errors} register={register} questions={question} />
              </Box>
            );
          })}
          <Button variant='contained' type='submit'>
            {t('prescriptions_making.step1.dialog.button')}
          </Button>
        </form>
      </DialogContent>
      {analysesFiltered[index].refund_cns && (
        <DialogActions>
          <Button
            variant='contained'
            onClick={() => {
              if (index + 1 < analysesFiltered.length) setIndex(index + 1);
              else {
                submitAction(analysisToAdd);
                setOpen(false);
              }
            }}
          >
            {t('prescriptions_making.step1.dialog.add')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default AnalysisQuestionsDialog;
