import { MenuItem, TextField } from '@mui/material';
import { GenericFormProps } from 'models/result.doctor.model';
import { FC } from 'react';
import { Controller } from 'react-hook-form';

export const DraftGenericListForm: FC<GenericFormProps> = ({ question, control }) => {
  return (
    <Controller
      control={control}
      rules={{ required: question.required }}
      name={question.id.toString()}
      defaultValue={question.answers[0] ? question.answers[0] : ''} // make a default value
      render={({ field }) => (
        <TextField
          select
          {...field}
          label={question.name}
          variant='standard'
          fullWidth
          InputLabelProps={{
            sx: { p: 0 },
          }}
        >
          {question.values.map((value) => {
            return (
              <MenuItem value={value?.id} key={value?.id}>
                {value?.name}
              </MenuItem>
            );
          })}
        </TextField>
      )}
    />
  );
};
