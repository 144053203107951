import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, LinkProps } from 'react-router-dom';
import RoutePaths from 'utils/RoutePaths';

export type RoutePathsKey = keyof typeof RoutePaths;

type ParametersExtractor<T> = T extends (...args: any) => any ? Parameters<T>[0] : never;

type ContextLinkProps<T extends RoutePathsKey> = Omit<LinkProps, 'to'> & {
  to: T;
  params?: ParametersExtractor<(typeof RoutePaths)[T]>;
  external?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  externalDynamicParams?: string;
  externalNewTab?: boolean;
};

const ContextLink: <T extends RoutePathsKey>(p: ContextLinkProps<T>) => ReactElement<ContextLinkProps<T>> = ({
  external = false,
  fullWidth = false,
  disabled = false,
  externalDynamicParams = undefined,
  externalNewTab = true,
  ...props
}) => {
  const { i18n } = useTranslation();
  const locale = useMemo(() => i18n.language.split('-').shift(), [i18n.language]);

  const routeToReach = RoutePaths[props.to];
  // @ts-ignore
  const path = typeof routeToReach === 'function' && props.params ? routeToReach(props.params) : routeToReach;
  const externalPath = `${path}${externalDynamicParams ?? ''}`;
  return external ? (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a
      {...props}
      // @ts-ignore
      href={`${externalPath}`}
      style={{
        textDecoration: 'none',
        textTransform: 'initial',
        ...(fullWidth && {
          width: '100%',
        }),
      }}
      target={externalNewTab ? '_blank' : '_self'}
      rel='noreferrer'
    />
  ) : (
    <>
      {!disabled ? (
        <Link
          {...props}
          to={`/${locale}${path}`}
          style={{
            textDecoration: 'none',
            textTransform: 'initial',
            color: 'currentColor',
            ...(fullWidth && {
              width: '100%',
            }),
          }}
        />
      ) : (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a
          {...props}
          style={{
            textDecoration: 'none',
            textTransform: 'initial',
            ...(fullWidth && {
              width: '100%',
            }),
          }}
          target='_blank'
          rel='noreferrer'
        />
      )}
    </>
  );
};

export default ContextLink;
