import theme_color from 'resources/theme/theme.colors';

import { createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: theme_color.palette.common.white,
          padding: '4px 24px',
          fontWeight: 700,
        },
      },
    },
  },
});

export default theme;
