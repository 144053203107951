import { Stack, Typography } from '@mui/material';
import api from 'api';
import { useContextRedirection, useNotification } from 'hooks';
import { ActivateNewPatientRequestForm, TokenRelativeRequestForm, VerifyTokenRequest, VerifyTokenResponse } from 'models/user.model';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { loginUser } from 'store/actions/session.actions';
import { tryCatch } from 'utils';
import { convertDate } from 'utils/func/Date.func';
import RoutePaths from 'utils/RoutePaths';
import NewPatientForm from './NewPatientForm';
import TokenRelativeForm from './TokenRelativeForm';
import VerifyTokenForm from './VerifyTokenForm';

const LoginTokenForm: FC<{ setOpenDialog: (open: boolean) => void }> = ({ setOpenDialog }) => {
  const { t } = useTranslation();
  const { notification } = useNotification();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [verifyResponse, setVerifyResponse] = useState<VerifyTokenResponse | undefined>(undefined);
  const navigate = useContextRedirection();
  const dispatch = useDispatch();

  const submitVerifyToken = (request: VerifyTokenRequest) => {
    setSubmitting(true);
    setToken(request.token);

    api.authentication
      .verifyToken(request)
      .then((response) => setVerifyResponse(response))
      .catch((error) => {
        setVerifyResponse(undefined);
        if (error.response.status === 404) {
          notification(t('landing_page.login.token.notification.error.incorrectToken'), 'error');
        } else {
          notification(t('landing_page.login.token.notification.error.verifyToken'), 'error');
        }
      })
      .finally(() => setSubmitting(false));
  };

  const submitTokenRelative = (request: TokenRelativeRequestForm) => {
    setSubmitting(true);
    const { birth_date } = request;
    const birth_dateShort = convertDate(birth_date, false, 'yyyy-MM-dd');
    tryCatch(
      () =>
        api.authentication
          .tokenRelative({
            ...request,
            token: token!,
            birth_date: birth_dateShort,
          })
          .then(() => {
            setSubmitting(false);
            dispatch(loginUser());
            navigate(RoutePaths['HOME']);
          }),
      () => {
        setSubmitting(false);
        notification(t('landing_page.login.token.notification.error.verifyToken'), 'error');
      },
    );
  };

  const submitNewPatient = (request: ActivateNewPatientRequestForm) => {
    setSubmitting(true);
    const { birth_date } = request;
    const birth_dateShort = convertDate(birth_date, false, 'yyyy-MM-dd');

    api.authentication
      .activateNewPatient({
        ...request,
        token: token!,
        birth_date: birth_dateShort,
      })
      .then(() => {
        dispatch(loginUser());
        navigate(RoutePaths['HOME']);
      })
      .catch(() => notification(t('landing_page.login.token.notification.error.newPatient'), 'error'))
      .finally(() => {
        setSubmitting(false);
      });
  };

  const displayForm = () => {
    if (!verifyResponse) {
      return <VerifyTokenForm onSubmit={submitVerifyToken} isSubmitting={isSubmitting} />;
    }
    if (verifyResponse.is_relative || verifyResponse.is_not_activable) {
      return <TokenRelativeForm onSubmit={submitTokenRelative} isSubmitting={isSubmitting} token={token} />;
    }
    return <NewPatientForm onSubmit={submitNewPatient} isSubmitting={isSubmitting} token={token} />;
  };

  return (
    <Stack
      direction='column'
      spacing={3}
      sx={{
        minWidth: '100%',
        minHeight: '100%',
        justifyContent: 'center',
        marginTop: '0px',
      }}
    >
      <Stack direction='column' spacing={1} alignItems='center'>
        <Typography variant='body1'>{t('landing_page.login.token.title')}</Typography>
      </Stack>
      {displayForm()}
    </Stack>
  );
};

export default LoginTokenForm;
