import { InfoOutlined } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Card, CardContent, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import { useContextRedirection, useNotification } from 'hooks';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StripeLogo from 'resources/images/logo_stripe.svg';
import { formatToLocaleCurrency } from 'utils/func/Number.func';
import RoutePaths from 'utils/RoutePaths';
import { DetailInvoicesGetFileProps } from '../DetailInvoices';

export const DetailInvoicesPaymentForm: FC<DetailInvoicesGetFileProps> = (data) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useContextRedirection();

  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const [paymentInvalid, setPaymentInvalid] = useState(false);
  const { notification } = useNotification();

  const CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
    style: {
      base: {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: `${theme.typography.body1.fontSize}px`,
      },
    },
    classes: {
      base: 'customStripe',
    },
    hidePostalCode: true,
    disabled: processing,
  };

  const handleSubmit = async () => {
    setProcessing(true);

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmCardPayment(data.client_secret, {
      payment_method: {
        card: elements.getElement(CardElement)!,
      },
    });

    if (result.error) {
      notification(`${result.error.message}`, 'error');
      setPaymentInvalid(true);
      setProcessing(false);
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        // TOFIX: wait 1s because of Stripe async call and API do not wait till the end
        setTimeout(() => {
          navigate(RoutePaths['INVOICES_OPEN']);
          notification(`${t('invoices.details.notification.successPayment')}`, 'success');
        }, 1000);
      }
    }
  };

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='h2'>{t('invoices.details.pay_online')}</Typography>
          </Grid>
          <Grid container item xs={12} justifyContent='center'>
            <Grid item xs={12} sm={9}>
              <Box
                sx={{
                  ...(theme.components?.MuiFilledInput?.styleOverrides?.root as object),
                  borderRadius: 1,
                  ...(paymentInvalid && {
                    borderColor: theme.palette.error.main,
                  }),
                  height: '100%',
                }}
              >
                <CardElement options={CARD_ELEMENT_OPTIONS} onReady={(e) => e.focus()} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <LoadingButton variant='contained' disabled={!stripe || processing} loading={processing} fullWidth onClick={handleSubmit}>
                {t('invoices.details.pay.label')} {formatToLocaleCurrency(data.amount)}
              </LoadingButton>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' sx={{ fontStyle: 'italic' }} display='flex' alignItems='center'>
                <InfoOutlined sx={{ mr: 1 }} />
                {t('invoices.details.pay.stripeHelper')}{' '}
                <IconButton href='https://stripe.com/' target='_blank'>
                  <Box component='img' alt={t('invoices.details.pay.altStripeLogo')} src={StripeLogo} sx={{ height: '0.75em' }} />
                </IconButton>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
