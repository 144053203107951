import { Grid, IconButton, Input, SvgIcon } from '@mui/material';
import { useContextRedirection } from 'hooks';
import { FC, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconSearch } from 'resources/icons/search.svg';
import { RoutePaths } from 'utils/RoutePaths';

const SearchBar: FC = () => {
  const { t } = useTranslation();
  const navigate = useContextRedirection();
  const { register: registerPatient, handleSubmit: handleSubmitPatient } = useForm<{
    searchPatient: string;
  }>();
  const { register: registerResult, handleSubmit: handleSubmitResult } = useForm<{
    searchResult: string;
  }>();

  const onSubmit = useCallback((route: keyof typeof RoutePaths, search: string) => navigate(RoutePaths[route] as string, search === '' ? {} : { search: search }), [navigate]);

  const onSubmitPatient: SubmitHandler<{ searchPatient: string }> = useCallback(({ searchPatient }) => onSubmit('DOCTOR_PATIENTS', searchPatient), [onSubmit]);
  const onSubmitResults: SubmitHandler<{ searchResult: string }> = useCallback(({ searchResult }) => onSubmit('DOCTOR_RESULTS', searchResult), [onSubmit]);

  return (
    <>
      <form onSubmit={handleSubmitPatient(onSubmitPatient)}>
        <Grid display='flex' justifyContent='center' alignItems='center'>
          <Input fullWidth sx={{ ml: 10, mr: 3 }} placeholder={t(`homepage_doctor.search_sections.placeholder_input_patient`)} {...registerPatient('searchPatient')} />
          <IconButton type='submit'>
            <SvgIcon component={IconSearch}></SvgIcon>
          </IconButton>
        </Grid>
      </form>
      <form onSubmit={handleSubmitResult(onSubmitResults)}>
        <Grid display='flex' justifyContent='center' alignItems='center'>
          <Input fullWidth sx={{ ml: 10, mr: 3 }} placeholder={t(`homepage_doctor.search_sections.placeholder_input_result`)} {...registerResult('searchResult')} />
          <IconButton type='submit'>
            <SvgIcon component={IconSearch}></SvgIcon>
          </IconButton>
        </Grid>
      </form>
    </>
  );
};

export default SearchBar;
