import { Checkbox, FormControl, Grid, InputLabel, ListItemText, Button, MenuItem, OutlinedInput, Select, SelectChangeEvent, Typography, CardActions } from '@mui/material';
import { useAuthenticated } from 'hooks';
import { AppointmentPatient, basicUnknownPatient } from 'models/appointment.model';
import { FC, useCallback, useEffect, useState } from 'react';
import { canMakeAppointmentForAppointmentPatient } from 'utils/func/Appointment.func';
import { Props } from '../index';
import { useWatch, useForm } from 'react-hook-form';
import BookingUnknownPatients from './Booking.unknown.patients';
import { useTranslation } from 'react-i18next';

const BookingPatientsStep: FC<Props> = ({ handleNext, handleState, state, selectablePersons }) => {
  const { t } = useTranslation();
  const isAuthenticated = useAuthenticated();

  const { register, watch, setValue, handleSubmit, control, formState, getValues, trigger } = useForm<{
    patients: AppointmentPatient[];
  }>({
    mode: 'onChange',
    defaultValues: {
      patients: isAuthenticated || state.patients.length > 0 ? state.patients.filter((patient) => patient.patient_id === null) : [basicUnknownPatient],
    },
    shouldFocusError: true,
  });

  const watchedPatients = useWatch({ control, name: 'patients' });
  const [selectedIds, setSelectedIds] = useState<number[]>(
    selectablePersons // otherwise retreive the selected persons ids from the form (and that are available to select in the interface)
      .filter((person) => watchedPatients.map((patient) => patient.first_name).includes(person.id.toString())) // filter the persons included in the form
      .map((person) => person.id), // only use the id
  );

  useEffect(() => {
    if (state.patients.length > 0) {
      let ids: number[] = [];
      state.patients.forEach((patient) => (patient.patient_id && patient.patient_id !== '' ? ids.push(parseInt(patient.patient_id)) : null));
      if (ids.length > 0) {
        setSelectedIds(ids);
      }
    }
  }, [state.patients]);

  // handle select changes
  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const {
      target: { value },
    } = event;
    const _value = typeof value === 'string' ? [] : value;
    setSelectedIds(_value);
  };
  // handle select visual render
  const renderValue = useCallback(
    (value: number[]): string => {
      return selectablePersons
        .filter((person) => value.includes(person.id))
        .map((person) => person.name)
        .join(', ');
    },
    [selectablePersons],
  );

  return (
    <form
      onSubmit={handleSubmit((data) => {
        if (!formState.errors.patients && (selectedIds.length > 0 || data.patients.length > 0)) {
          let test = isAuthenticated
            ? [
                ...selectablePersons
                  .filter((person) => selectedIds.includes(person.id)) // filtering only the selected persons
                  .map((person) => {
                    // const previousPatient = previousPatients.find((patient) => patient.patient_id === person.patient_id); // get the patient info if exist in current form
                    const { id, name, ...patient } = person; // remove the additional props from AppointmentPatient c.f. SelectablePerson
                    return patient; // if patient was already selected keep him so as to keep the medical acts, prescription and fasting options
                  }),
              ]
            : [];
          handleState([...test, ...data.patients], 'patients');
          handleNext();
        }
      })}
    >
      <Grid container>
        <Grid item sm={12}>
          {isAuthenticated && (
            <>
              <Typography variant='h1'>{t('appointment.book.steps.title')}</Typography>
              <Typography variant='body1'>
                {t('appointment.book.steps.patients.description.block1')}
                <br />
                {t('appointment.book.steps.patients.description.block2')}
              </Typography>
              <Typography variant='h2' sx={{ mt: 4, mb: 1 }}>
                {t('appointment.book.steps.patients.question')}
              </Typography>
            </>
          )}
          <FormControl fullWidth>
            {isAuthenticated && (
              <>
                <InputLabel sx={{ p: 0 }}>{t('appointment.book.steps.patients.label')}</InputLabel>
                <Select
                  multiple
                  error={watchedPatients.length === 0 && formState.isSubmitted && selectedIds.length === 0}
                  value={selectedIds}
                  renderValue={renderValue}
                  onChange={handleChange}
                  input={<OutlinedInput label={t('appointment.book.steps.patients.label')} />}
                  fullWidth
                >
                  {selectablePersons.map((person, i) => (
                    <MenuItem key={i} value={person.id} disabled={!canMakeAppointmentForAppointmentPatient(person)}>
                      <Checkbox sx={{ mr: 1 }} checked={selectedIds.indexOf(person.id) > -1} />
                      <ListItemText primary={`${person.name}${!canMakeAppointmentForAppointmentPatient(person) ? ` - ${t('appointment.tooltip.incompleteProfil')}` : ``}`} />
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
            <BookingUnknownPatients
              nbrPatient={isAuthenticated ? selectedIds.length : 0}
              trigger={trigger}
              state={state}
              control={control}
              formState={formState}
              getValues={getValues}
              setValue={setValue}
              register={register}
              watch={watch}
              defaultValues={state.patients}
            />
            <CardActions
              sx={{
                justifyContent: {
                  xs: 'space-between',
                  md: 'flex-end',
                },
                pt: 2,
              }}
            >
              <Button type='submit' variant='contained'>
                {t('appointment.book.action_buttons.next')}
              </Button>
            </CardActions>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};

export default BookingPatientsStep;
