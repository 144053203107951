import { Button, Menu, Stack } from '@mui/material';
import ContextLink from 'components/_base/ContextLink';
import { useIsPatient } from 'hooks';
import { Person, RelationInfo } from 'models/profile.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store/reducers';

const HeaderMenu: FC<{
  handleClose: any;
  open: boolean;
  anchorEl: null | HTMLElement;
  handleChangeUser: (user: RelationInfo | Person) => void;
}> = ({ handleClose, open, anchorEl, handleChangeUser }) => {
  const { t } = useTranslation();
  const isPatient = useIsPatient();

  const authenticateUser = useSelector((state: GlobalState) => state.session.authenticateUser);
  const currentUser = useSelector((state: GlobalState) => state.session.currentUser);

  return (
    <Menu id='profile-menu' anchorEl={anchorEl} open={open} onClick={handleClose} onClose={handleClose}>
      <Stack direction='column' spacing={1} alignItems='stretch' sx={{ padding: '4px 16px' }}>
        {isPatient && (
          <>
            <ContextLink to='PROFILE'>
              <Button variant='contained' fullWidth>
                {t('navigation.header.profile')}
              </Button>
            </ContextLink>
            {!currentUser?.is_relative ? (
              <ContextLink to='PROFILE_RELATIVE'>
                <Button variant='outlined' fullWidth>
                  {t('navigation.header.relative')}
                </Button>
              </ContextLink>
            ) : (
              <Button variant='outlined' onClick={authenticateUser && (() => handleChangeUser(authenticateUser))} fullWidth>
                {t('navigation.header.backToMainAccount')}
              </Button>
            )}
          </>
        )}
        <ContextLink to='LOGOUT'>
          <Button variant='outlined' fullWidth>
            {t('navigation.header.logout')}
          </Button>
        </ContextLink>
      </Stack>
    </Menu>
  );
};

export default HeaderMenu;
