import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { mapStyle } from 'resources/theme/GoogleMapStyle';
import config from 'config';
import { FC } from 'react';

const FindDoctorCollapseResultGoogleMap: FC<{ lat: number; lng: number }> = ({ lat, lng }) => {
  const { isLoaded: isGoogleLoaded /* , loadError: googleLoadError */ } = useLoadScript({
    googleMapsApiKey: config.googleMapsApiKey || '',
  });

  return (
    <>
      {isGoogleLoaded && (
        <GoogleMap
          center={{ lat, lng }}
          zoom={16}
          mapContainerStyle={{
            height: '100%',
            width: '100%',
          }}
          options={{
            styles: mapStyle,
          }}
        >
          <Marker position={{ lat, lng }} />
        </GoogleMap>
      )}
    </>
  );
};

export default FindDoctorCollapseResultGoogleMap;
