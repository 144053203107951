import { Visibility as VisibilityIcon } from '@mui/icons-material';
import { Box, Button, Card, CardActions, CardContent, Divider, List, Typography } from '@mui/material';
import api from 'api';
import ContextLink from 'components/_base/ContextLink';
import { useIsMobileView, useNotification } from 'hooks';
import { Record } from 'models/record.model';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store/reducers';
import { convertDate } from 'utils/func/Date.func';
import HomepageListingItems from './HomepageListingItems';

const HomepageResults: FC = () => {
  const isMobile = useIsMobileView();
  const { t } = useTranslation();
  const [data, setData] = useState<Record[]>([]);
  const { notification } = useNotification();
  const [error, setError] = useState(false);
  const currentUser = useSelector((state: GlobalState) => state.session.currentUser);

  useEffect(() => {
    currentUser &&
      api.results
        .getResults(3, 0, currentUser.id)
        .then((res) => setData(res.results))
        .catch(() => {
          setError(true);
          notification(t('homepage.results.notification.error'), 'error');
        });
  }, [currentUser, notification, t]);

  const CustomDivider: FC = () => {
    return (
      <Divider
        orientation='vertical'
        variant='middle'
        flexItem
        sx={{
          mx: 1,
          my: 0,
          border: (theme) => `2px solid ${theme.palette.divider}`,
        }}
      />
    );
  };

  return (
    <Card
      sx={{
        display: 'grid',
        width: '100%',
        minHeight: (theme) => theme.spacing(25),
      }}
    >
      <CardContent>
        <Typography variant='h2' display='flex' alignItems='center'>
          {isMobile && <VisibilityIcon sx={{ mr: 1 }} />}
          {t('common.results')}
        </Typography>
        {error ? (
          <Typography>{t('homepage.results.notification.error')}</Typography>
        ) : (
          <List dense>
            {data.map((result: Record, i: number) => {
              const consultationTime = result.sampling_time ? convertDate(result.sampling_time, true) : `${t('homepage.results.dateNoValid')}`;
              const issuer = [result.issuer.title, result.issuer.first_name, result.issuer.last_name].join(' ');
              const status = `homepage.results.status.${result.status}`;
              return (
                <HomepageListingItems
                  title={
                    <ContextLink to='DETAIL_RESULT' params={{ id: result.reference }}>
                      <Box
                        sx={{
                          display: 'grid',
                          width: '100%',
                          gridAutoFlow: 'column',
                          gridTemplateColumns: '6fr 20px 10fr 20px 5fr 20px 5fr',
                        }}
                        component={Button}
                        variant='header_light'
                      >
                        <Typography sx={{ alignSelf: 'center' }}>{consultationTime}</Typography>
                        <CustomDivider />
                        <Typography sx={{ placeSelf: 'center', textAlign: 'center' }}>{issuer}</Typography>
                        <CustomDivider />
                        <Typography sx={{ placeSelf: 'center', textAlign: 'center' }}>{result.reference}</Typography>
                        <CustomDivider />
                        <Typography sx={{ placeSelf: 'center', textAlign: 'center' }}>{t(status)}</Typography>
                      </Box>
                    </ContextLink>
                  }
                  key={`result-${i}`}
                />
              );
            })}
          </List>
        )}
      </CardContent>
      <CardActions sx={{ alignItems: 'flex-end' }}>
        <Box width='100%' display='flex' justifyContent='flex-end'>
          <ContextLink to='RESULTS'>
            <Button variant='contained' fullWidth={isMobile}>
              {t('homepage.results.buttonLabel')}
            </Button>
          </ContextLink>
        </Box>
      </CardActions>
    </Card>
  );
};

export default HomepageResults;
