import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const PreregistrationQRCodeRouter = () => {
  const { lang, QRCODE } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (lang && QRCODE) {
      navigate(`/${lang}/preregistration`,{replace:true});
    }
  }, [lang, QRCODE, navigate]);

  return null;
};

export default PreregistrationQRCodeRouter;