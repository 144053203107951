import { FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DraftGenericFormProps } from 'models/result.patient.model';
import { FC } from 'react';
import { Controller } from 'react-hook-form';

export const DraftGenericBooleanForm: FC<DraftGenericFormProps> = ({ question, control, register }) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography alignSelf='center'>{question.name}</Typography>
      <Controller
        control={control}
        rules={{ required: question.required }}
        defaultValue={question.answers[0] ? question.answers[0] : null}
        name={question.id.toString()}
        render={({ field }) => (
          <RadioGroup {...field} row>
            {question.values.map((value, index) => (
              <FormControlLabel key={index} value={value.id} control={<Radio />} label={value.name} />
            ))}
          </RadioGroup>
        )}
      />
      {question.has_comment && (
        <TextField
          label={t('results.details.remarksLabel')}
          fullWidth
          variant='standard'
          {...register(`comment-${question.id}`)}
          InputLabelProps={{
            sx: { p: 0 },
          }}
          defaultValue={question.answer_comment ?? ''}
        />
      )}
    </>
  );
};
