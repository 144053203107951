import { APIConfiguration, axiosMYLAB } from 'api';
import { BaseService } from './base.service';

class VideosService extends BaseService {
  async getVideos(lang: string) {
    const { data } = await axiosMYLAB.get<any>(`${this.apiConfig.VIDEOS.GET(this.getLang())}`);

    this.log(`getVideos()`);

    return data;
  }
}

export default (apiConfig: APIConfiguration) => {
  return new VideosService(apiConfig);
};
