import { Avatar, Box, Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import ContextLink from 'components/_base/ContextLink';
import { useIsMobileView } from 'hooks';
import { Profile } from 'models/profile.model';
import { FC } from 'react';
import { getInitials } from 'utils/func/Person.func';
import ProfileData from './shared/ProfileData';

const ProfileCard: FC<Profile> = ({ title, person, actions }) => {
  const isMobileView = useIsMobileView();

  return (
    <Card>
      <CardContent>
        <Box display='flex' justifyContent='space-between' mb={{ xs: 3 }}>
          <Typography variant={isMobileView ? 'h1' : 'h2'}>{title}</Typography>
          <Avatar
            sx={{
              display: { xs: 'none', md: 'inherit' },
              width: 56,
              height: 56,
            }}
          >
            {getInitials(person)}
          </Avatar>
        </Box>
        <ProfileData person={person} />
      </CardContent>
      <CardActions
        sx={{
          display: { xs: 'block', md: 'flex' },
          mt: 3,
          justifyContent: 'flex-end',
        }}
      >
        {actions?.map((action, key) => {
          return (
            <ContextLink to={action.routePath} key={key}>
              <Button
                sx={{
                  mb: 1,
                }}
                variant='outlined'
                fullWidth={isMobileView}
              >
                {action.label}
              </Button>
            </ContextLink>
          );
        })}
      </CardActions>
    </Card>
  );
};

export default ProfileCard;
