import { AlertColor, NotificationsAction, NotificationsActionType } from 'store/actions/notifications.actions';

type NotificationsInitialState = {
  isNotification: boolean;
  content?: {
    type: AlertColor;
    message: string;
    setButton?: boolean;
    buttonLabel?: string;
  };
};

const notificationsInitialState: NotificationsInitialState = {
  isNotification: false,
  content: undefined,
};

export default function notificationsReducer(prevState = notificationsInitialState, action: NotificationsAction) {
  switch (action.type) {
    case NotificationsActionType.DISPLAY_NOTIFICATION:
      return {
        ...prevState,
        isNotification: true,
        content: action.content,
      };

    case NotificationsActionType.RESET_NOTIFICATION:
      return {
        ...prevState,
        isNotification: false,
        content: undefined,
      };

    default:
      return prevState;
  }
}
