import EmailIcon from '@mui/icons-material/EmailOutlined';
import { Box, Button, Grid, Stack, SvgIcon, Typography } from '@mui/material';
import config from 'config';
import { useAuthenticated, useIsDoctor } from 'hooks';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MadeInLuxembourgLogo from 'resources/icons/made-in-luxembourg.svg';
import HostedLuxLogo from 'resources/icons/logo_hostedlux.svg';
import { ReactComponent as phoneIcon } from 'resources/icons/telephone.svg';
import ContextLink from '../../_base/ContextLink';
import ContactDialog from './ContactDialog';

const Footer: FC = () => {
  const { t } = useTranslation();
  const isAuthenticated = useAuthenticated();
  const isDoctor = useIsDoctor();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box
        component='footer'
        sx={{
          padding: (theme) => theme.spacing(1, 4),
          borderTop: isAuthenticated ? (theme) => `1px solid ${theme.palette.divider}` : `1px`,
          mt: 'auto',
          backgroundColor: 'footer.main',
        }}
      >
        <Grid container alignItems='center'>
          <Grid item xs>
            <Grid container direction='row' spacing={{ xs: 1, md: 5 }}>
              <Grid item>
                {isAuthenticated ? (
                  <Button variant='link' sx={{ mr: 3 }} onClick={() => setOpen(true)}>
                    {t('navigation.footer.contact_us')}
                  </Button>
                ) : (
                  <Button variant='link' startIcon={<EmailIcon />} href={`mailto:${config.footer.contactUsEmail}`}>
                    {config.footer.contactUsEmail}
                  </Button>
                )}
              </Grid>
              {!isDoctor ? (
                <Grid item>
                  <Stack direction='row'>
                    <Typography sx={{ mr: 1, mt: 0.25 }}>{'Tel.'}</Typography>
                    <ContextLink
                      to='PRIO_PHONE'
                      params={{
                        phoneNumber: isDoctor ? `${config.footer.doctorPriorityNumber}` : `${config.footer.priorityNumber}`,
                      }}
                      external
                    >
                      <Button variant='link' sx={{ mr: 3 }}>
                        <SvgIcon fontSize='small' component={phoneIcon} />
                        <Typography sx={{ lineHeight: 'unset' }}>{isDoctor ? config.footer.doctorPriorityNumber : config.footer.priorityNumber}</Typography>
                      </Button>
                    </ContextLink>
                  </Stack>
                </Grid>
              ) : null}
              <Grid item>
                <ContextLink to='GDPR'>
                  <Button variant='link'>{t('navigation.footer.gdpr')}</Button>
                </ContextLink>
              </Grid>
              <Grid item>
                <ContextLink to='DISCLAIMER'>
                  <Button variant='link'>{t('navigation.footer.disclaimer')}</Button>
                </ContextLink>
              </Grid>
            </Grid>
          </Grid>
          <Grid item justifyContent='flex-end'>
            <ContextLink to='MADE_IN_LUXEMBOURG' external>
              <Box
                component='img'
                alt='Made in Luxembourg'
                src={MadeInLuxembourgLogo}
                sx={{
                  height: { xs: '40px', sm: '70px' },
                  pr: 2,
                }}
              />
            </ContextLink>
            <ContextLink to='HOSTED_IN_LUXEMBOURG' external>
              <Box
                component='img'
                alt='Hosted in Luxembourg'
                src={HostedLuxLogo}
                sx={{
                  height: { xs: '40px', sm: '70px' },
                }}
              />
            </ContextLink>
          </Grid>
        </Grid>
      </Box>
      <ContactDialog open={open} setOpen={setOpen} />
    </>
  );
};

export default Footer;
