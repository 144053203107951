import { Button, MenuItem, Grid, TextField, Typography } from '@mui/material';
import GenericDatePicker from 'components/date-picker';
import PhoneInput from 'components/phone-input';
import { useTranslation } from 'react-i18next';
import { Preregistration } from 'models/preregistration.model';
import { ShortenPerson } from 'models/profile.model';
import { FC } from 'react';
import { Control, Controller, FormState, UseFieldArrayRemove, UseFormClearErrors, UseFormGetValues, UseFormRegister, UseFormSetError, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { cnsValidator } from 'utils/func/CnsValidator.func';
import { isManualValidDate } from 'utils/func/Date.func';
import { isGSMNumber } from 'utils/func/Phone.func';
import File from './AddFile';
import { sexes } from 'models/patients.model';

const AddPatient: FC<{
  currentUser: ShortenPerson | undefined;
  index: number;
  control: Control<Preregistration>;
  setValue: UseFormSetValue<Preregistration>;
  getValues: UseFormGetValues<Preregistration>;
  remove: UseFieldArrayRemove;
  register: UseFormRegister<Preregistration>;
  watch: UseFormWatch<Preregistration>;
  formState: FormState<Preregistration>;
  setError: UseFormSetError<Preregistration>;
  clearErrors: UseFormClearErrors<Preregistration>;
}> = ({ currentUser, index, control, setValue, getValues, remove, register, watch, formState: { errors } }) => {
  const { t } = useTranslation();

  return (
    <Grid item container xs={12} display='flex' justifyContent='space-between'>
      <Grid item xs={12}>
        <Typography>(*) {t('common.required_fields')}</Typography>
      </Grid>
      {index !== 0 && (
        <Grid item display='flex' xs={12} justifyContent='flex-end'>
          <Button
            variant='danger'
            onClick={() => {
              remove(index);
            }}
          >
            {t('common.action.delete')}
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          select
          disabled={currentUser && index === 0}
          variant='filled'
          label={t('preregistration.myData.form.gender')}
          {...register(`users.${index}.gender`, { required: true })}
          required
          error={errors?.users?.[index]?.gender !== undefined}
          defaultValue={getValues(`users.${index}.gender`)}
          fullWidth
        >
          {sexes.map(({ label, value }, i) => {
            return (
              <MenuItem value={value} key={`sex-${i}`}>
                {t(label)}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant='filled'
          {...register(`users.${index}.first_name`, {
            required: true,
            minLength: 2,
            maxLength: 255,
          })}
          required
          disabled={currentUser && index === 0 && currentUser.first_name !== ''}
          label={t('preregistration.myData.form.first_name')}
          fullWidth
          helperText={errors?.users?.[index]?.first_name ? t('preregistration.myData.form.error.invalidFirstName') : null}
          error={errors?.users?.[index]?.first_name !== undefined}
          InputLabelProps={getValues(`users.${index}.first_name`) !== '' ? { shrink: true } : {}}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant='filled'
          {...register(`users.${index}.birth_name`, {
            minLength: 2,
            maxLength: 255,
            required: true,
          })}
          required
          disabled={currentUser && index === 0 && currentUser.birth_name !== ''}
          label={t('preregistration.myData.form.birth_name')}
          error={errors?.users?.[index]?.birth_name !== undefined}
          InputLabelProps={getValues(`users.${index}.birth_name`) !== '' ? { shrink: true } : {}}
          helperText={errors?.users?.[index]?.birth_name !== undefined ? t('preregistration.myData.form.error.invalidBirthname') : null}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant='filled'
          {...register(`users.${index}.last_name`, {
            minLength: 2,
            maxLength: 255,
          })}
          disabled={currentUser && index === 0 && currentUser.last_name !== ''}
          label={t('preregistration.myData.form.last_name')}
          InputLabelProps={getValues(`users.${index}.last_name`) !== '' ? { shrink: true } : {}}
          error={errors?.users?.[index]?.last_name !== undefined}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <GenericDatePicker
          name={`users.${index}.birth_date`}
          control={control}
          required
          disabled={currentUser && index === 0 && currentUser.birth_date !== null && !errors?.users?.[index]?.birth_date}
          onValidate={() => isManualValidDate(getValues(`users.${index}.birth_date`))}
          error={errors?.users?.[index]?.birth_date !== undefined}
          helperText={t('preregistration.myData.form.helperText.birth_date')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant='filled'
          {...register(`users.${index}.cns`, {
            validate: (v) => cnsValidator(v, getValues(`users.${index}.birth_date`)),
          })}
          label={t('preregistration.myData.form.cns')}
          disabled={currentUser && index === 0 && currentUser.cns !== '' && !errors?.users?.[index]?.cns}
          InputLabelProps={getValues(`users.${index}.cns`) !== '' ? { shrink: true } : {}}
          fullWidth
          helperText={errors?.users?.[index]?.cns ? t('preregistration.myData.form.error.invalidCNS') : null}
          error={errors?.users?.[index]?.cns !== undefined}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          rules={{
            required: true,
            validate: (v) => isGSMNumber(v),
          }}
          name={`users.${index}.mobile_phone`}
          render={({ field }) => {
            return (
              <PhoneInput
                error={errors?.users?.[index]?.mobile_phone !== undefined}
                onChange={(e) => {
                  field.onChange(() => setValue(`users.${index}.mobile_phone`, e.toString()));
                }}
                value={field.value}
                label={t('preregistration.myData.form.mobile_phone')}
                helperText={t('preregistration.myData.form.helperText.mobile_phone')}
                required
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant='filled'
          {...register(`users.${index}.email`, {
            required: true,
          })}
          type='email'
          label={t('preregistration.myData.form.email')}
          fullWidth
          required
          helperText={errors?.users?.[index]?.email !== undefined ? t('preregistration.myData.form.error.invalidEmail') : t('preregistration.myData.form.helperText.email')}
          InputLabelProps={getValues(`users.${index}.email`) !== '' ? { shrink: true } : {}}
          error={errors?.users?.[index]?.email !== undefined}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant='filled'
          {...register(`users.${index}.confirmemail`, {
            required: true,
            validate: {
              correctEmail: (v) => v.toLowerCase() === getValues(`users.${index}.email`).toLowerCase(),
            },
          })}
          type='email'
          label={t('preregistration.myData.form.confirmemail')}
          fullWidth
          helperText={errors?.users?.[index]?.confirmemail !== undefined ? t('preregistration.myData.form.error.invalidConfirmEmail') : null}
          InputLabelProps={getValues(`users.${index}.confirmemail`) !== '' ? { shrink: true } : {}}
          error={errors?.users?.[index]?.confirmemail !== undefined}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant='filled'
          {...register(`users.${index}.identitynumber`)}
          label={t('preregistration.myData.form.identityNumber')}
          InputLabelProps={getValues(`users.${index}.identitynumber`) !== '' ? { shrink: true } : {}}
          fullWidth
          error={errors?.users?.[index]?.identitynumber !== undefined}
        />
      </Grid>
      <Grid item xs={12}>
        <File control={control} index={index} register={register} errors={errors} setValue={setValue} />
      </Grid>
    </Grid>
  );
};

export default AddPatient;
