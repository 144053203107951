export const getSortedResults = <T>(data: T[], valueToSort: string) => {
  const attributeToSort = (res: any, value: string) => {
    switch (value) {
      case 'identity':
        return res.identity;
      case 'name':
        return res.name;
      default:
        break;
    }
  };
  const sortedData: {
    title: string;
    items: T[];
    indexOffset: number;
  }[] = Object.values(
    data.reduce((acc: any, res: any) => {
      let firstLetterToSort = attributeToSort(res, valueToSort);
      let firstLetter = firstLetterToSort.trim()[0].toLocaleUpperCase();
      if (!acc[firstLetter]) {
        acc[firstLetter] = {
          title: firstLetter,
          items: [res],
        };
      } else {
        acc[firstLetter].items.push(res);
      }
      return acc;
    }, {}),
  );
  // use for and not map because order matters
  for (let index = 0; index < sortedData.length; index++) {
    sortedData[index].indexOffset = index === 0 ? 0 : sortedData[index - 1].items.length + sortedData[index - 1].indexOffset;
  }

  return sortedData;
};
