import { Box, CircularProgress, Grid } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { DetailPatientQuestions } from 'pages/shared/results/DetailPatientQuestions';
import PatientDetails from 'components/patient-details';
import { useContextRedirection, useIsMobileView, useIsPatient, useIsTempPatient, useNotification } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DetailPatientRecordTable } from './DetailPatientRecordTable';
import RoutePaths from 'utils/RoutePaths';
import { useTranslation } from 'react-i18next';

const ResultDetail: FC = () => {
  const isPatient = useIsPatient();
  const isTempPatient = useIsTempPatient();
  const { idResult } = useParams();
  const isMobileView = useIsMobileView();
  const { notification } = useNotification();
  const { t } = useTranslation();
  const [details, setDetails] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useContextRedirection();

  useEffect(() => {
    if (idResult) {
      setLoading(true);
      api.results
        .getResultByReference(idResult)
        .then((response) => setDetails(response))
        .catch(() => {
          notification(t('patient_details.tab.record.notification.error'), 'error');
          navigate(RoutePaths['NOT_FOUND']);
        })
        .finally(() => setLoading(false));
    }
  }, [idResult, notification, t, navigate]);

  return (
    <BodyTemplate title={t('common.results')} backButton={!isTempPatient}>
      <Grid container>
        {!isMobileView && !isPatient && !isTempPatient && (
          <Grid item xs={12}>
            <PatientDetails showPatientRecordAction patient={details?.patient} />
          </Grid>
        )}
        {!isMobileView && (
          <Grid item xs={12}>
            {details && details.questionnaires && details.questionnaires.length >= 1 && <DetailPatientQuestions reference={details.reference} questionnaires={details.questionnaires} />}
          </Grid>
        )}
        <Grid item xs={12}>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            details && <DetailPatientRecordTable {...details} />
          )}
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default ResultDetail;
