import { AxiosHeaders } from 'axios';
import jwtDecode from 'jwt-decode';
import { ActivateNewPatientRequest, ForgotPasswordRequest, TokenRelativeRequest, VerifyTokenRequest, VerifyTokenResponse } from 'models/user.model';
import { CONST_BIONEXT_CURRENT_USER_TOKEN, CONST_BIONEXT_TOKEN } from 'utils/Constants';
import { APIConfiguration, axiosMYLAB } from '.';
import config from '../config';
import { AuthenticationRequest, AuthenticationResponse } from '../models/authentication.model';
import { BaseService } from './base.service';

class AuthenticationService extends BaseService {
  decodeToken = jwtDecode;

  private token?: string;
  private currentUserId?: string;

  constructor(apiConfig: APIConfiguration) {
    super(apiConfig);

    const token = localStorage.getItem(CONST_BIONEXT_TOKEN);
    const currentUserId = localStorage.getItem(CONST_BIONEXT_CURRENT_USER_TOKEN);

    if (token) {
      this.token = token;
    }

    if (currentUserId) {
      this.currentUserId = currentUserId;
    }

    if (!this.tokenExists()) {
      this.clear();
    }

    axiosMYLAB.interceptors.request.use((axiosRequestConfig) => {
      if (!this.hasToken()) {
        this.clear();
        return axiosRequestConfig;
      }

      if (this.token) {
        (axiosRequestConfig.headers as AxiosHeaders).set('Authorization', `Bearer ${this.token}`);
      }

      return axiosRequestConfig;
    });

    axiosMYLAB.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          this.clear();
          window.location.reload();
        }
        throw error;
      },
    );
  }

  async authenticate(request: AuthenticationRequest) {
    const authentication = await axiosMYLAB.post<AuthenticationResponse>(`${this.apiConfig.AUTHENTICATE.MYLAB}`, {
      ...request,
      client_id: config.auth.clientId,
      client_secret: config.auth.clientSecret,
      grant_type: config.auth.grantType,
    });
    this.setTokens(authentication.data.access_token);

    this.log('authenticate()');
  }

  async accessWithUniqueToken(uniqueToken: string) {
    const authentication = await axiosMYLAB.post<AuthenticationResponse>(`${this.apiConfig.AUTHENTICATE.UNIQUE(this.getLang())}`, {
      unique_token: uniqueToken,
    });
    this.setTokens(authentication.data.access_token);

    this.log('accessWithUniqueToken()');
  }

  tokenExists() {
    return !!this.token;
  }

  currentUserIdExists() {
    return !!this.currentUserId;
  }

  clear() {
    this.token = undefined;
    this.currentUserId = undefined;
    localStorage.removeItem(CONST_BIONEXT_TOKEN);
    localStorage.removeItem(CONST_BIONEXT_CURRENT_USER_TOKEN);
  }

  setTokens(token: string) {
    this.token = token;
    localStorage.setItem(CONST_BIONEXT_TOKEN, this.token);
  }

  private hasToken() {
    return !!localStorage.getItem(CONST_BIONEXT_TOKEN);
  }

  async verifyToken(request: VerifyTokenRequest) {
    const { data } = await axiosMYLAB.get<VerifyTokenResponse>(`${this.apiConfig.AUTHENTICATE.VERIFY_TOKEN(this.getLang(), request.token)}`);

    this.log('verifyToken()');

    return data;
  }

  async forgotPassword(request: ForgotPasswordRequest) {
    const { data } = await axiosMYLAB.post(`${this.apiConfig.AUTHENTICATE.FORGOT_PWD(this.getLang(), request.email)}`);

    this.log('forgotPassword()');

    return data;
  }

  async resetPassword(request: any, token: string | undefined) {
    const { data } = await axiosMYLAB.post(`${this.apiConfig.AUTHENTICATE.RESET_PWD(this.getLang(), token ?? '')}`, {
      ...request,
    });

    this.log('resetPassword()');

    return data;
  }

  async tokenRelative(request: TokenRelativeRequest) {
    const authentication = await axiosMYLAB.post<AuthenticationResponse>(`${this.apiConfig.AUTHENTICATE.TOKEN_RELATIVE(this.getLang())}`, {
      ...request,
      app_id: config.auth.clientId,
    });

    this.setTokens(authentication.data.access_token);

    this.log('tokenRelative()');
  }

  async activateNewPatient(request: ActivateNewPatientRequest) {
    const authentication = await axiosMYLAB.post<AuthenticationResponse>(`${this.apiConfig.AUTHENTICATE.ACTIVATE_NEW_PATIENT(this.getLang())}`, {
      ...request,
      app_id: config.auth.clientId,
    });

    this.setTokens(authentication.data.access_token);

    this.log('activateNewPatient()');
  }
}

export default (apiConfig: APIConfiguration) => {
  return new AuthenticationService(apiConfig);
};
