import { Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, IconButton, InputAdornment, Stack, SvgIcon, TextField } from '@mui/material';
import GenericDatePicker from 'components/date-picker';
import { useTranslation } from 'react-i18next';
import { ActivateNewPatientRequestForm } from 'models/user.model';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ReactComponent as EmailIcon } from 'resources/icons/messagerie.svg';
import { ReactComponent as TokenIcon } from 'resources/icons/token.svg';

const NewPatientForm: FC<{
  onSubmit: (request: ActivateNewPatientRequestForm) => void;
  isSubmitting: boolean;
  token?: string;
}> = ({ onSubmit, isSubmitting, token }) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const { register, handleSubmit, formState, control } = useForm<ActivateNewPatientRequestForm>({
    mode: 'onChange',
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Stack
        spacing={3}
        sx={{
          minWidth: '100%',
          minHeight: '100%',
          justifyContent: 'center',
          marginTop: '0px',
        }}
      >
        <Stack direction='column' spacing={2}>
          <TextField
            fullWidth
            variant='filled'
            defaultValue={token}
            {...register('token', {
              required: true,
              disabled: true,
            })}
            name={'token'}
            label={t('landing_page.login.token.fields.token')}
            error={formState.errors?.token !== undefined}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SvgIcon component={TokenIcon} sx={{ fontSize: 24 }} />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Stack direction='column' spacing={2}>
          <GenericDatePicker name='birth_date' control={control} />
        </Stack>
        <Stack direction='column' spacing={2}>
          <TextField
            fullWidth
            variant='filled'
            {...register('username', {
              required: true,
            })}
            type='email'
            autoComplete='off'
            name={'username'}
            label={t('landing_page.login.token.fields.email')}
            error={formState.errors?.username !== undefined}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SvgIcon component={EmailIcon} sx={{ fontSize: 24 }} />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Stack direction='column' spacing={2}>
          <TextField
            fullWidth
            variant='filled'
            {...register('password', {
              required: true,
            })}
            autoComplete='new-password'
            name={'password'}
            type={showPassword ? 'text' : 'password'}
            label={t('landing_page.login.token.fields.password')}
            error={formState.errors?.username !== undefined}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SvgIcon component={Lock} sx={{ fontSize: 24 }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={handleClickShowPassword} edge='end'>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Stack direction='column' spacing={1} alignItems='center'>
          <Button type='submit' variant='contained' disabled={!formState.isValid || isSubmitting} fullWidth>
            {t('landing_page.login.login')}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default NewPatientForm;
