import { useTranslation } from 'react-i18next';
import { FC } from 'react';

const Error404: FC = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        margin: '-43px 0 0 -150px',
        width: '300px',
        fontFamily: 'sans-serif',
        textAlign: 'center',
        color: '#888',
      }}
    >
      <h1 style={{ color: '#555', fontSize: '2em', fontWeight: 400 }}>{t('404.title')}</h1>
      <p style={{ lineHeight: 1.2 }}>{t('404.subtitle')}</p>
    </div>
  );
};

export default Error404;
