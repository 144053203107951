import { APIConfiguration, axiosMYLAB } from 'api';
import { EudccCertificate } from 'models/eudcc-certificate.model';
import { Paginate } from 'models/utils.model';
import { BaseService } from './base.service';

class EudccCertificatesService extends BaseService {
  async getEUDCCCertifiates(size: number, page: number, userId: number) {
    const { data } = await axiosMYLAB.get<Paginate<EudccCertificate>>(`${this.apiConfig.EUDCC_CERTIFICATES.GET(this.getLang())}`, {
      params: {
        patient_id: userId,
        size,
        page,
      },
    });

    this.log(`getEUDCCCertificates()`);

    return data;
  }
}

export default (apiConfig: APIConfiguration) => {
  return new EudccCertificatesService(apiConfig);
};
