import { APIConfiguration, axiosMYLAB } from 'api';
import { Patient, PatientsListRequest, PatientsListResponse } from 'models/patients.model';
import { Paginate } from 'models/utils.model';
import { removeEmptyString } from 'utils';
import { DEFAULT_PAGINATION_SIZE } from 'utils/Constants';
import { BaseService } from './base.service';

class PatientsService extends BaseService {
  async getPatients(parameters: PatientsListRequest, size: number = DEFAULT_PAGINATION_SIZE, page: number = 1) {
    const { data } = await axiosMYLAB.get<PatientsListResponse>(`${this.apiConfig.PATIENTS.SEARCH(this.getLang())}`, {
      params: { size, page, ...removeEmptyString(parameters) },
    });

    this.log(`getPatients()`);

    return data;
  }

  async disableRelativeFromPatient(invitationId: number) {
    const { data } = await axiosMYLAB.delete(`${this.apiConfig.PATIENTS.DELETE_RELATIVE_FROM_PATIENT(this.getLang(), invitationId)}`);

    this.log(`disableRelativeFromPatient()`);

    return data;
  }

  async getPatientDetails(patientId: number) {
    const { data } = await axiosMYLAB.get<Patient>(`${this.apiConfig.PATIENTS.GET_BY_ID(this.getLang(), patientId)}`);

    this.log(`getPatientDetails()`);

    return data;
  }

  async getPatientMultipleSearch(input: string, size: number = DEFAULT_PAGINATION_SIZE, page: number = 1) {
    const { data } = await axiosMYLAB.get<Paginate<Patient>>(`${this.apiConfig.PATIENTS.SEARCH(this.getLang())}`, {
      params: {
        size,
        page,
        multiple_search: input,
      },
    });

    this.log('getPatientMultipleSearch()');

    return data;
  }
}

export default (apiConfig: APIConfiguration) => {
  return new PatientsService(apiConfig);
};
