import { Box, MenuItem, Select, TextField, Typography } from '@mui/material';
import { PrescriptionItem, PrescriptionItemMotivation, PrescriptionOptions } from 'models/prescription.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { Controller, UseFormSetValue, Control, FormState, FieldErrors } from 'react-hook-form';

const AnalysisMotivationForm: FC<{
  index: number;
  setValue: UseFormSetValue<{ options: PrescriptionOptions[] }>;
  control: Control<{ options: PrescriptionOptions[] }, any>;
  options: PrescriptionOptions[];
  formState: FormState<{ options: PrescriptionOptions[] }>;
  basketItem: PrescriptionItem;
}> = ({ basketItem, index, options, formState, setValue, control }) => {
  return (
    <>
      {basketItem.to_motivate.map((motivation, key) => {
        return (
          <AnalysisMotivationFormItem
            control={control}
            setValue={setValue}
            errors={formState.errors}
            is_free_text={options[index] && options[index].is_free_text}
            motivation={motivation}
            options={options}
            index={index}
            key={key}
          />
        );
      })}
    </>
  );
};

const AnalysisMotivationFormItem: FC<{
  control: Control<{ options: PrescriptionOptions[] }, any>;
  index: number;
  is_free_text: boolean;
  errors: FieldErrors<{ options: PrescriptionOptions[] }>;
  setValue: UseFormSetValue<{ options: PrescriptionOptions[] }>;
  motivation: PrescriptionItemMotivation;
  options: PrescriptionOptions[];
}> = ({ motivation, errors, options, index, control, setValue, is_free_text }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <SubdirectoryArrowRightIcon />

      <Box sx={{ ml: 2, width: '90%', display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Controller
          name={`options.${index}.motivation_code`}
          control={control}
          defaultValue={options[index].motivation_code}
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <Select
              size='small'
              name={name}
              fullWidth
              variant='standard'
              onBlur={onBlur} // notify when input is touched
              value={value}
              inputRef={ref} // value={}
              onChange={(event) => {
                onChange(event);
                let motiv = motivation.choices.find((e) => event.target.value === e.tag);
                setValue(`options.${index}.free_text`, '');
                setValue(`options.${index}.is_free_text`, motiv?.free_text ? true : false);
                setValue(`options.${index}.question_tag`, motivation.code);
              }}
              error={errors.options && errors.options[index] && errors.options[index]?.motivation_code ? true : false}
              placeholder={t('prescriptions_making.step1.yourSelection.toMotivate')}
              inputProps={{ sx: { fontSize: 12 } }}
            >
              {motivation.choices.map((value: any, key: number) => {
                return (
                  <MenuItem value={value.tag} key={key}>
                    {value['label']}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        />
        {errors.options && errors.options[index]?.motivation_code && (
          <Typography color='error' variant='caption'>
            {t('prescriptions_making.step1.dialog.missing_motivation')}
          </Typography>
        )}
        {is_free_text && (
          <Controller
            name={`options.${index}.free_text`}
            control={control}
            defaultValue={options[index].free_text}
            rules={{
              required: true,
              validate: (value) => {
                return !!value?.trim();
              },
            }}
            render={({ field }) => (
              <TextField
                size='small'
                key={`options.${index}.free_text`}
                fullWidth
                variant='standard'
                autoFocus
                error={errors.options && errors.options[index]?.free_text ? true : false}
                {...field}
                helperText={errors.options && errors.options[index]?.free_text ? t('prescriptions_making.step1.dialog.missing_motivation') : ''}
                placeholder={t('prescriptions_making.step1.dialog.free_text')}
                InputProps={{ style: { fontSize: 12 } }}
              />
            )}
          />
        )}
      </Box>
    </Box>
  );
};

export default AnalysisMotivationForm;
