import { TableCell, Typography } from '@mui/material';
import ContextLink from 'components/_base/ContextLink';
import { useTranslation } from 'react-i18next';
import { Result } from 'models/result.model';
import { FC } from 'react';

const DetailTabPanelBodyIsFile: FC<{ val: Result }> = ({ val }) => {
  const { t } = useTranslation();
  const FILE_TO_NOT_SHOW = 'notShow';

  return (
    <>
      <TableCell>
        {val.value === FILE_TO_NOT_SHOW ? (
          <Typography>{t('result.doctor.details.seeDoctorGettingResult')}</Typography>
        ) : (
          <ContextLink to='DETAIL_RESULT_PDF' params={{ dossierId: val.dossier_reference, type: 'result', filename: val.value }}>
            <Typography color='primary.main'>{t('common.action.seeFile')}</Typography>
          </ContextLink>
        )}
      </TableCell>
    </>
  );
};

export default DetailTabPanelBodyIsFile;
