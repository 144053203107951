import { Button, Card, CardContent, Grid } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { useIsMobileView, useNotification } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const EUDCCCertificateDetails: FC = () => {
  const { id, file } = useParams();
  const isMobileView = useIsMobileView();
  const [pdfData, setPdfData] = useState<Blob>();
  const { t } = useTranslation();
  const { notification } = useNotification();

  useEffect(() => {
    id &&
      api.results
        .getFile('eudcc', id, `${file}.pdf`)
        .then((res) => {
          setPdfData(res);
        })
        .catch(() => notification(t('certificates.notification.error'), 'error'));
  }, [file, id, notification, t]);

  const files = pdfData && new Blob([pdfData], { type: 'application/pdf' });
  const fileURL = files && URL.createObjectURL(files);

  return (
    <BodyTemplate title={t('bodyTemplate.certificates')} backButton>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={12} display='flex' alignItems='center' justifyContent='flex-end'>
                  <a href={fileURL} download style={{ textDecoration: 'none' }}>
                    <Button variant='outlined'>{t('common.action.pdfDownload')}</Button>
                  </a>
                </Grid>
                <Grid item xs={12}>
                  <iframe src={fileURL} title='certificate' style={{ border: 0 }} width='100%' height={isMobileView ? '500px' : '800px'} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default EUDCCCertificateDetails;
