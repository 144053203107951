import { Button, Divider, Grid, MenuItem, TextField, Typography } from '@mui/material';
import GenericDatePicker from 'components/date-picker';
import PhoneInput from 'components/phone-input';
import { useTranslation } from 'react-i18next';
import { AppointmentPatient } from 'models/appointment.model';
import { Gender } from 'models/profile.model';
import { FC } from 'react';
import { Control, FormState, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { isManualValidDate } from 'utils/func/Date.func';
import { sexes } from 'models/patients.model';

type Props = {
  index: number;
  register: UseFormRegister<{ patients: AppointmentPatient[] }>;
  setValue: UseFormSetValue<{ patients: AppointmentPatient[] }>;
  watch: UseFormWatch<{ patients: AppointmentPatient[] }>;
  formState: FormState<{ patients: AppointmentPatient[] }>;
  remove: (index: number) => void;
  control: Control<{ patients: AppointmentPatient[] }>;
  isAuthenticated: boolean;
  nbrPatient: number;
};

const BookingAddUnknownPatient: FC<Props> = ({ isAuthenticated, nbrPatient, index, register, setValue, watch, formState, remove, control }) => {
  const { t } = useTranslation();

  return (
    <Grid container columnSpacing={2} rowSpacing={3}>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant='h2'>
          {t('appointment.book.steps.patients.unknownPatients.new.title', {
            replace: { number: nbrPatient + index + 1 },
          })}
        </Typography>
        {(index !== 0 || isAuthenticated) && (
          <Button
            variant='danger'
            onClick={() => {
              remove(index);
            }}
          >
            {t('common.action.delete')}
          </Button>
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          select
          variant='filled'
          label={t('patients.search.fields.sex')}
          {...register(`patients.${index}.gender`, { required: true })}
          required
          error={formState.errors?.patients?.[index]?.gender !== undefined}
          defaultValue={Gender.FEMALE_FULL}
          fullWidth
        >
          {sexes.map(({ label, value }, i) => {
            return (
              <MenuItem value={value} key={`sex-${i}`}>
                {t(label)}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          variant='filled'
          {...register(`patients.${index}.first_name`, {
            required: true,
            minLength: 2,
            maxLength: 255,
          })}
          required
          error={formState.errors?.patients?.[index]?.first_name !== undefined}
          label={`${t('profile.data.first_name')}`}
          fullWidth
          helperText={formState.errors?.patients?.[index]?.first_name ? t('preregistration.myData.form.error.invalidFirstName') : null}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          variant='filled'
          {...register(`patients.${index}.birth_name`, {
            required: true,
            minLength: 2,
            maxLength: 255,
          })}
          required
          error={formState.errors?.patients?.[index]?.birth_name !== undefined}
          label={`${t('profile.data.birth_name')}`}
          helperText={formState.errors?.patients?.[index]?.birth_name ? t('preregistration.myData.form.error.invalidFirstName') : null}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          variant='filled'
          {...register(`patients.${index}.last_name`, { minLength: 2, maxLength: 255 })}
          label={`${t('profile.data.last_name')}`}
          error={formState.errors?.patients?.[index]?.last_name !== undefined}
          helperText={formState.errors?.patients?.[index]?.last_name ? t('preregistration.myData.form.error.invalidFirstName') : null}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <GenericDatePicker
          error={formState.errors?.patients?.[index]?.birth_date !== undefined}
          name={`patients.${index}.birth_date`}
          control={control}
          onValidate={() => isManualValidDate(watch(`patients.${index}.birth_date`))}
          helperText={t('preregistration.myData.form.helperText.birth_date')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField variant='filled' {...register(`patients.${index}.cns`)} label={`${t('profile.data.cns')}`} fullWidth />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          variant='filled'
          {...register(`patients.${index}.email`, {
            required: true,
          })}
          type='email'
          required
          error={formState.errors?.patients?.[index]?.email !== undefined}
          label={`${t('profile.data.email')}`}
          helperText={formState.errors?.patients?.[index]?.email ? t('preregistration.myData.form.error.invalidEmail') : null}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <PhoneInput
          onChange={(e) => {
            setValue(`patients.${index}.phone`, e.toString());
          }}
          value={watch(`patients.${index}.phone`)}
          label={`${t('profile.data.phone')}`}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <PhoneInput
          onChange={(e) => {
            setValue(`patients.${index}.mobile_phone`, e.toString());
          }}
          value={watch(`patients.${index}.mobile_phone`)}
          label={`${t('profile.data.mobile_phone')}`}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default BookingAddUnknownPatient;
