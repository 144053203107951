import { TableCell, Typography } from '@mui/material';
import { Result } from 'models/result.model';
import { FC } from 'react';

const DetailTabPanelBodyIsGraph: FC<{ val: Result }> = ({ val }) => {
  return (
    <TableCell colSpan={3}>
      <Typography
        variant='body2'
        dangerouslySetInnerHTML={{
          __html: val.value,
        }}
      />
    </TableCell>
  );
};

export default DetailTabPanelBodyIsGraph;
