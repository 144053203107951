import theme_color from 'resources/theme/theme.colors';

import { alpha, createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiButton: {
      variants: [
        // primary
        {
          props: { variant: 'contained' },
          style: {
            color: theme_color.palette.primary.contrastText,
            backgroundColor: theme_color.palette.primary.main,
            borderRadius: 4,
            border: '2px solid',
            borderColor: theme_color.palette.primary.main,
            disableElevation: true,
            boxShadow: 'none',
            padding: '8px 24px',
            '&:hover': {
              backgroundColor: 'transparent',
              border: '2px solid',
              borderColor: theme_color.palette.primary.main,
              boxShadow: 'none',
            },
            '&.Mui-disabled': {
              borderColor: 'transparent',
            },
          },
        },
        // secondary
        {
          props: { variant: 'outlined' },
          style: {
            backgroundColor: 'transparent',
            color: theme_color.palette.text.primary,
            borderRadius: 4,
            border: '2px solid',
            borderColor: theme_color.palette.secondary.main,
            disableElevation: true,
            boxShadow: 'none',
            padding: '8px 24px',
            '&:hover': {
              backgroundColor: theme_color.palette.secondary.main,
              color: theme_color.palette.common.white,
              border: '2px solid',
              borderColor: theme_color.palette.secondary.main,
              boxShadow: 'none',
            },
            '&.Mui-disabled': {
              border: '2px solid',
            },
          },
        },
        // tertiary (previous, back, read more, etc)
        {
          props: { variant: 'text' },
          style: {
            padding: '0px',
            minWidth: 'auto',
            color: theme_color.palette.text.primary,
            borderBottom: '2px solid',
            borderColor: theme_color.palette.primary.main,
            backgroundColor: 'transparent',
            boxShadow: 'none',
            borderRadius: 0,
            '& .MuiSvgIcon-root': {
              color: theme_color.palette.primary.main,
            },
            '&:hover': {
              backgroundColor: 'transparent',
              borderColor: theme_color.palette.text.primary,
              '& .MuiSvgIcon-root': {
                color: theme_color.palette.text.primary,
              },
            },
            '&.Mui-disabled': {
              borderColor: alpha(theme_color.palette.text.disabled, theme_color.palette.action.disabledOpacity),
            },
          },
        },
        // danger
        {
          props: { variant: 'danger' },
          style: {
            backgroundColor: 'red',
            color: 'white',
            borderRadius: 4,
            border: '2px solid',
            borderColor: 'red',
            disableElevation: true,
            boxShadow: 'none',
            padding: '8px 24px',
            '&:hover': {
              backgroundColor: 'transparent',
              color: 'red',
              boxShadow: 'none',
            },
            '&.Mui-disabled': {
              border: '2px solid',
            },
          },
        },
        // header - background light
        {
          props: { variant: 'header_light' },
          style: {
            padding: '0px',
            minWidth: 'auto',
            color: theme_color.palette.text.primary,
            borderBottom: '2px solid',
            borderColor: 'transparent',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            borderRadius: 0,
            '&:hover': {
              backgroundColor: 'transparent',
              borderColor: theme_color.palette.primary.main,
            },
          },
        },
        // header - background dark
        {
          props: { variant: 'header_dark' },
          style: {
            padding: '0px',
            minWidth: 'auto',
            color: theme_color.palette.primary.contrastText,
            borderBottom: '2px solid',
            borderColor: 'transparent',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            borderRadius: 0,
            '&:hover': {
              backgroundColor: 'transparent',
              borderColor: theme_color.palette.primary.contrastText,
            },
          },
        },
        // link (footer)
        {
          props: { variant: 'link' },
          style: {
            padding: '0px',
            minWidth: 'auto',
            color: theme_color.palette.text.primary,
            borderBottom: '1px solid',
            borderColor: 'transparent',
            fontWeight: 400,
            borderRadius: 0,
            '&:hover': {
              backgroundColor: 'transparent',
              borderColor: theme_color.palette.text.primary,
            },
          },
        },
      ],
    },
  },
});

export default theme;

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    link: true;
    danger: true;
    header_light: true;
    header_dark: true;
  }
}
