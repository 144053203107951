import { Grid, Typography } from '@mui/material';
import MissingData from 'components/missing-data';
import { Address } from 'models/profile.model';
import { FC } from 'react';
import { addressToString, isAddress } from 'utils/func/Address.func';
import { useTranslation } from 'react-i18next';
import { convertDate } from 'utils/func/Date.func';

type arrayPerson = {
  key: string;
  value: number;
};

const ProfileData: FC<any> = ({ person }) => {
  const { t } = useTranslation();

  function getProfileData(person: any): arrayPerson[] {
    return [
      {
        key: t('profile.data.birth_name'),
        value: person.birth_name,
      },
      {
        key: t('profile.data.birth_date'),
        value: convertDate(person.birth_date),
      },
      {
        key: t('profile.data.cns'),
        value: person.cns,
      },
      {
        key: t('profile.data.personal_address'),
        value: addressToString(person.addresses.home),
      },
      {
        key: t('profile.data.work_address'),
        value: addressToString(person.addresses.work),
      },
      {
        key: t('profile.data.phone'),
        value: person.phone,
      },
      {
        key: t('profile.data.mobile_phone'),
        value: person.mobile,
      },
      {
        key: t('profile.data.fax'),
        value: person.fax,
      },
      {
        key: t('profile.data.email'),
        value: person.email,
      },
    ];
  }

  /**
   * @desc display data according to its type
   * @param value the data to display
   * @returns the displayed data
   */
  function displayData(value: string | number | Address | undefined): string | React.ReactNode {
    if (isAddress(value)) {
      return addressToString(value);
    } else {
      return !!value ? `${value}` : <MissingData />;
    }
  }

  return (
    <Grid container spacing={1}>
      {getProfileData(person).map((data, key) => {
        return (
          <Grid container columnSpacing={1} spacing={0} item key={key}>
            <Grid item xs={12} sm={6}>
              <Typography variant='h3'>{data.key}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant='body1'>{displayData(data.value)}</Typography>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ProfileData;
