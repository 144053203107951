import { NavMenuAction, NavMenuActionType } from '../actions/navMenu.actions';

type NavMenuInitialState = {
  isOpen: boolean;
};

const navMenuInitialState: NavMenuInitialState = {
  isOpen: false,
};

export default function navMenuReducer(prevState = navMenuInitialState, action: NavMenuAction) {
  switch (action.type) {
    case NavMenuActionType.OPEN:
      return {
        ...prevState,
        isOpen: true,
      };

    case NavMenuActionType.CLOSE:
      return {
        ...prevState,
        isOpen: false,
      };

    default:
      return prevState;
  }
}
