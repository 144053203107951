import { Avatar, Card, CardContent, TableCell, TableRow, Typography } from '@mui/material';
import GenericTable, { GenericPaginator } from 'components/generic-table';
import NoResultsTableRow from 'components/no-results-table-row';
import { useTranslation } from 'react-i18next';
import { Analysis } from 'models/analysis.model';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { getSortedResults } from 'utils/func/SortedResults.func';
import AnalysisData from './AnalysisData';

interface AnalysisResultsProps {
  data?: any;
  paginatorData: GenericPaginator;
  handlePaginatorAction: (data: GenericPaginator) => void;
}

const AnalysisResults: FC<AnalysisResultsProps> = ({ data: analysesWrapper, paginatorData, handlePaginatorAction }) => {
  const [alphabeticallyArrangedData, setAlphabeticallyArrangedData] = useState<{ title: string; items: Analysis[]; indexOffset: number }[]>();
  const [open, setOpen] = useState<boolean[]>();
  const paginatorAction = useCallback(
    (data: GenericPaginator) => {
      handlePaginatorAction(data);
    },
    [handlePaginatorAction],
  );
  const { t } = useTranslation();

  useEffect(() => {
    setOpen(Array(analysesWrapper?.results?.length).fill(false));
    if (analysesWrapper?.results?.length > 0) {
      const sortedData = getSortedResults<Analysis>(analysesWrapper.results, 'name');
      setAlphabeticallyArrangedData(sortedData);
    } else {
      setAlphabeticallyArrangedData([]);
    }
  }, [analysesWrapper]);

  return (
    <>
      {alphabeticallyArrangedData && (
        <Card>
          <CardContent>
            {analysesWrapper && (
              <Typography py={2}>
                {analysesWrapper.pagination?.total > 0 &&
                  t('analysis.available_analysis', {
                    count: analysesWrapper.total,
                  })}
              </Typography>
            )}
            <GenericTable
              noHeaders
              {...(alphabeticallyArrangedData.length
                ? {
                    pagination: paginatorData,
                    setPagination: paginatorAction,
                  }
                : {
                    noPagination: true,
                  })}
            >
              {alphabeticallyArrangedData.length ? (
                alphabeticallyArrangedData.map((alpha, alphaIndex) => (
                  <Fragment key={alphaIndex}>
                    <TableRow sx={{ border: '0 !important' }}>
                      <TableCell>
                        <Avatar
                          sx={{
                            backgroundColor: 'primary.main',
                            color: 'primary.contrastText',
                            mb: 2,
                          }}
                        >
                          <Typography variant='h2'>{alpha.title.toUpperCase()}</Typography>
                        </Avatar>
                      </TableCell>
                    </TableRow>
                    {alpha.items.map((data, index) => (
                      <AnalysisData analysis={data} open={open} handleChangeOpen={setOpen} keyOpen={index + alpha.indexOffset} key={index + alpha.indexOffset} />
                    ))}
                  </Fragment>
                ))
              ) : (
                <NoResultsTableRow colSpan={1} />
              )}
            </GenericTable>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default AnalysisResults;
