import { Button, DialogTitle, Grid, Typography } from '@mui/material';
import { useNotification } from 'hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const DeleteAccountModal: FC = () => {
  const { t } = useTranslation();
  const { notification } = useNotification();

  const deleteAccount = () => {
    // TODO: Faire la suppression de compte
    notification(t('profile.deleteAccount.notification.success'), 'success');
  };

  return (
    <DialogTitle>
      <Typography variant='h2'>{t('profile.deleteAccount.title')}</Typography>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant='body2'>{t('profile.deleteAccount.subtitle')}</Typography>
          <Grid item xs={12} my={2} display='flex' justifyContent='flex-end'>
            <Button variant='outlined' sx={{ mr: 1 }}>
              {t('common.words.noBtn')}
            </Button>
            <Button variant='danger' sx={{ mr: 1 }} onClick={() => deleteAccount()}>
              {t('common.words.yesBtn')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </DialogTitle>
  );
};

export default DeleteAccountModal;
