import { Box, Button, IconButton, SvgIcon, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import api from 'api';
import GenericTable, { GenericPaginator } from 'components/generic-table';
import { GenericHeader } from 'components/generic-table/GenericTableHeader';
import NoResultsTableRow from 'components/no-results-table-row';
import ContextLink from 'components/_base/ContextLink';
import { useIsMobileView } from 'hooks';
import { Patient } from 'models/patients.model';
import { Record } from 'models/record.model';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconAbnormal } from 'resources/icons/anormaux.svg';
import { ReactComponent as IconStar } from 'resources/icons/favorite.svg';
import { ReactComponent as IconStarOutline } from 'resources/icons/favorite_outline.svg';
import { ReactComponent as IconUrgent } from 'resources/icons/urgent.svg';
import { DEFAULT_PAGINATION_SIZE, NO_DATA_DISPLAY } from 'utils/Constants';
import { convertDate } from 'utils/func/Date.func';

interface PatientRecordsListProps {
  patient: Patient;
}

const Dossiers: FC<PatientRecordsListProps> = ({ patient }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const [paginationStart, setPaginationStart] = useState(0);
  const [paginationSize, setPaginationSize] = useState(DEFAULT_PAGINATION_SIZE);
  const [paginationTotal, setPaginationTotal] = useState(0);
  const [recordsList, setRecordsList] = useState<Record[] | undefined>(undefined);

  useEffect(() => {
    api.results.getResults(paginationSize, paginationStart, patient.id).then((response) => {
      setRecordsList([...response.results!]);
      setPaginationTotal(response.total);
    });
  }, [paginationSize, paginationStart, patient.id]);

  const tagElement = useCallback((record: Record) => {
    api.results.markAResult(+record.reference).then((res) => {
      record.tagged = res.tagged;
      setRecordsList((list) => [...list!]);
    });
  }, []);

  const hasCC = (record: Record) => {
    return !!record.cc?.length;
  };

  const displayCC = (record: Record) => {
    return record.cc?.map((cc, key) => (
      <Typography variant='body1' key={key}>
        {`${cc.office_medical_staff?.medical_staff?.title} ${cc.office_medical_staff?.medical_staff?.first_name} ${cc.office_medical_staff?.medical_staff?.last_name}`}
      </Typography>
    ));
  };

  const hasIssuer = (record: Record) => {
    return !!record.issuer;
  };

  const displayIssuer = (record: Record) => {
    return <Typography variant='body1'>{`${record.issuer.title} ${record.issuer.first_name} ${record.issuer.last_name}`}</Typography>;
  };

  const tableHeaders: GenericHeader[] = [
    { id: 'quick_actions', label: undefined },
    {
      id: 'prescription_date',
      label: 'common.table.tableHead.prescription_date',
    },
    {
      id: 'sampling_date',
      label: 'common.table.tableHead.sample_date',
    },
    {
      id: 'record_reference',
      label: 'common.table.tableHead.reference_number',
    },
    {
      id: 'issuer',
      label: 'common.table.tableHead.issuer',
    },
    { id: 'cc', label: 'common.table.tableHead.cc' },
    {
      id: 'last_report',
      label: 'common.table.tableHead.last_report',
    },
    { id: 'status', label: 'patient_details.tab.record.headers.status' },
    { id: 'actions', label: undefined },
  ];

  const handlePaginationChange = (pagination: GenericPaginator) => {
    setPaginationSize(pagination.size);
    setPaginationStart(pagination.page);
    setPaginationTotal(pagination.total);
  };

  return (
    <>
      {recordsList && (
        <GenericTable
          {...(isMobileView ? { noHeaders: true } : { headers: tableHeaders })}
          {...(recordsList?.length
            ? {
                pagination: {
                  page: paginationStart,
                  size: paginationSize,
                  total: paginationTotal,
                },
                setPagination: handlePaginationChange,
              }
            : {
                noPagination: true,
              })}
        >
          {recordsList.length ? (
            recordsList.map((record, key) => {
              return (
                <TableRow hover key={key}>
                  <TableCell>
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)',
                        placeItems: 'center',
                      }}
                    >
                      <Tooltip title={t('common.table.tableActions.mark')}>
                        <IconButton onClick={() => tagElement(record)}>
                          <SvgIcon fontSize='small' component={record.tagged ? IconStar : IconStarOutline} />
                        </IconButton>
                      </Tooltip>
                      {record.has_abnormal_result && (
                        <Tooltip title={t('common.table.tableActions.abnormal')}>
                          <SvgIcon fontSize='small' component={IconAbnormal} />
                        </Tooltip>
                      )}
                      {record.priority === 8 && (
                        <Tooltip title={t('common.table.tableActions.urgent')}>
                          <SvgIcon fontSize='small' component={IconUrgent} />
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>{record.prescription_date ? <Typography variant='body1'>{convertDate(record.prescription_date)}</Typography> : NO_DATA_DISPLAY}</TableCell>
                  <TableCell>{record.sampling_time ? <Typography variant='body1'>{convertDate(record.sampling_time, true)}</Typography> : NO_DATA_DISPLAY}</TableCell>
                  <TableCell>
                    <Typography variant='body1'>{record.reference}</Typography>
                  </TableCell>
                  <TableCell>{hasIssuer(record) ? displayIssuer(record) : NO_DATA_DISPLAY}</TableCell>
                  <TableCell>{hasCC(record) ? displayCC(record) : NO_DATA_DISPLAY}</TableCell>
                  <TableCell>{record.last_report_time ? <Typography variant='body1'>{convertDate(record.last_report_time, true)}</Typography> : NO_DATA_DISPLAY}</TableCell>
                  <TableCell>
                    <Typography variant='body1'>
                      {t(
                        // @ts-ignore
                        `patient_details.tab.record.list.status.${record.status}`,
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='body1'>
                      <ContextLink
                        to='DETAIL_RESULT'
                        params={{
                          id: record.reference,
                        }}
                      >
                        <Button type='button' variant='text'>
                          {t('patient_details.tab.record.list.details')}
                        </Button>
                      </ContextLink>
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <NoResultsTableRow colSpan={tableHeaders.length} />
          )}
        </GenericTable>
      )}
    </>
  );
};

export default Dossiers;
