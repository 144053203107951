import { Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useIsMobileView } from 'hooks';
import { DirectoryFormValues, SearchType } from 'models/directory.model';
import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface FindHeaderProps {
  control: Control<DirectoryFormValues, any>;
}

const FindHeader: FC<FindHeaderProps> = ({ control }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm='auto' display='flex' alignItems='center'>
        <Typography variant='h2'>{t('findDoctor.searchBar.label')}</Typography>
      </Grid>
      <Grid item xs={12} sm='auto'>
        <Controller
          name={'type'}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <ToggleButtonGroup {...field} orientation={isMobileView ? 'vertical' : 'horizontal'} fullWidth={isMobileView} exclusive>
              <ToggleButton value={SearchType.PROFESSIONAL}>{t('findDoctor.searchBar.actions.professional')}</ToggleButton>
              <ToggleButton value={SearchType.HEALTH_INSTITUTION}>{t('findDoctor.searchBar.actions.institution')}</ToggleButton>
            </ToggleButtonGroup>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default FindHeader;
