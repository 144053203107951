import { Box, Divider, Grid, Radio, RadioGroup, TextField, Typography, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DraftGenericFormProps } from 'models/result.patient.model';
import { FC, Fragment } from 'react';
import { Controller } from 'react-hook-form';

export const DraftGenericMatrixForm: FC<DraftGenericFormProps & { control: any }> = ({ question, register, control }) => {
  const { t } = useTranslation();
  const widthGrid = 12 / (question.values.length > 0 ? question.values.length : 1);
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box>
          <Grid item xs={12} display='flex'>
            <Grid item xs={widthGrid + 1}>
              <Typography variant='subtitle1'>{question.name}</Typography>
            </Grid>
            {question.values.length > 0 ? (
              question.values.map((value, index) => (
                <Grid key={index} item xs={widthGrid} justifyContent='center' display='flex'>
                  <Typography>{value.name}</Typography>
                </Grid>
              ))
            ) : (
              <Grid key={'answers'} item xs={widthGrid} justifyContent='center' display='flex'>
                <Typography></Typography>
              </Grid>
            )}
            <Grid item xs={widthGrid - 1} />
          </Grid>
          <Divider sx={{ gridColumn: '1/-1', borderColor: 'primary.main' }} />
          {/* body */}
          {question.sub_questions.map((row, index, array) => (
            <Fragment key={index}>
              <Grid item xs={12} p={1} display='flex'>
                <Grid item xs={widthGrid + 1}>
                  <Typography justifyContent=''>{row.name}</Typography>
                </Grid>
                <Controller
                  control={control}
                  rules={{ required: row.required }}
                  name={row.id.toString()}
                  defaultValue={row.answers[0] ? row.answers[0] : ''}
                  render={({ field }) => {
                    if (question.values.length > 0)
                      return (
                        <RadioGroup {...field} row sx={{ width: '100%' }}>
                          {question.values.map((value, index) => {
                            return (
                              <Grid key={index} item xs={widthGrid} justifyContent='center' display='flex'>
                                <FormControlLabel labelPlacement='bottom' label='' control={<Radio />} value={value.id} />
                              </Grid>
                            );
                          })}
                        </RadioGroup>
                      );
                    return (
                      <Grid key={index} item xs={widthGrid} justifyContent='center' display='flex'>
                        <TextField {...field} variant='standard' fullWidth />
                      </Grid>
                    );
                  }}
                />
                <Grid item xs={widthGrid - 1}>
                  {row.has_comment && (
                    <TextField
                      label={t('results.details.remarksLabel')}
                      {...register(`comment-${row.id}`)}
                      fullWidth
                      variant='standard'
                      InputLabelProps={{
                        sx: { p: 0 },
                      }}
                      defaultValue={row.answer_comment ?? ''}
                    />
                  )}
                </Grid>
                {index + 1 !== array.length && <Divider sx={{ gridColumn: '1/-1' }} />}
              </Grid>
            </Fragment>
          ))}
        </Box>
      </Grid>
      {question.has_comment && (
        <Grid item xs={12}>
          <TextField
            multiline
            label={t('results.details.remarksLabel')}
            minRows={4}
            fullWidth
            {...register(`comment-${question.id}`)}
            variant='outlined'
            InputLabelProps={{
              sx: { p: 0 },
            }}
            //value={question.answer_comment ?? ''}
          />
        </Grid>
      )}
    </Grid>
  );
};
