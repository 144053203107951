import { AnalysisReference } from 'models/analysis.model';
import { PrescriptionProfile } from 'models/prescription-profile.model';
import { PrescriptionProfileAction, PrescriptionProfileActionType } from '../actions/prescriptionProfile.actions';

type PrescriptionProfileState = {
  name: string;
  description?: string;
  comment?: string;
  items: AnalysisReference[];
  editProfile?: PrescriptionProfile;
};

const prescriptionProfileInitialState: PrescriptionProfileState = {
  name: '',
  description: undefined,
  comment: undefined,
  items: [],
  editProfile: undefined,
};

export default function prescriptionProfileReducer(prevState = prescriptionProfileInitialState, action: PrescriptionProfileAction) {
  switch (action.type) {
    case PrescriptionProfileActionType.NEW_ADD_ITEM:
      if (prevState.items.filter((item) => item.analysis_reference === action.analysis.analysis_reference).length === 0) {
        return {
          ...prevState,
          items: [...prevState.items, action.analysis],
        };
      } else {
        return prevState;
      }

    case PrescriptionProfileActionType.NEW_REMOVE_ITEM:
      return {
        ...prevState,
        items: [...prevState.items.filter((item) => item.analysis_reference !== action.analysis_reference)],
      };

    case PrescriptionProfileActionType.NEW_CLEAR_ITEMS:
      return {
        ...prevState,
        items: [],
      };

    case PrescriptionProfileActionType.NEW_SET_NAME:
      return {
        ...prevState,
        name: action.name,
      };

    case PrescriptionProfileActionType.NEW_SET_DESCRIPTION:
      return {
        ...prevState,
        description: action.description,
      };

    case PrescriptionProfileActionType.NEW_SET_COMMENT:
      return {
        ...prevState,
        comment: action.comment,
      };

    case PrescriptionProfileActionType.NEW_CLEAR_PROFILE:
      return {
        ...prevState,
        name: '',
        description: undefined,
        items: [],
      };

    case PrescriptionProfileActionType.EDIT_SET_PROFILE:
      return {
        ...prevState,
        editProfile: action.profile,
      };

    case PrescriptionProfileActionType.EDIT_CLEAR_PROFILE:
      return {
        ...prevState,
        editProfile: undefined,
      };

    case PrescriptionProfileActionType.ALL_CLEAR:
      return {
        ...prevState,
        ...prescriptionProfileInitialState,
      };

    default:
      return prevState;
  }
}
