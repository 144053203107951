import { Box, Button, Card, CardContent, Grid, Skeleton, Typography } from '@mui/material';
import ContextLink from 'components/_base/ContextLink';
import { useAuthenticated, useContextRedirection } from 'hooks';
import { PreregistrationResponse } from 'models/preregistration.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import RoutePaths from 'utils/RoutePaths';

const PreregistrationStep3: FC<{
  loading: boolean;
  tokenResponseList: PreregistrationResponse[];
  atCenter: boolean;
}> = ({ loading, tokenResponseList, atCenter }) => {
  const { t } = useTranslation();
  const isAuthenticated = useAuthenticated();
  const navigate = useContextRedirection();

  const goHome = () => {
    if (isAuthenticated) {
      navigate(RoutePaths['LANDING_PAGE']);
    } else {
      navigate(RoutePaths['HOME']);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {loading && tokenResponseList.length === 0 ? (
          <Skeleton variant='rectangular' animation='wave' height={750} sx={{ margin: '0 auto' }} />
        ) : (
          <Card>
            <CardContent sx={{ textAlign: 'center' }}>
              <Typography variant='h1' gutterBottom>
                {t('preregistration.locationChoice.center.confirmation.title')}
              </Typography>
              <Box p={10}>
                <Typography variant='body1'>
                  <i>{t('preregistration.locationChoice.center.confirmation.informationText')}</i>
                </Typography>
              </Box>

              {!atCenter && (
                <Box
                  display='flex'
                  flexDirection='column'
                  gap={2}
                  py={3}
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.main,
                  }}
                >
                  <Typography variant='h2'>{t('preregistration.locationChoice.center.confirmation.bionextLinkSubtitle')}</Typography>
                  <Box display='flex' justifyContent='center'>
                    <ContextLink to='CENTERS'>
                      <Button variant='outlined' sx={{ my: 1 }}>
                        {t('preregistration.locationChoice.center.confirmation.centers')}
                      </Button>
                    </ContextLink>
                  </Box>
                </Box>
              )}
            </CardContent>
          </Card>
        )}
      </Grid>
      <Grid item xs={12}>
        <Button onClick={goHome}>{t('preregistration.validation.yourAddress.actions.backHome')}</Button>
      </Grid>
    </Grid>
  );
};

export default PreregistrationStep3;
