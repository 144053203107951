import { AppBar, Toolbar } from '@mui/material';
import { FC } from 'react';

import HeaderRightBloc from './HeaderRightBloc';
import HeaderLeftBloc from './HeaderLeftBloc';

const UserIsNotLogged: FC = () => {
  return (
    <AppBar
      position='static'
      sx={{
        height: '72px',
        boxShadow: 'none',
        justifyContent: 'center',
      }}
    >
      <Toolbar
        sx={{
          justifyContent: 'flex-end',
          padding: { xs: '16px', sm: '29px 82px 1vw' },
          //boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.16)',
          backgroundColor: 'background.paper',
          minHeight: '0px !important',
        }}
      >
        <HeaderLeftBloc isOpen={false} />
        <HeaderRightBloc />
      </Toolbar>
    </AppBar>
  );
};

export default UserIsNotLogged;
