import { Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { GenericFormProps } from 'models/result.patient.model';
import { FC } from 'react';

export const GenericDateForm: FC<GenericFormProps> = ({ question }) => {
  return (
    <Box
      sx={{
        '& > *': {
          width: '100%',
        },
      }}
    >
      <DatePicker label={question.name} value={question.answers[0] ?? null} onChange={() => {}} slotProps={{ textField: { variant: 'standard' } }} disabled />
    </Box>
  );
};
