import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { availableLanguages } from '../../i18n';

const PageDispatcher: FC = () => {
  const { lang: langFromParams } = useParams<{ lang: string }>();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const matchedLanguage = useMemo(() => {
    return langFromParams ? availableLanguages.find((lang) => lang.includes(langFromParams)) : undefined;
  }, [langFromParams]);

  useEffect(() => {
    if (!matchedLanguage) {
      navigate(`/${i18n.language.split('-').shift()}/404`);
    }
    if (matchedLanguage !== i18n.language) {
      i18n.changeLanguage(matchedLanguage);
    }
  }, [i18n, matchedLanguage, navigate]);

  return <Outlet />;
};

export default PageDispatcher;
