import { TableCell, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';

const DetailTabPanelBodyIsNotShow: FC = () => {
  const { t } = useTranslation();

  return (
    <TableCell colSpan={4}>
      <Typography variant='body1' fontStyle='italic'>
        {t('result_patient.not_show')}
      </Typography>
    </TableCell>
  );
};

export default DetailTabPanelBodyIsNotShow;
