import { Button, Card, CardContent, Grid } from '@mui/material';
import api from 'api';
import ResultViewerSkeleton from 'pages/doctor/results/resultViewer/index.skeleton';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { useIsMobileView, useNotification } from 'hooks';
import { PdfType } from 'models/file.model';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const PdfViewer: FC<{
  title: string;
  type: PdfType;
}> = ({ title, type }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const { idResult, filename } = useParams();
  const [pdfData, setPdfData] = useState<Blob>();
  const { notification } = useNotification();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    idResult &&
      filename &&
      api.results
        .getFile(type, idResult, filename)
        .then((file: Blob) => {
          setPdfData(file);
        })
        .catch(() => {
          notification(t('common.errorLoadDocument'), 'error');
        })
        .finally(() => setLoading(false));
  }, [filename, idResult, notification, t, type]);

  const files = new Blob([pdfData as BlobPart], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(files);

  return (
    // TODO: Faire la navigation
    <BodyTemplate title={title} backButton>
      <Card>
        {!isLoading ? (
          <CardContent>
            <Grid container>
              <Grid item xs={12} display='flex' alignItems='center' justifyContent='flex-end'>
                <a href={fileURL} download style={{ textDecoration: 'none' }}>
                  <Button variant='outlined'>{t('common.action.pdfDownload')}</Button>
                </a>
              </Grid>
              <Grid item xs={12}>
                <iframe src={fileURL} title='document' style={{ border: 0 }} width='100%' height={isMobileView ? '500px' : '800px'} />
              </Grid>
            </Grid>
          </CardContent>
        ) : (
          <ResultViewerSkeleton />
        )}
      </Card>
    </BodyTemplate>
  );
};

export default PdfViewer;
