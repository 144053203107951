import { Skeleton, TableCell, TableRow, Typography } from '@mui/material';
import { FC } from 'react';

type Props = {
  columns: number;
  rows: number;
};

const TableContentSkeleton: FC<Props> = ({ columns = 1, rows = 1 }) => {
  const _rows = new Array(rows).fill(0);
  const _columns = new Array(columns).fill(0);

  return (
    <>
      {_rows.map((row, rowKey) => (
        <TableRow hover key={rowKey}>
          {_columns.map((col, colKey) => (
            <TableCell key={colKey}>
              <Typography variant='body1'>
                <Skeleton variant='text' width='20%' />
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};
export default TableContentSkeleton;
