import { debounce, FormControlLabel, Grid, Button, CardActions, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import api from 'api';
import { endOfMonth } from 'date-fns';
import { useIsMobileView, useNotification } from 'hooks';
import { AvailableSlots } from 'models/appointment.slots.model';
import { FC, useCallback, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { treatAppointmentSlots } from 'utils/func/Appointment.func';
import { convertDate } from 'utils/func/Date.func';
import { Props } from '../index';

const BookingScheduleStep: FC<Props> = ({ handleBack, state, handleNext, handleState }) => {
  const { t } = useTranslation();
  const { notification } = useNotification();
  const isMobileView = useIsMobileView();
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [availableSlots, setAvailableSlots] = useState<AvailableSlots>();
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date());
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [fullDate, setFullDate] = useState('');
  const [selectableSlots, setSelectableSlots] = useState<
    {
      slotStartTime: string;
      slotEndTime: string;
      slotValue: string;
    }[]
  >([]);
  const { control, formState, handleSubmit } = useForm<{
    slot: string;
  }>({ mode: 'onChange', defaultValues: { slot: state.slot }, shouldFocusError: true });
  const watchedMedicalActs = state.patients[0].medical_acts;

  const gettingSlots = useCallback(
    (date: Date) => {
      api.appointments
        .getSlots(date, endOfMonth(date), watchedMedicalActs, state.address.zip_code, state.address.country_iso)
        .then((res) => {
          const { dates, slots } = treatAppointmentSlots(res);
          setAvailableDates(dates ?? []);
          setAvailableSlots(slots ?? []);
          if (dates[0]) {
            setSelectedDate(new Date(dates[0]));
          }
        })
        .catch((err) => {
          notification(t('appointment.book.steps.schedule.notification.error'), 'error');
        });
    },
    [notification, t, state.address.country_iso, state.address.zip_code, watchedMedicalActs],
  );

  useEffect(() => {
    const date = new Date(selectedMonth);
    date.setDate(1);
    gettingSlots(date);
  }, [gettingSlots, selectedMonth]);

  useEffect(() => {
    if (selectedDate && !isNaN(selectedDate.valueOf()) && availableSlots) {
      setFullDate(convertDate(selectedDate, false, 'eeee d MMMM yyyy'));
      setSelectableSlots(availableSlots[convertDate(selectedDate, false, 'yyyy-MM-dd')] ?? []);
    }
  }, [selectedDate, availableSlots]);

  const dateToDisable = (day: Date) => {
    return isNaN(day.valueOf()) || !availableDates.includes(convertDate(day, false, 'yyyy-MM-dd'));
  };

  const onChangeMobileDate = (day: string): void => {
    gettingSlots(new Date(day));
  };

  return (
    <form
      onSubmit={handleSubmit((data) => {
        if (!formState.errors.slot) {
          handleNext();
          handleState(data.slot, 'slot');
        }
      })}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} mb={2} md={8}>
          <Grid item display='flex' alignItems='center' flexDirection='row'>
            <Typography variant='h3' sx={{ wordWrap: 'break-word' }}>
              {t('appointment.book.steps.title')}
            </Typography>
          </Grid>
          <Typography marginTop={isMobileView ? 3 : 0} variant='h2'>
            {t('appointment.book.steps.schedule.title')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            variant='body1'
            dangerouslySetInnerHTML={{
              __html: t('appointment.book.steps.schedule.warning'),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3} textAlign='center'>
            <Grid item xs={12} md={6} alignSelf='center'>
              {!isMobileView ? (
                <DateCalendar value={selectedDate} onChange={(newDate) => setSelectedDate(newDate)} onMonthChange={(month) => setSelectedMonth(month)} shouldDisableDate={dateToDisable} />
              ) : (
                <TextField fullWidth type='date' defaultValue={convertDate(selectedDate, false, 'yyyy-MM-dd')} onChange={debounce((event) => onChangeMobileDate(event.target.value), 300)} />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant='h2' mb={1}>
                {fullDate}
              </Typography>
              {formState.errors.slot ? (
                <Typography color='error' variant='subtitle2' mb={1}>
                  error
                </Typography>
              ) : null}
              <Controller
                rules={{ required: true }}
                control={control}
                name='slot'
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Grid container spacing={0}>
                      {selectableSlots.map((slot, idx: number) => {
                        return (
                          <Grid item xs={12} md={6} key={idx}>
                            <FormControlLabel value={slot.slotValue} control={<Radio />} label={`${slot.slotStartTime}-${slot.slotEndTime}`} />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </RadioGroup>
                )}
              />
            </Grid>
          </Grid>
          <CardActions
            sx={{
              justifyContent: {
                xs: 'space-between',
                md: 'flex-end',
              },
              pt: 2,
            }}
          >
            <Button color='inherit' onClick={handleBack}>
              {t('appointment.book.action_buttons.previous')}
            </Button>
            <Button type='submit' variant='contained'>
              {t('appointment.book.action_buttons.next')}
            </Button>
          </CardActions>
        </Grid>
      </Grid>
    </form>
  );
};

export default BookingScheduleStep;
