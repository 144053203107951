import { Box, Dialog, DialogContent, DialogTitle, IconButton, SvgIcon } from '@mui/material';
import { FC } from 'react';
import { ReactComponent as IconClose } from 'resources/icons/close.svg';

type Props = {
  video: { video: string | undefined; thumbnail: string | undefined };
  open: boolean;
  setOpen: (bool: boolean) => void;
};

const VideoDialog: FC<Props> = ({ video, open, setOpen }) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: 'fit-content',
        },
      }}
    >
      <DialogTitle sx={{ position: 'relative' }}>
        <IconButton size='small' onClick={() => setOpen(false)} sx={{ position: 'absolute', zIndex: 2, top: 2, right: 2 }}>
          <SvgIcon fontSize='small' component={IconClose} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box position='relative' display='flex' justifyContent='center' alignItems='center'>
          <video
            src={video.video}
            poster={video.thumbnail}
            controls
            width='100%'
            style={{
              maxHeight: '75vh',
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default VideoDialog;
