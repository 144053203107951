import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, NavigateProps } from 'react-router-dom';

const ContextRedirect: FC<NavigateProps> = (props) => {
  const { i18n } = useTranslation();
  const locale = useMemo(() => i18n.language.split('-').shift(), [i18n.language]);

  const to = typeof props.to === 'string' ? `/${locale}${props.to}` : { ...props.to, pathname: `/${locale}${props.to.pathname}` };

  return <Navigate {...props} to={to} />;
};

export default ContextRedirect;
