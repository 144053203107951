import { Box, Button, Card, CardContent, CardHeader, debounce, IconButton, SvgIcon, TextField, Typography } from '@mui/material';
import api from 'api';
import { useContextRedirection, useNotification } from 'hooks';
import { PrescriptionProfile, PrescriptionProfilePostForm } from 'models/prescription-profile.model';
import { FC, useCallback, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as IconClose } from 'resources/icons/close.svg';
import {
  clearNewPrescriptionProfile,
  clearNewPrescriptionProfileItems,
  removeNewPrescriptionProfileItem,
  setEditPrescriptionProfile,
  setNewPrescriptionProfileDescription,
  setNewPrescriptionProfileName,
  setNewPrescriptionProfileComment,
} from 'store/actions/prescriptionProfile.actions';
import { GlobalState } from 'store/reducers';
import { tryCatch } from 'utils';
import RoutePaths from 'utils/RoutePaths';

type NewProfileCardProps = {
  editMode?: boolean;
};

const NewProfileCard: FC<NewProfileCardProps> = ({ editMode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useContextRedirection();
  const { notification } = useNotification();

  const { register, handleSubmit, control, formState } = useForm<PrescriptionProfilePostForm>({
    mode: 'onChange',
  });

  const items = useSelector((state: GlobalState) => state.prescriptionProfile.items);
  const profileName = useSelector((state: GlobalState) => state.prescriptionProfile.name);
  const profileDescription = useSelector((state: GlobalState) => state.prescriptionProfile.description);
  const profileComment = useSelector((state: GlobalState) => state.prescriptionProfile.comment);
  const profile = useSelector((state: GlobalState) => state.prescriptionProfile.editProfile);

  const [isSubmitting, setSubmitting] = useState(false);

  const handleProfileResponse = useCallback(
    (profile: PrescriptionProfile) => {
      if (editMode) {
        dispatch(setEditPrescriptionProfile(profile));
      }
    },
    [dispatch, editMode],
  );

  const handleAfterPost = useCallback(() => {
    dispatch(clearNewPrescriptionProfile());
  }, [dispatch]);

  const handleRemove = useCallback(
    (item: any) => {
      if (editMode) {
        api.prescriptions
          .deletePrescriptionProfileItem(profile!.id, item.reference)
          .then((response: PrescriptionProfile) => {
            handleProfileResponse(response);
          })
          .catch(() => notification(t('prescriptions_profile.add_analysis.notification.errorOnDeletingAnalysis'), 'error'));
      } else {
        dispatch(removeNewPrescriptionProfileItem(item.analysis_reference));
      }
    },
    [dispatch, editMode, handleProfileResponse, notification, profile, t],
  );

  const handleNameChange = useCallback(
    (name: string) => {
      if (!editMode) {
        dispatch(setNewPrescriptionProfileName(name));
      }
    },
    [dispatch, editMode],
  );

  const handleDescriptionChange = useCallback(
    (description: string) => {
      if (!editMode) {
        dispatch(setNewPrescriptionProfileDescription(description));
      }
    },
    [dispatch, editMode],
  );

  const handleCommentChange = useCallback(
    (comment: string) => {
      if (!editMode) {
        dispatch(setNewPrescriptionProfileComment(comment));
      }
    },
    [dispatch, editMode],
  );

  const handleClear = useCallback(() => {
    if (editMode) {
      api.prescriptions
        .deletePrescriptionProfileItems(profile!.id)
        .then((response: PrescriptionProfile) => {
          handleProfileResponse(response);
        })
        .catch(() => notification(t('prescriptions_profile.new_profile.notification.errorOnRemovingAllAnalysis'), 'error'));
    } else {
      dispatch(clearNewPrescriptionProfileItems());
    }
  }, [dispatch, editMode, handleProfileResponse, notification, profile, t]);

  const handleDelete = useCallback(() => {
    if (editMode) {
      api.prescriptions
        .deletePrescriptionProfile(profile!.id)
        .then(() => {
          navigate(RoutePaths['DOCTOR_PRESCRIPTION']);
          notification(t('prescriptions_profile.new_profile.notification.successOnDeleteProfile'), 'success');
        })
        .catch(() => notification(t('prescriptions_profile.new_profile.notification.errorOnRemovingProfile'), 'error'));
    }
  }, [editMode, navigate, notification, profile, t]);

  const onSubmit: SubmitHandler<PrescriptionProfilePostForm> = useCallback(
    (data) => {
      setSubmitting(true);
      const body = {
        ...data,
        items:
          editMode && profile
            ? profile.items.map((item) => {
                return { analysis_code: item.reference };
              })
            : items.map((item) => {
                return { analysis_code: item.analysis_reference };
              }),
      };
      if (editMode) {
        tryCatch(
          () =>
            api.prescriptions.putPrescriptionProfile(profile!.id, body).then((response: PrescriptionProfile) => {
              handleProfileResponse(response);
              notification(t('prescriptions_profile.new_profile.notification.successOnSaveProfile'), 'success');
            }),
          () => {
            notification(t('prescriptions_profile.new_profile.notification.errorOnEditingProfile'), 'error');
          },
          () => setSubmitting(false),
        );
      } else {
        tryCatch(
          () =>
            api.prescriptions.postPrescriptionProfile(body).then((response: PrescriptionProfile) => {
              handleAfterPost();
              notification(t('prescriptions_profile.new_profile.notification.successOnSaveProfile'), 'success');
              navigate(RoutePaths['DOCTOR_PRESCRIPTION_PROFILES_UPDATE'](response.id));
            }),
          () => {
            notification(t('prescriptions_profile.new_profile.notification.errorOnSavingProfile'), 'error');
          },
          () => setSubmitting(false),
        );
      }
    },
    [editMode, handleAfterPost, handleProfileResponse, items, navigate, notification, profile, t],
  );

  return (
    <>
      {((editMode && profile) || !editMode) && (
        <>
          <Card>
            <CardHeader
              title={
                <Typography variant='h2' mb={4}>
                  {editMode ? profile?.name : t('prescriptions_profile.new_profile.title')}
                </Typography>
              }
            />
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1 0 0',
              }}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name='name'
                  control={control}
                  defaultValue={editMode && profile ? profile.name : profileName}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      variant='filled'
                      label={t('prescriptions_profile.new_profile.profile_form.profile_name')}
                      {...field}
                      onChange={(event) => {
                        field.onChange(event);
                        debounce(() => handleNameChange(event.target.value), 300);
                      }}
                      sx={{ mb: 4 }}
                      fullWidth
                    />
                  )}
                />
                <Box sx={{ mb: 4, maxHeight: { md: '30vh' }, overflow: 'auto' }}>
                  {(editMode && profile ? profile.items : items).map((item, key) => (
                    <Box
                      key={key}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexShrink: 0,
                        py: 0.5,
                      }}
                    >
                      <IconButton onClick={() => handleRemove(item)}>
                        <SvgIcon sx={{ fontSize: 16, color: '#FF0000' }} component={IconClose} />
                      </IconButton>
                      <Typography variant='body1'>{item.name}</Typography>
                    </Box>
                  ))}
                </Box>
                <TextField
                  multiline
                  minRows={4}
                  variant='filled'
                  {...register('description')}
                  label={t(`prescriptions_profile.new_profile.profile_form.description`)}
                  defaultValue={editMode && profile ? profile.description : profileDescription}
                  onChange={debounce(({ target }) => handleDescriptionChange(target.value), 300)}
                  fullWidth
                />
                <TextField
                  multiline
                  minRows={4}
                  variant='filled'
                  {...register('comment')}
                  label={t(`prescriptions_profile.new_profile.profile_form.comment`)}
                  defaultValue={editMode && profile ? profile.comment : profileComment}
                  onChange={debounce(({ target }) => handleCommentChange(target.value), 300)}
                  sx={{ mt: 2 }}
                  fullWidth
                />
                <Button variant='contained' type='submit' disabled={!formState.isValid || isSubmitting} sx={{ mt: 4 }} fullWidth>
                  {t('prescriptions_profile.new_profile.profile_form.submit')}
                </Button>
              </form>
              <Button variant='outlined' onClick={() => handleClear()} sx={{ mt: 2 }} fullWidth>
                {t('prescriptions_profile.new_profile.remove_analysis')}
              </Button>
              {editMode && (
                <Button variant='outlined' onClick={() => handleDelete()} sx={{ mt: 2 }} fullWidth>
                  {t('prescriptions_profile.new_profile.delete_profile')}
                </Button>
              )}
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
};

export default NewProfileCard;
