import { APIConfiguration, axiosMYLAB } from 'api';
import { AppointmentMedicalAct } from 'models/appointment.medical.act.model';
import { Appointment } from 'models/appointment.model';
import { AppointmentSlots } from 'models/appointment.slots.model';
import { convertDate } from 'utils/func/Date.func';
import { BaseService } from './base.service';

class AppointmentService extends BaseService {
  async getMedicalActs() {
    const { data } = await axiosMYLAB.get<AppointmentMedicalAct[]>(this.apiConfig.APPOINTMENTS.GET_MEDICAL_ACTS_OLD(this.getLang()));

    this.log(`getMedicalActs()`);

    return data;
  }

  async getSlots(dateFrom: Date, dateTo: Date, medicalActsIds: string[], addressZipCode: string, addressCountryIso: string) {
    const { data } = await axiosMYLAB.post<AppointmentSlots>(this.apiConfig.APPOINTMENTS.GET_SLOTS_OLD(this.getLang()), {
      date_from: convertDate(dateFrom.toISOString(), false, 'yyyy-MM-dd'),
      date_to: convertDate(dateTo.toISOString(), false, 'yyyy-MM-dd'),
      medical_acts: medicalActsIds.map((id) => parseInt(id)),
      zip_code: addressZipCode,
      country_iso: addressCountryIso,
    });

    this.log(`getSlots()`);

    return data;
  }

  async bookAppointment(appointment: Appointment) {
    const { data } = await axiosMYLAB.post(this.apiConfig.APPOINTMENTS.POST_APPOINTMENT_OLD(this.getLang()), appointment);

    this.log(`bookAppointment()`);

    return data;
  }

  async getAppointments(patientId: number) {
    const { data } = await axiosMYLAB.get(this.apiConfig.APPOINTMENTS.GET_APPOINTMENTS(this.getLang()), {
      params: {
        patient_id: patientId,
      },
    });

    this.log(`getAppointments()`);

    return data;
  }

  async cancelAppointment(id: number) {
    await axiosMYLAB.delete(this.apiConfig.APPOINTMENTS.CANCEL_APPOINTMENT_OLD(this.getLang(), id));

    this.log(`cancelAppointment(${id})`);
  }
}

export default (apiConfig: APIConfiguration) => {
  return new AppointmentService(apiConfig);
};
