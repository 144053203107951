import { Grid } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { GenericPaginator } from 'components/generic-table';
import { useIsMobileView, useNotification } from 'hooks';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store/reducers';
import { DEFAULT_PAGINATION_SIZE } from 'utils/Constants';
import DesktopResults from './components/DesktopResults';
import MobileResults from './components/MobileResults';
import { useTranslation } from 'react-i18next';

const Results: FC = () => {
  const isMobileView = useIsMobileView();
  const [results, setResults] = useState<any[]>([]);
  const paginatorData: GenericPaginator = {
    total: 0,
    size: DEFAULT_PAGINATION_SIZE,
    page: 1,
  };
  const [paginator, setPaginator] = useState(paginatorData);
  const { t } = useTranslation();
  const { notification } = useNotification();
  const currentUser = useSelector((state: GlobalState) => state.session.currentUser);
  const [isLoading, setLoading] = useState(true);

  const getData = useCallback(async () => {
    setLoading(true);
    currentUser &&
      api.results
        .getResults(paginator.size, paginator.page, currentUser.id)
        .then((res) => {
          setResults(res.results);
          setPaginator({
            total: res.total,
            size: res.size,
            page: res.page,
          });
          setLoading(false);
        })
        .catch(() => notification(t('homepage.results.notification.error'), 'error'));
  }, [notification, paginator.size, paginator.page, t, currentUser]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handlePaginationChange = (pagination: GenericPaginator) => {
    setPaginator(pagination);
  };

  return (
    <BodyTemplate title={t('common.results')}>
      <Grid container justifyContent='center'>
        <Grid item xs={12}>
          {!isMobileView ? (
            <DesktopResults results={results} title={t('result_doctor.table_title')} pagination={paginator} isLoading={isLoading} onPaginationChange={handlePaginationChange} />
          ) : (
            <MobileResults results={results} isLoading={isLoading} />
          )}
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default Results;
