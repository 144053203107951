import theme_color from 'resources/theme/theme.colors';
import { createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiStepper: { defaultProps: { alternativeLabel: true } },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: 'transparent',
          border: '2px solid',
          borderColor: theme_color.palette.primary.main,
          borderRadius: '100%',
          fontWeight: 700,
          '&.Mui-active': {
            color: theme_color.palette.primary.main,
          },
          '&.Mui-completed': {
            color: theme_color.palette.primary.main,
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          border: '1px solid',
          borderColor: theme_color.palette.primary.main,
          backgroundColor: theme_color.palette.text.primary,
        },
        root: {
          right: 'calc(50% + 20px)',
          left: 'calc(-50% + 20px)',
        },
      },
    },
  },
});

export default theme;
