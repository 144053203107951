import { Box, Button, Card, CardContent, Dialog, DialogContent, DialogTitle, SvgIcon, Typography, Stack, Grid, Divider } from '@mui/material';
import ContextLink from 'components/_base/ContextLink';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useState } from 'react';
import { ReactComponent as IconToken } from 'resources/icons/token.svg';
import ForgotPassword from '../form/ForgotPasswordForm';
import ResetPassword from '../form/ResetPasswordForm';
import LoginForm from '../form/LoginForm';
import LoginTokenForm from '../form/LoginTokenForm';
import GooglePlayBadge from 'resources/images/logo_googleplay.svg';
import AppStoreBadge from 'resources/images/logo_appstore.svg';
import Logo from 'resources/images/MYLAB_mono-white.svg';
import { useParams } from 'react-router-dom';
import { useContextRedirection } from 'hooks';
import RoutePaths from 'utils/RoutePaths';

export type LoginDisplayStatus = 'SELECT' | 'CLASSIC' | 'TOKEN';

const LeftCard: FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isForgotPwd, setIsForgotPwd] = useState(false);
  const [isResetPwd, setIsResetPwd] = useState(false);
  const params = useParams();
  const navigate = useContextRedirection();

  useEffect(() => {
    if (params.resetToken) {
      setIsResetPwd(true);
    } else {
      setIsResetPwd(false);
    }
  }, [params]);

  const openDialog = () => {
    return (
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{t('landing_page.login.connection_with_token')}</DialogTitle>
        <DialogContent>
          <LoginTokenForm setOpenDialog={setOpen} />
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Card
      sx={{
        height: '100%',
        boxSizing: 'border-box',
        background: '#B6E5F9',
        backgroundImage: `url(${Logo}) `,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top 200px left 87px',
        backgroundSize: 'cover',
        boxShadow: 'none',
      }}
    >
      <CardContent sx={{ textAlign: 'center', alignItems: 'center' }}>
        <Grid item>
          <Typography variant='h1'>{t('landing_page.login.title')}</Typography>
        </Grid>
      </CardContent>
      <CardContent sx={{ textAlign: 'center' }}>
        {isForgotPwd ? (
          <Box>
            <ForgotPassword />
            <Button onClick={() => setIsForgotPwd(false)}>{t('landing_page.login.back')}</Button>
          </Box>
        ) : isResetPwd ? (
          <Box>
            <ResetPassword />
            <Button
              onClick={() => {
                setIsResetPwd(false);
                navigate(RoutePaths['LANDING_PAGE']);
              }}
            >
              {t('landing_page.login.back')}
            </Button>
          </Box>
        ) : (
          <>
            <Box p={3}>
              <LoginForm setIsForgotPwd={setIsForgotPwd} />
              <Divider
                sx={{
                  marginTop: '7%',
                  marginBottom: '7%',
                  '&::before, &::after': {
                    borderColor: 'black',
                  },
                }}
              >
                <Typography variant='h3'>{t('landing_page.login.or')}</Typography>
              </Divider>
              <Button
                variant='outlined'
                startIcon={<SvgIcon component={IconToken} sx={{ marginRight: '10px', width: '100%' }} />}
                onClick={() => setOpen(true)}
                fullWidth
                sx={{ backgroundColor: '#FFFFFF' }}
              >
                {t('landing_page.login.connection_with_token')}
              </Button>
            </Box>
            <Stack spacing={1} sx={{ alignItems: 'center' }}>
              <Grid item>
                <Grid container py={1} spacing={2}>
                  <Grid item>
                    <Typography variant='body2' sx={{ fontSize: '17px' }}>
                      {t('landing_page.download_app')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container py={1} spacing={2} sx={{ justifyContent: 'center' }}>
                  <Grid item>
                    <ContextLink to='GOOGLE_PLAY_STORE' external>
                      <Box sx={{ width: 100 }} component='img' alt='Google Play badge' src={GooglePlayBadge} />
                    </ContextLink>
                  </Grid>
                  <Grid item>
                    <ContextLink to='APP_STORE' external>
                      <Box sx={{ width: 100 }} component='img' alt='App Store Badge' src={AppStoreBadge} />
                    </ContextLink>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </>
        )}
      </CardContent>
      {openDialog()}
    </Card>
  );
};
export default LeftCard;
