import { Box, Grid } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { useNotification } from 'hooks';
import { PrescriptionProfile } from 'models/prescription-profile.model';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { clearEditPrescriptionProfile, setEditPrescriptionProfile } from 'store/actions/prescriptionProfile.actions';
import AddAnalysisCard from './components/AddAnalysisCard';
import NewProfileCard from './components/NewProfileCard';

const PrescriptionsProfilePage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const { notification } = useNotification();

  const handleProfileResponse = useCallback(
    (profile: PrescriptionProfile) => {
      if (editMode) {
        dispatch(setEditPrescriptionProfile(profile));
      }
    },
    [dispatch, editMode],
  );

  const clearProfileResponse = useCallback(() => {
    if (editMode) {
      dispatch(clearEditPrescriptionProfile());
    }
  }, [dispatch, editMode]);

  useEffect(() => {
    // if there is an id in the current route params,
    // then we're editing a prescription profile
    setEditMode(!!id);
    if (!!id) {
      api.prescriptions
        .getPrescriptionProfileById(id)
        .then((response: PrescriptionProfile) => {
          setLoading(false);
          handleProfileResponse(response);
        })
        .catch(() => notification(t('prescriptions_making.validation.prescription.notification.errorWhenProfileIsNotLoading'), 'error'));
    } else {
      setLoading(false);
    }
  }, [id, handleProfileResponse, notification, t]);

  useEffect(
    () => () => {
      clearProfileResponse();
    },
    [clearProfileResponse],
  );

  return (
    <BodyTemplate title={t('bodyTemplate.doctor.analysis_profiles')}>
      <Grid container columnSpacing={2} rowSpacing={3} sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}>
        {!isLoading && (
          <>
            <Grid item xs={12} md={8}>
              <AddAnalysisCard editMode={editMode} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ position: 'sticky', top: (theme) => theme.spacing(2) }}>
                <NewProfileCard editMode={editMode} />
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </BodyTemplate>
  );
};

export default PrescriptionsProfilePage;
