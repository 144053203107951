import { isValidPhoneNumber, isPossiblePhoneNumber, parsePhoneNumber } from 'libphonenumber-js/mobile';

export const isGSMNumber = (value: string) => {
  if (value !== undefined && isPossiblePhoneNumber(value)) {
    let phoneNumber = parsePhoneNumber(value);
    return isValidPhoneNumber(phoneNumber.number, phoneNumber.country) && phoneNumber.getType() === 'MOBILE';
  }
  return false;
};

export const normalizePhoneNumber = (value: string | undefined) => {
  if (value !== undefined && value !== null) {
    let phoneNumber = parsePhoneNumber(value, 'LU');
    return phoneNumber.formatInternational();
  }
  return value;
};
