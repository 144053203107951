import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import theme_color from 'resources/theme/theme.colors';
import GraphLegend from './GraphLegend';

const MobileChart: FC<{
  taggedCount: number;
  abnormalCount: number;
  urgentCount: number;
  unreadCount: number;
}> = ({ taggedCount, abnormalCount, urgentCount, unreadCount }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={11} sx={{ position: 'relative' }}>
        <Grid
          sx={{
            padding: '30px 0 0 30px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Grid>
            <Typography variant='h1' textAlign='center'>
              {unreadCount}
            </Typography>
            <Typography textAlign='center'>{t('homepage_doctor.results_graph.unread')}</Typography>
          </Grid>
        </Grid>
        <PieChart
          lineWidth={15}
          data={[
            {
              value: taggedCount,
              color: theme_color.palette.graph.error,
            },
            {
              value: abnormalCount,
              color: theme_color.palette.graph.warning,
            },
            {
              value: urgentCount,
              color: theme_color.palette.graph.success,
            },
          ]}
        />
      </Grid>
      <Grid item xs={12} marginY={1}>
        <Grid container spacing={3} textAlign='center'>
          <GraphLegend color={theme_color.palette.graph.success} title={t('homepage_doctor.results_graph.tagged')} />
          <GraphLegend color={theme_color.palette.graph.warning} title={t('homepage_doctor.results_graph.abnormal')} />
          <GraphLegend color={theme_color.palette.graph.error} title={t('homepage_doctor.results_graph.urgent')} />
        </Grid>
      </Grid>
    </>
  );
};
export default MobileChart;
