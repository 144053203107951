import { Box, CircularProgress } from '@mui/material';
import { useRole } from 'hooks';
import { UserRole } from 'models/profile.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Optional } from 'ts-toolbelt/out/Object/Optional';

const RequireRole: FC<Optional<Record<UserRole, JSX.Element | null>>> = (props) => {
  const role = useRole();
  const { i18n } = useTranslation();

  if (!role) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress color='primary' sx={{ marginX: 'auto' }} />
      </Box>
    );
  }

  return props[role] ?? <Navigate replace to={`/${i18n.language.split('-').shift()}/404`} />;
};

export default RequireRole;
