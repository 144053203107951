import config from 'config';
import { APIConfiguration } from '.';
import i18n from 'i18n';

export abstract class BaseService {
  constructor(protected apiConfig: APIConfiguration) {}

  public getLang() {
    return i18n.language.split('-').shift() ?? 'fr';
  }

  public log(message?: any, ...optionalParams: any[]) {
    if (config.environment === 'development') {
      console.log(message, ...optionalParams);
    }
  }
}
