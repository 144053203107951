import { LeftDrawerItem } from 'models/utils.model';
import { ReactComponent as BloodCenterIcon } from 'resources/icons/blood_centers.svg';
import { ReactComponent as CatalogueAnalyseIcon } from 'resources/icons/catalogue_analyse.svg';
import { ReactComponent as AppointmentIcon } from 'resources/icons/pickendoheem.svg';
import { ReactComponent as DoctorIcon } from 'resources/icons/doctor.svg';
import { ReactComponent as EUIcon } from 'resources/icons/EU.svg';
import { ReactComponent as InvoiceIcon } from 'resources/icons/factures.svg';
import { ReactComponent as DashboardIcon } from 'resources/icons/home.svg';
import { ReactComponent as MyDSPIcon } from 'resources/icons/mydsp.svg';
import { ReactComponent as ResultIcon } from 'resources/icons/resultat.svg';
import { ReactComponent as SafeplaceIcon } from 'resources/icons/safeplace.svg';
import { ReactComponent as PreregistrationIcon } from 'resources/icons/preregister.svg';
import { ReactComponent as BionextTVIcon } from 'resources/icons/tv.svg';

export const leftDrawerMenuPatient = (locale: string): LeftDrawerItem[] => [
  {
    title: 'bodyTemplate.dashboard',
    link: 'HOME',
    icon: DashboardIcon,
  },
  {
    title: 'common.results',
    link: 'RESULTS',
    icon: ResultIcon,
  },
  {
    title: 'bodyTemplate.patient.appointments',
    link: 'APPOINTMENT',
    icon: AppointmentIcon,
  },
  {
    title: 'bodyTemplate.patient.certificates',
    link: 'EUDCC_CERTIFICATES',
    icon: EUIcon,
  },
  {
    title: 'bodyTemplate.patient.invoices',
    link: 'INVOICES_OPEN',
    icon: InvoiceIcon,
  },
  {
    title: 'bodyTemplate.patient.safeplace',
    link: 'SAFEPLACE',
    icon: SafeplaceIcon,
    external: true,
    params: { locale: locale },
  },
  {
    title: 'bodyTemplate.patient.preregistration',
    link: 'PREREGISTRATION',
    icon: PreregistrationIcon,
  },
  {
    title: 'bodyTemplate.patient.centers',
    link: 'CENTERS',
    icon: BloodCenterIcon,
  },
  {
    title: 'bodyTemplate.patient.find_doctor',
    link: 'FIND_DOCTOR',
    icon: DoctorIcon,
  },
  {
    title: 'bodyTemplate.patient.bionext_tv',
    link: 'BIONEXT_TV',
    icon: BionextTVIcon,
  },
  {
    title: 'bodyTemplate.patient.myDSP',
    link: 'MY_DSP',
    icon: MyDSPIcon,
  },
  {
    title: 'bodyTemplate.doctor.analyses',
    link: 'ANALYSIS_CATALOG',
    icon: CatalogueAnalyseIcon,
  },
];
