import { Person, RelationInfo, RelationUsualInfo, ShortenPerson } from 'models/profile.model';
import { MedicalStaff } from 'models/record.model';

export const getInitials = (person: Person | ShortenPerson | RelationInfo | RelationUsualInfo) => {
  return `${person.first_name[0] || ''}${person.last_name[0] || ''}`.toUpperCase();
};

export const getFullName = (person: Person | ShortenPerson | RelationInfo | RelationUsualInfo) => {
  return `${person?.first_name} ${person?.last_name?.toUpperCase()}`;
};

export const getDoctorFullName = (person: MedicalStaff) => {
  return `${person.title ?? ''} ${person.first_name ?? ''} ${person.last_name ?? ''}`;
};
