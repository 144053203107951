import { Grid } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { GenericPaginator } from 'components/generic-table';
import FindPatientsForm from 'components/find-patients-form';
import PatientList from 'components/patient-list';
import { useNotification } from 'hooks';
import { Patient, PatientsListRequest, PatientsListResponse } from 'models/patients.model';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_PAGINATION_SIZE } from 'utils/Constants';

const Appointment: FC = () => {
  const formData: PatientsListRequest = {
    first_name: undefined,
    last_name: undefined,
    cns: undefined,
    gender: undefined,
    birth_name: undefined,
    birth_date: undefined,
  };

  const patientListData: Patient[] | undefined = undefined;

  const [form, setForm] = useState(formData);
  const [paginator, setPaginator] = useState({
    total: 0,
    size: DEFAULT_PAGINATION_SIZE,
    page: 1,
  });
  const [patientList, setPatientList] = useState<Patient[] | undefined>(patientListData);
  const { t } = useTranslation();
  const { notification } = useNotification();

  const requestPatientList = useCallback(
    (paginator: GenericPaginator, form: PatientsListRequest) => {
      api.patients
        .getPatients(form, paginator.size, paginator.page)
        .then((response: PatientsListResponse) => {
          setPatientList(response.results);
          setPaginator({
            total: response.total,
            size: response.size,
            page: response.page,
          });
        })
        .catch(() => {
          notification(t('prescriptions.notification.error'), 'error');
        });
    },
    [notification, t],
  );

  const handlePaginatorData = (data: GenericPaginator) => {
    setPaginator(data);
    requestPatientList(data, form);
  };

  const handleFormData = (data: PatientsListRequest) => {
    setForm(data);
    const resetedPaginator = { ...paginator, page: 1 };
    setPaginator(resetedPaginator);
    requestPatientList(resetedPaginator, data);
  };

  return (
    <BodyTemplate title={t('bodyTemplate.doctor.appointments')}>
      <Grid container>
        <Grid item xs={12}>
          <FindPatientsForm handleFormAction={handleFormData} />
        </Grid>
        <Grid item xs={12}>
          <PatientList
            patientDetailAction={{
              to: 'DOCTOR_PATIENTS_DETAILS',
            }}
            showAppointmentAction
            patientList={patientList}
            paginatorData={paginator}
            handlePaginatorAction={handlePaginatorData}
          />
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default Appointment;
