import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, SvgIcon, Typography } from '@mui/material';
import { useNotification } from 'hooks';
import { Preregistration } from 'models/preregistration.model';
import { FC, useRef } from 'react';
import { Control, UseFormRegister, UseFormSetValue, useWatch } from 'react-hook-form';
import { ReactComponent as AddIcon } from 'resources/icons/add.svg';
import { blobToBase64 } from 'utils/func/File.func';
import { MAX_SIZE_FILE } from 'utils/Constants';
import { useTranslation } from 'react-i18next';

const AddFile: FC<{
  index: number;
  control: Control<Preregistration>;
  setValue: UseFormSetValue<Preregistration>;
  register: UseFormRegister<Preregistration>;
  errors: any;
}> = ({ index, control, setValue, errors, register }) => {
  const { notification } = useNotification();
  const { t } = useTranslation();
  const inputFile = useRef<HTMLInputElement>(null);
  const file = useWatch({ control, name: `users.${index}.files` });
  const fileName = useWatch({ control, name: `users.${index}.fileName` });

  const addPrescriptionOnClick = () => {
    inputFile.current?.click();
  };

  const onChangeFile = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const newFile = event.target.files[0];
    if (newFile && (!(newFile.type === 'application/pdf' || newFile.type.startsWith('image/')) || newFile.size > MAX_SIZE_FILE)) {
      notification(t('appointment.book.steps.reasons.medical_acts.notification.errorOnUploading'), 'error');
    } else {
      var reader = new FileReader();
      reader.readAsDataURL(newFile);
      reader.onload = async function () {
        setValue(`users.${index}.files`, [...file, await blobToBase64(newFile)]);
        setValue(`users.${index}.fileName`, [...fileName, newFile.name]);
      };
    }
    event.target.value = null;
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant='h3'>
          {`${t('preregistration.myData.form.submitPrescription')} *`}
          <input type='file' {...register(`users.${index}.files`, { required: true, onChange: onChangeFile })} ref={inputFile} style={{ display: 'none' }} />
          <IconButton sx={{ ml: 2 }} onClick={addPrescriptionOnClick}>
            <SvgIcon component={AddIcon} inheritViewBox></SvgIcon>
          </IconButton>
        </Typography>
        {errors?.users?.[index]?.files && file?.length === 0 && (
          <Typography variant='body2' color='error'>
            {t('preregistration.myData.form.error.invalidFile')}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        {fileName.map((name, idx) => (
          <Grid
            sx={{
              mb: 1,
              border: '2px solid',
              borderRadius: 4,
              p: 2,
            }}
            item
            key={idx}
            xs={12}
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <Typography
              variant='body1'
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {name}
            </Typography>
            <IconButton
              onClick={() => {
                setValue(
                  `users.${index}.files`,
                  file.filter((e, i) => i !== idx),
                );
                setValue(
                  `users.${index}.fileName`,
                  fileName.filter((e, i) => i !== idx),
                );
              }}
            >
              <SvgIcon component={CloseIcon} inheritViewBox fontSize='small'></SvgIcon>
            </IconButton>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default AddFile;
