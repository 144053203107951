import { Card, CardContent, Grid, Typography } from '@mui/material';
import CardHomepageServices from 'components/homepage-service';
import ContextLink from 'components/_base/ContextLink';
import { useIsMobileView, useLocale } from 'hooks';
import { FC } from 'react';
import { ReactComponent as factureRegleIcon } from 'resources/icons/factureRegle.svg';
import { ReactComponent as SafeplaceIcon } from 'resources/icons/safeplace.svg';
import { ReactComponent as PreregisterIcon } from 'resources/icons/preregister.svg';
import { ReactComponent as LabomobileIcon } from 'resources/icons/labomobile.svg';
import { ReactComponent as PickendoheemIcon } from 'resources/icons/pickendoheem.svg';
import Logo from 'resources/images/MYLAB_mono-white.svg';
import RoutePaths from 'utils/RoutePaths';
import { useTranslation } from 'react-i18next';

const RightCards: FC = () => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const locale = useLocale();

  const cardsList: Array<{
    title: string;
    subtitle: string;
    icon: Object;
    link: keyof typeof RoutePaths;
    external?: boolean;
    params?: Object;
  }> = [
    {
      title: t('landing_page.card.administrative_preregister.title'),
      subtitle: t('landing_page.card.administrative_preregister.subtitle'),
      icon: PreregisterIcon,
      link: 'PREREGISTRATION',
    },
    {
      title: t('landing_page.card.anytime_anywhere.title'),
      subtitle: t('landing_page.card.anytime_anywhere.subtitle'),
      icon: PickendoheemIcon,
      link: 'BOOK_APPOINTMENT',
    },
    {
      title: t('landing_page.card.labomobile.title'),
      subtitle: t('landing_page.card.labomobile.subtitle'),
      icon: LabomobileIcon,
      link: 'LABOMOBILE',
      external: true,
    },
    {
      title: t('landing_page.card.safeplace.title'),
      subtitle: t('landing_page.card.safeplace.subtitle'),
      icon: SafeplaceIcon,
      link: 'SAFEPLACE',
      external: true,
      params: { locale: locale },
    },
    {
      title: t('landing_page.card.facture.title'),
      subtitle: t('landing_page.card.facture.subtitle'),
      icon: factureRegleIcon,
      link: 'INVOICES',
    },
  ];

  return (
    <Card
      sx={{
        height: '100%',
        boxSizing: 'border-box',
        background: '#DDDDDD',
        backgroundImage: `url(${Logo}) `,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top 200px right -1250px',
        backgroundSize: 'cover',
        boxShadow: 'none',
      }}
    >
      <CardContent sx={{ textAlign: 'center', alignItems: 'center' }}>
        <Grid item>
          <Typography variant='h1'>{t('landing_page.login.title2')}</Typography>
        </Grid>
      </CardContent>
      <CardContent sx={{ margin: '2%' }}>
        {cardsList.map((card) => (
          <Grid
            container
            key={card.title}
            spacing={1}
            sx={{
              alignItems: 'center',
              width: '100%',
              justifyContent: 'center',
              height: '100%',
              margin: isMobileView ? '2%' : '0.1',
            }}
          >
            <Grid
              item
              component={ContextLink}
              to={card.link}
              external={card.external}
              params={card.params}
              sx={{
                backgroundColor: 'white',
                margin: '2%',
                width: '100%',
                height: isMobileView ? '100%' : '90px',
                '&:hover': { opacity: 0.6 },
              }}
            >
              <CardHomepageServices logo={card.icon} title={card.title} description={card.subtitle} isLandingPage />
            </Grid>
          </Grid>
        ))}
      </CardContent>
    </Card>
  );
};

export default RightCards;
