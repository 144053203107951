import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import api from 'api';
import AnalysisList from 'components/analysis-list';
import { useNotification } from 'hooks';
import { PrescriptionProfileListResponse } from 'models/prescription.model';
import { PrescriptionProfile } from 'models/prescription-profile.model';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store/reducers';
import { useTranslation } from 'react-i18next';

type Props = {
  handleSelectElement: (items: any) => void;
  disabled: boolean;
};

export const ProfileSelection: FC<Props> = ({ handleSelectElement, disabled }) => {
  const { t } = useTranslation();
  const currentUser = useSelector((state: GlobalState) => state.session.authenticateUser);
  const [prescriptionProfiles, setPrescriptionProfiles] = useState<PrescriptionProfileListResponse>([]);
  const [profile, setProfile] = useState<PrescriptionProfile>();
  const { notification } = useNotification();

  useEffect(() => {
    api.prescriptions
      .getEditablePrescriptionsProfilesByDoctorId({
        ms_id: currentUser!.id,
      })
      .then((response: PrescriptionProfileListResponse) => {
        setPrescriptionProfiles(response);
      })
      .catch(() => notification(t('prescriptions.profiles.notification.error'), 'error'));
  }, [currentUser, notification, t]);

  const onProfileChange = (id: string) => {
    api.prescriptions.getPrescriptionProfileById(id).then((response) => {
      setProfile(response);
    });
  };

  const handleAllAnalysisFromProfile = () => {
    handleSelectElement(
      profile?.items?.map((item) => ({
        name: item.name,
        questions: item.questions,
        refund_cns: item.refund_cns === false ? item.price : null,
        currency: item.currency,
        motivation: item.motivation,
        analysis_reference: item.reference,
        title: profile.name,
        description: profile.description,
      })),
    );
  };

  return (
    <Box py={2}>
      <Typography variant='h3' mb={3}>
        {t('prescriptions_making.step1.profile_selection.title')}
      </Typography>
      <Box display='flex' gap={2}>
        <TextField
          disabled={disabled}
          select
          variant='filled'
          fullWidth
          label={`${t('prescriptions.profiles.field.profile_name')}`}
          defaultValue={''}
          onChange={(e) => onProfileChange(e.target.value)}
        >
          {prescriptionProfiles.map(({ id, name }, key) => {
            return (
              <MenuItem value={id} key={key}>
                {name}
              </MenuItem>
            );
          })}
        </TextField>
        {(profile?.items?.length !== undefined || profile?.items !== undefined) && (
          <Button disabled={disabled} variant='contained' onClick={handleAllAnalysisFromProfile}>
            {t('prescriptions_making.step1.profile_selection.addBtn')}
          </Button>
        )}
      </Box>

      <AnalysisList disabled={disabled} analysisList={profile?.items} onSelect={handleSelectElement} />
    </Box>
  );
};

export default ProfileSelection;
