import { Button, Card, CardContent, CardHeader, Grid, IconButton, SvgIcon, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import api from 'api';
import ContextLink from 'components/_base/ContextLink';
import { useAuthenticatedUser, useContextRedirection, useIsAdmin, useIsDoctor, useIsLaboratory, useIsMobileView, useNotification } from 'hooks';
import { Dossier } from 'models/dossier.model';
import { Selection } from 'models/record.model';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconStar } from 'resources/icons/favorite.svg';
import { ReactComponent as IconStarOutline } from 'resources/icons/favorite_outline.svg';
import { convertDate } from 'utils/func/Date.func';
import { getDoctorFullName } from 'utils/func/Person.func';
import { PrescriptionRequestMaker } from 'utils/func/Prescription.func';
import DetailMultiSelectRecord from './DetailMultiSelectRecord';
import DetailTabsTable from './DetailTabsTable';

export const DetailPatientRecordTable: FC<Dossier> = ({ reference, sampling_time, report, platforms, validator, prescription, issuer, patient, tagged, eudcc, highlight_abnormal_patient }) => {
  const isMobileView = useIsMobileView();
  const { t } = useTranslation();
  const [selection, setSelection] = useState<Selection[]>([]);
  const [localTag, setLocalTag] = useState(tagged);
  const { notification } = useNotification();
  const isDoctor = useIsDoctor();
  const isLaboratory = useIsLaboratory();
  const isAdmin = useIsAdmin();
  const authenticatedUser = useAuthenticatedUser();
  const navigate = useContextRedirection();

  const handleSelection = (ref: Selection) => {
    const index = selection.findIndex((elm: Selection) => elm.ref === ref.ref);
    let newArray = selection;
    if (index === -1) {
      newArray.push(ref);
      setSelection([...newArray]);
    } else {
      newArray.splice(index, 1);
      setSelection([...newArray]);
    }
  };

  const setTagged = useCallback(() => {
    api.results
      .markAResult(typeof reference === 'string' ? reference : parseFloat(reference))
      .then((res) => {
        setLocalTag(res.tagged);
        notification(res.tagged ? t('result.doctor.details.notification.markSuccess') : t('result.doctor.details.notification.unmarkSuccess'), 'success');
      })
      .catch(() => notification(t('result.doctor.details.notification.markError'), 'error'));
  }, [notification, reference, t]);

  return (
    <Card>
      <CardHeader
        title={
          <Box display='flex' justifyContent='space-between'>
            <Box>
              <Typography variant='h1' gutterBottom>
                {t('results.details.title')} : {reference}
              </Typography>
              <Box display='flex' flexDirection={isMobileView ? 'column' : 'row'} alignItems={isMobileView ? 'flex-start' : 'center'}>
                <Typography>{`${t('results.details.extractionDate')} ${convertDate(sampling_time, true)}`}</Typography>
                <Typography ml={isMobileView ? 0 : 5}>{`${t('results.details.extractionFrom')} ${getDoctorFullName(issuer?.medical_staff)}`}</Typography>
                <Typography ml={isMobileView ? 0 : 5}>
                  {isDoctor || isLaboratory || isAdmin
                    ? `${t('result_doctor.details.validationFrom')} ${validator}`
                    : `${t('results.details.extractionTo')} ${patient?.last_name} ${patient?.first_name}`}
                </Typography>
                {prescription !== null && (
                  <ContextLink to='DETAIL_PDF_PRESCRIPTION' params={{ dossierId: reference, filename: prescription }}>
                    <Button variant='header_light' sx={{ ml: isMobileView ? 0 : 5 }}>
                      {t('result.doctor.details.seeOrder')}
                    </Button>
                  </ContextLink>
                )}
                {eudcc !== null && (
                  <ContextLink to='DETAIL_PDF_EUDCC' params={{ dossierId: reference, filename: eudcc }}>
                    <Button variant='header_light' sx={{ ml: isMobileView ? 0 : 5 }}>
                      {t('result.doctor.details.seeEudcc')}
                    </Button>
                  </ContextLink>
                )}
              </Box>
            </Box>
            {(isDoctor || isLaboratory || isAdmin) && (
              <Tooltip title={localTag ? t('common.table.tableActions.markedMark') : t('common.table.tableActions.mark')}>
                <IconButton
                  onClick={() => setTagged()}
                  sx={{
                    gridColumn: '1',
                  }}
                >
                  <SvgIcon
                    sx={{
                      height: (theme) => theme.spacing(4),
                      width: (theme) => theme.spacing(4),
                      color: (theme) => theme.palette.gold,
                    }}
                    component={localTag ? IconStar : IconStarOutline}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        }
      />
      <CardContent>
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            mx='auto'
            my={2}
            display='flex'
            flexDirection={isMobileView ? 'column' : 'row'}
            justifyContent={isDoctor || isLaboratory || isAdmin ? 'space-between' : 'end'}
            alignItems='center'
          >
            {(isDoctor || isLaboratory || isAdmin) && (
              <Box display='flex' flexDirection={isMobileView ? 'column' : 'row'} alignItems='center' width={isMobileView ? '100%' : 'auto'} pb={isMobileView ? 2 : 0}>
                <DetailMultiSelectRecord selection={selection} setSelection={setSelection} />
                <Button variant='outlined' fullWidth={isMobileView} disabled={selection.length === 0}>
                  <ContextLink
                    to='DETAIL_RESULT_FORM'
                    params={{
                      dossierId: reference,
                      patientId: patient?.id,
                    }}
                    state={{
                      selection: selection,
                      patient: patient,
                    }}
                  >
                    {t('result_doctor.details.buttons.see_history')}
                  </ContextLink>
                </Button>
              </Box>
            )}
            <Box display='flex' flexDirection={isMobileView ? 'column' : 'row'} rowGap={1} width={isMobileView ? '100%' : 'auto'}>
              {isDoctor && (
                <Button
                  variant='contained'
                  fullWidth={isMobileView}
                  sx={{
                    marginLeft: (theme) => theme.spacing(1),
                  }}
                  onClick={() => {
                    authenticatedUser && PrescriptionRequestMaker(patient.id, navigate);
                  }}
                >
                  {t('common.action.makePrescription')}
                </Button>
              )}
              {(isDoctor || isLaboratory || isAdmin) && (
                <ContextLink to='DOCTOR_PATIENTS_HISTORY_REFERENCE' params={{ id: `${patient!.id}`, reference }}>
                  <Button
                    variant='outlined'
                    fullWidth={isMobileView}
                    sx={{
                      marginLeft: (theme) => theme.spacing(1),
                    }}
                  >
                    {t('bodyTemplate.patient.crosstab')}
                  </Button>
                </ContextLink>
              )}

              {report !== null ? (
                <ContextLink to='DETAIL_RESULT_PDF' params={{ dossierId: reference, type: 'report', filename: report }}>
                  <Button
                    variant='outlined'
                    fullWidth={isMobileView}
                    sx={{
                      marginLeft: (theme) => theme.spacing(1),
                    }}
                  >
                    {t('result_doctor.details.buttons.see_results_as_pdf')}
                  </Button>
                </ContextLink>
              ) : (
                <Button
                  variant='outlined'
                  fullWidth={isMobileView}
                  sx={{
                    marginLeft: (theme) => theme.spacing(1),
                  }}
                  disabled
                >
                  {t('result_doctor.details.buttons.see_results_as_pdf')}
                </Button>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} marginX='auto' my={5}>
            <DetailTabsTable data={platforms} isHighlight={highlight_abnormal_patient} selection={selection} setSelection={handleSelection} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
