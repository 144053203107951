import { Autocomplete, Card, CardContent, CardHeader, Checkbox, FormControlLabel, Grid, MenuItem, Stack, TextField, Typography, debounce } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import PatientDetails from 'components/patient-details';
import dayjs from 'dayjs';
import { useContextRedirection, useIsMobileView, useNotification } from 'hooks';
import { Patient } from 'models/patients.model';
import { Prescription, PrescriptionCopyToMedicalStaff } from 'models/prescription.model';
import { FC, useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { tryCatch } from 'utils';
import RoutePaths from 'utils/RoutePaths';
import RecapCompletionSelect from './RecapCompletionSelect';

const RecapCompletion: FC = () => {
  const { t } = useTranslation();
  const [patient, setPatient] = useState<Patient>();
  const { idPrescription } = useParams();
  const [selectedPrescription, setSelectedPrescription] = useState<Prescription>();
  const navigate = useContextRedirection();
  const [displayFaxField, setDisplayFaxField] = useState(false);
  const [displayPhoneField, setDisplayPhoneField] = useState(false);
  const [displayHomeCollectionField, setDisplayHomeCollectionField] = useState(false);
  const [displayOtherHomeMotivation, setDisplayOtherHomeMotivation] = useState(false);
  const [displayAccident, setDisplayAccident] = useState(false);
  const [motivations, setMotivations] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [copyMS, setCopyMS] = useState<any>([]);
  const [valueCopyMS, setValueCopyMS] = useState<any>([]);
  const [inputValueCopyMS, setInputValueCopyMS] = useState<string>('');
  const { notification } = useNotification();
  const isMobileView = useIsMobileView();

  const actualDate = new Date();
  const twoMonthsAfterDate = new Date();
  twoMonthsAfterDate.setMonth(actualDate.getMonth() + 2);

  const { register, control, handleSubmit, setValue } = useForm<FormProps>({
    defaultValues: { prescription_date: actualDate, validity_date: twoMonthsAfterDate, accident_date: null },
  });

  const loadBasketAnalysis = useCallback(() => {
    idPrescription &&
      tryCatch(
        () =>
          api.prescriptions
            .getPrescriptionByBasketId(idPrescription)
            .then((response) => {
              setSelectedPrescription(response);

              //CC
              const cc = [];
              if (response.copy_to_other_medical_staff) {
                cc.push(...response.copy_to_other_medical_staff.split(', '));
              }
              response.copy_to_medical_staff.forEach((element: PrescriptionCopyToMedicalStaff) => {
                cc.push({ id: element.id, label: `${element.title} ${element.first_name} ${element.last_name}` });
              });
              setValueCopyMS(cc);

              //Comment
              setValue('comment', response.comment);

              api.patients
                .getPatientDetails(response.patient_id)
                .then((patientDetails) => {
                  setPatient(patientDetails);
                })
                .catch(() => notification(t('prescriptions_making.step2.yourSelection.notification.errorWhenLoadingPatient'), 'error'));
            })
            .catch(() => {
              notification(t('prescriptions_making.step2.yourSelection.notification.errorWhenLoadingPrescription'), 'error');
            }),
        (e) => {
          navigate(RoutePaths['NOT_FOUND']);
        },
      );
  }, [idPrescription, navigate, notification, t, setValue]);

  useEffect(() => {
    loadBasketAnalysis();

    api.prescriptions
      .getPrescriptionMotivations()
      .then((response) => {
        const m = response;
        m.push({ id: 0, name: t('prescriptions_making.step2.verifyValidatePrescription.form.details.otherMotivation') });
        setMotivations(response);
      })
      .catch(() => notification(t('prescriptions_making.step2.notification.errorWhenLoadingMotivation'), 'error'));
  }, [loadBasketAnalysis, notification, t]);

  useEffect(() => {
    if (selectedPrescription?.items.length === 0) {
      navigate(RoutePaths['NOT_FOUND']);
    }

    if (selectedPrescription?.status === 'validated') {
      navigate(
        RoutePaths['DOCTOR_PRESCRIPTION_DETAILS_VALIDATION']({
          id: idPrescription,
        }),
      );
    }
  });

  type FormProps = {
    need_home_appointment: boolean;
    home_sampling_motivation: string;
    home_sampling_motivation_other: string;
    repetition: number;
    accident_number: string;
    prescription_date: Date;
    validity_date: Date;
    accident_date: Date | null;
    copy_to_other_medical_staff: string;
    urgent: boolean;
    to_be_faxed: boolean;
    to_be_phoned: boolean;
    fax: string;
    phone: string;
    comment: string;
  };

  const onSubmit: SubmitHandler<FormProps> = useCallback(
    (data) => {
      if (idPrescription) {
        setIsSaving(true);
        api.prescriptions
          .updatePrescriptionBasketData(idPrescription, {
            prescription_date: dayjs(data.prescription_date).format('YYYY-MM-DD'),
            validity_date: dayjs(data.validity_date).format('YYYY-MM-DD'),
            comment: data.comment,
            need_home_appointment: data.need_home_appointment,
            home_sampling_motivation: !data.home_sampling_motivation_other ? data.home_sampling_motivation?.split('-')[0] : null,
            home_sampling_motivation_other: data.home_sampling_motivation_other,
            repetition: data.repetition,
            urgent: data.urgent,
            to_be_faxed: data.to_be_faxed,
            fax: data.fax,
            to_be_phoned: data.to_be_phoned,
            phone: data.phone,
            accident_number: data.accident_number,
            accident_date: data.accident_date ? dayjs(data.accident_date).format('YYYY-MM-DD') : null,
            copy_to_other_medical_staff: data.copy_to_other_medical_staff,
          })
          .then(() => {
            api.prescriptions
              .postValidBasketPrescription(idPrescription)
              .then(() => {
                setIsSaving(false);
                navigate(
                  RoutePaths['DOCTOR_PRESCRIPTION_DETAILS_VALIDATION']({
                    id: idPrescription,
                  }),
                );
              })
              .catch(() => {
                notification(t('prescriptions_making.step2.yourSelection.notification.errorWhenValidateSelection'), 'error');
                setIsSaving(false);
              });
          })
          .catch(() => {
            notification(t('prescriptions_making.step2.notification.errorWhenUpdatingBasket'), 'error');
            setIsSaving(false);
          });
      }
    },
    [idPrescription, navigate, notification, t],
  );

  // eslint-disable-next-line
  const getOptionsDelayed = useCallback(
    debounce((query: string, callback: (res: any) => void) => {
      setCopyMS([]);
      if (query?.length > 2) {
        api.prescriptions.searchMedicalStaff(query).then(callback);
      }
    }, 400),
    [],
  );

  useEffect(() => {
    getOptionsDelayed(inputValueCopyMS, (res) => {
      setCopyMS(res.data?.results);
    });
  }, [inputValueCopyMS, getOptionsDelayed]);

  const addCC = useCallback(
    (cc: { id: number; title: string; first_name: string; last_name: string }) => {
      if (idPrescription) {
        api.prescriptions.postPrescriptionCC(idPrescription, cc.id).then((res) => setSelectedPrescription(res.data));
      }
    },
    [idPrescription],
  );

  const removeCC = useCallback(
    (cc: { id: number; title: string; first_name: string; last_name: string }) => {
      if (idPrescription) {
        api.prescriptions.deletePrescriptionCC(idPrescription, cc.id).then((res) => setSelectedPrescription(res.data));
      }
    },
    [idPrescription],
  );

  useEffect(() => {
    const unknownMS: string[] = [];
    valueCopyMS.forEach((element: any) => {
      if (typeof element === 'string') {
        unknownMS.push(element);
      }
    });
    setValue('copy_to_other_medical_staff', unknownMS.join(', '));
  }, [valueCopyMS, setValue, selectedPrescription]);

  return (
    <BodyTemplate title={t('bodyTemplate.doctor.makePrescription')}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <PatientDetails patient={patient} showPatientRecordAction />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={12} md={8}>
          <Card>
            <CardHeader
              title={
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Typography variant='h2' pb={2}>
                    {t('prescriptions_making.step2.verifyValidatePrescription.form.details.subtitle')}
                  </Typography>
                  <Controller
                    control={control}
                    name='urgent'
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        control={<Checkbox sx={{ mr: 1 }} checked={value} onChange={onChange} />}
                        label={
                          <Typography fontWeight='bold' fontSize={20}>
                            {t('prescriptions_making.step2.verifyValidatePrescription.form.askPriority.urgentTask')}
                          </Typography>
                        }
                      />
                    )}
                  />
                </Stack>
              }
            />
            <CardContent>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Controller
                    name='prescription_date'
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        label={t('prescriptions_making.step2.verifyValidatePrescription.form.details.prescriptionDate')}
                        {...(isMobileView && {
                          openTo: 'day',
                          views: ['year', 'month', 'day'],
                        })}
                        slotProps={{ textField: { variant: 'filled', fullWidth: true, required: true } }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name='validity_date'
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        label={t('prescriptions_making.step2.verifyValidatePrescription.form.details.validityDate')}
                        {...(isMobileView && {
                          openTo: 'day',
                          views: ['year', 'month', 'day'],
                        })}
                        {...field}
                        slotProps={{ textField: { variant: 'filled', fullWidth: true } }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    multiple
                    fullWidth
                    freeSolo
                    options={copyMS}
                    filterOptions={(x) => x}
                    value={valueCopyMS}
                    getOptionLabel={(option: string | any) => {
                      return typeof option === 'string' ? option : option.label ?? `${option.title} ${option.first_name} ${option.last_name}`;
                    }}
                    onChange={(event: any, newValue: any | null, reason, detail) => {
                      if (detail) {
                        if (typeof detail.option !== 'string') {
                          switch (reason) {
                            case 'removeOption':
                              removeCC(detail.option);
                              break;
                            case 'selectOption':
                              addCC(detail.option);
                              break;
                            default:
                              break;
                          }
                        }
                      }

                      setValueCopyMS(newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                      setInputValueCopyMS(newInputValue);
                    }}
                    renderInput={(params) => <TextField {...params} variant='filled' label={t('prescriptions_making.step2.verifyValidatePrescription.form.copy.doctorCopyReport')} />}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField variant='filled' fullWidth label={t('prescriptions_making.step2.verifyValidatePrescription.form.details.repeatOrder')} type='number' {...register('repetition')} />
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Controller
                    control={control}
                    name='to_be_faxed'
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        sx={{ ml: 0, width: '100%' }}
                        control={
                          <Checkbox
                            sx={{ mr: 1 }}
                            checked={value}
                            onChange={(e) => {
                              setDisplayFaxField(!displayFaxField);
                              onChange(e);
                            }}
                          />
                        }
                        label={t('prescriptions_making.step2.verifyValidatePrescription.form.askPriority.toFax')}
                      />
                    )}
                  />
                  {displayFaxField && <TextField variant='filled' fullWidth label={t('prescriptions_making.step2.verifyValidatePrescription.form.askPriority.fax')} {...register('fax')} />}
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Controller
                    control={control}
                    name='to_be_phoned'
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        sx={{ ml: 0, width: '100%' }}
                        control={
                          <Checkbox
                            sx={{ mr: 1 }}
                            checked={value}
                            onChange={(e) => {
                              setDisplayPhoneField(!displayPhoneField);
                              onChange(e);
                            }}
                          />
                        }
                        label={t('prescriptions_making.step2.verifyValidatePrescription.form.askPriority.toPhone')}
                      />
                    )}
                  />
                  {displayPhoneField && <TextField variant='filled' fullWidth label={t('prescriptions_making.step2.verifyValidatePrescription.form.askPriority.phone')} {...register('phone')} />}
                </Grid>
                {selectedPrescription && (
                  <>
                    <Grid item xs={12} md={selectedPrescription.home_forbidden ? 12 : 6} sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Controller
                        control={control}
                        name='need_home_appointment'
                        defaultValue={false}
                        render={({ field: { onChange, value } }) => (
                          <FormControlLabel
                            sx={{ ml: 0, width: '100%' }}
                            control={
                              <Checkbox
                                sx={{ mr: 1 }}
                                checked={value}
                                onChange={(e) => {
                                  setDisplayHomeCollectionField(!displayHomeCollectionField);
                                  onChange(e);
                                }}
                              />
                            }
                            label={t('prescriptions_making.step2.verifyValidatePrescription.form.details.homePatient')}
                          />
                        )}
                      />
                      {selectedPrescription.home_forbidden && (
                        <Typography fontStyle='italic' variant='caption' sx={{ width: '100%' }}>
                          {t('prescriptions_making.step2.verifyValidatePrescription.form.details.homePatientForbidden')}
                        </Typography>
                      )}
                      {displayHomeCollectionField && (
                        <TextField
                          select
                          variant='filled'
                          label={t('prescriptions_making.step2.verifyValidatePrescription.form.details.motivation')}
                          fullWidth
                          {...register('home_sampling_motivation')}
                          onChange={(event) => {
                            const v = event.target.value.split('-')[0];
                            setDisplayOtherHomeMotivation(v === '0');
                          }}
                        >
                          {motivations.map((motivation: { id: number; name: string }) => {
                            return (
                              <MenuItem value={`${motivation.id}-${motivation.name}`} key={motivation.id}>
                                {motivation.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      )}
                    </Grid>
                    {displayHomeCollectionField && (selectedPrescription.home_sampling_motivation_other || selectedPrescription.home_sampling_motivation?.id === 999 || displayOtherHomeMotivation) ? (
                      <Grid item xs={12} md={6}>
                        <TextField
                          variant='filled'
                          fullWidth
                          autoFocus
                          label={t('prescriptions_making.step2.verifyValidatePrescription.form.details.otherMotivation')}
                          {...register('home_sampling_motivation_other')}
                        />
                      </Grid>
                    ) : (
                      !isMobileView && !selectedPrescription.home_forbidden && <Grid item xs={12} md={6} />
                    )}
                  </>
                )}
                <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'row' }}>
                  <FormControlLabel
                    defaultValue={selectedPrescription?.accident_number}
                    name='accident'
                    control={<Checkbox sx={{ mr: 1 }} />}
                    label={t('prescriptions_making.step2.verifyValidatePrescription.form.details.accident')}
                    sx={{ ml: 0, width: '100%' }}
                    onChange={() => setDisplayAccident(!displayAccident)}
                  />
                  {displayAccident && (
                    <TextField variant='filled' fullWidth label={t('prescriptions_making.step2.verifyValidatePrescription.form.details.accidentNumber')} {...register('accident_number')} />
                  )}
                </Grid>
                {displayAccident && (
                  <Grid item xs={12} md={6}>
                    <Controller
                      name='accident_date'
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          label={t('prescriptions_making.step2.verifyValidatePrescription.form.details.accidentDate')}
                          {...(isMobileView && {
                            openTo: 'day',
                            views: ['year', 'month', 'day'],
                          })}
                          {...field}
                          slotProps={{ textField: { variant: 'filled', fullWidth: true } }}
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField multiline label={t('prescriptions_making.step2.verifyValidatePrescription.form.comments.field')} minRows={2} fullWidth variant='filled' {...register('comment')} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          {selectedPrescription && idPrescription && (
            <RecapCompletionSelect selectedPrescription={selectedPrescription} idPrescription={+idPrescription} isSaving={isSaving} onSubmit={handleSubmit(onSubmit)} />
          )}
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default RecapCompletion;
