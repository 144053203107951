import { TextField } from '@mui/material';
import { GenericFormProps } from 'models/result.patient.model';
import { FC } from 'react';
import { GenericListForm } from './GenericListForm';
import { GenericDateForm } from './GenericDateForm';
import { GenericBooleanForm } from './GenericBooleanForm';
import { GenericMultipleForm } from './GenericMultipleForm';
import { GenericMatrixForm } from './GenericMatrixForm';

export const GenericForm: FC<GenericFormProps> = ({ question }) => {
  switch (question.type) {
    case 'integer':
    case 'text':
      return (
        <TextField
          label={question.name}
          value={question.answers[0] ?? undefined}
          variant='standard'
          fullWidth
          disabled
          InputLabelProps={{
            sx: { p: 0 },
          }}
        />
      );
    case 'list':
      return <GenericListForm question={question} />;
    case 'matrix':
      return <GenericMatrixForm question={question} />;
    case 'date':
      return <GenericDateForm question={question} />;
    case 'multiple':
      return <GenericMultipleForm question={question} />;
    case 'boolean':
      return <GenericBooleanForm question={question} />;
    default:
      return null;
  }
};

export default GenericForm;
