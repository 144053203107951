import { Grid, SvgIcon, Typography, Avatar } from '@mui/material';
import { FC } from 'react';
import { useIsMobileView } from 'hooks';

interface CardHomepageServicesProps {
  logo: any;
  title: string;
  description?: string;
  descriptionOnMobile?: boolean;
  isLargeScreen?: boolean;
  isLandingPage?: boolean;
  bigFont?: boolean;
}

const CardHomepageServices: FC<CardHomepageServicesProps> = ({ logo, title, description, isLargeScreen = false, isLandingPage = false, bigFont = false }) => {
  const isMobileView = useIsMobileView();

  return (
    <Grid
      container
      spacing={0}
      flexWrap='nowrap'
      alignItems='left'
      p={(theme) => theme.spacing(4)}
      flexDirection={!isLargeScreen ? { xs: 'column', md: 'initial' } : {}}
      sx={{
        flexFlow: { xs: 'column', md: 'initial' },
        width: '100%',
        height: '100%',
      }}
    >
      <Grid
        item
        sx={
          isLandingPage
            ? {
                marginRight: { xs: '0', md: '20px' },
                display: 'flex',
                alignSelf: 'center',
              }
            : {
                marginRight: { xs: '0', md: '30px' },
                display: 'flex',
                alignSelf: 'center',
              }
        }
      >
        {isLandingPage ? (
          <Avatar sizes={'50%'} sx={{ backgroundColor: '#B6E5F9', width: '67px', height: '66px' }}>
            <SvgIcon htmlColor={'black'} component={logo} sx={{ fontSize: 40 }} />
          </Avatar>
        ) : (
          <SvgIcon component={logo} sx={{ fontSize: 60 }} />
        )}
      </Grid>
      <Grid
        item
        textAlign={!isLargeScreen ? (!isLandingPage ? 'center' : 'initial') : 'initial'}
        my={{ xs: '8px', md: '0' }}
        sx={
          isLandingPage
            ? {
                alignSelf: 'center',
                width: '120%',
              }
            : {
                alignSelf: 'center',
              }
        }
      >
        <Typography
          variant='body2'
          sx={{
            textAlign: { xs: 'center', md: 'initial' },
            fontWeight: 'bold',
            fontSize: bigFont ? 22 : 12,
          }}
        >
          {title}
        </Typography>
        <Typography
          variant='body2'
          display={isLargeScreen ? { xs: 'none', md: 'initial' } : ''}
          sx={{
            whiteSpace: 'pre-wrap',
            textAlign: isMobileView ? { xs: 'center', md: 'initial' } : '',
            fontSize: bigFont ? 15 : 12,
          }}
        >
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CardHomepageServices;
