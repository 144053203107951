import EmailIcon from '@mui/icons-material/EmailOutlined';
import LocationIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import { Box, Button, Grid, Stack, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import config from 'config';
import { format, setDay } from 'date-fns';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-GB';
import frLocale from 'date-fns/locale/fr';
import ptLocale from 'date-fns/locale/pt';
import { useIsMobileView } from 'hooks';
import { Center, CenterIncludedFile, CenterIncludedService } from 'models/centers.model';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { waitFormatHours } from 'utils/func/Date.func';

type Props = {
  centerInfo: {
    center: Center;
    file: CenterIncludedFile;
    services: CenterIncludedService[];
  };
  hideHeader?: boolean;
};

const CenterDetails = ({ centerInfo, hideHeader }: Props) => {
  const isMobileView = useIsMobileView();
  const { t, i18n } = useTranslation();
  const locale: string = useMemo((): string => {
    const lang = i18n.language.split('-').shift();
    return lang ? lang : 'en';
  }, [i18n.language]);
  const localeMap = useCallback((lang: string) => {
    switch (lang) {
      case 'en':
        return enLocale;
      case 'de':
        return deLocale;
      case 'pt':
        return ptLocale;
      default:
        return frLocale;
    }
  }, []);

  const getAddress = (center: Center) => {
    const nb = center.attributes.field_prelevement_numero_rue;
    const rue = center.attributes.field_prelevement_rue;
    const code = center.attributes.field_prelevement_code_postal;
    const ville = center.attributes.field_prelevement_ville;
    return `${nb}, ${rue} ${code} ${ville}`;
  };

  const getStatus = (center: Center) => {
    const now = new Date();
    for (let schedule of center.attributes.field_prelevement_horaires) {
      if (now.getDay() === schedule.day) {
        const startHour = waitFormatHours(schedule.starthours).split(':');
        const stopHour = waitFormatHours(schedule.endhours).split(':');
        const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), parseInt(startHour[0]), parseInt(startHour[1]), 0);
        const stop = new Date(now.getFullYear(), now.getMonth(), now.getDate(), parseInt(stopHour[0]), parseInt(stopHour[1]), 0);
        if (now > start && now < stop) {
          return 'open';
        }
      }
    }
    return 'close';
  };

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12}>
        {!hideHeader && (
          <Grid
            item
            sx={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {centerInfo.file && (
              <Box
                component={'img'}
                src={`${config.websiteBionextApiUrl}${centerInfo.file.attributes.uri.url.replace('files/', 'files/styles/medium/public/')}`}
                alt={centerInfo.center.relationships.field_prelevement_photo.data.meta.alt}
                sx={{
                  width: 'auto',
                  height: '100%',
                  maxHeight: '136px',
                  borderRadius: 2,
                }}
              />
            )}
          </Grid>
        )}
        <Grid container spacing={0} item xs={!hideHeader ?? 12}>
          {!hideHeader && (
            <>
              <Grid item xs={12}>
                <Typography variant='h2'>{centerInfo.center.attributes.title}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1'>{getAddress(centerInfo.center)}</Typography>
              </Grid>
            </>
          )}
          {hideHeader && (
            <Grid item xs={12} sx={{ ml: 0.5 }}>
              <Button
                variant='link'
                startIcon={<LocationIcon />}
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${centerInfo.center.attributes.title} ${getAddress(centerInfo.center)}`)}`}
              >
                {t('centers.details.location.button')}
              </Button>
            </Grid>
          )}
          <Grid item xs={12} sx={{ ml: 0.5 }}>
            <Button variant='link' startIcon={<PhoneIcon />} href={`tel:${centerInfo.center.attributes.field_prelevement_telephone}`}>
              {centerInfo.center.attributes.field_prelevement_telephone}
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ ml: 0.5 }}>
            <Button variant='link' startIcon={<EmailIcon />} href={`mailto:${centerInfo.center.attributes.field_prelevement_email}`}>
              {centerInfo.center.attributes.field_prelevement_email}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item xs={isMobileView ? 12 : 3}>
          <Stack direction='row' spacing={2}>
            <Typography variant='h3' sx={{ pb: 1 }}>
              {t('centers.details.schedule.title')}
            </Typography>
            <Box sx={{ backgroundColor: getStatus(centerInfo.center) === 'open' ? 'green' : 'red', borderRadius: 4, px: 1, height: 20, fontSize: 12, fontWeight: 900, color: 'white' }}>
              {t(`centers.details.schedule.${getStatus(centerInfo.center)}`)}
            </Box>
          </Stack>

          <Table size='small' padding='none'>
            <TableBody>
              {centerInfo.center.attributes.field_prelevement_horaires.map((h, index) => (
                <TableRow key={index} sx={{ border: 'none !important' }}>
                  <TableCell>{format(setDay(new Date(), h.day), 'EEEE', { locale: localeMap(locale) })}</TableCell>
                  <TableCell>{`${waitFormatHours(h.starthours)} - ${waitFormatHours(h.endhours)}`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={isMobileView ? 12 : 3}>
          <Typography variant='h3' sx={{ pb: 1 }}>
            {t('centers.details.specialities.title')}
          </Typography>
          {centerInfo.services.map((service) => (
            <Typography key={service.id}>{service.attributes.name}</Typography>
          ))}
        </Grid>
        {(centerInfo.center.attributes.field_prelevement_handicape || centerInfo.center.attributes.field_prelevement_parking) && (
          <Grid item xs={isMobileView ? 12 : 3}>
            <Typography variant='h3'>{t('centers.details.services.title')}</Typography>
            {centerInfo.center.attributes.field_prelevement_handicape && <Typography>{t('centers.details.services.handicape')}</Typography>}
            {centerInfo.center.attributes.field_prelevement_parking && <Typography>{t('centers.details.services.parking')}</Typography>}
          </Grid>
        )}
        {centerInfo.center.attributes.field_prelevement_info_important !== null && (
          <Grid item xs={isMobileView ? 12 : 3}>
            <Typography variant='h3' sx={{ pb: 1 }}>
              {t('centers.details.informations.title')}
            </Typography>
            <Typography sx={{ whiteSpace: 'pre-line' }}>{centerInfo.center.attributes.field_prelevement_info_important}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default CenterDetails;
