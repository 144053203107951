import { Box, Button, SvgIcon, Typography } from '@mui/material';
import { useAuthenticated, useNotification } from 'hooks';
import { AppointmentPatient, basicUnknownPatient } from 'models/appointment.model';
import { FC } from 'react';
import { useFieldArray } from 'react-hook-form';
import { ReactComponent as AddIcon } from 'resources/icons/add.svg';
import BookingAddUnknownPatient from './Booking.add.unknow.patient.form';
import { Control, FormState, UseFormTrigger, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
  defaultValues: AppointmentPatient[];
  control: Control<{ patients: AppointmentPatient[] }>;
  setValue: UseFormSetValue<{ patients: AppointmentPatient[] }>;
  getValues: UseFormGetValues<{ patients: AppointmentPatient[] }>;
  register: UseFormRegister<{ patients: AppointmentPatient[] }>;
  trigger: UseFormTrigger<{ patients: AppointmentPatient[] }>;
  watch: UseFormWatch<{ patients: AppointmentPatient[] }>;
  formState: FormState<{ patients: AppointmentPatient[] }>;
  state: any;
  nbrPatient: number;
};

const BookingUnknownPatients: FC<Props> = ({ control, setValue, trigger, nbrPatient, register, watch, formState }) => {
  const { t } = useTranslation();
  const { notification } = useNotification();
  const isAuthenticated = useAuthenticated();
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'patients',
  });

  const addNewPatientIfPossible = async () => {
    if (fields.length === 0 || (await trigger())) {
      append(basicUnknownPatient);
    } else {
      notification(t('appointment.book.steps.patients.unknownPatients.add.notification.error'), 'error');
    }
  };
  return (
    <Box>
      {fields.map((_, index) => (
        <Box mt={2} key={index}>
          <BookingAddUnknownPatient
            nbrPatient={nbrPatient}
            isAuthenticated={isAuthenticated}
            index={index}
            register={register}
            watch={watch}
            setValue={setValue}
            formState={formState}
            remove={remove}
            control={control}
          />
        </Box>
      ))}
      <Box mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
        <Button sx={{ border: 'none' }} variant='link' onClick={() => addNewPatientIfPossible()}>
          <Typography>{t('appointment.book.steps.patients.unknownPatients.add.button')}</Typography>
          <SvgIcon sx={{ ml: 2 }} component={AddIcon} inheritViewBox />
        </Button>
      </Box>
    </Box>
  );
};

export default BookingUnknownPatients;
