import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useLocale, useNotification } from 'hooks';
import SendIcon from '@mui/icons-material/Send';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { SectionQuestionnaire } from 'models/questionnaire.model';
import { useParams } from 'react-router-dom';
import api from 'api';
import { useForm } from 'react-hook-form';
import DraftGenericForm from './components';
import SignaturePopup from './components/SignaturePopup';
import { useTranslation } from 'react-i18next';

type Props = {
  questionnaire: SectionQuestionnaire;
  submit: (isDraft: boolean) => void;
};

const QuestionnaireDraft: FC<Props> = ({ questionnaire, submit }) => {
  const { t } = useTranslation();
  const locale = useLocale();
  const { questionnaire: questionnaireId } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const { notification } = useNotification();

  const { register, control, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const submitQuestionnaireApi = (data: any) => {
    if (questionnaireId) {
      api.questionnaires
        .putQuestionnaireById(data, questionnaireId)
        .then(() => {
          notification(t('results.details.notification.successSaveQuestionnaire'), 'success');
        })
        .catch(() => notification('error', 'error'));
    }
  };

  const signQuestionnaireApi = (signature: string) => {
    if (questionnaireId) {
      api.questionnaires
        .putSignQuestionnaireById(questionnaireId, signature, locale)
        .then(() => {
          notification(t('results.details.notification.successSignQuestionnaire'), 'success');
          setOpenDialog(false);
          submit(false);
        })
        .catch(() => notification('error', 'error'));
    }
  };

  const parseQuestionnaire = (data: any) => {
    let res: any[] = [];
    let arr = Object.keys(data);
    arr.forEach((key: string) => {
      if (!key.includes('comment-')) {
        let value = data[key as keyof typeof data];
        let object: any = {
          question: key,
          answers: Array.isArray(value) ? value : value === undefined || value === '' ? [] : [value],
        };
        if (arr.includes('comment-' + key)) object['comment'] = data[`comment-${key}` as keyof typeof data];
        res.push(object);
      }
    });
    return res;
  };

  return (
    <form onSubmit={handleSubmit((data) => submitQuestionnaireApi(parseQuestionnaire(data)))}>
      <Card>
        <CardContent>
          <Grid container>
            {openDialog && (
              <SignaturePopup
                open={openDialog}
                setOpen={setOpenDialog}
                submitAction={(signature: string) => {
                  handleSubmit((data) => submitQuestionnaireApi(parseQuestionnaire(data)));
                  signQuestionnaireApi(signature);
                }}
              />
            )}
            <Grid item xs={12} display='flex' alignItems='center' justifyContent='space-between'>
              <Grid item xs={9}>
                <Typography variant='h1'>{`${questionnaire.name} - ${t('results.details.draft')}`}</Typography>
              </Grid>
              <Grid item xs={3} display='flex' justifyContent='end'>
                <Button sx={{ mr: 1 }} type='submit' variant='contained' endIcon={<EditOutlinedIcon />}>
                  {t('common.action.save')}
                </Button>
                <Button variant='contained' onClick={() => setOpenDialog(true)} endIcon={<SendIcon />}>
                  {t('common.action.validateSend')}
                </Button>
              </Grid>
            </Grid>
            {questionnaire.sections.map((section) => (
              <Grid item xs={12} key={section.id}>
                <Typography variant='h2'>{section.name}</Typography>
                {section.questions.map((question) => (
                  <Box key={question.id} sx={{ pt: 2 }}>
                    <DraftGenericForm setValue={setValue} control={control} register={register} question={question} />
                  </Box>
                ))}
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default QuestionnaireDraft;
