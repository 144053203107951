import { Card, Grid } from '@mui/material';
import CardHomepageServices from 'components/homepage-service';
import ContextLink from 'components/_base/ContextLink';
import { FC, SVGProps } from 'react';

const CardButton: FC<{
  icon: FC<SVGProps<any>>;
  title: string;
  to: any;
}> = ({ icon, title, to }) => {
  return (
    <Grid item xs={6} display={{ xs: 'flex', md: 'none' }}>
      <Card component={ContextLink} to={to} sx={{ width: '100%' }}>
        <CardHomepageServices logo={icon} title={title} />
      </Card>
    </Grid>
  );
};

export default CardButton;
