import { MenuItem, TextField } from '@mui/material';
import { GenericFormProps } from 'models/result.patient.model';
import { FC } from 'react';

export const GenericListForm: FC<GenericFormProps> = ({ question, missingData }) => {
  return (
    <TextField
      select
      label={question.name}
      value={question.answers[0] ?? undefined}
      variant='standard'
      fullWidth
      InputLabelProps={{
        sx: { p: 0 },
      }}
      disabled
    >
      {question.values.map((value) => {
        return (
          <MenuItem value={value?.id} key={value?.id}>
            {value?.name}
          </MenuItem>
        );
      })}
    </TextField>
  );
};
