import { createTheme } from '@mui/material';
import type {} from '@mui/x-date-pickers/themeAugmentation';

import theme_color from 'resources/theme/theme.colors';
import theme_typography from 'resources/theme/theme.typography';

import muiAccordion from 'resources/theme/muiComponents/theme.accordion';
import muiAutocomplete from 'resources/theme/muiComponents/theme.autocomplete';
import muiAvatarGroup from 'resources/theme/muiComponents/theme.avatarGroup';
import muiButton from 'resources/theme/muiComponents/theme.button';
import muiCard from 'resources/theme/muiComponents/theme.card';
import muiCheckbox from 'resources/theme/muiComponents/theme.checkbox';
import muiChip from 'resources/theme/muiComponents/theme.chip';
import muiGrid from 'resources/theme/muiComponents/theme.grid';
import muiIconButton from 'resources/theme/muiComponents/theme.iconButton';
import muiInput from 'resources/theme/muiComponents/theme.input';
import muiList from 'resources/theme/muiComponents/theme.list';
import muiMenu from 'resources/theme/muiComponents/theme.menu';
import muiSlider from 'resources/theme/muiComponents/theme.slider';
import muiStepper from 'resources/theme/muiComponents/theme.stepper';
import muiTable from 'resources/theme/muiComponents/theme.table';
import muiToggleButton from 'resources/theme/muiComponents/theme.toggleButton';
import muiTooltip from 'resources/theme/muiComponents/theme.tooltip';
import muiPickersDay from 'resources/theme/muiComponents/theme.pickersday';

const theme = createTheme({
  palette: { ...theme_color.palette },
  typography: { ...theme_typography.typography },
  components: {
    MuiButton: { ...muiButton.components?.MuiButton },
    MuiIconButton: { ...muiIconButton.components?.MuiIconButton },
    MuiCard: { ...muiCard.components?.MuiCard },
    MuiCardContent: { ...muiCard.components?.MuiCardContent },
    MuiCardMedia: { ...muiCard.components?.MuiCardMedia },
    MuiCardHeader: { ...muiCard.components?.MuiCardHeader },
    MuiCardActions: { ...muiCard.components?.MuiCardActions },
    MuiFilledInput: { ...muiInput.components?.MuiFilledInput },
    MuiInputLabel: { ...muiInput.components?.MuiInputLabel },
    MuiTableContainer: { ...muiTable.components?.MuiTableContainer },
    MuiTable: { ...muiTable.components?.MuiTable },
    MuiTableHead: { ...muiTable.components?.MuiTableHead },
    MuiTableRow: { ...muiTable.components?.MuiTableRow },
    MuiCheckbox: { ...muiCheckbox.components?.MuiCheckbox },
    MuiMenu: { ...muiMenu.components?.MuiMenu },
    MuiGrid: { ...muiGrid.components?.MuiGrid },
    MuiList: { ...muiList.components?.MuiList },
    MuiStepper: { ...muiStepper.components?.MuiStepper },
    MuiStepIcon: { ...muiStepper.components?.MuiStepIcon },
    MuiStepConnector: { ...muiStepper.components?.MuiStepConnector },
    MuiSlider: { ...muiSlider.components?.MuiSlider },
    MuiAccordion: { ...muiAccordion.components?.MuiAccordion },
    MuiAccordionSummary: {
      ...muiAccordion.components?.MuiAccordionSummary,
    },
    MuiAccordionDetails: {
      ...muiAccordion.components?.MuiAccordionDetails,
    },
    MuiToggleButtonGroup: {
      ...muiToggleButton.components?.MuiToggleButtonGroup,
    },
    MuiToggleButton: { ...muiToggleButton.components?.MuiToggleButton },
    MuiAvatarGroup: { ...muiAvatarGroup.components?.MuiAvatarGroup },
    MuiChip: { ...muiChip.components?.MuiChip },
    MuiAutocomplete: { ...muiAutocomplete.components?.MuiAutocomplete },
    MuiTooltip: { ...muiTooltip.components?.MuiTooltip },
    MuiPickersDay: { ...muiPickersDay.components?.MuiPickersDay },
  },
});

export default theme;
