import { Toolbar } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import { useIsMobileView } from 'hooks';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMe, getPatientBadgesCount } from 'store/actions/session.actions';
import { GlobalState } from 'store/reducers';
import { DRAWER_WIDTH_OPEN } from 'utils/Constants';
import HeaderLeftBloc from './HeaderLeftBloc';
import HeaderRightBloc from './HeaderRightBloc';
import { useDisclaimerNeeded } from 'hooks';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH_OPEN,
    width: `calc(100% - ${DRAWER_WIDTH_OPEN}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const UserIsLogged: FC = () => {
  const dispatch = useDispatch();
  const isMobileView = useIsMobileView();
  const isOpen = useSelector((state: GlobalState) => state.navMenu.isOpen);
  const authenticateUser = useSelector((state: GlobalState) => state.session.authenticateUser);
  const currentUser = useSelector((state: GlobalState) => state.session.currentUser);
  const isDisclaimerNeeded = useDisclaimerNeeded();

  useEffect(() => {
    dispatch(fetchMe());
  }, [dispatch]);

  useEffect(() => {
    if (authenticateUser && authenticateUser.role === 'patient' && currentUser) {
      dispatch(getPatientBadgesCount());
    }
  }, [authenticateUser, dispatch, currentUser]);

  return (
    <AppBar
      position='sticky'
      sx={{
        height: '72px',
        boxShadow: 'none',
        justifyContent: 'center',
        ...(isOpen && isMobileView && { display: 'none' }),
      }}
      color='transparent'
      open={isOpen}
    >
      {!isDisclaimerNeeded && (
        <Toolbar
          sx={{
            justifyContent: 'flex-end',
            padding: { xs: '16px', sm: '16px 32px' },
            backgroundColor: 'background.paper',
            minHeight: '0px !important',
          }}
        >
          <HeaderLeftBloc isOpen={isOpen} />
          <HeaderRightBloc />
        </Toolbar>
      )}
    </AppBar>
  );
};

export default UserIsLogged;
