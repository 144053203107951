import { Box, Button, SvgIcon, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import ContextLink from '../_base/ContextLink';
import { resultTaggedEl } from 'models/result.model';
import { ReactComponent as IconAbnormal } from 'resources/icons/anormaux.svg';
import { ReactComponent as IconStar } from 'resources/icons/favorite.svg';
import { ReactComponent as IconUrgent } from 'resources/icons/urgent.svg';
import { PRIORITY } from 'utils/Constants';
import { convertDate } from 'utils/func/Date.func';

const ResultsTableContent: FC<{
  elem: resultTaggedEl;
}> = ({ elem }) => {
  const { t } = useTranslation();

  return (
    <TableRow
      hover
      sx={{
        '& > td > p, & > td > a > p': {
          ...(elem.consultation_time === null && { fontWeight: 'bold' }),
        },
      }}
    >
      <TableCell>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            placeItems: 'center',
            gap: 1,
          }}
        >
          {elem.tagged && (
            <Tooltip title={t('common.table.tableActions.markedMark')}>
              <SvgIcon fontSize='small' component={IconStar} sx={{ gridColumn: '1' }} />
            </Tooltip>
          )}
          {elem.has_abnormal_result && (
            <Tooltip title={t('common.table.tableActions.abnormal')}>
              <SvgIcon fontSize='small' component={IconAbnormal} sx={{ gridColumn: '2' }} />
            </Tooltip>
          )}
          {elem.priority === PRIORITY.URGENT && (
            <Tooltip title={t('common.table.tableActions.urgent')}>
              <SvgIcon fontSize='small' component={IconUrgent} sx={{ gridColumn: '3' }} />
            </Tooltip>
          )}
        </Box>
      </TableCell>
      <TableCell>
        <Typography>{convertDate(elem.prescription_date, false)}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{convertDate(elem.sampling_time, true)}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{elem.reference || '-'}</Typography>
      </TableCell>
      <TableCell>
        <ContextLink to='DOCTOR_PATIENTS_DETAILS' params={elem.patient.id.toString()}>
          <Typography color='primary'>
            <strong>{`${elem.patient.first_name} ${elem.patient.last_name}`}</strong>
          </Typography>
        </ContextLink>
      </TableCell>
      <TableCell>
        <Typography>{convertDate(elem.patient.birth_date)}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{elem.patient.cns}</Typography>
      </TableCell>
      <TableCell>
        {elem.issuer ? <Typography>{`${t('result_doctor.doctor')} ${elem.issuer.first_name} ${elem.issuer.last_name}`}</Typography> : <Typography>{t('common.missingData')}</Typography>}
      </TableCell>
      <TableCell>
        <Typography>
          {t(
            // @ts-ignore
            `result_doctor.${elem.status}`,
          )}
        </Typography>
      </TableCell>
      <TableCell>
        <ContextLink
          to='DETAIL_RESULT'
          params={{
            id: elem.reference,
          }}
        >
          <Button variant='text'>{t('homepage_doctor.results_table.details')}</Button>
        </ContextLink>
      </TableCell>
    </TableRow>
  );
};

export default ResultsTableContent;
