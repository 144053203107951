import { Box, IconButton, ListItem, ListItemButton, SvgIcon, Typography } from '@mui/material';
import api from 'api';
import { useNotification } from 'hooks';
import { Analysis } from 'models/analysis.model';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconAdd } from 'resources/icons/add.svg';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AnalysisAttributesDialog from '../analysis-attributes/AnalysisAttributesDialog';

type AnalysisListProps = {
  title?: string;
  analysisList?: Analysis[];
  disabled?: boolean;
  onSelect: (items: any) => void;
};

const AnalysisList: FC<AnalysisListProps> = ({ title, analysisList, onSelect, disabled = false }) => {
  const { t } = useTranslation();

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedAnalysis, setSelectedAnalysis] = useState<Analysis>();
  const { notification } = useNotification();

  const handleSelection = (element: Analysis) => {
    onSelect([
      {
        refund_cns: element.refund_cns === false ? element.price : null,
        currency: element.currency,
        name: element.name,
        motivation: element.motivation,
        questions: element.questions,
        analysis_reference: element.reference,
        title: null,
        description: null,
      },
    ]);
  };

  const openDialog = useCallback(
    (item: Analysis) => {
      if (item.attributes.length) {
        setSelectedAnalysis(item);
        setDialogOpen(true);
      } else {
        api.analysis
          .getAnalysisById(item.id)
          .then((response: Analysis) => {
            setSelectedAnalysis(response);
            setDialogOpen(true);
          })
          .catch(() => notification(t('prescriptions_profile.add_analysis.notification.errorOnViewingAnalysis'), 'error'));
      }
    },
    [notification, t],
  );

  return (
    <>
      {title && (
        <Typography variant='h3' mb={3}>
          {t(title)}
        </Typography>
      )}
      {analysisList && (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', md: 'repeat(2, 1fr)' },
          }}
        >
          {analysisList.length ? (
            analysisList.map((analysis, key) => (
              <ListItem
                key={key}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',

                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                  '&:last-child': {
                    borderBottom: 0,
                  },
                  '&:nth-last-of-type(2):nth-of-type(odd)': {
                    md: {
                      // remove border of element before last if it's odd
                      // (meaning there's an even number of elements to display)
                      borderBottom: 0,
                    },
                  },
                }}
                secondaryAction={
                  <Box sx={{ flexShrink: 0 }}>
                    <IconButton disabled={disabled} onClick={() => handleSelection(analysis)}>
                      <SvgIcon fontSize='small' component={IconAdd} />
                    </IconButton>
                    <IconButton onClick={() => openDialog(analysis)}>
                      <SvgIcon fontSize='small' component={InfoOutlinedIcon} />
                    </IconButton>
                  </Box>
                }
              >
                <ListItemButton disabled={disabled} onClick={() => handleSelection(analysis)}>
                  <Typography variant='body1' sx={{ overflowWrap: 'anywhere' }}>
                    {analysis.name}
                  </Typography>
                </ListItemButton>
              </ListItem>
            ))
          ) : (
            <Box
              sx={{
                gridColumn: 'span 2',
                placeSelf: 'center',
              }}
            >
              <Typography variant='h5' mb={3}>
                {t('prescriptions_making.step1.search_analysis.noResults')}
              </Typography>
            </Box>
          )}
        </Box>
      )}
      <AnalysisAttributesDialog analysis={selectedAnalysis} open={isDialogOpen} setOpen={setDialogOpen} />
    </>
  );
};

export default AnalysisList;
