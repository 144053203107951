import { TextField } from '@mui/material';
import { DraftGenericFormProps } from 'models/result.patient.model';
import { FC } from 'react';

export const DraftGenericTextForm: FC<
  DraftGenericFormProps & {
    type?: string;
  }
> = ({ question, register, type = 'text' }) => {
  return (
    <TextField
      variant='standard'
      fullWidth
      type={type}
      defaultValue={question.answers[0]}
      {...register(question.id.toString(), { required: question.required })}
      label={question.name}
      InputLabelProps={{
        sx: { p: 0 },
      }}
    />
  );
};
