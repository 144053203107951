export enum NavMenuActionType {
  OPEN = 'NAV_MENU_OPEN',
  CLOSE = 'NAV_MENU_CLOSE',
}

export type NavMenuAction = { type: NavMenuActionType.OPEN } | { type: NavMenuActionType.CLOSE };

export const openLeftDrawer = (): NavMenuAction => ({
  type: NavMenuActionType.OPEN,
});

export const closeLeftDrawer = (): NavMenuAction => ({
  type: NavMenuActionType.CLOSE,
});
