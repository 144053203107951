import { Grid, Skeleton } from '@mui/material';
import { FC } from 'react';

const MobileResultsSkeleton: FC = () => {
  return (
    <Grid container spacing={0} display='flex' py={1}>
      <Grid item xs={10}>
        <Skeleton variant='text' width='80%' />
        <Skeleton variant='text' width='80%' />
      </Grid>
      <Grid item xs={2} alignSelf='center' display='flex' justifyContent='flex-end'>
        <Skeleton variant='circular' width={32} height={32} />
      </Grid>
    </Grid>
  );
};

export default MobileResultsSkeleton;
