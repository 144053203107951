import { Box, Button, Card, CardContent, CardHeader, TableCell, TableRow, Typography } from '@mui/material';
import api from 'api';
import GenericTable from 'components/generic-table';
import { GenericHeader } from 'components/generic-table/GenericTableHeader';
import NoResultsTableRow from 'components/no-results-table-row';
import TableContentSkeleton from 'components/table-content-skeleton';
import ContextLink from 'components/_base/ContextLink';
import { useNotification } from 'hooks';
import { DSPDocument } from 'models/myDSP.model';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MyDSPLogo from 'resources/images/logo_mydsp.svg';
import { GlobalState } from 'store/reducers';
import { tryCatch } from 'utils';
import { convertDate } from 'utils/func/Date.func';

const ListDocumentDSP: FC = () => {
  const { t } = useTranslation();
  const currentUser = useSelector((state: GlobalState) => state.session.currentUser);
  const [isLoading, setLoading] = useState(true);
  const [documents, setDocuments] = useState<DSPDocument[]>();
  const { notification } = useNotification();

  useEffect(() => {
    setLoading(true);
    currentUser &&
      tryCatch(
        () =>
          api.myDSP.getDSPDocuments(currentUser.id).then((response: DSPDocument[]) => {
            setDocuments(response);
            setLoading(false);
          }),
        () => notification(t('common.errorLoadDocument'), 'error'),
      );
  }, [currentUser, notification, t]);

  const tableHeaders: GenericHeader[] = [
    {
      id: 'name',
      label: 'myDSP.listDSP.headers.type',
    },
    {
      id: 'author',
      label: 'myDSP.listDSP.headers.author',
    },
    {
      id: 'date',
      label: 'myDSP.listDSP.headers.creationDate',
    },
    {
      id: 'action',
      label: undefined,
    },
  ];

  return (
    <>
      <Card>
        <CardHeader
          title={
            <Box sx={{ display: 'flex', alignItems: 'center', pb: 3 }}>
              <Typography variant='h2'>{t('myDSP.listDSP.title')}</Typography>
              <Box component='img' alt={t('myDSP.alt')} sx={{ ml: 2 }} src={MyDSPLogo} />
            </Box>
          }
        />
        <CardContent>
          <GenericTable headers={tableHeaders} noPagination>
            {!isLoading && documents ? (
              documents.length ? (
                documents.map((document: DSPDocument, key) => (
                  <TableRow hover key={key}>
                    <TableCell>
                      <Typography variant='body1'>{document.name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1'>{document.authorInstitution ?? '-'}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1'>{convertDate(document.creationTime, true)}</Typography>
                    </TableCell>
                    <TableCell>
                      <ContextLink to='MY_DSP_DETAIL' params={{ uuid: document.uuid }}>
                        <Button variant='text'>{t('common.action.see')}</Button>
                      </ContextLink>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <NoResultsTableRow colSpan={tableHeaders.length} noResultText={'common.noResult'} />
              )
            ) : (
              <TableContentSkeleton columns={tableHeaders.length} rows={4} />
            )}
          </GenericTable>
        </CardContent>
      </Card>
    </>
  );
};
export default ListDocumentDSP;
