import { Badge, Box, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, SvgIcon, Tooltip, Typography, Button, ListItemButton } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { FC, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as IconBack } from 'resources/icons/back.svg';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import ContextLink from 'components/_base/ContextLink';
import { Person, RelationInfo } from 'models/profile.model';
import { useAuthenticated, useIsDoctor, useIsMobileView, useLocale, useNotification, useContextRedirection, useIsLaboratory, useIsAdmin, useIsPatient } from 'hooks';
import { LeftDrawerItem } from 'models/utils.model';
import { Location, useLocation } from 'react-router-dom';
import { ReactComponent as ExternalLinkIcon } from 'resources/icons/external-link.svg';
import { closeLeftDrawer } from 'store/actions/navMenu.actions';
import { convertToUser, setCurrentUser } from 'store/actions/session.actions';
import { GlobalState } from 'store/reducers';
import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_OPEN } from 'utils/Constants';
import RoutePaths from 'utils/RoutePaths';
import { getFullName } from 'utils/func/Person.func';
import { leftDrawerMenuDoctor } from './LeftDrawerMenuDoctor';
import { leftDrawerMenuPatient } from './LeftDrawerMenuPatient';
import { useTranslation } from 'react-i18next';
import AvatarBloc from './AvatarBloc';
import { leftDrawerMenuLaboratory } from './LeftDrawerMenuLaboratory';
import { leftDrawerMenuAdmin } from './LeftDrawerMenuAdmin';

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH_OPEN,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `${DRAWER_WIDTH_CLOSED}px`,
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: DRAWER_WIDTH_OPEN,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const LeftDrawer: FC = () => {
  const { t } = useTranslation();
  const isOpen = useSelector((state: GlobalState) => state.navMenu.isOpen);
  const resultsUnread = useSelector((state: GlobalState) => state.session.badgesCount?.dossiers ?? 0);
  const upcomingAppointments = useSelector((state: GlobalState) => state.session.badgesCount?.appointments ?? 0);
  const eudccUnread = useSelector((state: GlobalState) => state.session.badgesCount?.eudccs ?? 0);
  const invoicesToPay = useSelector((state: GlobalState) => state.session.badgesCount?.invoices ?? 0);
  const currentUser = useSelector((state: GlobalState) => state.session.currentUser);
  const authenticateUser = useSelector((state: GlobalState) => state.session.authenticateUser);
  const { notification } = useNotification();
  const dispatch = useDispatch();
  const handleDrawerClose = useCallback(() => {
    dispatch(closeLeftDrawer());
  }, [dispatch]);

  const usePrevious = (value: Location): Location | undefined => {
    const ref = useRef<Location>();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  /**
   * Close left drawer when route change on mobile view
   */
  const location: Location = useLocation();
  const prevLocation = usePrevious(location);
  const navigate = useContextRedirection();
  const isAuthenticated = useAuthenticated();
  const isMobileView = useIsMobileView();
  const isDoctor = useIsDoctor();
  const isAdmin = useIsAdmin();
  const isLaboratory = useIsLaboratory();
  const isPatient = useIsPatient();

  const locale = useLocale();
  useEffect(() => {
    if (prevLocation && location.pathname !== prevLocation.pathname) {
      handleDrawerClose();
    }
  }, [location, prevLocation, handleDrawerClose]);

  const menuItems = isAdmin ? leftDrawerMenuAdmin() : isDoctor ? leftDrawerMenuDoctor() : isLaboratory ? leftDrawerMenuLaboratory() : isPatient ? leftDrawerMenuPatient(locale) : [];

  const isCurrentPage = (menuItem: LeftDrawerItem): boolean => {
    const routeToReach = RoutePaths[menuItem.link];
    const path = typeof routeToReach === 'string' ? routeToReach : null;
    return path ? location.pathname.includes(path) : false;
  };

  const handleChangeCurrentUser = useCallback(
    (currentUser: RelationInfo | Person) => {
      dispatch(setCurrentUser(convertToUser(currentUser)));
      notification(
        `${t('notifications.authentification.profileSwicher.success', {
          replace: { name: getFullName(currentUser) },
        })}`,
        'success',
      );
      navigate(RoutePaths['HOME']);
    },
    [dispatch, navigate, notification, t],
  );

  const getCount = (menu: LeftDrawerItem) => {
    switch (menu.link) {
      case 'INVOICES_OPEN':
        return invoicesToPay;
      case 'RESULTS':
        return resultsUnread;
      case 'APPOINTMENT':
        return upcomingAppointments;
      case 'EUDCC_CERTIFICATES':
        return eudccUnread;
      default:
        return 0;
    }
  };

  return (
    <>
      {isAuthenticated && (
        <Drawer
          variant='permanent'
          open={isOpen}
          anchor='left'
          sx={{
            ...(isOpen &&
              isMobileView && {
                width: '100%',
                '& .MuiDrawer-paper': { width: '100%' },
              }),
          }}
        >
          <Box
            sx={{
              height: '40px',
              padding: (theme) => theme.spacing(2, 4),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton onClick={handleDrawerClose}>
              <SvgIcon fontSize='small' component={IconBack} />
            </IconButton>
          </Box>
          <Divider />
          <List
            sx={{
              py: isMobileView ? 1 : 4,
              px: 0,
            }}
          >
            {!isDoctor && isMobileView && (
              <>
                <ListItem
                  sx={{
                    justifyContent: 'center',
                    padding: (theme) => theme.spacing(2, 4),
                    fontWeight: 'bold',
                  }}
                >
                  {currentUser && getFullName(currentUser)}
                </ListItem>
                <AvatarBloc handleChangeUser={handleChangeCurrentUser} />
                <ListItemButton
                  sx={{
                    padding: (theme) => theme.spacing(2, 4),
                    '& a': {
                      marginLeft: '-6px',
                    },
                    '& span': {
                      fontWeight: 'bold !important',
                    },
                  }}
                >
                  <ContextLink to='PROFILE'>
                    <Button>{t('navigation.header.profile')}</Button>
                  </ContextLink>
                </ListItemButton>
                <ListItemButton
                  sx={{
                    padding: (theme) => theme.spacing(2, 4),
                    '& a': {
                      marginLeft: '-6px',
                    },
                    '& span': {
                      fontWeight: 'bold !important',
                    },
                  }}
                >
                  {!currentUser?.is_relative ? (
                    <ContextLink to='PROFILE_RELATIVE'>
                      <Button>{t('navigation.header.relative')}</Button>
                    </ContextLink>
                  ) : (
                    <ListItemText
                      onClick={authenticateUser && (() => handleChangeCurrentUser(authenticateUser))}
                      sx={{
                        display: isOpen ? 'flex' : 'none',
                        alignSelf: 'center',
                        margin: (theme) => theme.spacing(0),
                        '&>span': {
                          whiteSpace: 'break-spaces',
                          maxHeight: '50px',
                        },
                      }}
                      primary={t('navigation.header.backToMainAccount')}
                      //  fullWidth
                    />
                  )}
                </ListItemButton>
                <Divider sx={{ m: 1 }} />
              </>
            )}
            {menuItems.map((el: LeftDrawerItem, key: number) => {
              return (
                <Tooltip key={key} title={t(el.title)} placement='right' disableHoverListener={isOpen}>
                  <ListItemButton
                    sx={{
                      padding: (theme) => (isOpen ? theme.spacing(2, 4) : theme.spacing(2, 0)),
                      ...(isCurrentPage(el) && {
                        borderLeft: (theme) => `6px solid ${theme.palette.primary.main}`,
                        backgroundColor: (theme) => `${theme.palette.primary.main}45`,
                        '& a': {
                          marginLeft: '-6px',
                        },
                        '& span': {
                          fontWeight: 'bold !important',
                        },
                      }),
                    }}
                    disabled={el.isActivated === false}
                  >
                    <ContextLink to={el.link} params={el.params} external={el.external ?? false} fullWidth disabled={el.isActivated === false}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <ListItemAvatar
                          sx={{
                            display: 'flex',
                            minWidth: DRAWER_WIDTH_CLOSED,
                            justifyContent: isOpen ? 'flex-start' : 'center',
                          }}
                        >
                          <Badge badgeContent={isOpen ? 0 : getCount(el)} overlap='circular' color='primary'>
                            <SvgIcon
                              sx={{
                                color: 'primary.contrastText',
                              }}
                              component={el.icon}
                            />
                          </Badge>
                        </ListItemAvatar>
                        <ListItemText
                          sx={{
                            display: isOpen ? 'flex' : 'none',
                            alignSelf: 'center',
                            margin: (theme) => theme.spacing(0),
                            '&>span': {
                              whiteSpace: 'break-spaces',
                              maxHeight: '50px',
                            },
                          }}
                          primary={t(el.title)}
                        />
                        {isOpen && (
                          <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
                            <Badge badgeContent={getCount(el)} color='primary'></Badge>
                          </Box>
                        )}
                        {el.external && (
                          <Box
                            sx={{
                              display: isOpen ? 'flex' : 'none',
                              flexGrow: 1,
                              justifyContent: 'flex-end',
                            }}
                          >
                            <SvgIcon
                              sx={{
                                fontSize: 20,
                                color: 'text.secondary',
                                filter: 'brightness(1.4)',
                              }}
                              component={ExternalLinkIcon}
                            />
                          </Box>
                        )}
                      </Box>
                    </ContextLink>
                  </ListItemButton>
                </Tooltip>
              );
            })}
            {isMobileView && (
              <>
                <Divider />
                <ListItemButton
                  sx={{
                    padding: (theme) => theme.spacing(2, 4),
                    '& a': {
                      marginLeft: '-6px',
                    },
                    '& span': {
                      fontWeight: 'bold !important',
                    },
                  }}
                >
                  <ContextLink to='LOGOUT'>
                    <Typography color='error'>{t('navigation.header.logout')}</Typography>
                  </ContextLink>
                </ListItemButton>
              </>
            )}
          </List>
        </Drawer>
      )}
    </>
  );
};

export default LeftDrawer;
