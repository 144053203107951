import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Button, Card, CardContent, Collapse, Divider, IconButton, Stack, Typography } from '@mui/material';
import ContextLink from 'components/_base/ContextLink';
import { useIsDoctor } from 'hooks';
import { Questionnaire } from 'models/questionnaire.model';
import { FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const DetailPatientQuestions: FC<{
  reference: string;
  questionnaires: Questionnaire[];
}> = ({ reference, questionnaires }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const [nbQuestionnairesToSee, setNbQuestionnairesToSee] = useState<number>(0);
  const isDoctor = useIsDoctor();
  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    let nb: number = 0;
    for (let questionnaire of questionnaires) {
      if (isDoctor) {
        nb += questionnaire.status !== 'draft' ? 1 : 0;
      } else {
        nb++;
      }
    }
    setNbQuestionnairesToSee(nb);
  }, [questionnaires, isDoctor]);

  if (nbQuestionnairesToSee === 0) {
    return <></>;
  }

  return (
    <Card>
      <CardContent>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Typography variant='subtitle1' sx={{ fontSize: 20 }}>
            {t('result_doctor.details.questions_card_text', {
              count: questionnaires.length,
            })}
          </Typography>
          {questionnaires.length === 1 ? (
            <ContextLink
              to={'DETAIL_RESULT_QUESTIONNAIRE'}
              params={{
                folder: +reference,
                questionnaire: questionnaires[0].id,
              }}
            >
              <Button variant='outlined'>{t('result_doctor.details.buttons.consult')}</Button>
            </ContextLink>
          ) : (
            <IconButton onClick={handleClick}>{open ? <ExpandLess sx={{ fontSize: 40 }} /> : <ExpandMore sx={{ fontSize: 40 }} />}</IconButton>
          )}
        </Stack>
        {questionnaires.length > 1 && (
          <Stack>
            <Collapse in={open}>
              {questionnaires.map((quest, index) => (
                <Fragment key={index}>
                  {isDoctor && quest.status === 'draft' ? null : (
                    <>
                      <Divider sx={{ my: 1, background: '#46BFF5' }} light />
                      <Stack
                        key={quest.id}
                        direction='row'
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{
                          '&:hover': { background: 'bl#B6E5F9ack', opacity: 0.5 },
                        }}
                      >
                        {isDoctor && quest.status !== 'draft' ? <Typography>{quest.name}</Typography> : null}
                        {!isDoctor ? <Typography>{quest.name}</Typography> : null}
                        <ContextLink
                          to={'DETAIL_RESULT_QUESTIONNAIRE'}
                          params={{
                            folder: +reference,
                            questionnaire: quest.id,
                          }}
                        >
                          {isDoctor && quest.status !== 'draft' ? <Button variant='text'>{t('result_doctor.details.buttons.consult')}</Button> : null}
                          {!isDoctor && quest.status !== 'draft' ? <Button variant='text'>{t('result_doctor.details.buttons.consult')}</Button> : null}
                          {!isDoctor && quest.status === 'draft' ? <Button variant='text'>{t('common.action.modify')}</Button> : null}
                        </ContextLink>
                      </Stack>
                    </>
                  )}
                </Fragment>
              ))}
            </Collapse>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};
