import { createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: 'top',
      },
      styleOverrides: {
        tooltip: {
          fontSize: 14,
        },
      },
    },
  },
});

export default theme;
