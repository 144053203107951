import { APIConfiguration, axiosMYLAB } from 'api';
import config from 'config';
import { Disclaimer } from 'models/disclaimer.model';
import { BaseService } from './base.service';

class DisclaimerService extends BaseService {
  async getDisclaimer() {
    const disclaimer = await axiosMYLAB.get<Disclaimer>(this.apiConfig.DISCLAIMER.GET(this.getLang()));

    this.log(`getDisclaimer()`);

    return disclaimer;
  }

  async getPublicDisclaimer() {
    const disclaimer = await axiosMYLAB.get<Disclaimer>(this.apiConfig.DISCLAIMER.GET_PUBLIC(this.getLang(), config.auth.clientId!));

    this.log(`getPublicDisclaimer()`);

    return disclaimer;
  }

  async acceptDisclaimer(id: number) {
    await axiosMYLAB.post(this.apiConfig.DISCLAIMER.ACCEPT(this.getLang(), id));

    this.log(`acceptDisclaimer()`);
  }
}

export default (apiConfig: APIConfiguration) => {
  return new DisclaimerService(apiConfig);
};
