import config from 'config';
import { SectionQuestionnaire } from 'models/questionnaire.model';
import { APIConfiguration, axiosMYLAB } from '.';
import { BaseService } from './base.service';

class QuestionnairesService extends BaseService {
  async getQuestionnaireById(lang: string, id: string) {
    const { data } = await axiosMYLAB.get<SectionQuestionnaire>(`${this.apiConfig.RESULTS.GET_QUESTIONNAIRE_BY_ID(this.getLang(), id)}`);

    this.log(`getQuestionnaireById()`);

    return data;
  }

  async putQuestionnaireById(res: any[], id: string) {
    const { data } = await axiosMYLAB.put(`${this.apiConfig.RESULTS.PUT_QUESTIONNAIRE_BY_ID(this.getLang(), id)}`, res);
    return data;
  }

  async putSignQuestionnaireById(id: string, signature: string, lang: string) {
    const { data } = await axiosMYLAB.put(`${this.apiConfig.RESULTS.SIGN_QUESTIONNAIRE_BY_ID(this.getLang(), id)}`, {
      id: id,
      signature: signature,
      params: {
        language: lang,
      },
    });

    this.log('putSignQuestionnaireById()');

    return data;
  }

  async getQuestionnairePdfFileById(id: number) {
    const { data } = await axiosMYLAB.get<Blob>(`${config.myLabApiUrl}${this.apiConfig.RESULTS.GET_QUESTIONNAIRE_PDF_FILE_BY_ID(this.getLang(), id)}`, { responseType: 'blob' });

    this.log(`getQuestionnairePdfFileById()`);

    return data;
  }
}

export default (apiConfig: APIConfiguration) => {
  return new QuestionnairesService(apiConfig);
};
