import { Box, Card, CardContent, Grid } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import AnalysisList from 'components/analysis-list';
import PatientDetails from 'components/patient-details';
import { useContextRedirection, useNotification } from 'hooks';
import { Analysis, AnalysisReference } from 'models/analysis.model';
import { Patient } from 'models/patients.model';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { tryCatch } from 'utils';
import { Prescription } from 'models/prescription.model';
import RoutePaths from 'utils/RoutePaths';
import AnalysisQuestionsDialog from './analysis/AnalysisQuestionsDialog';
import ProfileSelection from './analysis/AnalysisProfileSelection';
import AnalysisRecap from './analysis/AnalysisRecap';
import { AnalysisSelection } from './analysis/AnalysisSelection';

const PatientsPrescription: FC = () => {
  const { idPrescription } = useParams();
  const [patient, setPatient] = useState<Patient>();
  const [patientId, setPatientId] = useState();
  const { t } = useTranslation();
  const [frequentAnalysis, setFrequentAnalysis] = useState<Analysis[]>();
  const [selectedPrescription, setSelectedPrescription] = useState<Prescription>();
  const navigate = useContextRedirection();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const VALIDATED_STATUS = 'validated';
  const { notification } = useNotification();
  const [loadOnAddingPrescription, setloadOnAddingPrescription] = useState(false);
  const [analysis, setAnalysis] = useState<AnalysisReference[]>([]);
  const [disabled, setDisabled] = useState<boolean>(false);

  const loadBasketAnalysis = useCallback(() => {
    idPrescription &&
      tryCatch(
        async () => {
          const response = await api.prescriptions.getPrescriptionByBasketId(idPrescription);
          setSelectedPrescription(response);
          setPatientId(response.patient_id);
          setloadOnAddingPrescription(false);
        },
        () => {
          navigate(
            RoutePaths['DOCTOR_PRESCRIPTION_DETAILS_VALIDATION']({
              id: idPrescription,
            }),
          );
        },
      );
  }, [idPrescription, navigate]);

  const loadPatient = useCallback(() => {
    patientId &&
      tryCatch(
        () =>
          api.patients.getPatientDetails(patientId).then((patientDetails) => {
            setPatient(patientDetails);
          }),
        () => {
          notification(t('patient_details.notification.error'), 'error');
        },
      );
  }, [patientId, notification, t]);

  useEffect(() => {
    api.results
      .getFrequentAnalysis()
      .then((response: Analysis[]) => {
        setFrequentAnalysis(response);
      })
      .catch(() => {
        notification(t('prescriptions_profile.new_profile.notification.error'), 'error');
      });
  }, [notification, t]);

  useEffect(() => {
    if (selectedPrescription?.status === VALIDATED_STATUS) {
      navigate(
        RoutePaths['DOCTOR_PRESCRIPTION_DETAILS_VALIDATION']({
          id: idPrescription,
        }),
      );
    } else {
      loadBasketAnalysis();
      loadPatient();
    }
  }, [idPrescription, loadBasketAnalysis, loadPatient, navigate, selectedPrescription?.status]);

  const addAnalysisToPrescription = (analysis: AnalysisReference[]) => {
    if (idPrescription) {
      setloadOnAddingPrescription(true);
      api.prescriptions
        .addAnalysisToPrescription(idPrescription, analysis)
        .then((response) => {
          setSelectedPrescription(response.data);
        })
        .catch(() => {
          notification(t('prescriptions_profile.add_analysis.notification.errorOnAdding'), 'error');
        })
        .finally(() => {
          setloadOnAddingPrescription(false);
        });
    }
  };

  const handleSelectElement = (analysisToAdd: AnalysisReference[]) => {
    if (analysisToAdd.filter((toAdd: AnalysisReference) => !toAdd.motivation && toAdd.questions && toAdd.questions?.length > 0).length > 0) {
      setIsDialogOpen(true);
      setAnalysis(analysisToAdd);
    } else {
      addAnalysisToPrescription(analysisToAdd);
    }
  };

  const handlePrescriptionDeleted = useCallback(() => {
    if (patient) {
      notification(t('prescriptions_making.step1.notification.deleted'), 'success');
      navigate(RoutePaths['DOCTOR_PATIENTS_DETAILS'](patient.id.toString()));
    }
  }, [notification, navigate, patient, t]);

  return (
    <BodyTemplate title={t('bodyTemplate.doctor.makePrescription')} backButton>
      {isDialogOpen && <AnalysisQuestionsDialog open={isDialogOpen} setOpen={setIsDialogOpen} analysis={analysis} submitAction={(data) => addAnalysisToPrescription(data)} />}
      <Grid container>
        <Grid item xs={12} md={12}>
          <PatientDetails patient={patient} showPatientRecordAction />
        </Grid>
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <ProfileSelection disabled={disabled} handleSelectElement={handleSelectElement} />
              <AnalysisSelection disabled={disabled} handleSelectElement={handleSelectElement} />
              <Box py={2}>
                <AnalysisList disabled={disabled} title={'prescriptions_profile.add_analysis.most_frequent'} analysisList={frequentAnalysis} onSelect={handleSelectElement} />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          {selectedPrescription && idPrescription && (
            <AnalysisRecap
              setDisabled={setDisabled}
              disabled={disabled}
              selectedPrescription={selectedPrescription}
              idPrescription={idPrescription}
              setSelectedPrescription={setSelectedPrescription}
              prescriptionDeletedCallback={handlePrescriptionDeleted}
              loadOnAddingPrescription={loadOnAddingPrescription}
              setloadOnAddingPrescription={setloadOnAddingPrescription}
            />
          )}
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default PatientsPrescription;
