import { Question } from 'models/result.patient.model';
import { DraftGenericMatrixForm } from './DraftGenericMatrixForm';
import { DraftGenericListForm } from './DraftGenericListForm';
import { DraftGenericTextForm } from './DraftGenericTextForm';
import { DraftGenericBooleanForm } from './DraftGenericBooleanForm';
import GenericDatePicker from 'components/date-picker';
import { DraftGenericMultipleForm } from './DraftGenericMultipleForm';
import { FC } from 'react';

export const DraftGenericForm: FC<{
  question: Question;
  setValue: any;
  register: any;
  control: any;
}> = ({ question, register, control, setValue }) => {
  switch (question.type) {
    case 'integer':
      return <DraftGenericTextForm type={'number'} question={question} register={register} />;
    case 'text':
      return <DraftGenericTextForm question={question} register={register} />;
    case 'list':
      return <DraftGenericListForm control={control} question={question} />;
    case 'matrix':
      return <DraftGenericMatrixForm control={control} register={register} question={question} />;
    case 'date':
      return (
        <GenericDatePicker defaultValue={question.answers[0]} dataFormat='ISO' required={question.required} label={question.name} name={question.id.toString()} variant='standard' control={control} />
      );
    case 'multiple':
      return <DraftGenericMultipleForm question={question} control={control} register={register} setValue={setValue} />;
    case 'boolean':
      return <DraftGenericBooleanForm control={control} register={register} question={question} />;
    default:
      return null;
  }
};

export default DraftGenericForm;
