import { Button, Card, CardContent, Grid, MenuItem, TextField, Typography } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import PatientDetails from 'components/patient-details';
import { useAuthenticatedUser, useContextRedirection } from 'hooks';
import { OfficeMedicalStaff } from 'models/office-medical-staff.model';
import { Patient } from 'models/patients.model';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { officeMedicalStaffInfoToString, PrescriptionRequestMaker } from 'utils/func/Prescription.func';

const PrescriptionsSelectOMS: FC = () => {
  const { t } = useTranslation();
  const navigate = useContextRedirection();
  const { patientId } = useParams();
  const [patient, setPatient] = useState<Patient>();
  const [officeMedicalStaffInfo, setofficeMedicalStaffInfo] = useState<OfficeMedicalStaff[]>();
  const [selectedOfficeStaff, setSelectedOfficeStaff] = useState<number>(0);
  const authenticatedUser = useAuthenticatedUser();

  useEffect(() => {
    patientId &&
      api.patients.getPatientDetails(+patientId).then((patientDetails) => {
        setPatient(patientDetails);
      });

    api.users.getAllOfficeMedicalStaffByUser().then((response) => setofficeMedicalStaffInfo(response));
  }, [patientId]);

  return (
    <BodyTemplate title={t('bodyTemplate.doctor.prescriptions')}>
      <Grid container columnSpacing={2} rowSpacing={3}>
        <Grid item xs={12} md={6}>
          <PatientDetails patient={patient} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant='h3' gutterBottom>
                {t('prescriptions_profile.new_profile.officeStaff.title')}
              </Typography>
              <TextField
                select
                label={t('prescriptions_profile.new_profile.officeStaff.label')}
                required
                variant='filled'
                fullWidth
                defaultValue={officeMedicalStaffInfo?.[0].id}
                onChange={(e) => {
                  setSelectedOfficeStaff(parseInt(e.target.value));
                }}
              >
                {officeMedicalStaffInfo?.map((el) => {
                  return (
                    <MenuItem key={el.id} value={el.id}>
                      {officeMedicalStaffInfoToString(el)}
                    </MenuItem>
                  );
                })}
              </TextField>
              <Button
                variant='contained'
                onClick={() => {
                  patientId && authenticatedUser && PrescriptionRequestMaker(+patientId, navigate, selectedOfficeStaff);
                }}
                sx={{ mt: 1 }}
              >
                {t('common.action.makePrescription')}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default PrescriptionsSelectOMS;
