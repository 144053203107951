import { ListItem, ListItemIcon, ListItemText, SvgIcon } from '@mui/material';
import { FC, ReactElement } from 'react';
import { ReactComponent as IconNext } from 'resources/icons/next.svg';

const HomepageListingItems: FC<{ title: ReactElement }> = ({ title }) => {
  return (
    <ListItem disableGutters>
      <ListItemIcon sx={{ minWidth: 'auto', marginRight: '10px' }}>
        <SvgIcon
          component={IconNext}
          sx={{
            fontSize: 16,
            color: 'text.secondary',
          }}
        ></SvgIcon>
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  );
};

export default HomepageListingItems;
