import { Slider, TableCell, Typography } from '@mui/material';
import { values } from 'pages/doctor/patients/history/utils';
import { useIsAdmin, useIsDoctor, useIsLaboratory, useIsMobileView } from 'hooks';
import { Result } from 'models/result.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { convertDate } from 'utils/func/Date.func';
import { calculateRatioSlider, displayLimit, hasUsualValues } from './DetailTabPanel.func';
import { formatToLocaleNumber } from 'utils/func/Number.func';

const DetailTabPanelBodyIsValue: FC<{ val: Result; isHighlight: boolean }> = ({ val, isHighlight }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const isDoctor = useIsDoctor();
  const isLaboratory = useIsLaboratory();
  const isAdmin = useIsAdmin();

  return (
    <>
      <TableCell width='200px' colSpan={hasUsualValues(val) ? 3 : 1}>
        <Typography variant='body1' color={val.is_abnormal && (isDoctor || isLaboratory || isAdmin) ? 'error.main' : ''} fontWeight={val.is_abnormal && (isDoctor || isLaboratory || isAdmin || isHighlight) ? 'bold' : ''}>
          {`${formatToLocaleNumber(val.value) || t('results.details.tableResults.label.waitingLabel')}${val.unit ? ` ${val.unit}` : ''}`}
        </Typography>
      </TableCell>
      {!isMobileView && (
        <>
          <TableCell width='300px'>
            {displayLimit(val.limit_min, val.limit_max, val.limit_text, val.limit_html, val.unit)}
            {val.limit_max && val.value && (isDoctor || isLaboratory || isAdmin) && <Slider sx={{ mt: 1 }} disabled defaultValue={calculateRatioSlider(val.limit_min, val.limit_max, val.value)} />}
          </TableCell>

          {val.antecedent !== null && (
            <TableCell>
              <Typography variant='body1'>{values(val.type, val.antecedent.value, val.antecedent.unit)}</Typography>
              <Typography variant='body1'>{convertDate(val.antecedent.sampling_time)}</Typography>
            </TableCell>
          )}
        </>
      )}
    </>
  );
};

export default DetailTabPanelBodyIsValue;
