import i18n from 'i18next';

export enum CurrencyFormat {
  SHORT = '€',
  LONG = 'EUR',
}

export const formatToLocaleNumber = (val: any, digits: boolean = false): string => {
  if (isNaN(val)) {
    return val;
  }
  return val
    ? `${Number(val).toLocaleString(i18n.language, {
        minimumFractionDigits: digits ? 2 : 0,
      })}`
    : '-';
};

export const formatToLocaleCurrency = (number: number, currencyFormat: CurrencyFormat = CurrencyFormat.LONG): string => {
  return number ? `${formatToLocaleNumber(number, true)} ${currencyFormat}` : '-';
};
