import { FC, useRef, useState } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, DialogActions, SvgIcon, Typography } from '@mui/material';
import { ReactComponent as IconClose } from 'resources/icons/close.svg';
import SignatureCanvas from 'react-signature-canvas';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  setOpen: (bool: boolean) => void;
  submitAction: (signature: string) => void;
};

const SignaturePopup: FC<Props> = ({ open, setOpen, submitAction }) => {
  const canvasRef = useRef(null) as React.MutableRefObject<any>;
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  const handleTouchStart = () => {
    if (!touched) {
      setTouched(true);
      setError(false);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        <Box display='flex' sx={{ width: '100%' }} justifyContent='space-between' alignItems='center'>
          <Typography>{t('result_patient.details.questionnaire_sign')}</Typography>
          <IconButton size='small' onClick={() => setOpen(false)}>
            <SvgIcon fontSize='small' component={IconClose} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <SignatureCanvas
          onBegin={handleTouchStart}
          canvasProps={{
            height: '300',
            width: '550',
            style: { border: error ? '3px solid red' : '3px solid #46BFF5', borderRadius: '12px' },
          }}
          ref={canvasRef}
          penColor='black'
          minDistance={3}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          onClick={() => {
            canvasRef.current.clear();
            setTouched(false);
          }}
        >
          {t('common.action.clear')}
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            if (touched) {
              submitAction(canvasRef.current.getTrimmedCanvas().toDataURL());
            } else setError(true);
          }}
        >
          {t('common.action.validateSend')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignaturePopup;
