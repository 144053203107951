import { APIConfiguration, axiosMYLAB } from 'api';
import { AnalysisReference } from 'models/analysis.model';
import { PrescriptionProfile, PrescriptionProfilePostForm } from 'models/prescription-profile.model';
import { BasketDataPrescription, PrescriptionProfileListRequest, PrescriptionProfileListResponse } from 'models/prescription.model';
import { PrescriptionListResponse } from './../models/prescription.model';
import { BaseService } from './base.service';
import { format } from 'date-fns';

class PrescriptionsService extends BaseService {
  async getEditablePrescriptionsProfilesByDoctorId(parameters: PrescriptionProfileListRequest) {
    const { data } = await axiosMYLAB.get<PrescriptionProfileListResponse>(this.apiConfig.PRESCRIPTIONS.GET_EDITABLE(this.getLang()), { params: parameters });

    this.log(`getEditablePrescriptionsProfilesByDoctorId()`);

    return data;
  }

  async getPrescriptionsByPatientId(patientId: string) {
    const { data } = await axiosMYLAB.get<PrescriptionListResponse>(this.apiConfig.PRESCRIPTIONS.GET_BY_PATIENT_ID(this.getLang(), patientId));

    this.log(`getPrescriptionsByPatientId()`);

    return data;
  }

  async postPrescriptionProfile(profile: PrescriptionProfilePostForm) {
    const { data } = await axiosMYLAB.post<PrescriptionProfile>(this.apiConfig.PRESCRIPTIONS.POST_PROFILE(this.getLang()), { ...profile });

    this.log(`postPrescriptionProfile()`);

    return data;
  }

  async postCalculate(id: string | number, options: any[]) {
    const analyses = options.map((item) => {
      return {
        analysis: item.analysis_reference,
        options: item.options?.map((option: { question_tag: any; value: any; other: any }) => {
          return {
            question: option.question_tag,
            value: option.value,
            other: option.other,
          };
        }),
        title: item.title,
        description: item.description,
      };
    });
    const { data } = await axiosMYLAB.post<PrescriptionProfile>(this.apiConfig.PRESCRIPTIONS.POST_CALCULATE(this.getLang(), id), { items: analyses });

    this.log(`postCalculate()`);

    return data;
  }

  async postBasketPrescription(patientId: number, medicalStaffId: number) {
    const now = new Date();
    const { data } = await axiosMYLAB.post(this.apiConfig.PRESCRIPTIONS.POST_BASKET(this.getLang()), {
      patient_id: patientId,
      issuer_id: medicalStaffId,
      prescription_date: format(now, 'yyyy-MM-dd'),
      validity_date: format(now.setMonth(now.getMonth() + 2), 'yyyy-MM-dd'),
    });

    this.log(`postBasketPrescription()`);

    return data;
  }

  async putPrescriptionProfile(id: string | number, profile: PrescriptionProfilePostForm) {
    const { data } = await axiosMYLAB.put<PrescriptionProfile>(this.apiConfig.PRESCRIPTIONS.PUT_PROFILE(this.getLang(), id), { ...profile });

    this.log(`putPrescriptionProfile()`);

    return data;
  }

  async deletePrescriptionProfile(id: string | number) {
    const { data } = await axiosMYLAB.delete<void>(this.apiConfig.PRESCRIPTIONS.DELETE_PROFILE(this.getLang(), id));

    this.log(`deletePrescriptionProfile()`);

    return data;
  }

  async getPrescriptionProfileById(id: string) {
    const { data } = await axiosMYLAB.get<PrescriptionProfile>(this.apiConfig.PRESCRIPTIONS.GET_PROFILE_BY_ID(this.getLang(), id));

    this.log(`getPrescriptionProfileById()`);

    return data;
  }

  async getPrescriptionByBasketId(id: string) {
    const { data } = await axiosMYLAB.get(this.apiConfig.PRESCRIPTIONS.GET_BY_BASKET_ID(this.getLang(), id));

    this.log(`getPrescriptionByBasketId()`);

    return data;
  }

  async postPrescriptionProfileItem(id: string | number, analysis_reference: string) {
    const { data } = await axiosMYLAB.post<PrescriptionProfile>(this.apiConfig.PRESCRIPTIONS.POST_PROFILE_ITEM(this.getLang(), id, analysis_reference));

    this.log(`postPrescriptionProfileItem()`);

    return data;
  }

  async deletePrescriptionProfileItem(id: string | number, analysis_reference: string) {
    const { data } = await axiosMYLAB.delete<PrescriptionProfile>(this.apiConfig.PRESCRIPTIONS.DELETE_PROFILE_ITEM(this.getLang(), id, analysis_reference));

    this.log(`deletePrescriptionProfileItem()`);

    return data;
  }

  async deletePrescriptionProfileItems(id: string | number) {
    const { data } = await axiosMYLAB.delete<PrescriptionProfile>(this.apiConfig.PRESCRIPTIONS.DELETE_PROFILE_ITEMS(this.getLang(), id));

    this.log(`deletePrescriptionProfileItems()`);

    return data;
  }

  async getPrescriptionMotivations() {
    const { data } = await axiosMYLAB.get(this.apiConfig.PRESCRIPTIONS.GET_MOTIVATIONS(this.getLang()));

    this.log(`getPrescriptionMotivations()`);

    return data;
  }

  async postValidBasketPrescription(id: string) {
    const { data } = await axiosMYLAB.post(this.apiConfig.PRESCRIPTIONS.POST_VALID_PRESCRIPTION(this.getLang(), id));

    this.log(`postValidBasketPrescription()`);

    return data;
  }

  async getPrescriptionPdfFile(id: string) {
    const { data } = await axiosMYLAB.get<Blob>(this.apiConfig.PRESCRIPTIONS.GET_PRESCRIPTION_PDF_FILE(this.getLang(), id), { responseType: 'blob' });

    this.log(`getPrescriptionPdfFile()`);

    return data;
  }

  async updatePrescriptionBasketData(id: string | number, basketData: BasketDataPrescription) {
    const { data } = await axiosMYLAB.put(this.apiConfig.PRESCRIPTIONS.PUT_PRESCRIPTION_BASKET(this.getLang(), id), basketData);

    this.log(`updatePrescriptionBasketData()`);

    return data;
  }

  async getPrescriptionFileForDoctor() {
    const { data } = await axiosMYLAB.get(this.apiConfig.PRESCRIPTIONS.GET_PRESCRIPTION_DOCTOR_FILE(this.getLang()), { responseType: 'blob' });

    this.log('getPrescriptionFileForDoctor()');

    return data;
  }

  async deletePrescription(id: string) {
    await axiosMYLAB.delete(this.apiConfig.PRESCRIPTIONS.DELETE_PRESCRIPTION(this.getLang(), id));

    this.log('deletePrescription()');
  }

  async addAnalysisToPrescription(basketId: string, items: AnalysisReference[]) {
    const analyses = items.map((item) => {
      return {
        analysis: item.analysis_reference,
        options: item.options,
        title: item.title,
        description: item.description,
      };
    });
    const data = await axiosMYLAB.post(`${this.apiConfig.PRESCRIPTIONS.ADD_ANALYSIS_TO_PRESCRIPTION(this.getLang(), basketId)}`, { items: analyses });

    this.log(`addAnalysisToPrescription()`);

    return data;
  }

  async deleteAllAnalysisFromPrescription(basketId: string) {
    const data = await axiosMYLAB.delete(`${this.apiConfig.PRESCRIPTIONS.DELETE_ALL_ANALYSIS_TO_PRESCRIPTION(this.getLang(), basketId)}`);

    this.log(`deleteAllAnalysisFromPrescription()`);

    return data;
  }

  async deleteAnalysisById(basketId: string, itemId: string) {
    const data = await axiosMYLAB.delete(`${this.apiConfig.PRESCRIPTIONS.DELETE_ANALYSIS_BY_ID(this.getLang(), basketId, itemId)}`);

    this.log('deleteAnalysisById()');

    return data;
  }

  async postPrescriptionCC(basketId: string, medicalStaffId: number) {
    const data = await axiosMYLAB.post(`${this.apiConfig.PRESCRIPTIONS.POST_CC(this.getLang(), basketId, medicalStaffId)}`);

    this.log('postPrescriptionCC()');

    return data;
  }

  async deletePrescriptionCC(basketId: string, medicalStaffId: number) {
    const data = await axiosMYLAB.delete(`${this.apiConfig.PRESCRIPTIONS.DELETE_CC(this.getLang(), basketId, medicalStaffId)}`);

    this.log('deletePrescriptionCC()');

    return data;
  }

  async searchMedicalStaff(search: string) {
    const data = await axiosMYLAB.get(`${this.apiConfig.PRESCRIPTIONS.SEARCH_MEDICAL_STAFF(this.getLang())}`, { params: { search, size: 9999, page: 1 } });

    this.log('searchMedicalStaff()');

    return data;
  }
}

export default (apiConfig: APIConfiguration) => {
  return new PrescriptionsService(apiConfig);
};
