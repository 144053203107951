import { Box, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DraftGenericFormProps } from 'models/result.patient.model';
import { FC, useState, useEffect } from 'react';

export const DraftGenericMultipleForm: FC<DraftGenericFormProps & { setValue: any }> = ({ setValue, question, register, control }) => {
  const { t } = useTranslation();
  const [checkboxValues, setCheckboxValues] = useState<any[]>(question.answers);

  const handleCheckboxChange = (event: any, value: any) => {
    if (event.target.checked) {
      setCheckboxValues([...checkboxValues, value]);
    } else {
      setCheckboxValues(checkboxValues.filter((v) => v !== value));
    }
    setValue(question.id.toString(), checkboxValues);
  };

  useEffect(() => {
    setValue(question.id.toString(), question.answers);
  }, [question, setValue]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>{question.name}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: `repeat(2, 1fr)`,
            gridGap: '10px',
          }}
        >
          {question.values.map((value, index) => (
            <FormControlLabel
              key={index.toString()}
              label={value.name}
              name={value.id.toString()}
              control={
                <Checkbox
                  checked={checkboxValues.includes(value.id)}
                  onChange={(e) => {
                    handleCheckboxChange(e, value.id);
                    // field.onChange(e.target.checked);
                  }}
                />
              }
            />
            //  <FormControlLabel key={index} control={<Checkbox />} checked={question.answers.includes(value.id)} label={value.name} />
          ))}
        </Box>
      </Grid>
      {question.has_comment && (
        <Grid item xs={4}>
          <TextField
            {...register('comment-' + question.id.toString())}
            multiline
            label={t('results.details.remarksLabel')}
            minRows={4}
            fullWidth
            variant='outlined'
            InputLabelProps={{
              sx: { p: 0 },
            }}
            defaultValue={question.answer_comment ?? ''}
          />
        </Grid>
      )}
    </Grid>
  );
};
