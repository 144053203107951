import { Box, LabelDisplayedRowsArgs, Table, TableBody, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import { useIsMobileView } from 'hooks';
import { FC, ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import GenericTableHead, { GenericHeader } from './GenericTableHeader';

export interface GenericPaginator {
  total: number;
  size: number;
  page: number;
}
interface CommonProps {
  children: ReactNode;
}

type HeaderProps =
  | {
      headers: GenericHeader[];
      noHeaders?: never;
    }
  | { headers?: never; noHeaders: boolean };

type PaginationProps =
  | {
      pagination: GenericPaginator;
      setPagination: (data: GenericPaginator) => void;
      rowsPerPages?: number[];
      noPagination?: never;
    }
  | {
      pagination?: never;
      setPagination?: never;
      rowsPerPages?: never;
      noPagination: boolean;
    };

type GenericTableProps = HeaderProps & PaginationProps;
type Props = CommonProps & GenericTableProps;
const DEFAULT_ROWS_PER_PAGE: number[] = [5, 10, 20, 50];

const GenericTable: FC<Props> = ({ children, headers, pagination, setPagination, rowsPerPages = DEFAULT_ROWS_PER_PAGE, noPagination = false, noHeaders = false }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      if (setPagination && pagination) {
        setPagination({ ...pagination, page: newPage + 1 });
      }
    },
    [pagination, setPagination],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (setPagination && pagination) {
        setPagination({
          ...pagination,
          size: parseInt(event.target.value, 10),
          page: 1,
        });
      }
    },
    [pagination, setPagination],
  );

  const defaultLabelDisplayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) => {
    return `${from}–${to} ${t('utils.table.on')} ${count !== -1 ? count : `${t('utils.table.more_than')} ${to}`}`;
  };

  return (
    <Box sx={{ overflow: 'auto' }}>
      <Table>
        <TableHead>{!noHeaders && <GenericTableHead headers={headers!} />}</TableHead>
        <TableBody>{children}</TableBody>
        {!noPagination && (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={rowsPerPages}
                count={pagination!.total || 0}
                rowsPerPage={pagination!.size}
                page={pagination!.page > 0 ? pagination!.page - 1 : 0}
                labelRowsPerPage={t('utils.table.rows_per_page')}
                labelDisplayedRows={defaultLabelDisplayedRows}
                SelectProps={{
                  native: isMobileView,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </Box>
  );
};

export default GenericTable;
