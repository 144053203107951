import { createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiGrid: {
      defaultProps: {
        spacing: 3,
      },
      // styleOverrides: {
      // 	root: {
      // 		minHeight: 'auto'
      // 	}
      // }
    },
  },
});

export default theme;
