import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useIsMobileView } from 'hooks';
import { Selection } from 'models/record.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TabPanelBody from './DetailTabPanelBody';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  data: any;
  selection: Selection[];
  setSelection: (ref: Selection) => void;
  isHighlight: boolean;
}

const TabPanel: FC<TabPanelProps> = (props) => {
  const { data, children, value, index, selection, setSelection, isHighlight, ...other } = props;
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();

  return (
    <div hidden={value !== index} {...other}>
      {data.disciplines.map((elem: any, idx: number) => {
        return (
          <Accordion defaultExpanded key={idx}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box display='flex' width='100%' alignItems='center' justifyContent='space-between'>
                <Typography variant='h3'>{elem.name}</Typography>
                <Typography variant='subtitle1'>
                  {`${t('results.details.accordionResultLabel', {
                    count: elem.results.length - elem.results.filter((elm: any) => elm.type === 'title').length,
                  })}`}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ margin: 0 }}>
              <TableContainer>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell width='20%'>
                        <Typography variant='subtitle1'>{t('results.details.tableResults.label.analysis')}</Typography>
                      </TableCell>
                      <TableCell width='20%'>
                        <Typography variant='subtitle1'>{t('common.results')}</Typography>
                      </TableCell>
                      {!isMobileView && (
                        <>
                          <TableCell width='20%'>
                            <Typography variant='subtitle1'>{t('common.table.tableHead.usualValues')}</Typography>
                          </TableCell>
                          <TableCell width='20%'>
                            <Typography variant='subtitle1'>{t('results.details.tableResults.label.backgrounds')}</Typography>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {elem.results.map((val: any, key: number) => {
                      return <TabPanelBody isHighlight={isHighlight} key={key} props={props} val={val} />;
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default TabPanel;
