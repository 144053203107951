import { Grid } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { GenericPaginator } from 'components/generic-table';
import FindPatientsForm from 'components/find-patients-form';
import PatientList from 'components/patient-list';
import { useNotification, useQuery } from 'hooks';
import { Patient, PatientsListRequest, PatientsListResponse } from 'models/patients.model';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_PAGINATION_SIZE } from 'utils/Constants';

const Patients: FC = () => {
  const { t } = useTranslation();
  const queryParams = useQuery();
  const { notification } = useNotification();

  const formData: PatientsListRequest = {
    first_name: undefined,
    last_name: undefined,
    cns: undefined,
    gender: undefined,
    birth_name: undefined,
    birth_date: undefined,
  };

  const [form, setForm] = useState(formData);
  const handleFormData = (data: PatientsListRequest) => {
    setForm(data);
    const resetedPaginator = { ...paginator, page: 1 };
    setPaginator(resetedPaginator);
    requestPatientList(resetedPaginator, data);
  };

  const paginatorData: GenericPaginator = {
    total: 0,
    size: DEFAULT_PAGINATION_SIZE,
    page: 1,
  };

  const [paginator, setPaginator] = useState(paginatorData);
  const patientListData: Patient[] | undefined = undefined;
  const [patientList, setPatientList] = useState<Patient[] | undefined>(patientListData);

  const handlePaginatorData = (data: GenericPaginator) => {
    setPaginator(data);
    requestPatientList(data, form);
  };

  const requestPatientList = useCallback(
    (paginator: GenericPaginator, form: PatientsListRequest) => {
      api.patients
        .getPatients(form, paginator.size, paginator.page)
        .then((response: PatientsListResponse) => {
          setPatientList(response.results);
          setPaginator({
            total: response.total,
            size: response.size,
            page: response.page,
          });
        })
        .catch(() => notification(t('patients.notification.error'), 'error'));
    },
    [notification, t],
  );

  useEffect(() => {
    queryParams.get('search') !== '' &&
      api.patients
        .getPatientMultipleSearch(queryParams.toString())
        .then((response) => {
          setPatientList(response.results);
          setPaginator({
            total: response.total,
            size: response.size,
            page: response.page,
          });
        })
        .catch(() => notification(t('patients.notification.error'), 'error'));
  }, [notification, queryParams, t]);

  return (
    <BodyTemplate title={t('bodyTemplate.doctor.patients')}>
      <Grid container columnSpacing={2} rowSpacing={3}>
        <Grid item xs={12}>
          <FindPatientsForm handleFormAction={handleFormData} />
        </Grid>
        <Grid item xs={12}>
          <PatientList patientDetailAction={{ to: 'DOCTOR_PATIENTS_DETAILS' }} patientList={patientList} paginatorData={paginator} handlePaginatorAction={handlePaginatorData} />
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default Patients;
