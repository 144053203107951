import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Skeleton } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { useNotification } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { tryCatch } from 'utils';
import { downloadBlob } from 'utils/func/File.func';

const DocumentDSPDetails: FC = () => {
  const { uuid } = useParams();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(true);
  const [pdfData, setPdfData] = useState<Blob>();
  const { notification } = useNotification();

  useEffect(() => {
    setLoading(true);
    uuid &&
      tryCatch(
        () =>
          api.myDSP.getDSPDocumentPDF(uuid).then((file: Blob) => {
            setPdfData(file);
            setLoading(false);
          }),
        () => notification(t('myDSP.detailDSP.notification.errorDownloadFile'), 'error'),
      );
  }, [notification, t, uuid]);

  const files = new Blob([pdfData as BlobPart], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(files);

  const downloadFile = () => {
    downloadBlob(files, `${uuid}.pdf`);
  };

  return (
    <BodyTemplate title={t('bodyTemplate.patient.myDSP')} backButton>
      <Card>
        <CardHeader
          title={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                pb: 2,
              }}
            >
              {pdfData ? (
                <Button variant='outlined' onClick={downloadFile}>
                  {t('common.action.pdfDownload')}
                </Button>
              ) : (
                <Skeleton variant='rectangular' width='210px' height='48px' sx={{ borderRadius: '100px' }} />
              )}
            </Box>
          }
        />
        <CardContent>
          <Box position='relative' display='flex' justifyContent='center' alignItems='center' minHeight='55vh'>
            {!isLoading ? <iframe id='myIframe' title={uuid} src={fileURL} style={{ border: 0 }} width='100%' height='750px' /> : <CircularProgress />}
          </Box>
        </CardContent>
      </Card>
    </BodyTemplate>
  );
};
export default DocumentDSPDetails;
