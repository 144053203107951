import { ChangeHistory as ChangeHistoryIcon, Done as DoneIcon } from '@mui/icons-material';
import { Box, Button, Card, CardActions, CardContent, List, Stack, Typography } from '@mui/material';
import api from 'api';
import ContextLink from 'components/_base/ContextLink';
import { useNotification } from 'hooks';
import { InvoicesResult } from 'models/invoices.model';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store/reducers';
import { convertDate } from 'utils/func/Date.func';
import { formatToLocaleCurrency } from 'utils/func/Number.func';
import HomepageListingItems from './HomepageListingItems';

const HomepageInvoices: FC = () => {
  const { t } = useTranslation();
  const [invoices, setInvoices] = useState<InvoicesResult[]>([]);
  const currentUser = useSelector((state: GlobalState) => state.session.currentUser);
  const { notification } = useNotification();
  const [error, setError] = useState(false);

  useEffect(() => {
    currentUser &&
      api.invoices
        .getInvoices(3, 1, currentUser.id)
        .then((res) => setInvoices(res.results))
        .catch(() => {
          setError(true);
          notification(t('homepage.invoices.notification.error'), 'error');
        });
  }, [currentUser, notification, t]);

  const InvoiceDetails = (data: InvoicesResult) => {
    return (
      <ContextLink to='INVOICE_DETAILS' params={{ id: parseInt(data.dossier_reference, 10) }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
          component={Button}
          variant='header_light'
        >
          <Typography>{`${convertDate(data.date, false, 'yyyyMMdd')}-${data.dossier_reference}`}</Typography>
          <Stack direction='row' spacing={1}>
            <Typography>
              {formatToLocaleCurrency(data.amount, data.currency)} - {data.status === 'open' ? t('homepage.invoices.noPaid') : t('homepage.invoices.paid')}
            </Typography>
            {data.status === 'open' ? <ChangeHistoryIcon color='error' /> : <DoneIcon color='success' />}
          </Stack>
        </Box>
      </ContextLink>
    );
  };

  return (
    <Card
      sx={{
        display: 'grid',
        width: '100%',
        minHeight: (theme) => theme.spacing(25),
      }}
    >
      <CardContent>
        <Typography variant='h2'>{t('homepage.invoices.title')}</Typography>
        <List dense>
          {invoices && invoices.length > 0 ? (
            invoices.map((result, key) => {
              return <HomepageListingItems title={InvoiceDetails(result)} key={key} />;
            })
          ) : error ? (
            <Typography>{t('homepage.invoices.notification.error')}</Typography>
          ) : (
            <Typography>{t('homepage.invoices.noInvoice')}</Typography>
          )}
        </List>
      </CardContent>
      <CardActions sx={{ alignItems: 'flex-end' }}>
        <Box width='100%' display='flex' justifyContent='flex-end'>
          <ContextLink to='INVOICES_OPEN'>
            <Button variant='contained'>{t('homepage.invoices.buttonLabel')}</Button>
          </ContextLink>
        </Box>
      </CardActions>
    </Card>
  );
};

export default HomepageInvoices;
