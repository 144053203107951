import { Box, Checkbox, TableCell, TableRow, Typography } from '@mui/material';
import { useIsAdmin, useIsDoctor, useIsLaboratory, useIsMobileView } from 'hooks';
import { Selection } from 'models/record.model';
import { Result } from 'models/result.model';
import { FC, useCallback } from 'react';
import { getBodyValue } from './DetailTabPanel.func';

const DetailTabPanelBody: FC<{
  val: Result;
  props: any;
  isHighlight: boolean;
}> = ({ val, props, isHighlight }) => {
  const { selection, setSelection } = props;
  const isMobileView = useIsMobileView();
  const isDoctor = useIsDoctor();
  const isAdmin = useIsAdmin();
  const isLaboratory = useIsLaboratory();

  const getFirstCell = useCallback(
    (val: Result) => {
      return (
        <TableCell
          sx={{
            width: '200px',
            paddingLeft: val.tabulated ? (isMobileView ? (theme) => theme.spacing(2) : (theme) => theme.spacing(4)) : '0',
          }}
        >
          <Box display='flex'>
            {(isDoctor || isLaboratory || isAdmin) && (
              <Checkbox
                sx={{ mr: 1 }}
                checked={selection.findIndex((elm: Selection) => elm.ref === val.analysis_reference) !== -1}
                onClick={() =>
                  setSelection({
                    ref: val.analysis_reference,
                    name: val.name,
                  })
                }
                disabled={val.flag !== 'numeric'}
              />
            )}

            <Box>
              <Typography variant='body1'>{val.name}</Typography>
              {val.analysis_method && <Typography variant='body2'>{val.analysis_method}</Typography>}
              {val.name_hint && <Typography variant='body2'>{val.name_hint}</Typography>}
            </Box>
          </Box>
        </TableCell>
      );
    },
    [isDoctor, isLaboratory, isAdmin, isMobileView, selection, setSelection],
  );

  return (
    val && (
      <TableRow>
        {(val.type === 'comment' || val.type === 'graph' || val.type === 'value' || val.type === 'file' || val.type === 'table') && getFirstCell(val)}
        {getBodyValue(val, isHighlight, isDoctor || isLaboratory || isAdmin)}
      </TableRow>
    )
  );
};

export default DetailTabPanelBody;
