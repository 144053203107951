import { Box, FormControlLabel, Grid, Radio, RadioGroup, SvgIcon, Typography, Button, CardActions } from '@mui/material';
import AddressForm from 'components/address-form';
import { useAuthenticated, useIsMobileView } from 'hooks';
import { Address } from 'models/profile.model';
import { FC, useCallback, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UrgentIcon } from 'resources/icons/urgent.svg';
import { addressToString, isAddress } from 'utils/func/Address.func';
import { Props } from '../index';

type PropsPlaceStep = Props & {
  patientHomeAddress?: Address;
  patientWorkAddress?: Address;
};

const BookingPlaceStep: FC<PropsPlaceStep> = ({ handleState, state, handleBack, handleNext, patientHomeAddress, patientWorkAddress }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const isAuthenticated = useAuthenticated();
  const { register, setValue, handleSubmit, control, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      address: state.address,
    },
    shouldFocusError: true,
  });

  const watchedAddressType = useWatch({ control, name: 'address.type' });

  const onChangeAddressType = useCallback(
    (type: string) => {
      let address;
      switch (type) {
        case 'home':
          address = {
            type: 'home',
            number: patientHomeAddress?.number ?? '',
            street: patientHomeAddress?.street ?? '',
            complement: patientHomeAddress?.complement ?? '',
            city_name: patientHomeAddress?.city ?? '',
            company: patientHomeAddress?.company ?? '',
            zip_code: patientHomeAddress?.zip_code ?? '',
            country_iso: patientHomeAddress?.country_iso ?? '',
          };
          break;
        case 'work':
          address = {
            type: 'work',
            number: patientWorkAddress?.number ?? '',
            street: patientWorkAddress?.street ?? '',
            complement: patientWorkAddress?.complement ?? '',
            city_name: patientWorkAddress?.city ?? '',
            company: patientWorkAddress?.company ?? '',
            zip_code: patientWorkAddress?.zip_code ?? '',
            country_iso: patientWorkAddress?.country_iso ?? '',
          };
          break;
        default:
          address =
            state.address.type === 'other' && isAddress(state.address)
              ? state.address
              : {
                  type: 'other',
                  number: '',
                  street: '',
                  complement: '',
                  city_name: '',
                  company: '',
                  zip_code: '',
                  country_iso: '',
                };
          break;
      }
      return address;
    },
    [patientHomeAddress, patientWorkAddress, state.address],
  );

  useEffect(() => {
    if (patientHomeAddress || patientWorkAddress) setValue('address', onChangeAddressType(watchedAddressType));
  }, [watchedAddressType, setValue, onChangeAddressType, patientHomeAddress, patientWorkAddress]);

  return (
    <form
      onSubmit={handleSubmit((data) => {
        if (!formState.errors.address) {
          handleState(data.address, 'address');
          handleNext();
        }
      })}
    >
      <Grid container justifyContent='space-between'>
        <Grid item sm={12} md={8} mb={2}>
          <Grid item display='flex' alignItems='center' flexDirection='row'>
            <Typography variant='h3' sx={{ wordWrap: 'break-word' }}>
              {t('appointment.book.steps.title')}
            </Typography>
          </Grid>
          <Typography marginTop={isMobileView ? 3 : 0} variant='h2'>
            {t('appointment.book.steps.place.title')}
          </Typography>
          {formState.errors.address?.type && (
            <Typography color={'error'} variant='subtitle2'>
              {t('appointment.book.steps.place.title')}
            </Typography>
          )}
        </Grid>
        {isAuthenticated && (
          <Grid item xs={12} md={4} mb={3}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <SvgIcon
                sx={{
                  color: 'error.main',
                  mr: '5px',
                }}
                component={UrgentIcon}
                fontSize='large'
              />
              <Typography variant='body1'>
                {t('appointment.book.steps.place.warning.block1')}
                <br />
                {t('appointment.book.steps.place.warning.block2')}
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid item sm={12}>
          <RadioGroup name='adress.type' defaultValue={state.address.type}>
            <Grid container>
              {isAuthenticated && (
                <Grid item sm={12} md={6}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {isAddress(patientHomeAddress) && (
                      <FormControlLabel
                        value='home'
                        control={<Radio {...register('address.type', { required: true })} />}
                        label={`${t('appointment.book.steps.place.home')} (
                          ${patientHomeAddress && addressToString(patientHomeAddress)})`}
                      />
                    )}
                    {isAddress(patientWorkAddress) && (
                      <FormControlLabel
                        value='work'
                        control={<Radio {...register('address.type', { required: true })} />}
                        label={`${t('appointment.book.steps.place.work')} (
                          ${patientWorkAddress && addressToString(patientWorkAddress)})`}
                      />
                    )}
                    <FormControlLabel value='other' control={<Radio {...register('address.type', { required: true })} />} label={`${t('appointment.book.steps.place.other')}`} />
                  </Box>
                </Grid>
              )}
              {(watchedAddressType === 'other' || !isAuthenticated) && (
                <Grid item sm={12} md={isAuthenticated ? 6 : 12}>
                  <Grid container>
                    <AddressForm control={control} register={register} formState={formState} isWork={isAuthenticated} value={undefined} />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </RadioGroup>
          <CardActions
            sx={{
              justifyContent: {
                xs: 'space-between',
                md: 'flex-end',
              },
              pt: 2,
            }}
          >
            <Button color='inherit' onClick={handleBack}>
              {t('appointment.book.action_buttons.previous')}
            </Button>
            <Button type='submit' variant='contained'>
              {t('appointment.book.action_buttons.next')}
            </Button>
          </CardActions>
        </Grid>
      </Grid>
    </form>
  );
};

export default BookingPlaceStep;
