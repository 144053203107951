import { Box, Button, Card, CardContent, CardHeader, Checkbox, Divider, FormControlLabel, Grid, SvgIcon, Typography } from '@mui/material';
import ContextLink from 'components/_base/ContextLink';
import { useCurrentUser, useNotification } from 'hooks';
import { Preregistration } from 'models/preregistration.model';
import { Gender } from 'models/profile.model';
import { FC, useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddIcon } from 'resources/icons/add.svg';
import { convertDate } from 'utils/func/Date.func';
import AddPatient from './components/AddPatient';

const PreregistrationStep1: FC<{
  handleNext: () => void;
  setFormData: (data: Preregistration) => void;
  formData: Preregistration | undefined;
}> = ({ handleNext, setFormData, formData }) => {
  const { t } = useTranslation();
  const { notification } = useNotification();

  const appendUser = {
    mylab_id: null,
    gender: Gender.MALE_FULL,
    first_name: '',
    birth_name: '',
    last_name: '',
    birth_date: null,
    mobile_phone: '',
    cns: '',
    email: '',
    confirmemail: '',
    identitynumber: '',
    fileName: [],
    files: [],
  };

  const defaultvalue = {
    users: [appendUser],
    readDocument: false,
  };

  const { handleSubmit, control, formState, reset, setValue, getValues, register, watch, setError, trigger, clearErrors } = useForm<Preregistration>({
    mode: 'onChange',
    defaultValues: formData !== undefined ? formData : defaultvalue,
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: `users`,
  });

  // Add the info of the connected user
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (formData === undefined) {
      currentUser &&
        currentUser !== null &&
        reset({
          users: [
            {
              mylab_id: currentUser.id,
              gender: currentUser.gender === 'm' ? Gender.MALE_FULL : Gender.FEMALE_FULL,
              first_name: currentUser.first_name,
              birth_name: currentUser.birth_name,
              last_name: currentUser.marital_name,
              birth_date: convertDate(currentUser.birth_date, false, 'yyyy-MM-dd'),
              cns: currentUser.cns,
              email: currentUser.email,
              confirmemail: currentUser.email,
              mobile_phone: currentUser.mobile,
              files: [],
              fileName: [],
            },
          ],
        });
    }
  }, [append, currentUser, formData, reset]);

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant='h1' gutterBottom textAlign='center'>
            {t('preregistration.myData.title')}
          </Typography>
        }
      />
      <CardContent>
        <form
          onSubmit={handleSubmit((data) => {
            setFormData(data);
            handleNext();
          })}
        >
          <Grid container spacing={0}>
            {fields.map((item, key) => {
              return (
                <Box key={key}>
                  {key > 0 && <Divider sx={{ my: 2 }} />}
                  <AddPatient
                    key={item.id}
                    currentUser={currentUser}
                    index={key}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    remove={remove}
                    register={register}
                    watch={watch}
                    formState={formState}
                    setError={setError}
                    clearErrors={clearErrors}
                  />
                </Box>
              );
            })}
            <Grid item container xs={12} spacing={0} py={2}>
              <Grid item xs={12} mb={1}>
                <Box
                  display='flex'
                  alignItems='center'
                  onClick={async () => {
                    (await trigger('users')) ? append(appendUser) : notification(t('appointment.book.steps.patients.unknownPatients.add.notification.error'), 'error');
                  }}
                >
                  <SvgIcon component={AddIcon} sx={{ color: 'primary.main', mr: 1, cursor: 'pointer' }} />
                  <Typography variant='body1'>{t('preregistration.myData.addMoreUser')}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={`readDocument`}
                  rules={{ required: true, value: true }}
                  render={({ field }) => (
                    <FormControlLabel
                      sx={{ margin: 0 }}
                      {...field}
                      label={
                        <div>
                          {t('preregistration.myData.form.readAcceptDoc')}&nbsp;
                          <ContextLink to='GDPR'>
                            <Button>{t('preregistration.myData.form.docTitle')}</Button>
                          </ContextLink>
                        </div>
                      }
                      control={<Checkbox required sx={{ mr: 1 }} />}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button type='submit' variant='contained'>
                {t('common.action.submit')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default PreregistrationStep1;
