import { FormControlLabel, MenuItem, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AnalysisQuestion } from 'models/analysis-question.model';
import { FC } from 'react';

export const GenericForm: FC<{
  questions: AnalysisQuestion;
  register?: any;
  errors?: any;
}> = ({ questions, register, errors }) => {
  const { t } = useTranslation();

  switch (questions.type) {
    case 'title':
      return (
        <Typography variant='h3' gutterBottom>
          {questions.question}
        </Typography>
      );
    case 'list':
      return (
        <TextField
          error={errors?.[questions.id.toString()] !== undefined}
          {...register(questions.id.toString(), { required: questions.mandatory })}
          select
          variant='filled'
          fullWidth
          label={questions.question}
          defaultValue={''}
        >
          {questions.choices.map((value: string, key: number) => (
            <MenuItem value={value} key={key}>
              {value}
            </MenuItem>
          ))}
        </TextField>
      );
    case 'boolean':
      return (
        <>
          <Typography color={errors?.[questions.id.toString()] !== undefined ? 'error' : ''}>{questions.question}</Typography>
          <RadioGroup {...register(questions.id.toString(), { required: questions.mandatory })}>
            {/* <RadioGroup row> */}
            <FormControlLabel value={true} control={<Radio />} label={t('common.words.yesBtn')} />
            <FormControlLabel value={false} control={<Radio />} label={t('common.words.noBtn')} />
          </RadioGroup>
        </>
      );
    case 'number':
      return (
        <TextField
          error={errors?.[questions.id.toString()] !== undefined}
          {...register(questions.id.toString(), { required: questions.mandatory })}
          type='number'
          fullWidth
          label={questions.question}
          variant='filled'
        />
      );
    default:
      return null;
  }
};

export default GenericForm;
