import { Grid } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { GenericPaginator } from 'components/generic-table';
import ResultsTable from 'components/results-table';
import { useIsMobileView, useNotification, useQuery } from 'hooks';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_PAGINATION_SIZE } from 'utils/Constants';
import { convertDate } from 'utils/func/Date.func';
import MobileTable from './components/MobileTable';
import PatientFileSearchBar, { SearchFormParams } from './components/PatientFileSearchBar';

const Results: FC = () => {
  const [data, setData] = useState<any[]>([]);
  const [paginationStart, setPaginationStart] = useState(0);
  const [paginationSize, setPaginationSize] = useState(DEFAULT_PAGINATION_SIZE);
  const [paginationTotal, setPaginationTotal] = useState(0);
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const [mobileSearch] = useState(false);
  const query = useQuery();
  const { notification } = useNotification();
  const [isLoading, setLoading] = useState(true);

  const defaultSearchFormValues: SearchFormParams = {
    patientName: '',
    from: null,
    to: null,
    recordReference: query.get('search') ?? '',
    cnsNumber: '',
    status: 0,
    unread: query.get('unread') ? !!query.get('unread') : undefined,
    tagged: query.get('tagged') ? !!query.get('tagged') : undefined,
    abnormal: query.get('abnormal') ? !!query.get('abnormal') : undefined,
    urgent: query.get('urgent') ? !!query.get('urgent') : undefined,
  };

  const [searchFormParams, setSearchFormParams] = useState<SearchFormParams>(defaultSearchFormValues);

  useEffect(() => {
    setLoading(true);
    api.results
      .getFilteredRecords({
        size: paginationSize,
        page: paginationStart,
        patientName: searchFormParams?.patientName,
        from: searchFormParams.from && convertDate(searchFormParams.from, false, 'yyyy-MM-dd'),
        to: searchFormParams.to && convertDate(searchFormParams.to, false, 'yyyy-MM-dd'),
        recordReference: searchFormParams.recordReference,
        cnsNumber: searchFormParams.cnsNumber,
        status: searchFormParams.status === 0 ? null : searchFormParams.status === 1 ? 'partial' : 'complete',
        consulted: !searchFormParams.unread,
        tagged: searchFormParams.tagged,
        abnormal: searchFormParams.abnormal,
        urgent: searchFormParams.urgent,
      })
      .then((res) => {
        setData(res.results);
        setPaginationTotal(res.total);
      })
      .catch(() => {
        notification(t('homepage_doctor.search_sections.notification.error'), 'error');
      })
      .finally(() => setLoading(false));
  }, [notification, paginationSize, paginationStart, searchFormParams, t]);

  const setTagged = useCallback(
    (reference: string) => {
      const idx = data.findIndex((elem: any) => elem.reference === reference);
      let elemToBeToggled = data[idx];
      elemToBeToggled.tagged = !elemToBeToggled.tagged;
      setData([...data]);

      api.results.markAResult(elemToBeToggled.reference);
    },
    [data],
  );

  const handlePaginationChange = (pagination: GenericPaginator) => {
    setPaginationSize(pagination.size);
    setPaginationStart(pagination.page);
    setPaginationTotal(pagination.total);
  };

  const handleSearch = useCallback(
    (formParams: SearchFormParams) => {
      setSearchFormParams(formParams);
    },
    [setSearchFormParams],
  );

  return (
    <BodyTemplate title={t('common.results')}>
      <Grid container>
        {((isMobileView && mobileSearch) || !isMobileView) && (
          <Grid item xs={12}>
            <PatientFileSearchBar defaultSearchFormValues={searchFormParams} onSearch={handleSearch} columnRatio={4} />
          </Grid>
        )}
        {((isMobileView && !mobileSearch) || !isMobileView) && (
          <Grid item xs={12}>
            {!isMobileView ? (
              <ResultsTable
                data={data}
                title={t('result_doctor.table_title')}
                pagination={{
                  page: paginationStart,
                  size: paginationSize,
                  total: paginationTotal,
                }}
                onPaginationChange={handlePaginationChange}
                isLoading={isLoading}
              />
            ) : (
              <MobileTable data={data} setTagged={setTagged} />
            )}
          </Grid>
        )}
      </Grid>
    </BodyTemplate>
  );
};

export default Results;
