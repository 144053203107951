import { APIConfiguration, axiosMYLAB } from 'api';
import { Directory, SearchType } from 'models/directory.model';
import { Cities, Specialities } from 'models/doctor.model';
import { Paginate } from 'models/utils.model';
import { removeEmptyString } from 'utils';
import { BaseService } from './base.service';

class DoctorsService extends BaseService {
  async getDoctors(size: number, page: number, city: string, name: string, speciality: number, type: SearchType) {
    const { data } = await axiosMYLAB.get<Paginate<Directory>>(`${this.apiConfig.DOCTORS.SEARCH(this.getLang())}`, {
      params: removeEmptyString({
        type: type === SearchType.PROFESSIONAL ? 0 : 1,
        city,
        name,
        speciality: speciality !== -1 ? speciality : undefined,
        size,
        page,
      }),
    });

    this.log(`getDoctors()`);

    return data;
  }

  async getSpecialities(lang: string, type?: SearchType) {
    const { data } = await axiosMYLAB.get<Specialities>(`${this.apiConfig.DOCTORS.GET_SPECIALITIES(this.getLang(), type === SearchType.PROFESSIONAL ? 0 : 1)}`);

    this.log(`getSpecialities()`);

    return data;
  }

  async getCities() {
    const { data } = await axiosMYLAB.get<Cities>(`${this.apiConfig.DOCTORS.GET_CITIES(this.getLang())}`);

    this.log(`getCities()`);

    return data;
  }
}

export default (apiConfig: APIConfiguration) => {
  return new DoctorsService(apiConfig);
};
