import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { useTranslation } from 'react-i18next';
import jwt_decode from 'jwt-decode';
import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CONST_ESANTE_ACCESS_TOKEN, CONST_ESANTE_PKCE_TOKEN } from 'utils/Constants';
import { storeTokens } from 'utils/func/eSante.func';

export interface JWT {
  sub: string;
  authorities: string[];
}

const CallbackDispatcher: FC = () => {
  const codeVerifier = localStorage.getItem(CONST_ESANTE_PKCE_TOKEN);
  const { t } = useTranslation();
  const [decoded, setDecoded] = useState<{
    authorities: string[];
    sub: string;
  }>();
  let [searchParams] = useSearchParams();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isCurrent = true;
    if (localStorage.getItem(CONST_ESANTE_ACCESS_TOKEN)) {
      setDecoded(jwt_decode(localStorage.getItem(CONST_ESANTE_ACCESS_TOKEN) || ''));
    } else {
      (async () => {
        setLoading(true);
        await api.ePrescription
          .getToken(searchParams.get('code'), codeVerifier)
          .then(async (response) => {
            if (isCurrent && response.data) {
              const body = await response.data;
              storeTokens(body.access_token, body.refresh_token, body.expires_in);
              setDecoded(jwt_decode(body.access_token));
            } else {
              setError(true);
            }
          })
          .catch(() => setError(true))
          .finally(() => setLoading(false));
      })();
    }

    return () => {
      isCurrent = false;
    };
  }, [codeVerifier, searchParams]);

  return (
    <BodyTemplate title={t('esante.title')}>
      <Card>
        <CardHeader title={error ? t('esante.dispatcher.error') : t('esante.dispatcher.title')} />
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              {loading ? (
                <Typography variant='body1'>{t('esante.dispatcher.loader')}</Typography>
              ) : (
                <>
                  <Typography variant='h1'>{decoded?.authorities}</Typography>
                  <Typography variant='body1'>{decoded?.sub}</Typography>
                </>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </BodyTemplate>
  );
};

export default CallbackDispatcher;
