import { Button, Card, CardContent, Grid } from '@mui/material';
import ContextLink from 'components/_base/ContextLink';
import { Person } from 'models/profile.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getFullName } from 'utils/func/Person.func';
import ProfileCard from './ProfileCard';

const IndexRelativeProfile: FC<{ relativesCurrentUser: Person[] }> = ({ relativesCurrentUser }) => {
  const { t } = useTranslation();

  return (
    <>
      {relativesCurrentUser?.map((relative, key) => {
        return (
          relative.is_relative && (
            <Grid item xs={12} md={6} display='flex' alignItems='stretch' key={key}>
              <ProfileCard title={getFullName(relative)} person={relative} />
            </Grid>
          )
        );
      })}
      <Grid item xs={12} md={6} display='grid' alignItems='stretch'>
        <Card>
          <CardContent
            sx={{
              display: 'grid',
              justifyItems: 'center',
              height: '100%',
              alignContent: 'center',
            }}
          >
            <ContextLink to='PROFILE_ADD_RELATIVE'>
              <Button variant='outlined'>{t('profile.relatives.add.add_relative')}</Button>
            </ContextLink>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default IndexRelativeProfile;
