import { CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, TimeScale, Tooltip } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { ArrayOfDataset, Axis, HistoryData, HistoryValues } from 'models/graph.model';
import { FC } from 'react';
import { Line } from 'react-chartjs-2';
import { colorGenerator } from 'utils';

const GraphHistory: FC<HistoryValues> = ({ historyDataValues }) => {
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, TimeScale);

  var analysisData: ArrayOfDataset = [];
  const yAxes: Axis = {};

  historyDataValues.forEach((data, _) => {
    var historyData: HistoryData = [];

    Object.keys(data.history).forEach((key: string, _) => {
      historyData.push({
        value: parseFloat(data.history[parseInt(key)].value),
        date: data.history[parseInt(key)].last_report_time,
      });
    });

    analysisData.push({
      yAxisID: data.reference,
      label: data.name,
      tension: 0,
      data: historyData.map((el, key) => ({
        x: new Date(el.date),
        y: el.value,
      })),
      backgroundColor: colorGenerator(),
      fill: false,
    });
  });

  // Get all dates from all results and histories
  var getAllDates: Date[] = [];
  analysisData.forEach((analysis) => {
    analysis.data.map((e) => getAllDates.push(e.x));
  });

  // Remove all duplicates date to get min and max dates
  // TODO: Typer la variable
  let unduplicateDates: any[] = getAllDates.filter((c, index) => {
    return getAllDates.indexOf(c) === index;
  });

  // Min and max dates without changing anything
  var maxDate = new Date(Math.max.apply(null, unduplicateDates));
  var minDate = new Date(Math.min.apply(null, unduplicateDates));

  analysisData.forEach((dataset, index) => {
    yAxes[dataset.yAxisID] = {
      display: true,
      position: 'left',
      axis: 'y',
      weight: -index,
      title: {
        display: true,
        text: dataset.label,
        color: dataset.backgroundColor,
      },
    };
  });

  return (
    <Line
      options={{
        scales: {
          xAxis: {
            type: 'time',
            time: {
              unit: 'month',
              displayFormats: {
                month: 'MM/yy',
              },
            },
            // Set the min and max (time) on the graph
            min: minDate.setMonth(minDate.getMonth() - 1),
            max: maxDate.setMonth(maxDate.getMonth() + 1),
          },
          ...yAxes,
        },
      }}
      data={{
        datasets: analysisData,
      }}
    />
  );
};

export default GraphHistory;
