import { Box, Button, Drawer, Grid, IconButton, List, ListItem, SvgIcon, Typography } from '@mui/material';
import api from 'api';
import ContextLink from 'components/_base/ContextLink';
import config from 'config';
import { useNotification, useToggle } from 'hooks';
import { DrupalTypes } from 'models/drupal.model';
import { DataProps } from 'models/news.model';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconPrev } from 'resources/icons/back.svg';
import { ReactComponent as ErrorIcon } from 'resources/icons/error.svg';
import { ReactComponent as IconNext } from 'resources/icons/next.svg';
import theme from 'resources/theme/theme.colors';
import { convertDate } from 'utils/func/Date.func';

const RightDrawer: FC = () => {
  const [isRightDrawerOpen, toggleRightDrawer] = useToggle();
  const [newsData, setNewsData] = useState<DataProps[]>();
  const { t, i18n } = useTranslation();
  const imageSize = 130;
  const [error, setError] = useState(false);
  const { notification } = useNotification();

  useEffect(() => {
    api.bnl
      .getNews()
      .then((res) => {
        const filteredNews: DataProps[] = [];
        res.data.forEach((news) => {
          filteredNews.push({
            news: news,
            file: res.included.find((included) => news.relationships.field_image.data?.id === included.id && included.type === DrupalTypes.file),
          });
        });
        setNewsData(filteredNews);
      })
      .catch(() => {
        setError(true);
        notification(t('homepage.rightDrawer.error'), 'error');
      });
  }, [i18n.language, notification, t]);

  return (
    <Drawer
      variant='permanent'
      anchor='right'
      open={isRightDrawerOpen}
      sx={{ height: '100%', width: 'min-content' }}
      PaperProps={{
        sx: {
          position: 'unset',
          borderLeft: 'unset',
          maxWidth: !isRightDrawerOpen ? 'min-content' : 'inherit',
          p: 4,
          overflowX: 'hidden',
        },
      }}
    >
      <Grid container spacing={0} direction='column' sx={{ flexFlow: 'column', height: error ? '100%' : 'max-content' }}>
        <Grid item display='flex' alignItems={isRightDrawerOpen ? 'center' : 'baseline'}>
          <IconButton onClick={toggleRightDrawer} disabled={error}>
            <SvgIcon
              component={!isRightDrawerOpen ? IconPrev : IconNext}
              sx={{
                fontSize: 16,
                color: `${theme.palette.primary.main}`,
              }}
            />
          </IconButton>
          <Typography variant='h2'>{t('homepage.rightDrawer.title')}</Typography>
        </Grid>
        <Grid item display='flex' alignItems='center' alignSelf='center' height='100%'>
          {error ? (
            <Box display='grid' textAlign='center' justifyItems='center'>
              <ErrorIcon fontSize={24} />
              <Typography variant='caption'>{t('homepage.rightDrawer.error')}</Typography>
            </Box>
          ) : (
            <List>
              {newsData?.map((data, key: number) => (
                <ListItem
                  sx={{
                    justifyContent: isRightDrawerOpen ? 'space-between' : 'center',
                    paddingY: `${theme.spacing(3)}`,
                    borderBottom: isRightDrawerOpen ? `1px solid ${theme.palette.divider}` : '',
                    ':last-child': {
                      borderBottom: 'none',
                    },
                  }}
                  disablePadding
                  key={key}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: !isRightDrawerOpen ? 'row' : 'column',
                      alignItems: 'flex-start',
                      mr: 2,
                      transition: 'width ease-in-out 0.3s, opacity ease-in-out 0.1s',
                      width: !isRightDrawerOpen ? 0 : '320px',
                      opacity: !isRightDrawerOpen ? 0 : 1,
                      maxHeight: `${imageSize}px`,
                    }}
                  >
                    <>
                      <Typography gutterBottom>{convertDate(data.news.attributes.created)}</Typography>
                      <Typography variant='h3' gutterBottom>
                        {data.news.attributes.title}
                      </Typography>
                      <ContextLink to='BIONEXT_PATH' externalDynamicParams={data.news.attributes.path.alias} external>
                        <Button variant='text'>
                          {t('homepage.rightDrawer.readMore')}
                          <SvgIcon
                            component={IconNext}
                            sx={{
                              fontSize: 16,
                              color: `${theme.palette.primary.main}`,
                              marginLeft: `${theme.spacing(1)}`,
                            }}
                          />
                        </Button>
                      </ContextLink>
                    </>
                  </Box>
                  <Box zIndex={1}>
                    {data && (
                      <ContextLink to='BIONEXT_PATH' externalDynamicParams={data.news.attributes.path.alias} external>
                        <img
                          src={`${config.websiteBionextApiUrl}${data?.file?.attributes.uri.url}`}
                          alt={`${t('homepage.rightDrawer.altLabelPicture')}`}
                          width={imageSize}
                          height={imageSize}
                          style={{
                            objectFit: 'cover',
                          }}
                        />
                      </ContextLink>
                    )}
                  </Box>
                </ListItem>
              ))}
            </List>
          )}
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default RightDrawer;
