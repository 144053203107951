import { Button, Card, CardContent, Grid } from '@mui/material';
import api from 'api';
import { useIsMobileView, useNotification } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DetailInvoicesGetFileProps } from '../DetailInvoices';

const DetailInvoicesGetFile: FC<DetailInvoicesGetFileProps> = ({ dossierReference, reference }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const [pdfData, setPdfData] = useState<Blob>();
  const dispatch = useDispatch();
  const { notification } = useNotification();

  useEffect(() => {
    api.invoices
      .getInvoiceFileByReference(dossierReference)
      .then((res) => {
        setPdfData(res);
      })
      .catch(() => notification(t('invoices.details.notification.error'), 'error'));
  }, [dispatch, dossierReference, notification, t]);

  const files = new Blob([pdfData as BlobPart], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(files);

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={12} textAlign='right'>
            <a
              href={fileURL}
              download={`${reference}.pdf`}
              target='_blank'
              rel='noreferrer'
              style={{
                textDecoration: 'none',
                textTransform: 'initial',
              }}
            >
              <Button
                variant='outlined'
                fullWidth={isMobileView}
                onClick={() => {
                  notification(t('invoices.details.notification.successDownload'), 'success');
                }}
              >
                {t('common.action.pdfDownload')}
              </Button>
            </a>
          </Grid>
          <Grid item xs={12}>
            {!isMobileView && <iframe id='myIframe' src={fileURL} title={t('invoices.details.title_iframe')} style={{ border: 0 }} width='100%' height='800px' />}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DetailInvoicesGetFile;
