import { Grid } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { useLocale, useNotification } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import VideoCard from './components/VideoCard';

const BionextTv: FC = () => {
  const [videos, setVideos] = useState([]);
  const { t } = useTranslation();
  const locale = useLocale();
  const { notification } = useNotification();

  useEffect(() => {
    api.videos
      .getVideos(locale)
      .then((res: any) => setVideos(res))
      .catch(() => notification(t('tv.notification.error'), 'error'));
  }, [locale, notification, t]);

  return (
    <BodyTemplate title={t('bodyTemplate.tv')}>
      <Grid container>
        {videos?.map((video, key) => {
          return (
            <Grid item xs={12} md={3} key={key}>
              <VideoCard video={video} />
            </Grid>
          );
        })}
      </Grid>
    </BodyTemplate>
  );
};

export default BionextTv;
