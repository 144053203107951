import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionQuestionnaire } from 'models/questionnaire.model';
import api from 'api';
import GenericForm from './components';

type Props = {
  questionnaire: SectionQuestionnaire;
};

const QuestionnairePdf: FC<Props> = ({ questionnaire }) => {
  const { t } = useTranslation();

  const handlePDFDownload = async () => {
    await api.questionnaires.getQuestionnairePdfFileById(questionnaire.id).then((pdf) => {
      const files = new Blob([pdf], { type: 'application/pdf' });
      window.open(URL.createObjectURL(files));
    });
  };

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={12} display='flex' alignItems='center' justifyContent='space-between'>
            <Grid item xs={9}>
              <Typography variant='h1'>{`${questionnaire.name} - ${t('results.details.validatedLabel')}`}</Typography>
            </Grid>
            <Button variant='outlined' onClick={handlePDFDownload}>
              {t('common.action.pdfDownload')}
            </Button>
          </Grid>
          {questionnaire?.sections.map((section) => (
            <Grid item xs={12} key={section.id}>
              <Typography variant='h2'>{section.name}</Typography>
              {section.questions.map((question) => (
                <Box key={question.id} sx={{ pt: 2 }}>
                  <GenericForm question={question} />
                </Box>
              ))}
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default QuestionnairePdf;
