import { Box, Button, Card, CardContent, Grid, IconButton, SvgIcon, TableCell, TableRow, Typography } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import GenericTable, { GenericPaginator } from 'components/generic-table';
import { GenericHeader } from 'components/generic-table/GenericTableHeader';
import NoResultsTableRow from 'components/no-results-table-row';
import TableContentSkeleton from 'components/table-content-skeleton';
import ContextLink from 'components/_base/ContextLink';
import { useIsMobileView, useNotification } from 'hooks';
import { EudccCertificate } from 'models/eudcc-certificate.model';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReactComponent as CovidCheckLogo } from 'resources/icons/covidcheck_logo.svg';
import { ReactComponent as IconNext } from 'resources/icons/next.svg';
import { GlobalState } from 'store/reducers';
import { DEFAULT_PAGINATION_SIZE } from 'utils/Constants';
import { convertDate } from 'utils/func/Date.func';

const tableHeaders: GenericHeader[] = [
  {
    id: 'certificate',
    label: 'certificates.table.header.certificateNumber',
  },
  { id: 'date', label: 'certificates.table.header.testDate' },
  { id: 'actions', label: undefined },
];

const EUDCCCertificates: FC = () => {
  const { t } = useTranslation();
  const currentUser = useSelector((state: GlobalState) => state.session.currentUser);
  const [data, setData] = useState<EudccCertificate[]>();
  const [loading, setLoading] = useState(true);
  const paginatorData: GenericPaginator = {
    total: 0,
    size: DEFAULT_PAGINATION_SIZE,
    page: 1,
  };
  const [paginator, setPaginator] = useState(paginatorData);
  const isMobileView = useIsMobileView();
  const { notification } = useNotification();

  useEffect(() => {
    setLoading(true);
    currentUser &&
      api.eudccCertificates
        .getEUDCCCertifiates(paginator.size, paginator.page, currentUser?.id)
        .then((res) => {
          setData(res.results);
          setPaginator({
            total: res.total,
            size: res.size,
            page: res.page,
          });
          setLoading(false);
        })
        .catch(() => notification(t('homepage.certificate.notification.error'), 'error'));
  }, [currentUser, notification, paginator.size, paginator.page, t]);

  const handlePaginationChange = (pagination: GenericPaginator) => {
    setPaginator(pagination);
  };

  return (
    <BodyTemplate title={t('bodyTemplate.certificates')}>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <GenericTable
                {...(isMobileView ? { noHeaders: true } : { headers: tableHeaders })}
                {...(data?.length
                  ? {
                      pagination: paginator,
                      setPagination: handlePaginationChange,
                    }
                  : {
                      noPagination: true,
                    })}
              >
                {!loading && data ? (
                  data.length ? (
                    data.map((certificate: any, key: number) => {
                      return (
                        <TableRow
                          hover
                          key={key}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell component='th' scope='row'>
                            <Typography variant={isMobileView ? 'subtitle1' : 'body1'} display='flex' sx={{ fontWeight: certificate.consultation_time ? 'normal' : 'bold' }}>
                              <SvgIcon sx={{ mr: 1 }}>
                                <CovidCheckLogo />
                              </SvgIcon>
                              {t(`homepage.certificate.label.${certificate.report_type}`)}
                            </Typography>
                            {isMobileView && <Typography variant='body1'>{convertDate(certificate.report_time, true)}</Typography>}
                          </TableCell>
                          {!isMobileView && (
                            <TableCell>
                              <Typography variant='body1' sx={{ fontWeight: certificate.consultation_time ? 'normal' : 'bold' }}>
                                {convertDate(certificate.report_time, true)}
                              </Typography>
                            </TableCell>
                          )}
                          <TableCell>
                            <Box width='100%' display='flex' flexDirection='row-reverse'>
                              <ContextLink
                                to='EUDCC_CERTIFICATE_DETAILS'
                                params={{
                                  id: certificate.reference,
                                  fileName: certificate.report.slice(0, -4),
                                }}
                              >
                                {!isMobileView ? (
                                  <Button variant='text'>{t('certificates.table.action')}</Button>
                                ) : (
                                  <IconButton>
                                    <SvgIcon fontSize='small' component={IconNext} />
                                  </IconButton>
                                )}
                              </ContextLink>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <NoResultsTableRow colSpan={tableHeaders.length} noResultText={'common.noResult'} />
                  )
                ) : (
                  <TableContentSkeleton columns={tableHeaders.length} rows={4} />
                )}
              </GenericTable>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default EUDCCCertificates;
