import { APIConfiguration, axiosMYLAB } from 'api';
import { ContactRequest } from './../models/contact-request.model';
import { BaseService } from './base.service';

class ContactService extends BaseService {
  async postContact(requestData: ContactRequest) {
    const { data } = await axiosMYLAB.post<void>(this.apiConfig.CONTACT.POST_CONTACT(this.getLang()), requestData);

    this.log(`postContact()`);

    return data;
  }
}
export default (apiConfig: APIConfiguration) => {
  return new ContactService(apiConfig);
};
