import { FC, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import RoutePaths from 'utils/RoutePaths';
import { useAuthenticated, useContextRedirection, useDisclaimerNeeded } from '../../hooks';
import ContextRedirect from './ContextRedirect';
import api from 'api';
import { useDispatch } from 'react-redux';
import { loginUser } from 'store/actions/session.actions';

const RequireAuth: FC<{ children: JSX.Element }> = ({ children }) => {
  const location = useLocation();
  const isAuthenticated = useAuthenticated();
  const isDisclaimerNeeded = useDisclaimerNeeded();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useContextRedirection();

  const uniqueToken = searchParams.get('unique_token');

  useEffect(() => {
    if (uniqueToken !== null && uniqueToken !== '') {
      api.authentication
        .accessWithUniqueToken(uniqueToken)
        .then(() => {
          dispatch(loginUser());
          searchParams.delete('unique_token');
          setSearchParams(searchParams);
        })
        .catch(() => navigate(RoutePaths['LANDING_PAGE']));
    }
  }, [dispatch, navigate, uniqueToken, searchParams, setSearchParams]);

  if (uniqueToken) {
    return <></>;
  }

  return isAuthenticated ? (
    !isDisclaimerNeeded ? (
      children
    ) : (
      <ContextRedirect to={RoutePaths['DISCLAIMER']} state={{ redirectionUrl: location.pathname.substring(3) }} />
    )
  ) : (
    <ContextRedirect to={RoutePaths['LANDING_PAGE']} state={{ redirectionUrl: location.pathname.substring(3) }} />
  );
};

export default RequireAuth;
