import CloseIcon from '@mui/icons-material/Close';
import { Button, Card, CardActions, CardContent, Dialog, DialogContent, Grid, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import GenericTable from 'components/generic-table';
import { GenericHeader } from 'components/generic-table/GenericTableHeader';
import NoResultsTableRow from 'components/no-results-table-row';
import ContextLink from 'components/_base/ContextLink';
import { isAfter, isEqual } from 'date-fns';
import { useCurrentUser, useNotification } from 'hooks';
import { AppointmentItem } from 'models/appointment.item.model';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { convertDate } from 'utils/func/Date.func';
import AppointmentCancelModal from './Appointment.cancel.modal';

const tableHeaders: GenericHeader[] = [
  {
    id: 'patients',
    label: 'appointment.list.table.patients',
  },
  {
    id: 'address',
    label: 'appointment.list.table.address.title',
  },
  {
    id: 'date',
    label: 'appointment.list.table.date',
  },
  { id: 'actions', label: undefined },
];

const AppointmentsList: FC = () => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const [appointments, setAppointments] = useState<AppointmentItem[]>([]);
  const { notification } = useNotification();
  const [reload, setReload] = useState(false);

  const [modalCancelAppointment, openModalCancelAppointment] = useState(false);
  const [appointmentToCancel, setAppointementToCancel] = useState<AppointmentItem>();

  const formatAddress = useCallback(
    (address: any) => {
      const type = address.type === 'home' ? t('appointment.list.table.address.home') : address.type === 'work' ? t('appointment.list.table.address.work') : t('appointment.list.table.address.other');
      const formattedAddress = [
        [address.number, address.street, address.zip_code].filter((elem) => elem !== null).join(', '),
        /* @ts-ignore */
        [address.city_name, t(`countries.${address.country_iso}`)].join(' '),
      ].join(' - ');
      return `(${type}) ${formattedAddress}`;
    },
    [t],
  );

  useEffect(() => {
    if (currentUser) {
      api.appointments
        .getAppointments(currentUser.id)
        .then((res: any) => {
          setAppointments(
            res
              .filter((appointment: any) => isAfter(new Date(appointment.date_time_from), new Date()) || isEqual(new Date(appointment.date_time_from), new Date()))
              .map((appointment: any) => ({
                id: appointment.id,
                patientsNames: appointment.patients.map((patient: any) => patient?.formatted_name ?? null ),
                address: appointment.address,
                date: convertDate(appointment.date_time_from, false, 'Pp'),
              })),
          );
        })
        .catch(() => notification(t('appointment.list.notification.listing.error'), 'error'));
    }
  }, [currentUser, notification, t, reload]);

  useEffect(() => {
    if (appointmentToCancel) {
      openModalCancelAppointment(true);
    } else {
      openModalCancelAppointment(false);
    }
  }, [appointmentToCancel]);

  const handleAppointmentCancelationModal = async (appointement: AppointmentItem) => {
    setAppointementToCancel(appointement);
  };

  const handleCancelationModalClose = () => {
    setAppointementToCancel(undefined);
    setReload(!reload);
  };

  return (
    <BodyTemplate title={t('homepage.appointment.title')}>
      <Grid container>
        <Grid item xs={12} md={7}>
          <Card>
            <CardContent>
              <GenericTable headers={tableHeaders} noPagination>
                {appointments.length > 0 ? (
                  appointments.map((appointment, index) => (
                    <TableRow hover key={index}>
                      <TableCell>
                        <Typography variant='body1'>{appointment.patientsNames.join(', ')}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='body1'>{formatAddress(appointment.address)}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='body1'>{appointment.date}</Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleAppointmentCancelationModal(appointment)}>
                          <CloseIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <NoResultsTableRow colSpan={tableHeaders.length} noResultText={'common.noResult'} />
                )}
              </GenericTable>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={5}>
          <Card>
            <CardContent>
              <Typography variant='h1'>{t('appointment.list.book.title')}</Typography>
              <Typography variant='body1' sx={{ my: 2 }}>
                {t('appointment.list.book.description')}
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <ContextLink to='BOOK_APPOINTMENT'>
                <Button variant='contained' fullWidth>
                  {t('appointment.list.book.button')}
                </Button>
              </ContextLink>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      {appointmentToCancel && (
        <Dialog open={modalCancelAppointment} onClose={handleCancelationModalClose} fullWidth>
          <DialogContent>
            <AppointmentCancelModal appointment={appointmentToCancel} closeModal={handleCancelationModalClose} />
          </DialogContent>
        </Dialog>
      )}
    </BodyTemplate>
  );
};

export default AppointmentsList;
