import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import { useIsMobileView } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface FindFormProps {
  specialities: { id: number; label: string }[];
  cities: { label: string }[];
  isSubmitting: boolean;
}

const FindForm: FC<FindFormProps> = ({ specialities, cities, isSubmitting }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const [specialityInput, setSpecialityInput] = useState('');
  const [cityInput, setCityInput] = useState('');
  const { register, reset, setValue } = useFormContext();

  useEffect(() => {
    setSpecialityInput('');
    setCityInput('');
  }, [specialities]);

  return (
    <Grid container spacing={isMobileView ? 1 : 3}>
      <Grid item xs={12} md={3}>
        <Autocomplete
          disablePortal
          inputValue={specialityInput}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          onInputChange={(event, value, reason) => {
            if (event && event.type === 'blur') {
              setSpecialityInput('');
            } else if (reason !== 'reset') {
              setSpecialityInput(value);
            }
          }}
          onChange={(_, value) => {
            setValue('speciality', value?.id ?? -1);
            setSpecialityInput(value?.label ?? '');
          }}
          options={specialities}
          renderInput={(params) => {
            return <TextField {...params} variant='filled' label={t('findDoctor.form.speciality')} name='speciality' />;
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          variant='filled'
          label={t('findDoctor.form.name')}
          {...register('name')}
          inputProps={{
            name: 'name',
            id: 'name',
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Autocomplete
          disablePortal
          inputValue={cityInput}
          {...register('city')}
          options={cities}
          onInputChange={(event, value, reason) => {
            if (event && event.type === 'blur') {
              setCityInput('');
            } else if (reason !== 'reset') {
              setCityInput(value);
            }
          }}
          onChange={(_, value) => {
            setCityInput(value?.label ?? '');
          }}
          renderInput={(params) => <TextField {...params} variant='filled' label={t('findDoctor.form.city')} name='city' />}
          // TODO fix min width
          fullWidth
        />
      </Grid>
      <Grid item xs={12} display='flex' alignItems='center' justifyContent={isMobileView ? 'right' : 'none'}>
        <Button
          variant='outlined'
          disabled={isSubmitting}
          onClick={() => {
            reset();
            setSpecialityInput('');
          }}
        >
          {t('findDoctor.searchBar.actions.resetFilter')}
        </Button>
        <Button type='submit' variant='contained' disabled={isSubmitting} sx={{ marginLeft: (theme) => theme.spacing(1) }}>
          {t('common.action.search')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default FindForm;
