import { Button, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useIsMobileView } from 'hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  handleBack: () => void;
  handleNext: () => void;
  submit: (atCenter: boolean) => void;
};

const PreregistrationStep2: FC<Props> = ({ handleBack, handleNext, submit }) => {
  const isMobileView = useIsMobileView();
  const { t } = useTranslation();

  return (
    <Grid container justifyContent='center'>
      <Grid item xs={12}>
        <Typography sx={{ pb: 2 }}>{t('preregistration.locationChoice.subtitle')}</Typography>
        <ToggleButtonGroup
          orientation={isMobileView ? 'vertical' : 'horizontal'}
          exclusive
          fullWidth
          value={null}
          onChange={(_, val) => {
            handleNext();
            submit(val);
          }}
        >
          <ToggleButton value={true} sx={{ backgroundColor: 'white', height: 200 }}>
            <Typography fontSize={20}>{t('preregistration.locationChoice.atCenter')}</Typography>
          </ToggleButton>
          <ToggleButton value={false} sx={{ backgroundColor: 'white', height: 200 }}>
            <Typography fontSize={20}>{t('preregistration.locationChoice.notAtCenter')}</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <Button onClick={handleBack}>{t('common.action.previous')}</Button>
      </Grid>
    </Grid>
  );
};

export default PreregistrationStep2;
