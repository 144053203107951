import { Card, CardContent, Grid, Step, StepLabel, Stepper, Typography } from '@mui/material';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import api from 'api';
import { useAuthenticated, useIsMobileView, useNotification } from 'hooks';
import { Preregistration as PreregistrationProps, PreregistrationResponse, PreregistrationUserData } from 'models/preregistration.model';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { convertDate } from 'utils/func/Date.func';
import PreregistrationStep1 from './PreregistrationStep1';
import PreregistrationStep2 from './PreregistrationStep2';
import PreregistrationStep3 from './PreregistrationStep3';
import ConnectAccountDialog from 'components/connect-account-dialog';
import RoutePaths from 'utils/RoutePaths';

const Preregistration: FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const isAuthenticated = useAuthenticated();
  const [formData, setFormData] = useState<PreregistrationProps>();
  const { notification } = useNotification();
  const [loading, setLoading] = useState(false);
  const [atCenter, setAtCenter] = useState<boolean>();
  const [tokenResponseList, setTokenResponseList] = useState<PreregistrationResponse[]>([]);

  const sendCorrectData = useCallback(
    (atCenter: boolean, data?: PreregistrationProps) => {
      const arrayOfUser: PreregistrationUserData[] = [];

      if (formData) {
        if (data?.users !== undefined) {
          formData.users &&
            formData.users.forEach((user) => {
              const files: { name: string; data: string }[] = [];
              user.fileName.forEach((el: string, key: number) => {
                files.push({ name: el, data: user.files[key] });
              });

              arrayOfUser.push({
                mylab_id: user.mylab_id,
                first_name: user.first_name,
                last_name: user.last_name,
                birth_name: user.birth_name,
                birth_date: convertDate(user.birth_date, false, 'yyyy-MM-dd'),
                gender: user.gender,
                matricule: user.cns,
                mobile_phone: user.mobile_phone,
                email: user.email,
                identity_number: user.identitynumber,
                files: files,
                is_at_center: atCenter ?? false,
              });
            });
        }
        return arrayOfUser;
      }
    },
    [formData],
  );

  const submitHandler = useCallback(
    (atCenter: boolean) => {
      setAtCenter(atCenter);
      const userData = sendCorrectData(atCenter, formData);

      if (userData) {
        setLoading(true);
        api.preregistration
          .preregister(userData)
          .then((response) => setTokenResponseList(response))
          .catch(() => {
            notification(t('preregistration.validation.notification.error'), 'error');
            setTokenResponseList([]);
          })
          .finally(() => setLoading(false));
      }
    },
    [notification, t, formData, sendCorrectData],
  );

  const steps: { title: string; component: JSX.Element }[] = useMemo(() => {
    const handleNext = () => {
      setCompleted((completed) => {
        const c = [...completed];
        c[activeStep] = true;
        return c;
      });
      setActiveStep((activeStep) => (activeStep >= steps.length - 1 ? activeStep : activeStep + 1));
    };

    const handleBack = () => {
      setActiveStep((activeStep) => activeStep - 1);
    };

    const navigationProps = { handleNext, handleBack };

    const stepper = [
      {
        title: t('preregistration.myData.title'),
        component: <PreregistrationStep1 {...navigationProps} setFormData={setFormData} formData={formData} />,
      },
      {
        title: t('preregistration.locationChoice.title'),
        component: <PreregistrationStep2 {...navigationProps} submit={submitHandler} />,
      },
      {
        title: t('preregistration.validation.title'),
        component: <PreregistrationStep3 loading={loading} tokenResponseList={tokenResponseList} atCenter={atCenter ?? false} />,
      },
    ];

    return stepper;
  }, [activeStep, formData, atCenter, t, setFormData, submitHandler, loading, tokenResponseList]);

  const [completed, setCompleted] = useState<boolean[]>(new Array(steps.length).fill(false));

  return (
    <BodyTemplate title={t('bodyTemplate.preregistration')} backButton={!isAuthenticated}>
      <Grid container justifyContent='center'>
        {activeStep === 0 && !isAuthenticated && (
          <>
            {!isMobileView && <Grid item md={3} />}
            <ConnectAccountDialog redirect={RoutePaths['PREREGISTRATION']} />
            {!isMobileView && <Grid item md={3} />}
          </>
        )}
        {activeStep === 0 && (
          <>
            {!isMobileView && <Grid item md={3} />}
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography
                    textAlign='center'
                    lineHeight={2}
                    dangerouslySetInnerHTML={{
                      __html: t('preregistration.disclaimer'),
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
            {!isMobileView && <Grid item md={3} />}
          </>
        )}
        <Grid item xs={12}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((step, key) => (
              <Step key={key} completed={completed[key]}>
                <StepLabel>{isMobileView ? '' : step.title}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={12} md={6}>
          {steps[activeStep].component}
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default Preregistration;
