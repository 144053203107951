import { Box, Button, Card, CardContent, CardHeader, Grid, Tooltip, Typography } from '@mui/material';
import ContextLink from 'components/_base/ContextLink';
import { useTranslation } from 'react-i18next';
import { Prescription } from 'models/prescription.model';
import { FC, Fragment } from 'react';
import { LoadingButton } from '@mui/lab';
import { formatToLocaleCurrency } from 'utils/func/Number.func';

const RecapCompletionSelect: FC<{
  selectedPrescription: Prescription;
  idPrescription: number;
  isSaving: boolean;
  onSubmit: any;
}> = ({ selectedPrescription, idPrescription, isSaving, onSubmit }) => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant='h2' sx={{ pb: 2 }}>
            {t('prescriptions_making.step2.yourSelection.title')}
          </Typography>
        }
      />
      <CardContent>
        <Grid container spacing={1}>
          {selectedPrescription && (
            <>
              <Grid item xs={12}>
                <Box border='1px solid red' display='flex' justifyContent='center' sx={{ m: 1, p: 1 }}>
                  <Typography variant='h6' fontWeight='bold' color='error'>
                    {selectedPrescription.fasting
                      ? t('prescriptions_making.step2.verifyValidatePrescription.form.details.fastingPatientYes')
                      : t('prescriptions_making.step2.verifyValidatePrescription.form.details.fastingPatientNo')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {selectedPrescription.items.filter((el) => (el.price_state > 0 || (el.price_state === 0 && el.price_patient === 0 && el.price_patient_hn === 0)) && !el.do_not_bill).length > 0 && (
                  <Box sx={{ pt: 2 }}>
                    <Typography variant='h3' gutterBottom>
                      {t('prescriptions_making.step2.yourSelection.refundedAnalysis')}
                    </Typography>
                    {selectedPrescription.items
                      .filter((el) => (el.price_state > 0 || (el.price_state === 0 && el.price_patient === 0 && el.price_patient_hn === 0)) && !el.do_not_bill)
                      .map((item, key) => {
                        return (
                          <Fragment key={key}>
                            <Box display='flex' alignItems='center' justifyContent='space-between'>
                              <Typography>{item.title}</Typography>
                            </Box>
                          </Fragment>
                        );
                      })}
                  </Box>
                )}
                {selectedPrescription.items.filter((el) => el.do_not_bill).length > 0 && (
                  <Box sx={{ pt: 2 }}>
                    <Typography variant='h3' gutterBottom>
                      {t('prescriptions_making.step2.yourSelection.refundedAnalysisLNS')}
                    </Typography>
                    {selectedPrescription.items
                      .filter((el) => el.do_not_bill)
                      .map((item, key) => {
                        return (
                          <Fragment key={key}>
                            <Box display='flex' alignItems='center' justifyContent='space-between'>
                              <Typography>{item.title}</Typography>
                            </Box>
                          </Fragment>
                        );
                      })}
                  </Box>
                )}
                {selectedPrescription.items.filter((el) => el.price_patient > 0 || el.price_patient_hn > 0).length > 0 && (
                  <Box sx={{ pt: 2 }}>
                    <Typography variant='h3' gutterBottom>
                      {t('prescriptions_making.step2.yourSelection.toPayAnalysis')}
                    </Typography>
                    {selectedPrescription.items
                      .filter((el) => el.price_patient > 0)
                      .map((item, key) => {
                        return (
                          <Fragment key={key}>
                            <Box display='flex' alignItems='center' justifyContent='space-between'>
                              <Typography>{item.title}</Typography>
                              <Typography sx={{ width: '140px' }} textAlign='end' alignSelf='baseline'>
                                {formatToLocaleCurrency(item.price_patient, selectedPrescription.currency)}
                              </Typography>
                            </Box>
                          </Fragment>
                        );
                      })}
                    {selectedPrescription.items
                      .filter((el) => el.price_patient_hn > 0)
                      .map((item, key) => {
                        return (
                          <Fragment key={key}>
                            <Box display='flex' alignItems='center' justifyContent='space-between'>
                              <Typography>{item.title}</Typography>
                              <Tooltip enterTouchDelay={0} placement='left' title={t('prescriptions_making.step2.yourSelection.hn_info')}>
                                <Typography sx={{ textDecoration: 'underline' }} textAlign='end' alignSelf='baseline' fontSize={14}>
                                  HN
                                </Typography>
                              </Tooltip>
                            </Box>
                          </Fragment>
                        );
                      })}
                  </Box>
                )}
              </Grid>
            </>
          )}
          <Grid item xs={12} mt={2}>
            <LoadingButton onClick={onSubmit} variant='contained' fullWidth loading={isSaving}>
              {t('prescriptions_making.step2.yourSelection.submitBtn')}
            </LoadingButton>
          </Grid>
          <Grid item xs={12}>
            <ContextLink to='DOCTOR_PRESCRIPTION_DETAILS' params={{ id: idPrescription }}>
              <Button variant='outlined' fullWidth>
                {t('prescriptions_making.step2.yourSelection.modifyBtn')}
              </Button>
            </ContextLink>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RecapCompletionSelect;
