import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import fr from './fr.json';
import de from './de.json';
import pt from './pt.json';

const resources = {
  fr: { translation: fr },
  en: { translation: en },
  de: { translation: de },
  pt: { translation: pt },
};

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n.use(initReactI18next).init({
  returnNull: false,
  load: 'languageOnly',
  fallbackLng: ['fr', 'en'],
  resources,
  interpolation: {
    escapeValue: false,
  },
});

export const availableLanguages = Object.keys(resources);
export type TranslationMessages = typeof fr;

export default i18n;
