import theme_color from 'resources/theme/theme.colors';

import { alpha, createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: theme_color.palette.text.primary,
          padding: '0',
          '&.Mui-checked': {
            color: theme_color.palette.primary.main,
          },
          '&.Mui-disabled': {
            color: alpha(theme_color.palette.text.disabled, theme_color.palette.action.disabledOpacity),
          },
        },
      },
    },
  },
});

export default theme;
