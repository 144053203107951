import { Gender } from 'models/profile.model';

export type AppointmentPatient = {
  patient_id?: string | null;
  cns?: string;
  birth_date?: string | null | Date | undefined;
  birth_name?: string;
  gender?: string;
  last_name?: string;
  first_name: string;
  phone?: string;
  mobile_phone?: string;
  email?: string;
  fasting: boolean;
  medical_acts: string[];
  // prescriptions: { file_name?: string; file_content: string; file_type?: string }[];
  prescriptions: any[];
  comment?: string;
};

export type Appointment = {
  creator:
    | {
        last_name: string | undefined;
        first_name: string | undefined;
        phone?: string;
        mobile_phone?: string;
        email?: string;
      }
    | undefined;
  comment: string;
  slot: string;
  address: {
    type: string; // home | other?
    number: string;
    street: string;
    complement: string;
    city_name: string;
    company: string;
    zip_code: string;
    country_iso: string;
  };
  patients: AppointmentPatient[];
  prescription_id: number | undefined;
};

export const basicUnknownPatient: AppointmentPatient = {
  patient_id: null,
  cns: '',
  birth_date: null,
  birth_name: '',
  gender: Gender.FEMALE,
  last_name: '',
  first_name: '',
  phone: '',
  mobile_phone: '',
  email: '',
  fasting: false,
  medical_acts: [],
  prescriptions: [],
  comment: '',
};
