import { Marker, useGoogleMap } from '@react-google-maps/api';
import { Clusterer } from '@react-google-maps/marker-clusterer';
import { FC, useEffect } from 'react';

type Props = {
  position: { lat: number; lng: number };
  opened: boolean;
  index: number;
  handleOpen: (index: number) => void;
  clusterer?: Clusterer;
};

const MapMarker: FC<Props> = ({ position, opened, handleOpen: _handleOpen, index, clusterer }) => {
  const map = useGoogleMap();

  useEffect(() => {
    if (opened && map) {
      map.panTo(position);
    }
  }, [map, opened, position]);

  const handleOpen = () => {
    _handleOpen(index);
  };

  return <Marker position={position} onClick={handleOpen} animation={opened ? google.maps.Animation.BOUNCE : undefined} clusterer={clusterer} />;
};

export default MapMarker;
