import { Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { useIsPatient, useIsRelative, useRelatives } from 'hooks';
import { Relation, RelationUsualInfo, ShortenPerson } from 'models/profile.model';
import { getFullName } from 'utils/func/Person.func';
import RoutePaths from 'utils/RoutePaths';
import ProfileCard from '../ProfileCard';
import AccessCard from '../shared/AccessCard';
import { useTranslation } from 'react-i18next';

const MyProfile: FC<{ currentUser: ShortenPerson }> = ({ currentUser }) => {
  const { t } = useTranslation();
  const relatives = useRelatives();
  const isPatient = useIsPatient();
  const isRelative = useIsRelative();

  const grantees: RelationUsualInfo[] = [];
  const grantors: RelationUsualInfo[] = [];

  const getGrantees = relatives && relatives?.grantors;
  const getGrantors = relatives && relatives?.grantees;

  getGrantees &&
    getGrantees?.map((granteeInfo: Relation) => {
      const { grantee, id } = granteeInfo;
      return (
        grantees &&
        grantees.push({
          id: grantee.id,
          invitationId: id,
          first_name: grantee.first_name,
          last_name: grantee.last_name,
          birth_date: grantee.birth_date,
        })
      );
    });

  getGrantors &&
    getGrantors?.map((grantorInfo: Relation) => {
      const { grantor, id } = grantorInfo;
      return (
        grantors &&
        grantors.push({
          id: grantor.id,
          invitationId: id,
          first_name: grantor.first_name,
          last_name: grantor.last_name,
          birth_date: grantor.birth_date,
        })
      );
    });

  const profileActions: {
    label: string;
    routePath: keyof typeof RoutePaths;
  }[] = !isRelative
    ? [
        {
          label: t('profile.actions.modify.data'),
          routePath: 'PROFILE_UPDATE',
        },
      ]
    : [];

  return (
    <>
      <Grid item xs={12} md={6}>
        {currentUser && <ProfileCard title={getFullName(currentUser)} person={currentUser} actions={profileActions} />}
      </Grid>
      {isPatient && !isRelative && (
        <Grid item xs={6} display={{ xs: 'none', md: 'block' }}>
          <Grid container rowSpacing={3}>
            <Grid item xs={12}>
              <AccessCard title={<Typography variant='h2'>{t('profile.results.access_mine')}</Typography>} accessers={grantors} />
            </Grid>

            <Grid item xs={12}>
              <AccessCard title={<Typography variant='h2'>{t('profile.results.access_my_relatives')}</Typography>} accessers={grantees} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default MyProfile;
