import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Collapse, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import AnalysisAttributes from 'components/analysis-attributes';
import { AnalysisDataProps } from 'models/analysis.model';
import { FC } from 'react';

const AnalysisData: FC<AnalysisDataProps> = ({ analysis, open, handleChangeOpen, keyOpen }) => {
  const toggleOpenCollapse = () => {
    open &&
      handleChangeOpen(
        open.map((element: boolean, i: number) => {
          return i === keyOpen ? !element : element;
        }),
      );
  };

  return (
    <>
      <TableRow hover onClick={() => toggleOpenCollapse()}>
        <TableCell
          size='small'
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: '0 !important',
            cursor: 'pointer',
          }}
        >
          <Typography sx={{ overflowWrap: 'anywhere' }}>{analysis.name}</Typography>
          <IconButton size='small'>{open && open[keyOpen] ? <RemoveIcon /> : <AddIcon />}</IconButton>
        </TableCell>
      </TableRow>
      <TableRow sx={{ border: '0 !important', p: 0 }}>
        <TableCell size='small' sx={{ p: 0 }}>
          <Collapse in={open && open[keyOpen]} timeout='auto' unmountOnExit>
            <AnalysisAttributes analysis={analysis} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default AnalysisData;
