import { Box, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GenericFormProps } from 'models/result.patient.model';
import { FC } from 'react';

export const GenericMultipleForm: FC<GenericFormProps> = ({ question }) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>{question.name}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: `repeat(2, 1fr)`,
            gridGap: '10px',
          }}
        >
          {question.values.map((value, index) => (
            <FormControlLabel key={index} control={<Checkbox />} checked={question.answers.includes(value.id)} label={value.name} sx={{ ml: 0 }} disabled />
          ))}
        </Box>
      </Grid>
      {question.has_comment && (
        <Grid item xs={4}>
          <TextField
            multiline
            label={t('results.details.remarksLabel')}
            minRows={4}
            fullWidth
            variant='outlined'
            InputLabelProps={{
              sx: { p: 0 },
            }}
            value={question.answer_comment ?? ''}
            disabled
          />
        </Grid>
      )}
    </Grid>
  );
};
