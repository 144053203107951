import { Box, Button, Grid, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useIsAdmin, useIsMobileView, useNotification } from 'hooks';
import InfoBox from './components/InfoBox';
import SearchBar from './components/SearchBar';
import { ReactComponent as IconAbnormal } from 'resources/icons/anormaux.svg';
import { ReactComponent as IconFavorite } from 'resources/icons/favorite.svg';
import { ReactComponent as IconUrgent } from 'resources/icons/urgent.svg';
import { ReactComponent as IconCatalogAnalyse } from 'resources/icons/catalogue_analyse.svg';
import { ReactComponent as IconPatients } from 'resources/icons/patients.svg';
import { ReactComponent as IconPrescription } from 'resources/icons/prescription.svg';
import { ReactComponent as IconResultat } from 'resources/icons/resultat.svg';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { GenericPaginator } from 'components/generic-table';
import ResultsTable from 'components/results-table';
import { DEFAULT_PAGINATION_SIZE, PRIORITY } from 'utils/Constants';
import CardButton from './components/CardButton';
import MobileChart from './components/MobileChart';
import { useTranslation } from 'react-i18next';

const Home: FC = () => {
  const isMobileView = useIsMobileView();
  const { t } = useTranslation();
  const [recordStats, setRecordStats] = useState<{
    tagged: number;
    abnormal: number;
    urgent: number;
  }>({ tagged: 0, abnormal: 0, urgent: 0 });
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [paginationStart, setPaginationStart] = useState(0);
  const [paginationSize, setPaginationSize] = useState(DEFAULT_PAGINATION_SIZE);
  const [paginationTotal, setPaginationTotal] = useState(0);
  const { notification } = useNotification();
  const isAdmin = useIsAdmin();

  const getRecordStats = useCallback(() => {
    api.users
      .getRecordStats()
      .then((res) => {
        setRecordStats(res);
      })
      .catch(() => notification(t('homepage_doctor.results_graph.notification.error'), 'error'));
  }, [notification, t]);

  useEffect(() => {
    getRecordStats();
  }, [getRecordStats]);

  useEffect(() => {
    setLoading(true);
    api.results
      .getFilteredRecords({
        size: paginationSize,
        page: paginationStart,
        consulted: false,
      })
      .then((res) => {
        setData(res.results);
        setPaginationTotal(res.total);
      })
      .catch(() => notification(t('homepage_doctor.search_sections.notification.error'), 'error'))
      .finally(() => setLoading(false));
  }, [notification, paginationSize, paginationStart, t]);

  const unreadFoldersAbnormal = useMemo(() => {
    return data.filter((elem) => elem.abnormal_result && elem.consultation_time === null).length > 0;
  }, [data]);

  const unreadFoldersUrgent = useMemo(() => data.filter((elem) => elem.priority === PRIORITY.URGENT && elem.consultation_time === null).length > 0, [data]);

  const markAllResultsAsRead = () => {
    setLoading(true);
    api.results
      .markAllResultsRead()
      .then(() => {
        getRecordStats();
        notification(t('homepage.doctor.notifications.markAllReadSuccess'), 'success');
      })
      .catch(() => notification(t('homepage.doctor.notifications.markAllReadError'), 'error'))
      .finally(() => setLoading(false));
  };

  const handlePaginationChange = (pagination: GenericPaginator) => {
    setPaginationSize(pagination.size);
    setPaginationStart(pagination.page);
    setPaginationTotal(pagination.total);
  };

  return (
    <BodyTemplate title={t('bodyTemplate.dashboard')} hideHeaderOnMobile>
      <Grid container spacing={2} justifyContent='center'>
        {isMobileView ? (
          // Mobile View
          <MobileChart taggedCount={recordStats.tagged} abnormalCount={recordStats.abnormal} urgentCount={recordStats.urgent} unreadCount={data.length}></MobileChart>
        ) : (
          // Desktop View
          <>
            <Grid item xs={10} textAlign='center' marginY={5}>
              <Typography
                variant='h2'
                mb={2}
                sx={{ b: { color: 'primary.main' } }}
                dangerouslySetInnerHTML={{
                  __html: t('homepage_doctor.title'),
                }}
              />
              <SearchBar />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent='center'>
                <InfoBox
                  icon={IconUrgent}
                  text={`${t('common.results')}<b> ${t('homepage_doctor.results_graph.urgent')}</b>`}
                  num={recordStats.urgent}
                  color='graph.error'
                  unreadFolders={unreadFoldersUrgent}
                  to='RESULTS'
                  search={{ urgent: 1, unread: 1 }}
                />

                <InfoBox
                  icon={IconAbnormal}
                  text={`${t('common.results')}<b> ${t('homepage_doctor.results_graph.abnormal')}</b>`}
                  num={recordStats.abnormal}
                  color='graph.warning'
                  unreadFolders={unreadFoldersAbnormal}
                  to='RESULTS'
                  search={{ abnormal: 1, unread: 1 }}
                />
                <InfoBox
                  icon={IconFavorite}
                  text={`${t('common.results')}<b> ${t('homepage_doctor.results_graph.tagged')}</b>`}
                  num={recordStats.tagged}
                  color='graph.info'
                  to='RESULTS'
                  search={{ tagged: 1 }}
                />
              </Grid>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          {isMobileView ? (
            // Mobile View
            <Grid container spacing={2}>
              <CardButton icon={IconPatients} title={t('homepage_doctor.buttons_mobile.patients')} to='HOMEPAGE' />
              <CardButton icon={IconResultat} title={t('common.results')} to='RESULTS' />
              <CardButton icon={IconPrescription} title={t('common.action.makePrescription')} to='HOMEPAGE' />
              <CardButton icon={IconCatalogAnalyse} title={t('homepage_doctor.buttons_mobile.catalog_analysis')} to='HOMEPAGE' />
            </Grid>
          ) : (
            // Desktop View
            <ResultsTable
              data={data}
              isLoading={isLoading}
              title={
                !isAdmin ? (
                  <Box display='flex' alignItems='center'>
                    <Typography variant='h2'>{t('homepage_doctor.results_table.title')} -</Typography>
                    <Button variant='header_light' onClick={markAllResultsAsRead} sx={{ ml: 0.5, mt: 0.25 }}>
                      {t('homepage.doctor.actions.markAllRead')}
                    </Button>
                  </Box>
                ) : (
                  ''
                )
              }
              pagination={{
                page: paginationStart,
                size: paginationSize,
                total: paginationTotal,
              }}
              onPaginationChange={handlePaginationChange}
            />
          )}
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default Home;
