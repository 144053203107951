import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    common: {
      black: '#212121',
      white: '#FFFFFF',
    },
    primary: {
      main: '#46BFF5',
      contrastText: '#212121',
    },
    secondary: {
      main: '#000000',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#FF3838',
      contrastText: '#212121',
    },
    warning: {
      main: '#FF3366',
      contrastText: '#212121',
    },
    success: {
      main: '#02A521',
      contrastText: '#212121',
    },
    text: {
      primary: '#212121',
      secondary: '#808080',
      disabled: '#808080',
    },
    graph: {
      success: '#00E661',
      error: '#FF3366',
      warning: '#FF9C33',
      info: '#FFC32C',
    },
    background: {
      default: '#F1F1EF',
      paper: '#FFFFFF', //card
    },
    footer: {
      main: '#FFFFFF',
    },
    header: {
      main: '#ECF9FE',
    },
    divider: '#E6E6E6',
    gold: '#FFD800',
  },
});

export default theme;

declare module '@mui/material/styles' {
  interface Palette {
    graph: {
      success: string;
      error: string;
      warning: string;
      info: string;
    };
    gold: string;
    footer: { main: string };
    header: { main: string };
  }
  interface PaletteOptions {
    graph: {
      success: string;
      error: string;
      warning: string;
      info: string;
    };
    gold: string;
    footer: { main: string };
    header: { main: string };
  }

  export function createTheme(options?: PaletteOptions): Palette;
}
