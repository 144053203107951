import { Button, TableCell, TableRow, Typography } from '@mui/material';
import api from 'api';
import GenericTable from 'components/generic-table';
import { GenericHeader } from 'components/generic-table/GenericTableHeader';
import NoResultsTableRow from 'components/no-results-table-row';
import ContextLink, { RoutePathsKey } from 'components/_base/ContextLink';
import { useNotification } from 'hooks';
import { Patient } from 'models/patients.model';
import { Prescription, PrescriptionListResponse } from 'models/prescription.model';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NO_DATA_DISPLAY } from 'utils/Constants';
import { convertDate } from 'utils/func/Date.func';

interface PatientPrescriptionsListProps {
  patient: Patient;
}

const Prescriptions: FC<PatientPrescriptionsListProps> = ({ patient }) => {
  const { t } = useTranslation();
  const { notification } = useNotification();

  const [prescriptionsList, setPrescriptionsList] = useState<Prescription[] | undefined>(undefined);

  const requestPatientPrescriptions = useCallback(() => {
    api.prescriptions
      .getPrescriptionsByPatientId(`${patient.id}`)
      .then((response: PrescriptionListResponse) => {
        setPrescriptionsList(response);
      })
      .catch(() => notification(t('prescriptions.notification.error'), 'error'));
  }, [notification, patient.id, t]);

  useEffect(() => {
    requestPatientPrescriptions();
  }, [requestPatientPrescriptions]);

  const hasCC = (record: Prescription) => {
    return !!record.copy_to_medical_staff?.length;
  };

  const displayCC = (record: Prescription) => {
    return record.copy_to_medical_staff?.map((cc, index) => (
      <Typography key={index} variant='body1'>
        {`${cc.title} ${cc.first_name} ${cc.last_name} (${cc.code})`}
      </Typography>
    ));
  };

  const hasIssuer = (record: Prescription) => {
    return !!record.medical_staff_data;
  };

  const displayIssuer = (record: Prescription) => {
    return <Typography variant='body1'>{`${record.medical_staff_data.title} ${record.medical_staff_data.first_name} ${record.medical_staff_data.last_name}`}</Typography>;
  };

  const getPrescriptionActionButton = (
    prescription: Prescription,
    action: {
      to: RoutePathsKey;
      label: string;
    },
  ) => {
    return (
      <Typography variant='body1'>
        <ContextLink
          to={action.to}
          params={{
            id: prescription.id,
          }}
        >
          <Button type='button' variant='text'>
            {t(action.label)}
          </Button>
        </ContextLink>
      </Typography>
    );
  };

  const displayPrescriptionAction = (prescription: Prescription) => {
    switch (prescription.status) {
      case 'validated':
        return getPrescriptionActionButton(prescription, {
          to: `DOCTOR_PRESCRIPTION_DETAILS_VALIDATION`,
          label: 'patient_details.tab.prescription.list.actions.view',
        });
      case 'open':
        return getPrescriptionActionButton(prescription, {
          to: `DOCTOR_PATIENTS_DETAILS_PRESCRIPTION`,
          label: 'common.action.modify',
        });
      case 'cancelled':
      case 'pending':
      case 'closed':
      default:
        return NO_DATA_DISPLAY;
    }
  };

  const displayAppointmentAction = (prescription: Prescription) => {
    switch (prescription.status) {
      case 'validated':
        return (
          <Typography variant='body1'>
            <ContextLink to={'DOCTOR_APPOINTMENT_PRESCRIPTION'} params={{ id: patient.id, prescId: prescription.id }}>
              <Button type='button' variant='text'>
                {t('patient_details.tab.prescription.list.actions.appointment')}
              </Button>
            </ContextLink>
          </Typography>
        );
      case 'open':
      case 'cancelled':
      case 'pending':
      case 'closed':
      default:
        return NO_DATA_DISPLAY;
    }
  };

  const tableHeaders: GenericHeader[] = [
    {
      id: 'prescription_date',
      label: 'common.table.tableHead.prescription_date',
    },
    {
      id: 'issuer',
      label: 'common.table.tableHead.issuer',
    },
    { id: 'cc', label: 'common.table.tableHead.cc' },
    {
      id: 'status',
      label: 'patient_details.tab.prescription.headers.status',
    },
    {
      id: 'validity_date',
      label: 'patient_details.tab.prescription.headers.validity_date',
    },
    {
      id: 'actions_prescription',
      label: undefined,
    },
    { id: 'actions_appointment', label: undefined },
  ];

  return (
    <GenericTable headers={tableHeaders} noPagination>
      {prescriptionsList && prescriptionsList.length ? (
        prescriptionsList.map((prescription, key) => {
          return (
            <TableRow hover key={key}>
              <TableCell>{prescription.date ? <Typography variant='body1'>{convertDate(prescription.date)}</Typography> : NO_DATA_DISPLAY}</TableCell>
              <TableCell>{hasIssuer(prescription) ? displayIssuer(prescription) : NO_DATA_DISPLAY}</TableCell>
              <TableCell>{hasCC(prescription) ? displayCC(prescription) : NO_DATA_DISPLAY}</TableCell>
              <TableCell>
                <Typography variant='body1'>
                  {t(
                    // @ts-ignore
                    `patient_details.tab.prescription.list.status.${prescription.status}`,
                  )}
                </Typography>
              </TableCell>
              <TableCell>{prescription.validity_date ? <Typography variant='body1'>{convertDate(prescription.validity_date, false)}</Typography> : NO_DATA_DISPLAY}</TableCell>
              <TableCell>{displayPrescriptionAction(prescription)}</TableCell>
              <TableCell>{displayAppointmentAction(prescription)}</TableCell>
            </TableRow>
          );
        })
      ) : (
        <NoResultsTableRow colSpan={tableHeaders.length} />
      )}
    </GenericTable>
  );
};

export default Prescriptions;
