import { BadgesCount, Person, ShortenPerson } from 'models/profile.model';
import { CONST_BIONEXT_CURRENT_USER_TOKEN } from 'utils/Constants';
import api from '../../api';
import { SessionAction, SessionActionType } from '../actions/session.actions';

type SessionInitialState = {
  isAuthenticated: boolean;
  authenticateUser?: Person;
  currentUser?: ShortenPerson;
  relativesCurrentUser?: Person[];
  badgesCount?: BadgesCount;
};

const sessionInitialState: SessionInitialState = {
  isAuthenticated: api.authentication.tokenExists(),
  authenticateUser: undefined,
  currentUser: undefined,
  relativesCurrentUser: undefined,
  badgesCount: undefined,
};

export default function sessionReducer(prevState = sessionInitialState, action: SessionAction) {
  switch (action.type) {
    case SessionActionType.LOGIN:
    case SessionActionType.REFRESH_TOKEN:
      return {
        ...prevState,
        isAuthenticated: true,
      };
    case SessionActionType.VALIDATE_DISCLAIMER:
      if (!prevState.authenticateUser) return prevState;
      return {
        ...prevState,
        authenticateUser: {
          ...prevState.authenticateUser,
          is_disclaimer_needed: false,
        } as Person,
      };

    case SessionActionType.LOGOUT: {
      api.authentication.clear();
      return {
        ...prevState,
        isAuthenticated: false,
        isCallCenterAuthenticated: false,
        authenticateUser: undefined,
        currentUser: undefined,
        relativesCurrentUser: [],
      };
    }

    case SessionActionType.SET_USER: {
      return {
        ...prevState,
        authenticateUser: action.authenticateUser,
      };
    }

    case SessionActionType.SET_CALL_CENTER: {
      return {
        ...prevState,
        isCallCenterAuthenticated: true,
      };
    }

    case SessionActionType.SET_CURRENT_USER: {
      localStorage.setItem(CONST_BIONEXT_CURRENT_USER_TOKEN, action.currentUser.id.toString());
      return {
        ...prevState,
        currentUser: action.currentUser,
      };
    }

    case SessionActionType.SET_RELATIVES_CURRENT_USER: {
      return {
        ...prevState,
        relativesCurrentUser: action.relativesCurrentUser,
      };
    }

    case SessionActionType.SET_BADGES_COUNT: {
      return {
        ...prevState,
        badgesCount: action.badgesCount,
      };
    }

    default:
      return prevState;
  }
}
