import { useAuthenticatedUser, useContextRedirection, useIsTempPatient } from 'hooks';
import { FC, useEffect } from 'react';
import RoutePaths from 'utils/RoutePaths';

const TempHome: FC = () => {
  const navigate = useContextRedirection();
  const authenticatedUser = useAuthenticatedUser();
  const isTempPatient = useIsTempPatient();

  useEffect(() => {
    if (isTempPatient && authenticatedUser && authenticatedUser.dossier_reference) {
      navigate(RoutePaths['DETAIL_RESULT']({ id: authenticatedUser.dossier_reference }));
    }
  }, [isTempPatient, authenticatedUser, navigate]);

  return <div></div>;
};

export default TempHome;
