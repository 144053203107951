import { Box, Button, Card, CardContent, CardHeader, Grid, TextField, Typography } from '@mui/material';
import api from 'api';
import AnalysisList from 'components/analysis-list';
import { useNotification } from 'hooks';
import { Analysis } from 'models/analysis.model';
import { PrescriptionProfile } from 'models/prescription-profile.model';
import { SearchAnalysisForm } from 'models/search-analysis-form.model';
import { Paginate } from 'models/utils.model';
import { FC, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addNewPrescriptionProfileItem, setEditPrescriptionProfile } from 'store/actions/prescriptionProfile.actions';
import { GlobalState } from 'store/reducers';
import { ANALYSIS_PROFILE } from 'utils/Constants';

type AddAnalysisCardProps = {
  editMode?: boolean;
};

const AddAnalysisCard: FC<AddAnalysisCardProps> = ({ editMode }) => {
  const { t } = useTranslation();
  const [frequentAnalysis, setFrequentAnalysis] = useState<Analysis[]>();
  const [searchResult, setSearchResult] = useState<Analysis[]>();
  const { register, handleSubmit } = useForm<SearchAnalysisForm>();
  const dispatch = useDispatch();
  const { notification } = useNotification();
  const profile = useSelector((state: GlobalState) => state.prescriptionProfile.editProfile);

  const onSubmit: SubmitHandler<SearchAnalysisForm> = useCallback(
    (data) => {
      api.analysis
        .getAnalysis(ANALYSIS_PROFILE.MAX_ANALYSIS, 0, data.search)
        .then((response: Paginate<Analysis>) => {
          setSearchResult(response.results);
        })
        .catch(() => notification(t('prescriptions_profile.add_analysis.notification.error'), 'error'));
    },
    [notification, t],
  );

  const handleProfileResponse = useCallback(
    (profile: PrescriptionProfile) => {
      if (editMode) {
        dispatch(setEditPrescriptionProfile(profile));
      }
    },
    [dispatch, editMode],
  );

  const handleSelectElement = useCallback(
    (item: any) => {
      if (editMode) {
        api.prescriptions
          .postPrescriptionProfileItem(profile!.id, item[0].analysis_reference)
          .then((response: PrescriptionProfile) => {
            handleProfileResponse(response);
          })
          .catch(() => notification(t('prescriptions_profile.add_analysis.notification.errorOnAdding'), 'error'));
      } else {
        dispatch(addNewPrescriptionProfileItem(item[0]));
      }
    },
    [dispatch, editMode, handleProfileResponse, notification, profile, t],
  );

  useEffect(() => {
    api.results
      .getFrequentAnalysis()
      .then((response: Analysis[]) => {
        setFrequentAnalysis(response);
      })
      .catch(() => {
        notification(t('prescriptions_profile.new_profile.notification.error'), 'error');
      });
  }, [notification, t]);

  return (
    <>
      {((editMode && profile) || !editMode) && (
        <Card>
          <CardHeader
            title={
              <Typography variant='h2' mb={4}>
                {profile
                  ? t('prescriptions_profile.add_analysis.title_to', {
                      replace: { profile_name: profile.name },
                    })
                  : t('prescriptions_profile.add_analysis.title')}
              </Typography>
            }
          />
          <CardContent
            sx={{
              display: 'flex',
              flex: '1 0 0',
              flexDirection: 'column',
            }}
          >
            <Box mb={4}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container item xs={12}>
                  <Grid container item spacing={{ xs: 2, md: 4 }}>
                    <Grid item xs={12} md={9}>
                      <TextField variant='filled' label={t('prescriptions_profile.add_analysis.search_form.input')} {...register('search')} defaultValue={''} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Button type='submit' variant='outlined' fullWidth>
                        {t('prescriptions_profile.add_analysis.search_form.button')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Box>
            <Box>
              {searchResult && (
                <Box mb={2}>
                  <AnalysisList title={'prescriptions_profile.add_analysis.search_results'} analysisList={searchResult} onSelect={handleSelectElement} />
                </Box>
              )}
              <AnalysisList title={'prescriptions_profile.add_analysis.most_frequent'} analysisList={frequentAnalysis} onSelect={handleSelectElement} />
            </Box>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default AddAnalysisCard;
