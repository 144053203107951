import { Button, Card, CardContent, CardHeader, Checkbox, FormControlLabel, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useIsMobileView } from 'hooks';
import { FC, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const defaultFormValues = {
  patientName: '',
  from: null,
  to: null,
  recordReference: '',
  cnsNumber: '',
  status: 0,
  unread: false,
  tagged: false,
  abnormal: false,
  urgent: false,
};

export interface SearchFormParams {
  patientName: string;
  from: Date | null;
  to: Date | null;
  recordReference: string | null;
  cnsNumber: string;
  status: number;
  unread: boolean | undefined;
  tagged: boolean | undefined;
  abnormal: boolean | undefined;
  urgent: boolean | undefined;
}

interface SearchProps {
  defaultSearchFormValues?: SearchFormParams;
  onSearch?: (formParams: SearchFormParams) => void;
  columnRatio: number;
}

const PatientFileSearchBar: FC<SearchProps> = ({ onSearch, columnRatio, defaultSearchFormValues }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();

  const selectionStatus = [`${t('result_doctor.search.undefined')}`, `${t('result_doctor.partial')}`, `${t('result_doctor.complete')}`];

  const { register, control, handleSubmit, watch } = useForm<SearchFormParams>({
    defaultValues: defaultSearchFormValues ?? defaultFormValues,
  });

  const onSubmit: SubmitHandler<SearchFormParams> = (data) => {
    onSearch && onSearch(data);
  };
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if ((name === 'unread' || name === 'tagged' || name === 'abnormal' || name === 'urgent') && type === 'change') {
        onSearch &&
          onSearch({
            patientName: value.patientName ?? '',
            from: value.from ?? null,
            to: value.to ?? null,
            recordReference: value.recordReference ?? '',
            cnsNumber: value.cnsNumber ?? '',
            status: value.status ?? 0,
            unread: value.unread,
            tagged: value.tagged,
            abnormal: value.abnormal,
            urgent: value.urgent,
          });
      }
    });
    return () => subscription.unsubscribe();
  }, [defaultSearchFormValues, onSearch, watch]);

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant='h2' mb={3}>
            {t('result_doctor.search.title')}
          </Typography>
        }
      />
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={columnRatio}>
              <TextField label={`${t('result_doctor.search.patient_name')}`} {...register('patientName')} variant='filled' fullWidth />
            </Grid>
            <Grid item xs={12} md={columnRatio}>
              <Controller
                name='from'
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <DatePicker
                    label={`${t('result_doctor.search.from')}`}
                    {...(isMobileView && {
                      openTo: 'day',
                      views: ['year', 'month', 'day'],
                    })}
                    {...field}
                    slotProps={{ textField: { variant: 'filled', fullWidth: true } }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={columnRatio}>
              <Controller
                name='to'
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <DatePicker
                    label={`${t('result_doctor.search.to')}`}
                    {...(isMobileView && {
                      openTo: 'day',
                      views: ['year', 'month', 'day'],
                    })}
                    {...field}
                    slotProps={{ textField: { variant: 'filled', fullWidth: true } }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={columnRatio}>
              <TextField {...register('recordReference')} label={`${t('result_doctor.search.record_reference')}`} variant='filled' fullWidth />
            </Grid>
            <Grid item xs={12} md={columnRatio}>
              <TextField {...register('cnsNumber')} label={`${t('result_doctor.search.cns')}`} variant='filled' fullWidth />
            </Grid>
            <Grid item xs={12} md={columnRatio}>
              <TextField select label={`${t('result_doctor.search.status')}`} {...register('status')} defaultValue={0} variant='filled' fullWidth>
                {selectionStatus.map((state, i) => {
                  return (
                    <MenuItem value={i} key={`status-${i}`}>
                      {state}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} marginTop={3}>
              <Grid container marginX='auto'>
                <Grid item xs={6} md='auto'>
                  <Controller
                    name='unread'
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label={`${t(`common.results`)} ${t(`result_doctor.search.filters.unread`)}`}
                        sx={{
                          mr: '30px',
                        }}
                      />
                    )}
                  />
                  <Controller
                    name='tagged'
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label={`${t(`common.results`)} ${t(`result_doctor.search.filters.tagged`)}`}
                        sx={{
                          mr: '30px',
                        }}
                      />
                    )}
                  />
                  <Controller
                    name='abnormal'
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label={`${t(`common.results`)} ${t(`result_doctor.search.filters.abnormal`)}`}
                        sx={{
                          mr: '30px',
                        }}
                      />
                    )}
                  />
                  <Controller
                    name='urgent'
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label={`${t(`common.results`)} ${t(`result_doctor.search.filters.urgent`)}`}
                        sx={{
                          mr: '30px',
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} display='flex' justifyContent='end' alignItems='end'>
              <Button variant='contained' fullWidth={isMobileView} type='submit'>
                {t('result_doctor.search.button')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default PatientFileSearchBar;
