import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import api from 'api';

import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import MissingData from 'components/missing-data';
import ContextLink from 'components/_base/ContextLink';
import PatientDetails from 'components/patient-details';
import { useTranslation } from 'react-i18next';
import { Patient } from 'models/patients.model';
import { Selection } from 'models/record.model';
import { Result, ResultGraph, ResultObject } from 'models/result.model';
import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { convertDate } from 'utils/func/Date.func';
import GraphHistory from './graph';
import { formatToLocaleNumber } from 'utils/func/Number.func';
interface CustomizedState {
  patient: Patient;
  selection: Selection[];
}

const History: FC = () => {
  const location = useLocation();
  const { patient, selection } = location.state as CustomizedState;
  const referencesArray: string[] = useMemo(() => [], []);
  const [historyDataValues, setHistoryDataValues] = useState([]);
  const { t } = useTranslation();
  const historyDataValuesArray: ResultGraph[] = [];

  useEffect(() => {
    selection.map((el: { ref: string; name: string }) => referencesArray.push(el.ref));
    api.results.getHistoryFormResults(patient.id, referencesArray).then((response) => setHistoryDataValues(response));
  }, [patient.id, referencesArray, selection]);

  historyDataValues.forEach((el: ResultObject, key: number) => {
    const historyArray: Result[] = [];
    Object.keys(el.history).forEach((el2, key2) => {
      historyArray.push(el.history[el2]);
    });
    historyDataValuesArray.push({
      name: historyArray[0].name,
      reference: el.reference,
      history: historyArray.filter((value) => value.sampling_time !== null),
    });
  });

  return (
    <BodyTemplate title={t('bodyTemplate.doctor.resultsHistory')} backButton>
      <Grid container>
        <Grid item xs={12}>
          <PatientDetails showPatientRecordAction patient={patient} />
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <GraphHistory historyDataValues={historyDataValuesArray} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant='h2'>{t('common.results')}</Typography>
              {historyDataValuesArray.map((el: ResultGraph, key: number) => {
                return (
                  <Accordion defaultExpanded={true} key={key}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography variant='h3'>{el.history[0] ? el.history[0].name : <MissingData />}</Typography>
                      </Box>
                    </AccordionSummary>

                    <AccordionDetails sx={{ margin: 0 }}>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography variant='body1'>{t('common.table.tableHead.sample_date')}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant='body1'>{t('common.table.tableHead.value')}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant='body1'>{t('common.table.tableHead.usualValues')}</Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.keys(el.history).map((e, key) => {
                              return (
                                <TableRow key={key}>
                                  <TableCell>
                                    <Typography variant='body1'>{convertDate(el.history[parseInt(e)].sampling_time, false, 'dd/MM/yyyy hh:mm')}</Typography>
                                  </TableCell>
                                  <TableCell>
                                    {el.history[parseInt(e)].value !== null || el.history[parseInt(e)].unit !== null ? (
                                      el.history[parseInt(e)].is_abnormal ? (
                                        <Typography variant='body2' sx={{ color: 'red' }}>
                                          <strong>{el.history[parseInt(e)].value + ' ' + el.history[parseInt(e)].unit}</strong>
                                        </Typography>
                                      ) : (
                                        <Typography variant='body2'>{formatToLocaleNumber(el.history[parseInt(e)].value) + ' ' + el.history[parseInt(e)].unit}</Typography>
                                      )
                                    ) : (
                                      '-'
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant='caption'>
                                      {el.history[parseInt(e)].limit_max !== null || el.history[parseInt(e)].limit_min !== null || el.history[parseInt(e)].unit !== null
                                        ? formatToLocaleNumber(el.history[parseInt(e)].limit_min) + ' - ' + formatToLocaleNumber(el.history[parseInt(e)].limit_max) + el.history[parseInt(e)].unit
                                        : '-'}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <ContextLink
                                      to='DETAIL_RESULT'
                                      params={{
                                        id: el.history[parseInt(e)].dossier_reference,
                                      }}
                                    >
                                      <Button variant='header_light'>
                                        {t('result.doctor.resultsHistory.action.file', {
                                          replace: {
                                            date:
                                              convertDate(el.history[parseInt(e)].sampling_time, false, 'dd/MM/yyyy ') !== '-'
                                                ? convertDate(el.history[parseInt(e)].sampling_time, false, 'dd/MM/yyyy ')
                                                : convertDate(new Date(), false, 'dd/MM/yyyy '),
                                          },
                                          interpolation: {
                                            escapeValue: false,
                                          },
                                        })}
                                      </Button>
                                    </ContextLink>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default History;
