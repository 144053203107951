import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import GenericTable, { GenericPaginator } from 'components/generic-table';
import { GenericHeader } from 'components/generic-table/GenericTableHeader';
import NoResultsTableRow from 'components/no-results-table-row';
import TableContentSkeleton from 'components/table-content-skeleton';
import { FC } from 'react';
import DesktopResultsTable from './DesktopResultsTable';
import { resultTaggedEl } from 'models/result.model';

const DesktopResults: FC<{
  results: any[];
  title: string | JSX.Element;
  pagination: GenericPaginator;
  isLoading: boolean;
  onPaginationChange: (pagination: GenericPaginator) => void;
}> = ({ results, title, pagination, onPaginationChange, isLoading }) => {
  const tableHeaders: GenericHeader[] = [
    {
      id: 'prescription_date',
      label: 'common.table.tableHead.prescription_date',
    },
    { id: 'sampleDate', label: 'common.table.tableHead.sample_date' },
    { id: 'fileReference', label: 'common.table.tableHead.reference_number' },
    { id: 'patient', label: 'common.table.tableHead.patient' },
    { id: 'dateOfBirth', label: 'common.table.tableHead.birth_date' },
    { id: 'cns', label: 'common.table.tableHead.cns_number' },
    { id: 'issuer', label: 'common.table.tableHead.issuer' },
    { id: 'lastUpdate', label: 'results.list.table.last_update' },
    { id: 'status', label: 'results.list.table.status' },
    { id: 'actions', label: undefined },
  ];

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant='h2' mb={3}>
            {title}
          </Typography>
        }
      />
      <CardContent>
        <GenericTable headers={tableHeaders} pagination={pagination} setPagination={onPaginationChange}>
          {!isLoading && results ? (
            results.length ? (
              results.map((result: resultTaggedEl, key: number) => {
                return <DesktopResultsTable result={result} key={key} />;
              })
            ) : (
              <NoResultsTableRow colSpan={tableHeaders.length} noResultText={'common.noResult'} />
            )
          ) : (
            <TableContentSkeleton columns={tableHeaders.length} rows={4} />
          )}
        </GenericTable>
      </CardContent>
    </Card>
  );
};

export default DesktopResults;
