import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionSummary, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC, ReactElement } from 'react';

const ResultAccordionSummary: FC<{
  title: string;
  children: ReactElement;
  nbResults: number;
}> = ({ title, children, nbResults }) => {
  const { t } = useTranslation();

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant='h3'>{title}</Typography>
          <Typography variant='subtitle1'>
            {t('results.details.accordionResultLabel', {
              count: nbResults,
            })}
          </Typography>
        </Box>
      </AccordionSummary>
      {children}
    </Accordion>
  );
};

export default ResultAccordionSummary;
