import { APIConfiguration, axiosMYLAB } from 'api';
import { Analysis } from 'models/analysis.model';
import { Paginate } from 'models/utils.model';
import { BaseService } from './base.service';

class AnalysisService extends BaseService {
  async getAnalysis(size: number, page: number, name: string = '') {
    const { data } = await axiosMYLAB.get<Paginate<Analysis>>(`${this.apiConfig.ANALYSIS.SEARCH(this.getLang())}`, {
      params: { name, size, page },
    });

    this.log('getAnalysis()');

    return data;
  }

  async getAnalysisById(id: string | number) {
    const { data } = await axiosMYLAB.get<Analysis>(this.apiConfig.ANALYSIS.GET_BY_ID(this.getLang(), id));

    this.log('getAnalysisById()');

    return data;
  }
}

export default (apiConfig: APIConfiguration) => {
  return new AnalysisService(apiConfig);
};
