import { Button, DialogTitle, Grid, Typography } from '@mui/material';
import api from 'api';
import { useContextRedirection, useNotification } from 'hooks';
import { RelationUsualInfo } from 'models/profile.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getFullName } from 'utils/func/Person.func';
import RoutePaths from 'utils/RoutePaths';

const AccessCardModal: FC<{
  relative: RelationUsualInfo;
  closeModal: () => void;
}> = ({ relative, closeModal }) => {
  const { t } = useTranslation();
  const { notification } = useNotification();
  const navigate = useContextRedirection();

  const removeRelativeFromPatient = () => {
    api.patients
      .disableRelativeFromPatient(relative.invitationId)
      .then(() => {
        notification(t('profile.results.notification.success'), 'success');
        closeModal();
        navigate(RoutePaths['HOME']);
      })
      .catch(() => notification(t('profile.results.notification.error'), 'error'));
  };

  return (
    <DialogTitle>
      <Typography variant='h2'>
        {t('profile.results.modal.title', {
          replace: { name: getFullName(relative) },
        })}
      </Typography>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant='body2'>{t('profile.results.modal.subtitle')}</Typography>
          <Grid item xs={12} my={2} display='flex' justifyContent='flex-end'>
            <Button variant='outlined' sx={{ mr: 1 }} onClick={closeModal}>
              {t('common.words.noBtn')}
            </Button>
            <Button variant='danger' sx={{ mr: 1 }} onClick={() => removeRelativeFromPatient()}>
              {t('common.words.yesBtn')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </DialogTitle>
  );
};

export default AccessCardModal;
