import { convertDate } from './Date.func';

const luhn_checksum = (code: string) => {
  var len = code.length;
  var parity = len % 2;
  var sum = 0;
  for (var i = len - 1; i >= 0; i--) {
    var d = parseInt(code.charAt(i));
    if (i % 2 === parity) {
      d *= 2;
    }
    if (d > 9) {
      d -= 9;
    }
    sum += d;
  }
  return sum % 10;
};

const luhn_validate = (fullcode: string) => {
  return luhn_checksum(fullcode) === 0;
};

/* 
    For more info on the algorithm: http://en.wikipedia.org/wiki/Verhoeff_algorithm
    by Sergey Petushkov, 2014 
*/

// Multiplication table
var d = [
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [1, 2, 3, 4, 0, 6, 7, 8, 9, 5],
  [2, 3, 4, 0, 1, 7, 8, 9, 5, 6],
  [3, 4, 0, 1, 2, 8, 9, 5, 6, 7],
  [4, 0, 1, 2, 3, 9, 5, 6, 7, 8],
  [5, 9, 8, 7, 6, 0, 4, 3, 2, 1],
  [6, 5, 9, 8, 7, 1, 0, 4, 3, 2],
  [7, 6, 5, 9, 8, 2, 1, 0, 4, 3],
  [8, 7, 6, 5, 9, 3, 2, 1, 0, 4],
  [9, 8, 7, 6, 5, 4, 3, 2, 1, 0],
];

// Permutation table
var p = [
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [1, 5, 7, 6, 2, 8, 3, 0, 9, 4],
  [5, 8, 0, 3, 7, 9, 6, 1, 4, 2],
  [8, 9, 1, 6, 0, 4, 3, 5, 2, 7],
  [9, 4, 5, 3, 1, 2, 6, 8, 7, 0],
  [4, 2, 8, 6, 5, 7, 3, 9, 0, 1],
  [2, 7, 9, 3, 8, 0, 6, 4, 1, 5],
  [7, 0, 4, 6, 9, 1, 3, 2, 5, 8],
];

// Converts string or number to an array and inverts it
const invArray = (array: any) => {
  if (Object.prototype.toString.call(array) === '[object Number]') {
    array = String(array);
  }
  if (Object.prototype.toString.call(array) === '[object String]') {
    array = array.split('').map(Number);
  }
  return array.reverse();
};

// Validates checksum
const verhoeff_validate = (array: any) => {
  var c = 0;
  var invertedArray = invArray(array);
  for (var i = 0; i < invertedArray.length; i++) {
    c = d[c][p[i % 8][invertedArray[i]]];
  }
  return c === 0;
};

export const cnsValidator = (value: string, birth_date: string | null) => {
  var returnedValue = true;

  if (value !== '') {
    // Check length
    if (!/^[0-9]{13}$/.test(value)) {
      return false;
    }

    // Check of Luhn checksum
    let cnsNumberWithLuhnChecksum = value.substring(0, 12);
    if (!luhn_validate(cnsNumberWithLuhnChecksum)) {
      return false;
    }

    // Check of Verhoeff checksum
    let cnsNumberWithVerhoeffChecksum = value.slice(0, value.length - 2) + value.slice(value.length - 1);
    if (!verhoeff_validate(cnsNumberWithVerhoeffChecksum)) {
      return false;
    }

    // Check for birth_date (if provided)
    if (birth_date !== null) {
      try {
        birth_date = convertDate(new Date(birth_date), false, 'yyyyMMdd');
      } catch (error) {
        return false;
      }
      return birth_date === value.substring(0, 8);
    }
  }

  return returnedValue;
};
