import api from 'api';
import { OfficeMedicalStaff } from 'models/office-medical-staff.model';
import RoutePaths from 'utils/RoutePaths';

const createPrescription = (patientId: number, omsId: number, navigation: (to: string, params?: {}) => void) => {
  api.prescriptions.postBasketPrescription(patientId, omsId).then((response) => {
    navigation(
      RoutePaths['DOCTOR_PRESCRIPTION_DETAILS']({
        id: response.id,
      }),
    );
  });
};

export const PrescriptionRequestMaker = (patientId: number, navigation: (to: string, params?: {}) => void, selectedOfficeStaff?: number) => {
  if (selectedOfficeStaff) {
    createPrescription(patientId, selectedOfficeStaff, navigation);
  } else {
    api.users.getAllOfficeMedicalStaffByUser().then((idResponse) => {
      //If more than one office, we must ask the user to select one
      if (idResponse.length > 1) {
        navigation(
          RoutePaths['DOCTOR_SELECT_OMS']({
            id: patientId,
          }),
        );
      } else {
        createPrescription(patientId, idResponse[0].id, navigation);
      }
    });
  }
};

export const officeMedicalStaffInfoToString = (officeMedicalStaff: OfficeMedicalStaff) => {
  return `${officeMedicalStaff.medical_staff.title ?? ''} ${officeMedicalStaff.medical_staff.last_name} ${officeMedicalStaff.medical_staff.first_name} (${
    officeMedicalStaff.address?.number ?? ''
  } ${officeMedicalStaff.address?.street}, ${officeMedicalStaff.address?.postal_code} ${officeMedicalStaff.address?.city})`;
};
