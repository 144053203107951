import { Box, Divider } from '@mui/material';
import { useAuthenticated } from 'hooks';
import { FC } from 'react';

const CustomDivider: FC = () => {
  const isAuthenticated = useAuthenticated();

  return (
    <Box
      sx={{
        padding: (theme) => `${theme.spacing(0, 3)}`,
      }}
      alignSelf='center'
    >
      <Divider
        orientation='vertical'
        sx={{
          height: '15px',
          border: (theme) => `2px solid ${isAuthenticated ? theme.palette.primary.main : theme.palette.common.white}`,
        }}
      />
    </Box>
  );
};

export default CustomDivider;
