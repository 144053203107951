import { Grid } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { useNotification } from 'hooks';
import { InvoiceStatus } from 'models/invoices.model';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import DetailInvoicesGetFile from './components/DetailInvoicesGetFile';
import { DetailInvoicesPaymentForm } from './components/DetailInvoicesPaymentForm';

export type DetailInvoicesGetFileProps = {
  file: string;
  reference: string;
  amount: number;
  currency: string;
  status: string;
  dossierReference: string;
  client_secret: string;
};

const InvoiceDetails: FC = () => {
  const { id } = useParams();
  const [data, setData] = useState<DetailInvoicesGetFileProps>();
  const { t } = useTranslation();
  const { notification } = useNotification();

  useEffect(() => {
    id &&
      api.invoices
        .getInvoiceByReference(id)
        .then((res) => {
          setData({ ...res });
        })
        .catch(() => notification(t('invoices.details.notification.error'), 'error'));
  }, [id, notification, t]);

  return (
    <BodyTemplate title={t('bodyTemplate.invoice_details', { replace: { reference: data?.reference ?? '' } })} backButton>
      <Grid container spacing={3}>
        {data && data.status === InvoiceStatus.OPEN && (
          <Grid item xs={12}>
            {data && <DetailInvoicesPaymentForm {...data} />}
          </Grid>
        )}

        <Grid item xs={12}>
          {data && <DetailInvoicesGetFile {...data} />}
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default InvoiceDetails;
