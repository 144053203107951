import { AccordionDetails, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { FC } from 'react';
import { formatUsualValues, isOK } from 'pages/doctor/patients/history/utils';
import { useTranslation } from 'react-i18next';
import { AnalysisCT } from 'models/analysis.model';
import { convertDate } from 'utils/func/Date.func';
import { formatToLocaleNumber } from 'utils/func/Number.func';

type Props = {
  analyses: AnalysisCT[];
};

const ResultsCrosstabAccordionDetails: FC<Props> = ({ analyses }) => {
  const { t } = useTranslation();

  return (
    <AccordionDetails sx={{ margin: 0 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width='10%'>
                <Typography variant='body1'>{t('patient_details.history.analysesHeader')}</Typography>
              </TableCell>
              {analyses[0].results.map((result, key) => (
                <TableCell key={key} width={`${90 / analyses[0].results.length}%`}>
                  <Typography variant='body1'>{convertDate(result.date, true)}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {analyses.map((analysis, key) => (
              <TableRow key={key}>
                <TableCell>
                  <Typography variant='body1'>{analysis.name}</Typography>
                </TableCell>
                {analysis.results.map((result, _key) => (
                  <TableCell key={`${key}-${_key}`}>
                    <Typography
                      variant='body1'
                      sx={{
                        ...(!isOK(result.value, result.min, result.max) && {
                          color: 'red',
                          fontWeight: 'bold',
                        }),
                      }}
                    >
                      {result.value ? `${formatToLocaleNumber(result.value, false)} ${result.unit ?? ''}` : '-'}
                    </Typography>
                    <Typography variant='caption'>{formatUsualValues(result.min, result.max, result.unit)}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </AccordionDetails>
  );
};

export default ResultsCrosstabAccordionDetails;
