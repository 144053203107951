import { Button, Divider, Grid, IconButton, SvgIcon, TableCell, TableRow, Typography } from '@mui/material';
import GenericTable, { GenericPaginator } from 'components/generic-table';
import { GenericHeader } from 'components/generic-table/GenericTableHeader';
import NoResultsTableRow from 'components/no-results-table-row';
import TableContentSkeleton from 'components/table-content-skeleton';
import ContextLink from 'components/_base/ContextLink';
import { useIsMobileView } from 'hooks';
import { InvoiceStatus } from 'models/invoices.model';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconNext } from 'resources/icons/next.svg';
import { convertDate } from 'utils/func/Date.func';
import { formatToLocaleNumber } from 'utils/func/Number.func';

interface InvoicesTableProps {
  toPayTab: boolean;
  invoices: any;
  pagination: GenericPaginator;
  onPaginationChange: (pagination: GenericPaginator) => void;
  isLoading: boolean;
}

const InvoicesTable: FC<InvoicesTableProps> = ({ toPayTab, invoices, pagination, onPaginationChange, isLoading }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();

  const tableHeaders: GenericHeader[] = useMemo(
    () => [
      { id: 'reference', label: 'invoices.table.reference' },
      { id: 'date', label: 'invoices.table.date' },
      {
        id: 'amount',
        label: toPayTab ? 'invoices.table.amount_to_pay' : 'invoices.table.amount_paid',
        alignRight: true,
      },
      { id: 'actions', label: undefined },
    ],
    [toPayTab],
  );

  return (
    <GenericTable {...(isMobileView ? { noHeaders: true } : { headers: tableHeaders })} pagination={pagination} setPagination={onPaginationChange}>
      {!isLoading && invoices ? (
        invoices.length ? (
          invoices
            .filter((invoice: any) => invoice.status === (toPayTab ? InvoiceStatus.OPEN : InvoiceStatus.PAID))
            .map((invoice: any) =>
              isMobileView ? (
                <TableRow hover key={`${invoice.id}-mobile`}>
                  <TableCell>
                    <Grid container spacing={0} direction='row' alignItems='center' justifyContent='space-between'>
                      <Grid item>
                        <Grid container direction='column' spacing={0}>
                          <Grid item xs>
                            <Grid container direction='row' spacing={1}>
                              <Grid item xs>
                                <Typography variant='body1'>{invoice.dossier_reference}</Typography>
                              </Grid>
                              <Grid item>
                                <Divider orientation='vertical' sx={{ borderColor: (theme) => theme.palette.text.primary }} />
                              </Grid>
                              <Grid item xs>
                                <Typography variant='body1'>{convertDate(invoice.date)}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <ContextLink to='INVOICE_DETAILS' params={{ id: invoice.dossier_reference }}>
                          <IconButton>
                            <SvgIcon component={IconNext} sx={{ fontSize: 16 }}></SvgIcon>
                          </IconButton>
                        </ContextLink>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow hover key={invoice.id}>
                  <TableCell>
                    <Typography variant='body1'>{invoice.reference}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='body1'>{convertDate(invoice.date)}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='body1' align='right'>
                      {`${formatToLocaleNumber(invoice.amount, true)} ${invoice.amount ? invoice.currency : ''}`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <ContextLink to='INVOICE_DETAILS' params={{ id: invoice.dossier_reference }}>
                      <Button variant='text'>{t('invoices.table.details')}</Button>
                    </ContextLink>
                  </TableCell>
                </TableRow>
              ),
            )
        ) : (
          <NoResultsTableRow colSpan={tableHeaders.length} noResultText={'common.noResult'} />
        )
      ) : (
        <TableContentSkeleton columns={tableHeaders.length} rows={4} />
      )}
    </GenericTable>
  );
};

export default InvoicesTable;
