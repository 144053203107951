import { Grid } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import PatientDetails from 'components/patient-details';
import { useContextRedirection, useNotification } from 'hooks';
import { Patient } from 'models/patients.model';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { tryCatch } from 'utils';
import RoutePaths from 'utils/RoutePaths';
import ValidationConfirm from './ValidationConfirm';
import ValidationPdfGenerator from './ValidationPdfGenerator';

const PrescriptionValidation: FC = () => {
  const { t } = useTranslation();
  const [patient, setPatient] = useState<Patient>();
  const navigate = useContextRedirection();
  const { idPrescription } = useParams();
  const { notification } = useNotification();

  const loadBasketAnalysis = useCallback(() => {
    idPrescription &&
      tryCatch(
        () =>
          api.prescriptions.getPrescriptionByBasketId(idPrescription).then((response) => {
            api.patients
              .getPatientDetails(response.patient_id)
              .then((patientDetails) => {
                setPatient(patientDetails);
              })
              .catch(() => notification(t('patient_details.notification.error'), 'error'));
          }),
        () => {
          notification(t('prescriptions_making.step2.yourSelection.notification.errorWhenLoadingPrescription'), 'error');
          navigate(RoutePaths['NOT_FOUND']);
        },
      );
  }, [idPrescription, navigate, notification, t]);

  useEffect(() => {
    loadBasketAnalysis();
  }, [loadBasketAnalysis]);

  return (
    <BodyTemplate title={t('bodyTemplate.doctor.makePrescription')}>
      <Grid container>
        <Grid item xs={12}>
          <PatientDetails patient={patient} showPatientRecordAction showNewPrescriptionAction />
        </Grid>
        <Grid item xs={12}>
          {patient && <ValidationConfirm patientId={patient.id.toString()} idPrescription={idPrescription} />}
        </Grid>
        <Grid item xs={12}>
          {idPrescription && <ValidationPdfGenerator idPrescription={idPrescription} />}
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default PrescriptionValidation;
