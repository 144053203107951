import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Divider, IconButton, SvgIcon, Tooltip, Typography } from '@mui/material';
import api from 'api';
import { useTranslation } from 'react-i18next';
import { Prescription, PrescriptionOptions } from 'models/prescription.model';
import { FC, Fragment, useEffect } from 'react';
import { ReactComponent as IconClose } from 'resources/icons/close.svg';
import { useContextRedirection } from 'hooks';
import RoutePaths from 'utils/RoutePaths';
import AnalysisMotivationForm from './AnalysisMotivationForm';
import { useForm, useFieldArray } from 'react-hook-form';
import { formatToLocaleCurrency } from 'utils/func/Number.func';

const AnalysisRecap: FC<{
  selectedPrescription: Prescription;
  idPrescription: string;
  disabled: boolean;
  setDisabled: any;
  setSelectedPrescription: any;
  prescriptionDeletedCallback: () => void;
  loadOnAddingPrescription: boolean;
  setloadOnAddingPrescription: (bool: boolean) => void;
}> = ({ disabled, setDisabled, selectedPrescription, idPrescription, setSelectedPrescription, prescriptionDeletedCallback, loadOnAddingPrescription, setloadOnAddingPrescription }) => {
  const { t } = useTranslation();
  const navigate = useContextRedirection();

  const handleValidate = (data: { options: PrescriptionOptions[] }) => {
    let options = data.options.map((e: PrescriptionOptions) => {
      let draft: any = {
        analysis_reference: e.analysis_reference,
        options: [
          {
            question_tag: e.question_tag,
            value: e.motivation_code,
          },
        ],
      };
      if (e.is_free_text) draft.options[0]['other'] = e.free_text?.trim();
      return draft;
    });

    if (idPrescription) {
      setloadOnAddingPrescription(true);
      api.prescriptions
        .postCalculate(idPrescription, options)
        .then((res) => setSelectedPrescription(res))
        .catch(() => t('prescriptions_making.step1.notification.error'))
        .finally(() => {
          setDisabled(true);
          setloadOnAddingPrescription(false);
        });
    }
  };
  const { handleSubmit, control, watch, formState, setValue } = useForm<{ options: PrescriptionOptions[] }>({
    mode: 'onChange',
    defaultValues: {
      options: selectedPrescription.items
        .filter((el) => el.to_motivate)
        .flatMap((e) => {
          if (e.options.length > 0) {
            return e.options.map((option) => ({
              analysis_reference: e.analysis_reference,
              analysis: e,
              motivation_code: option.code,
              question_tag: option.tag,
              is_free_text: option.other ? true : false,
              free_text: option.other ? option.other : '',
            }));
          }
          return {
            analysis_reference: e.analysis_reference,
            analysis: e,
            motivation_code: '',
            question_tag: '',
            is_free_text: false,
            free_text: '',
          };
        }),
    },
  });
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'options',
  });
  const dataOptions = watch();
  const handleNextStep = () => {
    if (idPrescription) {
      navigate(RoutePaths['DOCTOR_PRESCRIPTION_DETAILS_COMPLETION']({ id: idPrescription }));
    }
  };

  const handleDeleteAllElement = () => {
    if (idPrescription) {
      setloadOnAddingPrescription(true);
      api.prescriptions
        .deleteAllAnalysisFromPrescription(idPrescription)
        .then((res) => setSelectedPrescription(res.data))
        .catch(() => t('prescriptions_making.step1.notification.error'))
        .finally(() => setloadOnAddingPrescription(false));
    }
  };

  const handleDeleteAnalysis = (itemId: string) => {
    if (idPrescription) {
      setloadOnAddingPrescription(true);
      api.prescriptions
        .deleteAnalysisById(idPrescription, itemId)
        .then((res) => setSelectedPrescription(res.data))
        .catch(() => t('prescriptions_making.step1.notification.error'))
        .finally(() => {
          setloadOnAddingPrescription(false);
          let index = fields.findIndex((e) => e.analysis_reference === itemId);
          if (index > -1) remove(index);
        });
    }
  };

  const handleDeleteOrder = () => {
    if (idPrescription) {
      setloadOnAddingPrescription(true);
      api.prescriptions
        .deletePrescription(idPrescription)
        .then(() => {
          prescriptionDeletedCallback();
        })
        .catch(() => t('prescriptions_making.step1.notification.error_delete'))
        .finally(() => setloadOnAddingPrescription(false));
    }
  };

  const handleModifyPrescription = () => {
    if (idPrescription) {
      setloadOnAddingPrescription(true);
      api.prescriptions
        .getPrescriptionByBasketId(idPrescription)
        .then((res) => setSelectedPrescription(res.data))
        .catch(() => t('prescriptions_making.step1.notification.error'))
        .finally(() => {
          setloadOnAddingPrescription(false);
          setDisabled(false);
        });
    }
  };

  useEffect(() => {
    let diff_add = selectedPrescription.items.filter((el) => el.to_motivate && !dataOptions.options.some((obj) => el.analysis_reference === obj.analysis_reference));
    let diff_remove = dataOptions.options.filter((el) => !selectedPrescription.items.filter((el) => el.to_motivate).some((obj) => el.analysis_reference === obj.analysis_reference));
    if (diff_remove.length > 0) {
      diff_remove.forEach((item) => {
        let index = fields.findIndex((e) => e.analysis_reference === item.analysis_reference);
        if (index > -1) remove(index);
      });
    }
    if (diff_add.length > 0) {
      diff_add.map((analysisItem) =>
        append({
          analysis_reference: analysisItem.analysis_reference,
          analysis: analysisItem,
          motivation_code: '',
          question_tag: '',
          is_free_text: false,
          free_text: '',
        }),
      );
    }
  }, [selectedPrescription, append, dataOptions.options, fields, remove]);

  return loadOnAddingPrescription ? (
    <Card sx={{ textAlign: 'center' }}>
      <CircularProgress />
    </Card>
  ) : (
    <Card>
      <CardHeader
        title={
          <Typography variant='h2' sx={{ pb: 2 }}>
            {t('prescriptions_making.step1.yourSelection.title')}
          </Typography>
        }
      />
      <CardContent>
        {selectedPrescription && (
          <>
            {disabled && (
              <>
                {selectedPrescription.items.filter((el) => (el.price_state > 0 || (el.price_state === 0 && el.price_patient === 0 && el.price_patient_hn === 0)) && !el.do_not_bill).length > 0 && (
                  <Box sx={{ pt: 2 }}>
                    <Typography variant='h3' gutterBottom>
                      {t('prescriptions_making.step2.yourSelection.refundedAnalysis')}
                    </Typography>
                    {selectedPrescription.items
                      .filter((el) => (el.price_state > 0 || (el.price_state === 0 && el.price_patient === 0 && el.price_patient_hn === 0)) && !el.do_not_bill)
                      .map((item, key) => {
                        return (
                          <Fragment key={key}>
                            <Box display='flex' alignItems='center' justifyContent='space-between'>
                              <Typography>{item.title}</Typography>
                            </Box>
                          </Fragment>
                        );
                      })}
                  </Box>
                )}
                {selectedPrescription.items.filter((el) => el.do_not_bill).length > 0 && (
                  <Box sx={{ pt: 2 }}>
                    <Typography variant='h3' gutterBottom>
                      {t('prescriptions_making.step2.yourSelection.refundedAnalysisLNS')}
                    </Typography>
                    {selectedPrescription.items
                      .filter((el) => el.do_not_bill)
                      .map((item, key) => {
                        return (
                          <Fragment key={key}>
                            <Box display='flex' alignItems='center' justifyContent='space-between'>
                              <Typography>{item.title}</Typography>
                            </Box>
                          </Fragment>
                        );
                      })}
                  </Box>
                )}
                {selectedPrescription.items.filter((el) => el.price_patient > 0 || el.price_patient_hn > 0).length > 0 && (
                  <Box sx={{ pt: 2 }}>
                    <Typography variant='h3' gutterBottom>
                      {t('prescriptions_making.step2.yourSelection.toPayAnalysis')}
                    </Typography>
                    {selectedPrescription.items
                      .filter((el) => el.price_patient > 0)
                      .map((item, key) => {
                        return (
                          <Fragment key={key}>
                            <Box display='flex' alignItems='center' justifyContent='space-between'>
                              <Typography>{item.title}</Typography>
                              <Typography sx={{ width: '140px' }} textAlign='end' alignSelf='baseline'>
                                {formatToLocaleCurrency(item.price_patient, selectedPrescription.currency)}
                              </Typography>
                            </Box>
                          </Fragment>
                        );
                      })}
                    {selectedPrescription.items
                      .filter((el) => el.price_patient_hn > 0)
                      .map((item, key) => {
                        return (
                          <Fragment key={key}>
                            <Box display='flex' alignItems='center' justifyContent='space-between'>
                              <Typography>{item.title}</Typography>
                              <Tooltip enterTouchDelay={0} placement='left' title={t('prescriptions_making.step2.yourSelection.hn_info')}>
                                <Typography sx={{ textDecoration: 'underline' }} textAlign='end' alignSelf='baseline' fontSize={14}>
                                  HN
                                </Typography>
                              </Tooltip>
                            </Box>
                          </Fragment>
                        );
                      })}
                  </Box>
                )}
              </>
            )}
            {selectedPrescription.items.filter((el) => el.to_motivate).length > 0 && !disabled && (
              <>
                <Typography variant='h3' gutterBottom>
                  {t('prescriptions_making.step1.yourSelection.toMotivate')}
                </Typography>
                {fields.map((item, key) => {
                  return (
                    <Fragment key={key}>
                      <Box display='flex' alignItems='center' justifyContent='space-between'>
                        <Typography>{item.analysis.title}</Typography>
                        <IconButton onClick={() => handleDeleteAnalysis(item.analysis_reference)}>
                          <SvgIcon sx={{ fontSize: 16, path: { fill: (theme) => theme.palette.primary.main } }} component={IconClose} />
                        </IconButton>
                      </Box>
                      <AnalysisMotivationForm setValue={setValue} control={control} options={dataOptions.options} index={key} formState={formState} basketItem={item.analysis} />
                    </Fragment>
                  );
                })}
              </>
            )}
            {selectedPrescription.items.filter((el) => !el.to_motivate).length > 0 && !disabled && (
              <>
                {selectedPrescription.items.filter((el) => el.to_motivate).length > 0 && <Divider sx={{ my: 2 }} />}
                {selectedPrescription.items
                  .filter((el) => !el.to_motivate)
                  .map((item, key) => {
                    return (
                      <Box key={key} display='flex' alignItems='center' justifyContent='space-between'>
                        <Typography>{item.title}</Typography>
                        <IconButton
                          onClick={() => {
                            handleDeleteAnalysis(item.analysis_reference);
                          }}
                        >
                          <SvgIcon sx={{ fontSize: 16, path: { fill: (theme) => theme.palette.primary.main } }} component={IconClose} />
                        </IconButton>
                      </Box>
                    );
                  })}
              </>
            )}
            {!disabled && (
              <>
                {selectedPrescription.items.length > 0 && (
                  <>
                    <Button variant='contained' fullWidth onClick={handleSubmit(handleValidate)} sx={{ mt: 2 }}>
                      {t('common.action.validate')}
                    </Button>
                    <Button variant='outlined' fullWidth onClick={handleDeleteAllElement} sx={{ mt: 2 }}>
                      {t('prescriptions_making.step1.yourSelection.deleteAnalysis')}
                    </Button>
                  </>
                )}
                <Button variant='outlined' fullWidth onClick={handleDeleteOrder} sx={{ mt: 2 }}>
                  {t('prescriptions_making.step1.yourSelection.deleteOrder')}
                </Button>
              </>
            )}
            {disabled && (
              <>
                <Button variant='contained' fullWidth onClick={handleNextStep} sx={{ mt: 2 }}>
                  {t('prescriptions_making.step1.yourSelection.nextStep')}
                </Button>
                <Button variant='outlined' fullWidth onClick={handleModifyPrescription} sx={{ mt: 2 }}>
                  {t('prescriptions_making.step1.yourSelection.edit')}
                </Button>
              </>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default AnalysisRecap;
