import { Box, Container, Grid, IconButton, SvgIcon, Typography } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as IconBack } from 'resources/icons/back.svg';
import theme from 'resources/theme/theme.colors';

type BodyTemplateProps = {
  title?: string;
  children: ReactNode;
  hideHeaderOnMobile?: boolean;
  backButton?: boolean;
};

const BodyTemplate: (p: BodyTemplateProps) => ReactElement<BodyTemplateProps> = ({ title, children, hideHeaderOnMobile = false, backButton }) => {
  const navigate = useNavigate();

  return (
    <Grid item container spacing={0} sx={{ backgroundColor: 'background.default', flexGrow: 1 }} alignContent='baseline'>
      {title && (
        <Grid item xs={12} display={hideHeaderOnMobile ? { xs: 'none', md: 'flex' } : 'flex'}>
          <Box
            display='flex'
            alignItems='center'
            width='100%'
            sx={{
              padding: {
                xs: theme.spacing(2),
                md: theme.spacing(3),
              },
              position: 'relative',
              backgroundColor: (theme) => theme.palette.header.main,
            }}
          >
            {backButton && (
              <Box sx={{ position: 'absolute', height: 'auto' }}>
                <IconButton onClick={() => navigate(-1)}>
                  <SvgIcon component={IconBack} sx={{ fontSize: { xs: 16, sm: 24 } }} />
                </IconButton>
              </Box>
            )}
            <Box display='flex' width='100%' justifyContent='center'>
              <Typography variant='h1' textAlign='center'>
                {title}
              </Typography>
            </Box>
          </Box>
        </Grid>
      )}

      <Grid item xs={12}>
        <Container
          maxWidth={false}
          disableGutters
          sx={{
            padding: {
              xs: theme.spacing(2),
              sm: theme.spacing(4),
            },
          }}
        >
          {children}
        </Container>
      </Grid>
    </Grid>
  );
};

export default BodyTemplate;
