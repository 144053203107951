import { createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-disabled:not(.Mui-selected)': {
            color: '#DDDEEE',
          },
        },
      },
    },
  },
});

export default theme;
