import { Box, Button, TextField, Typography } from '@mui/material';
import api from 'api';
import AnalysisList from 'components/analysis-list';
import { useNotification } from 'hooks';
import { Analysis } from 'models/analysis.model';
import { AnalysisReference } from 'models/analysis.model';
import { Paginate } from 'models/utils.model';
import { FC, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ANALYSIS_PROFILE } from 'utils/Constants';

export type AnalysisSelectionType = {
  analysis_reference: string;
  id: number;
  name: string;
};

export const AnalysisSelection: FC<{
  handleSelectElement: (item: AnalysisReference[]) => void;
  disabled: boolean;
}> = ({ handleSelectElement, disabled }) => {
  const { register, handleSubmit } = useForm<{ analysis_name: string }>();
  const [analysis, setAnalysis] = useState<Analysis[]>();
  const { t } = useTranslation();
  const { notification } = useNotification();

  const onSubmit: SubmitHandler<{ analysis_name: string }> = useCallback(
    (data) => {
      api.analysis
        .getAnalysis(ANALYSIS_PROFILE.MAX_ANALYSIS, 0, data.analysis_name)
        .then((response: Paginate<Analysis>) => {
          setAnalysis(response.results);
        })
        .catch(() => {
          notification(t('prescriptions_profile.add_analysis.notification.error'), 'error');
        });
    },
    [notification, t],
  );

  return (
    <Box py={2}>
      <Typography variant='h3' mb={3}>
        {t('prescriptions_making.step1.search_analysis.title')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex' }}>
        <TextField
          variant='filled'
          fullWidth
          disabled={disabled}
          sx={{ pr: 2 }}
          label={t('prescriptions_making.step1.search_analysis.analysis_label')}
          defaultValue={''}
          {...register('analysis_name', {
            required: true,
          })}
        />
        <Button disabled={disabled} variant='contained' type='submit'>
          {t('prescriptions_making.step1.search_analysis.searchBtn')}
        </Button>
      </form>
      <AnalysisList disabled={disabled} analysisList={analysis} onSelect={handleSelectElement} />
    </Box>
  );
};
