import { APIConfiguration, axiosMYLAB } from 'api';
import { PreregistrationUserData } from 'models/preregistration.model';
import { BaseService } from './base.service';

class PreRegistrationService extends BaseService {
  async preregister(userData: PreregistrationUserData[]) {
    const { data } = await axiosMYLAB.post(this.apiConfig.PREREGISTRATION.PRE_REGISTER(this.getLang()), { patients: userData });

    this.log(`myLabPreRegister()`);

    return data;
  }
}

export default (apiConfig: APIConfiguration) => {
  return new PreRegistrationService(apiConfig);
};
