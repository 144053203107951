import { nopeResolver } from '@hookform/resolvers/nope';
import { InfoOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, CardContent, Grid, IconButton, TextField, Typography, useTheme } from '@mui/material';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import ContextRedirect from 'components/_base/ContextRedirect';
import { useAuthenticated, useContextRedirection, useNotification } from 'hooks';
import { InvoiceDisconnectedRequest, InvoicesResult } from 'models/invoices.model';
import Nope from 'nope-validator';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import StripeLogo from 'resources/images/logo_stripe.svg';
import { buildShape } from 'utils';
import { formatToLocaleCurrency } from 'utils/func/Number.func';
import RoutePaths from 'utils/RoutePaths';

const InvoicesDisconnected: FC = () => {
  const { t } = useTranslation();
  const { notification } = useNotification();
  const navigate = useContextRedirection();
  const isAuthenticated = useAuthenticated();
  const theme = useTheme();

  const stripe = useStripe();
  const elements = useElements();
  const [invoice, setInvoice] = useState<InvoicesResult | null>(null);
  const [processing, setProcessing] = useState(false);
  const [paymentInvalid, setPaymentInvalid] = useState(false);

  const CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
    style: {
      base: {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: `${theme.typography.body1.fontSize}px`,
      },
    },
    classes: {
      base: 'customStripe',
    },
    hidePostalCode: true,
    disabled: processing,
  };

  const InvoiceDisconnectedSchema = Nope.object().shape(
    buildShape<InvoiceDisconnectedRequest>({
      reference: Nope.string().required(),
      amount: Nope.number().positive().required(),
      email: Nope.string().email(),
    }),
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<InvoiceDisconnectedRequest>({
    resolver: nopeResolver(InvoiceDisconnectedSchema),
  });
  const onSubmit = (data: InvoiceDisconnectedRequest) => {
    api.invoices
      .getInvoiceByReferenceAndAmount(data.reference, data.amount, data.email)
      .then((data) => {
        setInvoice(data);
      })
      .catch(() => notification(t('invoices.disconnected.notification.error'), 'error'));
  };

  const handlePayment = async () => {
    setProcessing(true);

    if (!stripe || !elements || !invoice) {
      return;
    }

    const result = await stripe.confirmCardPayment(invoice.client_secret, {
      payment_method: {
        card: elements.getElement(CardElement)!,
      },
    });

    if (result.error) {
      notification(`${result.error.message}`, 'error');
      setPaymentInvalid(true);
      setProcessing(false);
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        // TOFIX: wait 1s because of Stripe async call and API do not wait till the end
        setTimeout(() => {
          navigate(RoutePaths['LANDING_PAGE']);
          notification(`${t('invoices.details.notification.successPayment')}`, 'success');
        }, 1000);
      }
    }
  };

  return isAuthenticated ? (
    <ContextRedirect to={RoutePaths['HOME']} />
  ) : (
    <BodyTemplate title={t(`bodyTemplate.invoicesDisconnected`)} backButton>
      <Card>
        {!invoice ? (
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container justifyContent='center' spacing={2}>
                <Grid item xs={12} md={8}>
                  <TextField
                    variant='filled'
                    {...register('reference')}
                    defaultValue={undefined}
                    label={t('invoices.disconnected.form.reference')}
                    error={errors?.reference !== undefined}
                    fullWidth
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    variant='filled'
                    {...register('amount')}
                    label={t('invoices.disconnected.form.amount')}
                    error={errors?.amount !== undefined}
                    type='number'
                    inputProps={{ step: 0.01 }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField variant='filled' {...register('email')} label={t('invoices.disconnected.form.email')} error={errors?.email !== undefined} fullWidth />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography variant='caption'>{t('invoices.disconnected.form.emailHelper')}</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Button type='submit' variant='contained' fullWidth>
                    {t('invoices.disconnected.form.payButton')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        ) : (
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h3'>{`${t('invoices.details.title')} ${invoice.reference}`}</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Box
                  sx={{
                    ...(theme.components?.MuiFilledInput?.styleOverrides?.root as object),
                    ...(paymentInvalid && {
                      borderColor: theme.palette.error.main,
                    }),
                    height: '100%',
                  }}
                >
                  <CardElement options={CARD_ELEMENT_OPTIONS} onReady={(e) => e.focus()} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <LoadingButton variant='contained' disabled={!stripe || processing} loading={processing} fullWidth onClick={handlePayment}>
                  {`${t('invoices.details.pay.label')} ${formatToLocaleCurrency(invoice.amount)}`}
                </LoadingButton>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1' sx={{ fontStyle: 'italic' }} display='flex' alignItems='center'>
                  <InfoOutlined sx={{ mr: 1 }} />
                  {t('invoices.details.pay.stripeHelper')}{' '}
                  <IconButton href='https://stripe.com/' target='_blank'>
                    <Box component='img' alt={t('invoices.details.pay.altStripeLogo')} src={StripeLogo} sx={{ height: '0.75em' }} />
                  </IconButton>
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
    </BodyTemplate>
  );
};

export default InvoicesDisconnected;
