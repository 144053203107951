import { LoadingButton } from '@mui/lab';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, SvgIcon, TextField } from '@mui/material';
import api from 'api';
import { useIsMobileView, useNotification } from 'hooks';
import { ContactRequest } from 'models/contact-request.model';
import { FC, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconClose } from 'resources/icons/close.svg';

type Props = {
  open: boolean;
  setOpen: (bool: boolean) => any;
};

const ContactDialog: FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const { notification } = useNotification();

  const [submitting, setSubmitting] = useState(false);
  const { register, handleSubmit, formState, reset } = useForm<ContactRequest>({
    mode: 'onChange',
  });

  const handleClose = useCallback(() => {
    setOpen(false);
    reset({ subject: '', message: '' });
  }, [reset, setOpen]);

  const onSubmit: SubmitHandler<ContactRequest> = useCallback(
    (formData) => {
      setSubmitting(true);
      const requestData: ContactRequest = {
        subject: formData.subject,
        message: formData.message,
      };
      api.contact
        .postContact(requestData)
        .then(() => {
          handleClose();
          notification(t('contact.notifications.success'), 'success');
        })
        .catch((error) => {
          notification(t('contact.notifications.error'), 'error');
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [handleClose, notification, t],
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          minWidth: { xs: '85vw', md: '50vw' },
          maxWidth: 'fit-content',
        },
      }}
    >
      <DialogTitle sx={{ position: 'relative' }}>
        <Box component='span'>{t('contact.title')}</Box>
        <IconButton size='small' onClick={handleClose} sx={{ position: 'absolute', zIndex: 2, top: 2, right: 2 }}>
          <SvgIcon fontSize='small' component={IconClose} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: 'grid', gridTemplateRows: '1fr 6fr 1fr', gap: 2 }}>
            <TextField variant='filled' {...register('subject', { required: true })} defaultValue={undefined} label={t('contact.form.subject')} fullWidth />
            <TextField
              variant='filled'
              multiline
              {...register('message', { required: true })}
              defaultValue={undefined}
              label={t('contact.form.message')}
              fullWidth
              sx={{
                '&>.MuiFilledInput-root': {
                  height: '100%',
                  '&>.MuiFilledInput-input': {
                    height: '100% !important',
                    overflow: 'auto !important',
                  },
                },
              }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton type='submit' variant='contained' fullWidth={isMobileView} disabled={!formState.isValid} loading={submitting}>
                {t('contact.form.submit')}
              </LoadingButton>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};
export default ContactDialog;
