import { Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, SvgIcon, TextField } from '@mui/material';
import api from 'api';
import { useNotification } from 'hooks';
import { PasswordUpdateProps } from 'models/profile.model';
import { FC, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const PasswordUpdate: FC<{ closeModal: () => void }> = ({ closeModal }) => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm<PasswordUpdateProps>();
  const [showPassword, toggleShowPassword] = useState<boolean>(false);
  const [confirmShowPassword, toggleConfirmShowPassword] = useState<boolean>(false);
  const { notification } = useNotification();

  const handleClickShowPassword = () => {
    toggleShowPassword(!showPassword);
  };

  const handleClickConfirmShowPassword = () => {
    toggleConfirmShowPassword(!confirmShowPassword);
  };

  const onSubmit: SubmitHandler<PasswordUpdateProps> = useCallback(
    (data) => {
      api.users
        .updatePassword(data)
        .then((response) => {
          closeModal();
          notification(t('profile.updatePassword.notification.success'), 'success');
        })
        .catch((e) => {
          notification(t('profile.updatePassword.notification.error'), 'error');
        });
    },
    [closeModal, notification, t],
  );

  return (
    <>
      <DialogTitle>{t('profile.update.actions.modify.password')}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={0} flexDirection='column'>
          <DialogContent>
            <Grid item xs={12} py={1}>
              <TextField
                variant='filled'
                {...register('password')}
                type={showPassword ? 'text' : 'password'}
                label={t('profile.updatePassword.form.newPassword')}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SvgIcon component={Lock} sx={{ fontSize: 24 }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={handleClickShowPassword} edge='end'>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
              />
            </Grid>
            <Grid item xs={12} py={1}>
              <TextField
                variant='filled'
                {...register('password_confirm')}
                type={confirmShowPassword ? 'text' : 'password'}
                label={t('profile.updatePassword.form.confirmNewPassword')}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SvgIcon component={Lock} sx={{ fontSize: 24 }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={handleClickConfirmShowPassword} edge='end'>
                        {confirmShowPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12} textAlign='right'>
              <Button variant='contained' type='submit'>
                {t('common.action.validate')}
              </Button>
            </Grid>
          </DialogActions>
        </Grid>
      </form>
    </>
  );
};

export default PasswordUpdate;
