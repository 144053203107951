import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider, deDE, frFR, ptBR, enUS } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config from 'config';
import { FC } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-GB';
import frLocale from 'date-fns/locale/fr';
import ptLocale from 'date-fns/locale/pt';
import MyLabTheme from 'resources/theme/theme';
import store from 'store';
import AppLayout from './components/_layout';
import { useTranslation } from 'react-i18next';

// Load stripe for online payment
const stripePromise = loadStripe(config.stripeApiKey!);

const App: FC = () => {
  const { i18n } = useTranslation();

  const locale: string = i18n.language.split('-').shift() ?? 'en';

  const localeMap: { [key: string]: Locale } = {
    en: enLocale,
    fr: frLocale,
    de: deLocale,
    pt: ptLocale,
  };

  const localeTextMap: { [key: string]: any } = {
    en: enUS.components.MuiLocalizationProvider.defaultProps.localeText,
    fr: frFR.components.MuiLocalizationProvider.defaultProps.localeText,
    de: deDE.components.MuiLocalizationProvider.defaultProps.localeText,
    pt: ptBR.components.MuiLocalizationProvider.defaultProps.localeText,
  };

  return (
    <Elements stripe={stripePromise}>
      <LocalizationProvider adapterLocale={localeMap[locale]} dateAdapter={AdapterDateFns} localeText={localeTextMap[locale]}>
        <ThemeProvider theme={MyLabTheme}>
          <CssBaseline />
          <Provider store={store}>
            <Router>
              <AppLayout />
            </Router>
          </Provider>
        </ThemeProvider>
      </LocalizationProvider>
    </Elements>
  );
};

export default App;
