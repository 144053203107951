import { Box, Dialog, DialogContent, DialogTitle, IconButton, SvgIcon, Typography } from '@mui/material';
import AnalysisAttributes from 'components/analysis-attributes';
import { Analysis } from 'models/analysis.model';
import { FC } from 'react';
import { ReactComponent as IconClose } from 'resources/icons/close.svg';

type Props = {
  analysis?: Analysis;
  open: boolean;
  setOpen: (bool: boolean) => any;
};

const AnalysisAttributesDialog: FC<Props> = ({ analysis, open, setOpen }) => {
  return (
    <>
      {analysis && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          sx={{
            '& .MuiPaper-root': {
              maxWidth: 'fit-content',
            },
          }}
        >
          <DialogTitle sx={{ position: 'relative' }}>
            <Typography fontSize={18} fontWeight={700}>
              {analysis.name}
            </Typography>
            <IconButton
              size='small'
              onClick={() => setOpen(false)}
              sx={{
                position: 'absolute',
                zIndex: 2,
                top: 1,
                right: 1,
              }}
            >
              <SvgIcon fontSize='small' component={IconClose} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box position='relative' display='flex' justifyContent='center' alignItems='center'>
              <AnalysisAttributes analysis={analysis} />
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default AnalysisAttributesDialog;
