import { LeftDrawerItem } from 'models/utils.model';
import { ReactComponent as CatalogueAnalyseIcon } from 'resources/icons/catalogue_analyse.svg';
import { ReactComponent as AppointmentIcon } from 'resources/icons/pickendoheem.svg';
import { ReactComponent as DashboardIcon } from 'resources/icons/home.svg';
import { ReactComponent as PatientsIcon } from 'resources/icons/patients.svg';
import { ReactComponent as PrescriptionIcon } from 'resources/icons/prescription.svg';
import { ReactComponent as ResultIcon } from 'resources/icons/resultat.svg';

export const leftDrawerMenuDoctor = (): LeftDrawerItem[] => [
  {
    title: 'bodyTemplate.dashboard',
    link: 'HOME',
    icon: DashboardIcon,
  },
  {
    title: 'bodyTemplate.doctor.patients',
    link: 'DOCTOR_PATIENTS',
    icon: PatientsIcon,
  },
  {
    title: 'common.results',
    link: 'DOCTOR_RESULTS',
    icon: ResultIcon,
  },
  {
    title: 'bodyTemplate.doctor.prescriptions',
    link: 'DOCTOR_PRESCRIPTION',
    icon: PrescriptionIcon,
  },
  {
    title: 'bodyTemplate.patient.appointments',
    link: 'APPOINTMENT',
    icon: AppointmentIcon,
  },
  {
    title: 'bodyTemplate.doctor.analyses',
    link: 'ANALYSIS_CATALOG',
    icon: CatalogueAnalyseIcon,
  },
];
