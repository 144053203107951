import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, List, ListItemButton, ListItemText, Pagination, Typography } from '@mui/material';
import { useIsMobileView } from 'hooks';
import { Center, CenterIncludedFile, CenterIncludedService } from 'models/centers.model';
import { FC, useState } from 'react';
import CenterDetails from './CenterDetails';

interface Props {
  centersInfo: {
    center: Center;
    file: CenterIncludedFile;
    services: CenterIncludedService[];
  }[];
  handleOpenMapMarker?: (index: number) => void;
}

const CentersList: FC<Props> = ({ centersInfo, handleOpenMapMarker }) => {
  const isMobileView = useIsMobileView();
  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <>
      {!isMobileView && (
        <List sx={{ pt: 0 }}>
          {centersInfo.slice((page - 1) * 5, (page - 1) * 5 + 5).map((info, i) => {
            return (
              <ListItemButton
                key={`center-${i}`}
                {...(handleOpenMapMarker !== undefined && {
                  onClick: () => handleOpenMapMarker((page - 1) * 5 + i),
                })}
              >
                <ListItemText
                  primary={info.center.attributes.title}
                  secondary={`${info.center.attributes.field_prelevement_numero_rue}, ${info.center.attributes.field_prelevement_rue} ${info.center.attributes.field_prelevement_code_postal} ${info.center.attributes.field_prelevement_ville}`}
                />
              </ListItemButton>
            );
          })}
        </List>
      )}
      {isMobileView &&
        centersInfo.slice((page - 1) * 5, (page - 1) * 5 + 5).map((info, i) => {
          return (
            <Accordion key={`center-${i}`}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography variant='h2'>{info.center.attributes.title}</Typography>
                  <Typography variant='body1'>
                    {`${info.center.attributes.field_prelevement_numero_rue}, ${info.center.attributes.field_prelevement_rue} ${info.center.attributes.field_prelevement_code_postal} ${info.center.attributes.field_prelevement_ville}`}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ mt: 0 }}>
                <CenterDetails centerInfo={info} hideHeader />
              </AccordionDetails>
            </Accordion>
          );
        })}
      <Pagination page={page} count={Math.ceil(centersInfo.length / 5)} onChange={handleChange} sx={{ mb: 1, '> ul': { justifyContent: 'center' } }} />
    </>
  );
};

export default CentersList;
