import theme_color from 'resources/theme/theme.colors';

import { createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          width: 'auto',
        },
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'medium',
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:not(:first-of-type)': {
            borderTop: '2px solid',
            borderTopColor: theme_color.palette.divider,
          },
          '&:first-of-type': {
            border: 'none',
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: '2px solid',
          borderBottomColor: theme_color.palette.primary.main,
        },
      },
    },
  },
});

export default theme;
