import { Box, Card, CardContent, Grid, IconButton, SvgIcon, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { FC } from 'react';

import { useContextRedirection } from 'hooks';
import { ReactComponent as IconAbnormal } from 'resources/icons/anormaux.svg';
import { ReactComponent as IconStar } from 'resources/icons/favorite.svg';
import { ReactComponent as IconStarOutline } from 'resources/icons/favorite_outline.svg';
import { ReactComponent as IconNext } from 'resources/icons/next.svg';
import { ReactComponent as IconUrgent } from 'resources/icons/urgent.svg';
import { PRIORITY } from 'utils/Constants';
import RoutePaths from 'utils/RoutePaths';
import { useTranslation } from 'react-i18next';

const MobileTable: FC<{
  data: any[];
  setTagged: (reference: string) => void;
}> = ({ data, setTagged }) => {
  const navigate = useContextRedirection();
  const { t } = useTranslation();

  const handleOnClick = (reference: string) => {
    navigate(`${RoutePaths['RESULTS']}/${reference}`);
  };

  return (
    <Card>
      <CardContent>
        <TableContainer>
          <Table>
            <TableBody>
              {data &&
                data.map((elem: any, i: number) => (
                  <TableRow hover key={i}>
                    <TableCell>
                      <Box paddingY={2} position='relative'>
                        <Grid container spacing={0} alignItems='center'>
                          <Grid item xs={2}>
                            <Grid container spacing={0} alignItems='center'>
                              <Grid item xs={6}>
                                <IconButton onClick={() => setTagged(elem.reference)}>
                                  <SvgIcon fontSize='small' component={elem.tagged ? IconStar : IconStarOutline} />
                                </IconButton>
                              </Grid>

                              <Grid item xs={6}>
                                {elem.has_abnormal_result && <SvgIcon fontSize='small' component={IconAbnormal} />}
                                {elem.priority === PRIORITY.URGENT && <SvgIcon fontSize='small' component={IconUrgent} />}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={9} onClick={() => handleOnClick(elem.reference)}>
                            <Typography
                              sx={{
                                fontWeight: 'bold',
                              }}
                            >
                              {`${elem.patient.first_name} ${elem.patient.last_name}`}
                            </Typography>
                            {elem.issuer ? (
                              <Typography>{`${t('result_doctor.doctor')} ${elem.issuer.first_name} ${elem.issuer.last_name}`}</Typography>
                            ) : (
                              <Typography>{t('common.missingData')}</Typography>
                            )}
                            <Typography>{`${elem.reference} | ${elem.prescription_date ? `${elem.prescription_date} | ` : ''}${t(
                              //@ts-ignore
                              `result_doctor.${elem.status}`,
                            )}`}</Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton>
                              <SvgIcon
                                color='primary'
                                component={IconNext}
                                sx={{
                                  fontSize: 16,
                                }}
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default MobileTable;
