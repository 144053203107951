import { TableCell, Typography } from '@mui/material';
import { Result } from 'models/result.model';
import { FC } from 'react';

const DetailTabPanelBodyIsComment: FC<{ val: Result }> = ({ val }) => {
  return (
    <TableCell colSpan={4}>
      <Typography variant='body1' color={val.is_abnormal ? 'error.main' : ''} fontWeight={val.is_abnormal ? 'bold' : ''} style={{ whiteSpace: 'pre-line' }}>
        {val.value.split('<br />').join('  ')}
      </Typography>
    </TableCell>
  );
};

export default DetailTabPanelBodyIsComment;
