export const blobToBase64 = (blob: Blob) => {
  return new Promise<string>((resolve, _) => {
    var reader = new FileReader();
    reader.onload = function () {
      var dataUrl = reader.result;
      var base64 = '';
      if (dataUrl && typeof dataUrl === 'string') {
        base64 = dataUrl.split(',')[1];
      }
      resolve(base64);
    };
    reader.readAsDataURL(blob);
  });
};

export const downloadBlob = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.parentNode!.removeChild(link);
};
