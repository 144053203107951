import { createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiList: {
      defaultProps: {
        dense: false,
      },
    },
  },
});

export default theme;
