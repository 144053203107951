import { APIConfiguration, axiosMYLAB } from 'api';
import { DSPDocument, DSPDocumentRaw, DSPStatus, getDSPDocumentListRequest } from 'models/myDSP.model';
import { MYDSP } from 'utils/Constants';
import { BaseService } from './base.service';

class MyDSPService extends BaseService {
  async checkIfDSPExists(patientId: number) {
    const { data } = await axiosMYLAB.get<DSPStatus>(this.apiConfig.MYDSP.CHECK_IF_EXISTS(this.getLang()), {
      params: {
        patient_id: patientId,
      },
    });

    this.log(`checkIfExists()`);

    return data;
  }

  async getDSPDocuments(patientId: number) {
    const { data } = await axiosMYLAB.get<DSPDocumentRaw[]>(this.apiConfig.MYDSP.GET_DOCUMENTS(this.getLang()), {
      params: {
        patient_id: `${patientId}`,
        reference: 0,
        type_code: MYDSP.TYPE_CODE['BIOLOGICAL_EXAMINATIONS_RESULTS'],
        type_coding_scheme: MYDSP.TYPE_CODE_SCHEME,
        since: null,
        exclude_internals: 0,
      } as getDSPDocumentListRequest,
    });

    this.log(`getDSPDocuments()`);

    return data.map((doc) => this.convertDSPDocumentRaw(doc)).reverse();
  }

  async getDSPDocumentPDF(uuid: string) {
    const { data } = await axiosMYLAB.get<Blob>(this.apiConfig.MYDSP.GET_DOCUMENT_PDF(this.getLang(), uuid), { responseType: 'blob' });

    this.log(`getDSPDocumentPdf()`);

    return data;
  }

  private convertDSPDocumentRaw(documentRaw: DSPDocumentRaw): DSPDocument {
    let institution;
    if (documentRaw.author_institution) {
      institution = documentRaw.author_institution.split('^').shift();
    }
    let person;
    if (documentRaw.author_person) {
      let data = documentRaw.author_person.split('^');
      person = `${data[1]} ${data[2]}`;
    }
    return {
      id: documentRaw.id,
      uuid: documentRaw.uuid,
      typeCode: documentRaw.type_code,
      typeScheme: documentRaw.type_scheme,
      format: documentRaw.format_code,
      creationTime: documentRaw.creation_time,
      name: documentRaw.name || '',
      authorInstitution: institution,
      authorPerson: person,
    };
  }
}

export default (apiConfig: APIConfiguration) => {
  return new MyDSPService(apiConfig);
};
