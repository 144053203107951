import { Box, Card, CardContent, CardHeader, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import MyDSPLogo from 'resources/images/logo_mydsp.svg';

const LoadingDSP: FC = () => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardHeader title={<Box component='img' alt={t('myDSP.alt')} src={MyDSPLogo} sx={{ height: '12rem', width: '100%' }} />}></CardHeader>
      <CardContent>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant='h2' sx={{ py: 2 }}>
            {t('myDSP.loadingDSP.title')}
          </Typography>
          <CircularProgress />
        </Box>
      </CardContent>
    </Card>
  );
};
export default LoadingDSP;
