import { createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiFilledInput-root': {
            paddingTop: '15px',
            paddingLeft: '24px',
          },
        },
      },
    },
  },
});

export default theme;
