import { Box, Grid, Typography } from '@mui/material';
import { FC } from 'react';

const GraphLegend: FC<{ color: any; title: string }> = ({ color, title }) => {
  return (
    <Grid item xs={4}>
      <Box
        marginX='auto'
        sx={{
          width: 30,
          height: 30,
          borderRadius: 30,
          backgroundColor: color,
        }}
      ></Box>
      <Typography>{title}</Typography>
    </Grid>
  );
};
export default GraphLegend;
