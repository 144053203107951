import theme_color from 'resources/theme/theme.colors';

import { alpha, createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          maxHeight: '300px',
          '& .MuiMenu-paper': {
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.16)',
          },
          '& .MuiMenu-list': {
            backgroundColor: theme_color.palette.background.paper,
            borderRadius: '10px',
            padding: '16px 0',
          },
          '& .MuiMenuItem-root': {
            padding: '8px 16px',
            '&:hover': {
              backgroundColor: alpha(theme_color.palette.primary.main, 0.2),
            },
            '&.Mui-selected': {
              backgroundColor: alpha(theme_color.palette.primary.main, 0.4),
            },
          },
        },
      },
    },
  },
});

export default theme;
